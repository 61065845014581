import React, { useState, useEffect } from "react";
import {
  CardContent,
  CardActions,
  Grid,
  TextField,
  Box,
  Typography,
  IconButton,
} from "@material-ui/core";
import { ErrorMessage,Formik } from "formik";
import { useSnackbar } from "notistack";
import Card from "../../../components/Card";
import { makeStyles } from "@material-ui/core/styles";
import { Autocomplete } from "@material-ui/lab";
import ButtonWithLoading from "../../../components/ButtonWithLoading";
import { useDispatch, useSelector } from "react-redux";
import * as Yup from "yup";
import { useNavigate } from "react-router-dom";
import CircularProgress from "@material-ui/core/CircularProgress";
import { Get } from "../../../actions";
import {
  GET_AllCompaniesList_URL,
  Get_AllCompanyRepresentativeContacts_URL,
} from "../../../constants/apiUrls";
import { CountrySelection } from "../../../constants/countries";
import { getTranslation } from "../../../heplers/translationHelper";
import { Cancel } from "@material-ui/icons";

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

const representativeRoles = ["President", "Secretary", "Ordinary Member"];

const CreateManagementCompany = ({ editSPV,onSubmit, onCancel }) => {
  const [companyRepresentatvies, setCompanyRepresentatvies] = useState([]);
  const [companies, setCompanies] = useState([]);
  const [selectedCompany, setSelectedCompany] = useState("");
  const history = useNavigate();
  const dispatch = useDispatch();
  const getSelectedCompany = (company) => {};

  console.log("jksdghfsd",editSPV);

  useEffect(() => {
    Get(
      {},
      GET_AllCompaniesList_URL,
      history,
      (response) => {
        setCompanies(response.data);
      },
      (error) => {}
    );
  }, []);

  useEffect(() => {
    Get(
      {
        id: selectedCompany,
      },
      Get_AllCompanyRepresentativeContacts_URL,
      history,
      (response) => {
        setCompanyRepresentatvies(response.data);
      },
      (error) => {}
    );
  }, [selectedCompany]);

  const initialValues = {
    title: "",
    companyId: "",
    company: {},
  };

  const basicValidationSchema = Yup.object().shape({
    title: Yup.string().required("Company is required"),
    companyId: Yup.string().required("Represented By Person is required"),
    company: Yup.object().required("Represented By Person is required"),
  });

  const defaultValue = editSPV ? editSPV : initialValues;
  return (
    <Card title="Add SPV">
      <Formik
        enableReinitialize
        initialValues={defaultValue}
        validationSchema={basicValidationSchema}
        onSubmit={(values, actions) => {
          actions.setSubmitting(true);
          onSubmit(values, actions);
          actions.resetForm();
        }}
      >
        {({
          errors,
          touched,
          values,
          handleSubmit,
          isSubmitting,
          getFieldProps,
          setFieldValue,
          setTouched
        }) => (
          <form>
            <CardContent>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={12} md={4} lg={4}>
                  <TextField
                    fullWidth
                    label={getTranslation("Title","Titre","Titel")}
                    required
                    size="small"
                    variant="outlined"
                    name="title"
                    {...getFieldProps("title")}
                    error={touched.title && Boolean(errors.title)}
                    helperText={touched.title && errors.title}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={4} lg={4}>
                  <Autocomplete
                    name="companyId"
                    disabled={editSPV?true:false}
                    value={values?.company}
                    options={companies}
                    getOptionLabel={(option) => option.companyName}
                    // getOptionSelected={(selected, value) => {
                    //    return selected.id === value}

                    // }

                    size="small"
                    onChange={(e, value) => {
                      setFieldValue("company", value);
                      setFieldValue("companyId", value?.id);
                      setTouched("companyId", true); 
                      setTouched("company", true); 
                    }}
                    onBlur={() => {
                      setTouched("companyId", true); 
                      setTouched("company", true); 
                    }}
                    renderInput={(params) => (
                      <div>

                        <TextField
                          {...params}
                          label={getTranslation("Select Company","Sélectionnez l'entreprise","Unternehmen auswählen")}
                          variant="outlined"
                          error={(touched.companyId || touched.company) && Boolean(errors.companyId || errors.company)}
                          helperText={(touched.companyId || touched.company) && (errors.companyId || errors.company)}
                        />
                        {/* <ErrorMessage
                        name='companyId'
                        component='div'
                        style={{ color: "#f44336", fontSize: "13px", margin:"4px 0px 0px 4px" }}
                        /> */}
                      </div>
                    )}
                  />
                </Grid>
              </Grid>
            </CardContent>
            <CardActions>
              <Box
                pr={1}
                pb={1}
                width="100%"
                display="flex"
                alignItems="center"
                justifyContent="flex-end"
              >
                {editSPV ? (
                  <IconButton onClick={onCancel}>
                    <Cancel />
                  </IconButton>
                ) : null}
                <ButtonWithLoading
                  title={editSPV?getTranslation("Update","Mettre à jour","Aktualisieren"):getTranslation("Create","Créer","Erstellen")}
                  size="small"
                  variant="contained"
                  color="primary"
                  loading={isSubmitting}
                  onClick={handleSubmit}
                />
              </Box>
            </CardActions>
          </form>
        )}
      </Formik>
    </Card>
  );
};

export default CreateManagementCompany;
