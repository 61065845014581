
import CardComponent from "../../../../components/Card";
import { CardActionArea, CardActions, CardContent } from "@material-ui/core";
import { RenderAgendaItem } from "./renderAgendaItem";
import ButtonWithLoading from "../../../../components/ButtonWithLoading";
import React from "react";

export const ReviewAgenda=React.forwardRef(({agendas},ref)=>{

    return <CardComponent title="Agendas">
        <CardContent>
            <div ref={ref}>
            {agendas.map((agenda) => <RenderAgendaItem agendaItem={agenda} />)}
            </div>
        </CardContent>
    </CardComponent>
})