import React, { useState, useEffect } from "react";
import Table from "../../../../../components/table";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useSnackbar } from "notistack";
import { Get } from "../../../../../actions";
import { Get_AllCircularResolutionsWithPagination_URL } from "../../../../../constants/apiUrls";
import { DatePicker } from "@material-ui/pickers";
import { Grid, IconButton } from "@material-ui/core";
import { Toc } from "@material-ui/icons";
import { getTranslation } from "../../../../../heplers/translationHelper";

const columns = [
  {
    id: "subject",
    numeric: false,
    disablePadding: true,
    label: getTranslation("Subject","Sujette","Fach"),
  },
  {
    id: "language",
    numeric: false,
    disablePadding: true,
    label: getTranslation("Language","Langue","Sprache"),
  },
  {
    id: "personInchargeName",
    numeric: false,
    disablePadding: true,
    label: getTranslation("Person Incharge","Personne en charge","Verantwortlicher"),
  },

  {
    id: "meetingDate",
    numeric: false,
    disablePadding: true,
    format: "date",
    label: getTranslation("Date","Date","Datum"),
  },
  {
    id: "resultText",
    numeric: false,
    disablePadding: true,
    label: getTranslation("Result","Résultat","Ergebnis"),
  },
  {
    id: "status",
    numeric: false,
    disablePadding: true,
    label: getTranslation("Status","Statut","Status"),
  },
  {
    id: "actions",
    numeric: true,
    disablePadding: false,
    align: "right",
    label: getTranslation("Logs","Journaux","Protokolle"),
  },
];

// const Funds = [
//   {
//     id: "001",
//     title: "Company",
//     investerRelation: "Asghar",
//     manager: "Khan",
//   },
// ];
// this is template for list given
export const CircularResolutionList = ({ meetings }) => {
  const history = useNavigate();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const [open, setOpen] = useState(false);
  const [deleteRow, setDeleteRow] = useState({});
  const [searchString, setSearchString] = useState("");
  const [page, setPage] = useState(0);
  const [createCompany, setCreateCompany] = useState(false);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [rows, setRows] = useState([]);
  const [loadingFunds, setloadingFunds] = useState(false);

  //   useEffect(() => {
  //     dispatch({ type: "Clear_All_BreadCrumb" });
  //     dispatch({
  //       type: "Add_BreadCrumb",
  //       payload: { title: "Funds", url: "/funds" },
  //     });
  //   }, []);

  // const { activities } = useSelector((state) => state.activities);
  const handleOpenDeleteDialog = (row) => {
    setDeleteRow(row);
    setOpen(true);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value));
    setPage(0);
  };

  const handleDetailCompany = (row) => {
    // // navigting to this route"/meetings/:managementCompanyId/circularResolution/:circularResolutionId"
    history(row.id);
  };
  const handleOpenAuditLog = (row) => {
    history(row.id + "/auditlogs");
  };

  return (
    <div>
      <Table
        dense
        loading={loadingFunds}
        colums={columns}
        sortBy="meetingDate"
        rows={meetings?.data}
        count={meetings?.totalCount}
        page={page}
        rowsPerPage={rowsPerPage}
        handleChangePage={(event, newPage) => handleChangePage(event, newPage)}
        handleChangeRowsPerPage={(e) => handleChangeRowsPerPage(e)}
        viewEdit
        onEdit={(row) => handleDetailCompany(row)}
        actions={[
          {
            component: (row) => (
              <IconButton onClick={() => handleOpenAuditLog(row)}>
                <Toc />{" "}
              </IconButton>
            ),
          },
        ]}
        // viewDelete
        // onDelete={(row) => handleOpenDeleteDialog(row)}
        viewSearch
        //onSearch={(searchString) => handleSearchFund(searchString)}
      />
    </div>
  );
};
