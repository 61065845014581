export const LOGIN_URL = "Auth/Login";
export const Post_Register_URL = "Auth/Register";
export const Post_RefreshToken_URL = "Auth/RefreshToken";
export const GET_USER_PROFILE_URL = "Auth/GetUserProfile";
export const Post_UpdateUSER_PROFILE_URL = "Auth/UpdateUserProfile";
export const Post_UpdateUserPassword_URL = "Auth/ChangePassword";
export const Post_ForgetPassword_URL = "Auth/ForgetPassword";
export const Get_FeatureToggles_URL = "Auth/GetFeatureToggles";
export const Get_Version_URL = "Auth/GetVersion";

export const Post_AddNewText_URL = `${process.env.REACT_APP_MANAGER_SERVER_URL}Language/AddNewText`;
export const Get_LanguageDictionary_URL = `${process.env.REACT_APP_MANAGER_SERVER_URL}Language/GetLanguageDictionary`;

export const GET_AllUsersList_URL = "Auth/GetAllUsers";
export const GET_AllUsers_URL = "Auth/GetAllUsersWithPagination";
export const GET_AllUsersWithoutAuthorization_URL =
  "Auth/GetAllUsersWithoutAuthorization";
export const Post_Logout_URL = "Auth/Logout";
export const Post_MakeUserInActive_URL = "Auth/MakeUserInactive";
export const Post_MakeUserActive_URL = "Auth/MakeUserActive";
export const Post_GenerateAuthCode_URL="/Auth/GenerateAuthCode";
export const Post_VerifyAuthCode_URL="/Auth/VerifyAuthCode"

// File Manager URL's
export const Post_FileUpload_URL = "FileManager/Upload";
export const Get_File_URL = "FileManager/Get";
export const Get_DeleteFile_URL = "FileManager/DeleteFile";
export const Get_FileInfo_URL = "FileManager/GetFileInfo";

// Meeting URL's
export const Post_FileInsert_URL = "Meeting/InsertFile";
export const Post_FileDelete_URL = "Meeting/DeleteFiles";
export const Get_Files_URL = "Meeting/GetFiles";
export const Get_MeetingClashes_URL = "Meeting/GetMeetingClashes";
export const Post_TryResolvingMeetingClashes_URL =
  "Meeting/TryResolvingMeetingClashes";
export const Post_UpdateMeetingMemberReminder_URL =
  "Meeting/UpdateMeetingMemberReminder";
export const Get_AllMeetingRemindermembers_URL =
  "Meeting/GetAllReminderCCMembers";
  export const Post_Insert_Todo_URL="Meeting/InsertTodoList";
  export const Get_All_Meeting_Todo="/Meeting/GetAllMeetingToDos";
  export const Get_All_SPV_Todos="/Meeting/GetAllSPVToDos";
  export const Post_Insert_Agenda="/Meeting/InsertAgenda"
  export const Get_All_Agenda="/Meeting/GetAllAgendas"
  export const Update_Agenda="/Meeting/UpdateAgenda"
  export const Delete_Agenda="/Meeting/DeleteAgenda"
  export const Delete_Todo_List="/Meeting/DeleteTodoList";
  export const Get_Todo_List="/Meeting/GetToDoList";
  export const Update_ToDo_ListItem="/Meeting/UpsertTodoListItem";
  export const Delete_Todo_ListItem="/Meeting/DeleteTodoListItem";
  export const Update_Todo_ListItem_Status="/Meeting/ChangeToDoListItemStatus";

//Contact URL's
export const GET_AllContacts_URL = "Contact/GetAllContacts";
export const GET_AllContactList_URL = "Contact/GetAllContactList";
export const GET_Contact_URL = "Contact/GetContact";
export const SAVE_Contact_URL = "Contact/InsertContact";
export const UPDATE_Contact_URL = "Contact/UpdateContact";
export const DELETE_Contact_URL = "Contact/DeleteContacts";

//Company URL's
export const GET_AllTeamMembers_URL = "Company/GetAllTeamMembers";
export const GET_AllCompanies_URL = "Company/GetAllCompanies";
export const GET_AllCompaniesList_URL = "Company/GetAllCompaniesList";
export const GET_Company_URL = "Company/GetCompany";
export const SAVE_Company_URL = "Company/InsertCompany";
export const UPDATE_Company_URL = "Company/UpdateCompany";
export const DELETE_Company_URL = "Company/DeleteCompanies";
export const GET_CompanyTeam_URL = "Company/GetAllTeamMembersWithPagination";
export const Post_InsertCompanyTeamMember_URL = "Company/InsertTeamMember";
export const Post_UpdateTeamMember_URL = "Company/UpdateTeamMember";
export const Post_DeleteTeamMember_URL = "Company/DeleteTeamMembers";

// Company Group URL's
export const Get_AllCompanyGroups_URL = "Company/GetAllCompanyGroups";
export const Create_CompanyGroup_URL = "Company/InsertCompanyGroup";

// Legal Form URL's
export const Get_AllLegalForm_URL = "Basic/GetAllBasicLegalForms";
export const Get_AllLegalFormListItems_URL = "Basic/GetAllLegalFormsList";
export const Post_InsertBasicLegalForm_URL = "Basic/InsertBasicLegalForm";
export const Post_UpdateBasicLegalForm_URL = "Basic/UpdateBasicLegalForm";
export const Post_DeleteBasicLegalForm_URL = "Basic/DeleteBasicLegalForms";
// Representative URL's
export const Get_AllBoardRepresentative_URL =
  "Representative/GetAllBoardRepresentatives";
export const Get_AllManagerRepresentative_URL =
  "Representative/GetAllManagerRepresentatives";

export const Create_BoardCompanyRepresentative_URL =
  "Representative/InsertBoardCompanyRepresentative";
export const Create_UpdateCompanyRepresentative_URL =
  "Representative/UpdateCompanyRepresentative";
export const Create_AddNewServiceProviderRepresentative_URL =
  "Company/AddNewServiceProviderRepresentative";
export const Create_ManagerCompanyRepresentative_URL =
  "Representative/InsertManagerCompanyRepresentative";
export const Create_BoardPersonRepresentative_URL =
  "Representative/InsertBoardPersonRepresentative";
export const Create_ManagerPersonRepresentative_URL =
  "Representative/InsertManagerPersonRepresentative";
export const Update_BoardPersonRepresentative_URL =
  "Representative/UpdateBoardPersonRepresentative";
export const Update_PersonRepresentative_URL =
  "Representative/UpdatePersonRepresentative";

export const Get_AllPersonsRepresentative_URL =
  "Representative/GetAllRepresentedByContact";
export const Get_Contact_BelongsTo_URL =
  "Representative/GetAllBelongsToContact";
export const Get_AllCompanyRepresentativeContacts_URL =
  "Representative/GetAllContactsByCompany";

export const Delete_CompanyRepresentative_URL =
  "Representative/DeleteRepresentedByCompany";
export const Delete_PersonRepresentative_URL =
  "Representative/DeleteRepresentedByContact";

// Shareholders URL's
export const Get_AllShareholders_URL = "Shareholder/GetAllShareholders";
export const Get_ShareholderStat_URL = "Shareholder/GetShareholderStat";

export const Create_Shareholders_URL = "Shareholder/InsertShareholder";
export const Create_UpdateShareholder_URL = "Shareholder/UpdateShareholder";
export const Delete_Shareholders_URL = "Shareholder/DeleteShareholders";

// Asset URL's
export const Get_AllAssetsWithPagination_URL =
  "Asset/GetAllAssetsWithPagination";
export const Get_AllAssets_URL = "Asset/GetAllAssets";
export const Post_DeleteAsset_URL = "Asset/DeleteAssets";
export const Post_CreateAsset_URL = "Asset/InsertAsset";
export const Post_UpdateAsset_URL = "Asset/UpdateAsset";

export const Get_AssetCompany_URL = "Asset/GetAssetCompany";
export const GET_AssetNetAssetValue_URL = "Asset/GetNetAssetValue";
export const Get_Asset_URL = "Asset/GetAsset";
export const Get_AllAssetOperation_URL =
  "Asset/GetAssetOperationWithPagination";
export const Create_AssetOperation_URL = "Asset/InsertAssetOperation";
export const Get_AssetBoardMembers_URL = "Asset/GetAssetBoardMembers";
export const Get_OperationsSummary_URL = "Asset/GetOperationsSummary";
export const Get_AssetManagerMembers_URL = "Asset/GetAssetManagerMembers";
export const Get_AssetShareholdersMembers_URL =
  "Asset/GetAssetShareholdersMembers";
export const Get_AssetFeeAgreement_URL = "Asset/GetAssetFeeAgreement";
export const Post_CancelAssetFeeAgreement_URL =
  "FeeAgreement/CancelAssetFeeAgreement";
export const Get_AssetFeeAgreementHistory_URL = "Asset/GetFeeAgreementHistory";
export const Get_AssetDocuments_URL = "Asset/GetAssetDocument";

export const Post_InsertAssetDocument_URL = "Asset/InsertAssetDocument";
export const Post_DeleteAssetDocument_URL = "Asset/DeleteAssetDocument";

// NetAssetValue URL's
export const Get_NetAssetValueItems_URL = "NetAssetValue/InsertNetAssetValue";
export const SAVE_NetAssetValue_URL = "NetAssetValue/InsertNetAssetValue";
export const Post_CreateNetAssetValue_URL =
  "NetAssetValue/InsertNetAssetValueList";

export const UPDATE_NetAssetValue_URL = "NetAssetValue/UpdateNetAssetValue";

// Operation URL's
export const DELETE_Operation_URL = "NetAssetValue/DeleteOperations";
export const DELETE_NetAssetValue_URL = "NetAssetValue/DeleteNetAssetValues";

// Fee Agreements URL's
export const Post_UpdateFeeAgreement_URL = "FeeAgreement/UpdateFeeAgreement";
export const Post_InsertFeeAgreementCeresService_URL =
  "FeeAgreement/InsertCeresService";
export const Post_DeleteFeeAgreementCeresService_URL =
  "FeeAgreement/DeleteCeresService";
export const Post_UpdateFeeAgreementCeresService_URL =
  "FeeAgreement/UpdateCeresService";

// Ceres Services URL's
export const Get_AllCeresServicesWithPagination_URL =
  "CeresService/GetAllCeresServicesWithPagination";
export const Get_AllCeresServices_URL = "CeresService/GetAllCeresServices";
export const Get_AllCeresServiceTypes_URL =
  "CeresService/GetAllCeresServiceTypes";
export const Post_InsertCeresService_URL = "CeresService/InsertCeresService";
export const Post_ChangeStatusCeresService_URL =
  "CeresService/ChangeStatusCeresService";
export const Post_InsertCeresServiceType_URL =
  "CeresService/InsertCeresServiceType";
export const Post_UpdateCeresServiceTitle_URL =
  "CeresService/UpdateCeresService";

export const Post_UpdateCeresService_URL = "CeresService/UpdateNetAssetValue";
export const Post_DeleteCeresService_URL = "CeresService/DeleteCeresServices";

// Services URL's
export const Get_AllServiceTypesWithPagination_URL =
  "Company/GetAllServiceTypesWithPagination";
export const Get_AllServiceTypes_URL =
  "ExternalServiceProvider/GetAllServiceTypes";
export const Post_InsertServiceType_URL = "Company/InsertServiceType";

export const Get_AllServiceWithPagination_URL =
  "ExternalServiceProvider/GetAllServicesWithPagination";
export const Get_AllServices_URL = "ExternalServiceProvider/GetAllServices";
export const Post_InsertService_URL = "ExternalServiceProvider/InsertService";
export const Post_InsertExternalServiceType_URL =
  "ExternalServiceProvider/InsertServiceType";
export const Post_UpdateService_URL = "ExternalServiceProvider/UpdateService";
export const Post_DeleteService_URL = "ExternalServiceProvider/DeleteServices";

// Company Service URL's
export const Get_AllCompanyServicesWithPagination_URL =
  "Company/GetAllExternalServiceProvider";
export const Get_CompanyCeresService_URL = "Company/GetCompanyCeresService";
export const Get_AllServiceProviderRepresentatives_URL =
  "Contact/GetAllContactList";
export const Post_InsertCompanyService_URL =
  "Company/InsertCompanyCeresService";
export const Post_DeleteCompanyExternalServiceProvider_URL =
  "/Company/DeleteExternalServiceProviders";
export const Get_AllAvailabilities_URL = "Contact/GetAllAvailabilities";
export const Post_AddNewAvailability_URL = "Contact/AddNewAvailability";
export const Post_UpdateAvailability_URL = "Contact/UpdateAvailability";
export const Post_DeleteAvailability_URL = "Contact/DeleteAvailability";
export const Post_UpsertAvailableDay_URL = "Contact/UpsertAvailableDay";

// Funds URL's
export const Get_AllFundsWithPagination_URL = "Fund/GetAllFundsWithPagination";
export const Get_AllFunds_URL = "Fund/GetAllFunds";
export const Post_DeleteFund_URL = "Fund/DeleteFunds";
export const Post_CreateFund_URL = "Fund/InsertFund";
export const Post_FundOperation_URL = "Fund/InsertFundOperation";

export const GET_FundNetAssetValue_URL = "Fund/GetNetAssetValue";
export const Get_AllFundOperation_URL = "Fund/GetOperationWithPagination";
export const Get_FundFeeAgreement_URL = "Fund/GetFeeAgreement";
export const Post_CancelFundFeeAgreement_URL =
  "FeeAgreement/CancelFeeAgreement";
export const Get_FundFeeAgreementHistory_URL = "Fund/GetFeeAgreementHistory";
export const Get_AssetOperationsSummary_URL =
  "ManagementCompany/GetAssetOperationsSummary";
export const Get_FundOperationsSummary_URL =
  "ManagementCompany/GetFundOperationsSummary";
export const Post_UpdateAssetOperation_URL =
  "ManagementCompany/UpdateAssetOperation";
export const Post_ManagementCompanyDeleteOperation_URL =
  "ManagementCompany/DeleteOperation";
export const Post_UpdateManagementCompany_URL =
  "ManagementCompany/UpdateManagementCompany";

// Management Companies URL's
export const Get_AllManagementCompaniesWithPagination_URL =
  "ManagementCompany/GetAllManagementCompanies";
export const Post_CreateManagementCompany_URL =
  "ManagementCompany/InsertManagementCompany";
export const Get_ManagementCompanyFeeAgreement_URL =
  "ManagementCompany/GetFeeAgreement";

export const Get_GetAllManagementCompanyFundsWithPagination_URL =
  "ManagementCompany/GetAllManagementCompanyFundsWithPagination";
export const Get_GetAllManagementCompanyFunds_URL =
  "ManagementCompany/GetAllManagementCompanyFunds";
export const Post_InsertManagementCompanyFund_URL =
  "ManagementCompany/InsertManagementCompanyFund";

export const Get_GetAllManagementCompanyFundOperations_URL =
  "ManagementCompany/GetAllManagementCompanyFundOperations";
export const Post_InsertFundOperation_URL =
  "ManagementCompany/InsertFundOperation";
export const Post_UpdateFundOperation_URL =
  "ManagementCompany/UpdateFundOperation";

export const Get_GetAllManagementCompanyAssetsWithPagination_URL =
  "ManagementCompany/GetAllManagementCompanyAssetsWithPagination";
export const Get_GetAllManagementCompanyAssets_URL =
  "ManagementCompany/GetAllManagementCompanyAssets";
export const Post_InsertManagementCompanyAsset_URL =
  "ManagementCompany/InsertManagementCompanyAsset";

export const Get_GetAllManagementCompanyAssetOperations_URL =
  "ManagementCompany/GetAllManagementCompanyAssetOperations";
export const Post_InsertAssetOperation_URL =
  "ManagementCompany/InsertAssetOperation";
export const Post_DeleteManagementCompanies_URL =
  "ManagementCompany/DeleteManagementCompanies";

export const Get_GetAllManagementCompanyFeeServices_URL =
  "ManagementCompany/GetFeeServices";
export const Get_AllManagementCompanyFeeServicesByType_URL =
  "ManagementCompany/GetFeeServicesByType";
export const Get_ManagementCompanyBoardMembers_URL =
  "ManagementCompany/GetManagementCompanyBoardMembers";
export const Post_CancelManagementCompanyFeeAgreement_URL =
  "FeeAgreement/CancelManagementCompanyFeeAgreement";
export const Get_ManagementCompanyFeeAgreementHistory_URL =
  "ManagementCompany/GetFeeAgreementHistory";

// Meeting URL's
export const Post_GenerateMeetings_URL = "Meeting/GenerateMeetings";
export const Post_InsertNotifyess_URL = "Meeting/InsertNotifyee";
export const Post_DeleteNotifyess_URL = "Meeting/DeleteNotifyee";
export const Post_PowerOfAttorney_URL = "Meeting/UsePowerOfAttorney";
export const Post_JoinMeeting_URL = "Meeting/JoinMeeting";
export const Post_GenerateAdhocMeetings_URL = "Meeting/InsertAdhocMeeting";
export const Post_GenerateCircularMeetings_URL =
  "Meeting/InsertCircularResolution";
export const Post_InsertCircularResolution_URL =
  "Meeting/InsertCircularResolution";
export const Post_AskForAgenda_URL = "Meeting/InsertAgenda";
export const Post_MinutesForMeeting_URL = "Meeting/AddMinutes";
export const Post_CommentsOfMeeting_URL = "Meeting/AddComments";
export const Post_UpdateMemberComment_URL = "Meeting/UpdateMemberComment";
export const Post_ConfirmCRByMember_URL =
  "Meeting/ConfirmCircularResolutionByMember";
export const Post_UpdateSponserComment_URL =
  "Meeting/UpdateCRCommentsBySponsor";
export const Post_UpdateStatusBySponser_URL = "Meeting/UpdateCRStatusBySponsor";
export const Post_DeleteMemberCircularResolution_URL = "Meeting/DeleteMember";
export const Post_AddNewMemberCircularResolution_URL = "Meeting/InsertMember";
export const Post_UpdateMemberDecisionManually_URL =
  "Meeting/UpdateMemberDecisionManually";
export const Post_InsertDecisionMakerCircularResolution_URL =
  "Meeting/InsertDecisionMaker";
export const Post_DeleteDecisionMakerCircularResolution_URL =
  "Meeting/DeleteDecisionMaker";
export const Post_AddNewFileCircularResolution_URL =
  "Meeting/AddCircularResolutionFile";
export const Post_UpdateCircularResolutionSubject_URL =
  "Meeting/UpdateCircularResolutionSubject";
export const Post_UpdateCircularResolutionResolutionText_URL =
  "Meeting/UpdateCircularResolutionResolutionText";
export const Post_UpdateCircularResolutionDeadline_URL =
  "Meeting/UpdateCircularResolutionDeadline";
export const Post_ChangeSponsorCircularResolution_URL = "Meeting/ChangeSponsor";
export const Post_UpdateMemberDecision_URL = "Meeting/UpdateMemberDecision";
export const Post_SendEmailForConfirmationCircularResolution_URL =
  "Meeting/SendEmailForConfirmation";

export const Post_PostponeRegularMeeting_URL = "Meeting/PostponeRegularMeeting";
export const Post_ConfirmationOfAutoGeneratedMeetings_URL =
  "Meeting/Confirmation";
export const Get_AskForApprovalOfMeetings_URL =
  "Meeting/AskForApprovalOfMeetings";

export const Get_DecisionMakers_URL = "Meeting/GetDecisionMakers";
export const Get_GetDecisionMakerMembers_URL =
  "Meeting/GetDecisionMakerMembers";
export const Get_MeetingInfo_URL = "Meeting/GetMeeting";
export const Get_PowerOfAttorney_URL = "Meeting/GetPowerOfAttorney";
export const Get_PowerOfAttorneyMeeting_URL =
  "Meeting/GetPowerOfAttorneyMeeting";

////////// Milestone 2.1 APIs
export const Get_AllMeetingMembers_URL = "Meeting/GetAllMeetingMembers";
export const Get_CircularResolutionMemberContact_URL =
  "Meeting/GetMemberContact";
export const Get_MeetingMember_URL = "Meeting/GetMeetingMember";
export const Get_AllUnConfirmedMeetingsOfMember_URL =
  "Meeting/GetAllUnConfirmedMeetingsOfMember";
export const Get_AllUnConfirmedMeetings_URL =
  "Meeting/GetAllUnconfirmendMeetings";
export const Get_AllCompanyBelongsToList_URL = "Company/GetAllCompanyBelongsTo";
export const Post_MeetingMemberDescision_URL = "Meeting/MeetingMemberDescision";

// External Service providers
export const Post_ExternalServiceProvider_URL =
  "Company/InsertExternalServiceProvider";
export const Post_UpdateExternalServiceProvider_URL =
  "Company/UpdateExternalServiceProvider";

//////////

export const Get_MCBoardMembers_URL =
  "ManagementCompany/GetManagementCompanyBoardMembers";
export const Get_MCShareholders_URL =
  "ManagementCompany/GetManagementCompanyShareholders";
export const Get_MCManagers_URL =
  "ManagementCompany/GetManagementCompanyManagers";
export const Get_MCNotifyess_URL = "Meeting/GetNotifyees";
export const Get_AllUpcomingMeetings_URL = "Meeting/UpcomingMeetings";
export const Get_AllAgendasWithPagination_URL =
  "Meeting/GetAllAgendasWithPagination";
export const Get_AllPreviousMeetings_URL = "Meeting/PreviousMeetings";
export const Get_CircularResolutionUploadedFiles_URL =
  "Meeting/GetCircularResolutionUploadedFiles";
export const Get_NotifyeeList_URL = "Meeting/GetNotifyees";
export const Get_SingleCircularResolution_URL = "Meeting/GetCircularResolution";
export const Get_GetCircularResolutionMembers_URL =
  "Meeting/GetCircularResolutionMembers";

export const Get_MCBoardMembersByCircularResolution_URL =
  "Meeting/GetBoardMembersByCircularResolution";
export const Get_MCShareholderMembersByCircularResolution_URL =
  "Meeting/GetShareHolderMembersByCircularResolution";
export const Get_MCManagerMembersByCircularResolution_URL =
  "Meeting/GetManagersMembersByCircularResolution";
export const Get_CircularResolutionActivities_URL =
  "Meeting/GetAllCircularResolutionActivities";
export const Get_SelectedBoardMembersCircularResolution_URL =
  "Meeting/GetSelectedBoardMembers";
export const Get_SelectedManagersCircularResolution_URL =
  "Meeting/GetSelectedManagers";
export const Get_SelectedShareholdersCircularResolution_URL =
  "Meeting/GetSelectedShareholders";

export const Get_RegularMeetingMCBoardMembers_URL =
  "Meeting/GetManagementCompanyBoardMembers";
export const Get_RegularMeetingMCShareholders_URL =
  "Meeting/GetManagementCompanyShareholders";

export const Get_AllMeetingActivities_URL = "Meeting/GetAllMeetingActivities";
export const Post_UpdateMeetingInfo_URL = "Meeting/UpdateMeetingInfo";
export const Post_InsertNewRegularMeetingMember_URL =
  "Meeting/AddMeetingMember";
export const Post_RemoveRegularMeetingMember_URL =
  "Meeting/RemoveMeetingMember";

export const Get_AllMeetingDocuments_URL = "Meeting/GetAllMeetingDocuments";
export const Post_AddNewMeetingDocument_URL = "Meeting/InsertMeetingDocument";
export const Post_RemoveMeetingDocument_URL = "Meeting/RemoveMeetingDocument";
export const Post_UploadMeetingDocument_URL = "Meeting/UploadMeetingDocument";

export const Get_AllMeetingAgendas_URL = "Meeting/GetAllAgendas";
export const Post_ChangeAgendaByTemplate_URL = "Meeting/ChangeAgendaByTemplate";
export const Post_AddNewAgenda_URL = "Meeting/InsertAgenda";
export const Post_DeleteAgenda_URL = "Meeting/DeleteAgenda";

export const Post_UpdateRegularMeetingAgendaInterval_URL =
  "Meeting/UpdateRegularMeetingAgendaInterval";
export const Post_UpdateRegularMeetingReminderInterval_URL =
  "Meeting/UpdateRegularMeetingReminderInterval";
export const Post_UpdateRegularMeetingReminderText_URL =
  "Meeting/UpdateRegularMeetingReminderText";

//Guest
export const Post_AddMeetingGuest_URL = "Meeting/AddMeetingGuest";
export const Post_RemoveMeetingGuest_URL = "Meeting/RemoveMeetingGuest";
export const Get_GetAllGuestsWithPagination_URL =
  "Meeting/GetAllGuestsWithPagination";
export const Get_GetAllGuests_URL = "Meeting/GetAllGuests";
export const Get_GetAllAdhocMeetingsWithPagination_URL =
  "Meeting/GetAdhocMeetingsWithPagination";
export const Get_AllCircularResolutionsWithPagination_URL =
  "Meeting/GetAllCircularResolutionsWithPagination";

// Settings
export const Post_SettingForMeeting_URL = "SystemSetting/UpsertSystemSetting";
export const Get_AllHolidays_URL = "SystemSetting/GetAllHolidays";
export const Get_SystemSetting_URL = "SystemSetting/GetSystemSetting";
export const Post_AddNewHoliday_URL = "SystemSetting/AddNewHoliday";
export const Post_UpdateHoliday_URL = "SystemSetting/UpdateHoliday";
export const Post_DeleteHoliday_URL = "/SystemSetting/DeleteHoliday";
export const Post_UpdateDocuSignSetting_URL = "/SystemSetting/UpdateDocuSignSetting";
export const Post_UpdateDocuSignStatus_URL = "/SystemSetting/UpdateDocuSignStatus";
export const Get_ValidateDocuSign_URL = "/SystemSetting/ValidateDocuSign";
export const Get_Email_Template_URL="/SystemSetting/GetEmailTemplate";
export const Post_Update_Email_Template_URL="/SystemSetting/UpdateEmailTemplate";

// Templates
////////////////      Get           ////////////////
export const Get_AllCircularResolutionsTemplatesWithPagination_URL =
  "Template/GetAllCircularResolutionTemplatesWithPagination";
export const Get_CircularResolutionsTemplates_URL =
  "Template/GetCRTemplateList";
export const Get_AllLegalFormList_URL = "Template/GetAllLegalFormList";
export const Get_BasicLegalFormByCountry_URL =
  "Basic/GetBasicLegalFormByCountry";
export const Get_TemplateResolutionText_URL =
  "Template/GetCRTemplateResolution";
export const Get_AgendaTemplateList_URL = "Template/GetAgendaTemplateList";
export const Get_AllAgendaTemplateItems_URL =
  "Template/GetAllAgendaTemplateItemList";
////////////////      Post           ////////////////
export const Post_DeleteCircularResolutionTemplate_URL =
  "Template/DeleteCRTemplate";
export const Post_InsertCircularResolutionTemplate_URL =
  "Template/InsertCRTemplate";
export const Post_UpdateCircularResolutionTemplate_URL =
  "Template/UpdateCRTemplate";
export const Post_InsertAgendaTemplate_URL = "Template/InsertAgendaTemplate";
export const Post_InsertAgendaTemplateItem_URL =
  "Template/InsertAgendaTemplateItem";
export const Post_DeleteAgendaTemplateItem_URL =
  "Template/DeleteAgendaTemplateItems";

//////////// Calendar ////////////////////////
export const Post_GetAllMeetings_URL = "/Calendar/GetAllMeetings";

////////////// Operations //////////////////////
export const Post_DeleteOperation_URL = "Operation/DeleteOperations";

///////////////// Report Template APIs V3.0 ///////////////

/////////// Forms ////////////////////////
export const Get_AllForms_URL = "ReportTemplate/GetAllForms";
export const Post_DeleteForm_URL = "ReportTemplate/DeleteForm";
export const Post_UpsertForm_URL = "ReportTemplate/UpsertForm";

/////////// Tables ////////////////////////

export const Get_AllTables_URL = "ReportTemplate/GetAllTables";
export const Post_DeleteTable_URL = "ReportTemplate/DeleteTable";
export const Post_UpsertTable_URL = "ReportTemplate/UpsertTable";

//////////// Sections ////////////////////////

export const Get_AllSections_URL = "ReportTemplate/GetAllSections";
export const Post_DeleteSection_URL = "ReportTemplate/DeleteSection";
export const Post_UpsertSection_URL = "ReportTemplate/UpsertSection";
export const Get_SectionById_URL = "ReportTemplate/GetSection";
///////////// Reports ////////////
export const Get_AllReports_URL = "ReportTemplate/GetAllReports";
export const Get_ReportingUser_URL = "ReportTemplate/GetReportingUser";
export const Post_DeleteReport_URL = "ReportTemplate/DeleteReport";
export const Post_UpsertReport_URL = "ReportTemplate/UpsertReport";
export const Get_MeetingReportUsers_URL = "MeetingReport/GetReportingUsers";
export const Get_GetReportInfo_URL = "MeetingReport/GetReportInfo";
export const Post_ChangeReportingUser_URL = "MeetingReport/ChangeReportingUser";
export const Post_SendNotification_URL = "MeetingReport/SendNotification";

///////////// Meeting Report /////////////////////
export const Get_CompanyInfo_URL = "Meeting/GetCompany";
export const Get_ReportDataCollected_URL = "MeetingReport/GetReportDataCollected";
export const Post_SubmitFormReportCollection_URL = "MeetingReport/SubmitFormReportCollection";
export const Get_FormReportCollection_URL = "MeetingReport/GetFormReportCollection";
export const Get_TableReportCollection_URL = "MeetingReport/GetTableReportCollection";
export const Post_UpdateStatusOfReport_URL = "MeetingReport/UpdateStatusOfReport";
export const Post_SubmitTableReportCollection_URL = "MeetingReport/SubmitTableReportCollection";
export const Get_SendNotificationForReview_URL = "MeetingReport/SendNotificationForReview";

//////////// Meeting Minutes /////////////////////

export const Get_ToDoListItems_URL = "Meeting/GetToDoList";
export const Post_MeetingAcceptMinutes_URL = "Meeting/AcceptMinutes";
export const Post_MeetingDeclineMinutes_URL = "Meeting/DeclineMinutes";
export const Get_Spv_TeamMembers_URL="/Meeting/GetSPVTeamMembers";
