import React, { useState, useRef, useEffect } from "react";
import { ToggleButton, ToggleButtonGroup } from "@material-ui/lab";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { getTranslation } from "../../../../heplers/translationHelper";
import { HtmlEditor } from "../../../../components/HtmlEditor";
import {
  FormControl,
  Checkbox,
  FormControlLabel,
  Typography,
  TextField,
  Grid,
  Card,
  CardContent,
  CardHeader,
  Box,
  ButtonGroup,
  Button,
} from "@material-ui/core";
import ButtonWithLoading from "../../../../components/ButtonWithLoading";
import {
  Get_Email_Template_URL,
  Post_Update_Email_Template_URL,
} from "../../../../constants/apiUrls";
import { useSnackbar } from "notistack";
import { Get, Create } from "../../../../actions";
import { makeStyles } from "@material-ui/core/styles";
import { TagButtons } from "./TagButtons";

const useStyles = makeStyles((theme) => ({
  responsiveButtonGroup: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    margin: "5px",
    flexWrap: "wrap",
  },
  fixedButton: {
    margin: "5px",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    [theme.breakpoints.down("sm")]: {
      flex: "0 0 120px",
    },
  },
}));

const MeetingReportMail = () => {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const [type, setType] = useState("MeetingReportDataCollectionRequest");

  const [meetingMailTemplate, setMeetingMailTempate] = useState(true);

  const [enableReloading, setEnableReloading] = useState(false);
  const [template, setTemplate] = useState("");
  const [subject, setSubject] = useState("");
  const editorRef = useRef(null);

  const handleChange = (event, newAlignment) => {
    setType(newAlignment);
  };

  const AppendTemplate = (template, text, setFieldValue) => {
    editorRef.current.insertContent(text);
  };

  const GetReminderTemplate = (type) => {
    Get(
      { reminderKey: type },
      Get_Email_Template_URL,
      null,
      (resp) => {
        //setReminderTempateText(resp?.data?.template);
        setTemplate(resp?.data?.template != null ? resp?.data?.template : " ");
        setSubject(resp?.data?.subject != null ? resp?.data?.subject : " ");
        setEnableReloading(true);
      },
      (error) => {
        enqueueSnackbar(error.data, { variant: "error" });
      }
    );
  };

  useEffect(() => {
    GetReminderTemplate(type);
  }, [type]);

  const initialValues = {
    template: "",
    subject: "",
  };

  const basicValidationSchema = Yup.object().shape({
    template: Yup.string().required("Reminder text is required"),
    subject: Yup.string().required(),
  });

  const UpdateTemplate = (values, actions) => {
    actions.setSubmitting(true);
    const { template, subject } = values;
    const placeholderRegex = /\[Submit_URL\]/g;
    const matches = template.match(placeholderRegex);

    if (!matches || matches.length !== 1) {
      enqueueSnackbar("Template must contain one tap of Subimt Button", { variant: "error" });
      actions.setSubmitting(false);
      return;
    }
    const payload = {
      emailTemplateKey: type,
      dayOfMonth: 1,
      template: template,
      subject: subject,
    };
    console.log(payload);

    Create(
      payload,
      Post_Update_Email_Template_URL,
      null,
      (resp) => {
        actions.setSubmitting(false);
        enqueueSnackbar("Reminders sent", { variant: "success" });
      },
      (error) => {
        actions.setSubmitting(false);
        enqueueSnackbar(error?.data, { variant: "error" });
      }
    );
  };

  return (
    <>
      <ToggleButtonGroup
        style={{
          display: "flex",
          alignItems: "center",
          flexWrap: "wrap",
          justifyContent: "center",
        }}
        color="primary"
        value={type}
        exclusive
        onChange={handleChange}
        aria-label="Platform"
      >
        <ToggleButton value="MeetingReportDataCollectionRequest">
        {getTranslation("Meeting Report Data Collection Request","Meeting Report Data Collection Request","Meeting Report Data Collection Request")}
        </ToggleButton>
        <ToggleButton value="MeetingReportDecline">
        {getTranslation("Meeting Report Decline","Meeting Report Decline","Meeting Report Decline")}
        </ToggleButton>
        <ToggleButton value="MeetingReportApprove">
        {getTranslation("Meeting Report Approve","Meeting Report Approve","Meeting Report Approve")}
          
        </ToggleButton>
        <ToggleButton value="MeetingReportReview">
        {getTranslation("Meeting Report Review","Meeting Report Review","Meeting Report Review")}

        </ToggleButton>
        <ToggleButton value="MeetingReportSubmission">
        {getTranslation("Meeting Report Submission","Meeting Report Submission","Meeting Report Submission")}

        </ToggleButton>
      </ToggleButtonGroup>

      <br />

      <Formik
        enableReinitialize
        initialValues={initialValues}
        validationSchema={basicValidationSchema}
        onSubmit={(values, actions) => {
          UpdateTemplate(values, actions);
        }}
      >
        {({
          values,
          setFieldValue,
          errors,
          touched,
          handleChange,
          handleSubmit,
          isSubmitting,
          getFieldProps,
        }) => (
          <>
            <Form>
              <>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={12} md={3} lg={3}>
                    <TextField
                      fullWidth
                      variant="outlined"
                      required
                      id="subject"
                      label="subject"
                      name="subject"
                      size="small"
                      //value={values.sender}
                      {...getFieldProps("subject")}
                      error={touched.subject && Boolean(errors.subject)}
                      helperText={touched.subject && errors.subject}
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </Grid>

                  <Grid item xs={12} sm={12} md={9} lg={9}></Grid>
                  <Grid item xs={12} sm={12} md={12} lg={12}>
                    <Typography variant="h5">
                      {getTranslation(
                        "Reminder Text",
                        "Texte de rappel",
                        "Erinnerungstext"
                      )}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={12} md={12} lg={12}>
                  <TagButtons appendTemplate={AppendTemplate} classes={classes} values={values} setFieldValue={setFieldValue}/>

                  </Grid>
                  <Grid item xs={12} sm={12} md={12} lg={12}>
                    <Card>
                      <CardHeader
                        title={type}
                        style={{
                          background: "#233044",
                          color: "#FFFFFF",
                        }}
                      />
                      <CardContent>
                        <HtmlEditor
                          ref={editorRef}
                          text={template}
                          onChange={(text) => {
                            setEnableReloading(true);
                            setFieldValue("template", text);
                            setEnableReloading(false);
                          }}
                          isLoading={false}
                        />

                        <br />
                        <Box
                          pr={1}
                          pb={1}
                          width="100%"
                          display="flex"
                          alignItems="center"
                          justifyContent="center"
                        >
                          <ButtonWithLoading
                            title={getTranslation(
                              "Save",
                              "sauvegarder",
                              "Speichern"
                            )}
                            size="small"
                            variant="contained"
                            color="primary"
                            type="submit"
                            loading={isSubmitting}
                            onClick={handleSubmit}
                          />
                        </Box>
                      </CardContent>
                    </Card>
                  </Grid>
                </Grid>
              </>
            </Form>
          </>
        )}
      </Formik>
    </>
  );
};

export default MeetingReportMail;
