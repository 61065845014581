import { CardContent, Checkbox, CircularProgress, Typography } from "@material-ui/core"
import CardComponent from "../../components/Card"
import TableComponent from "../../components/table"
import { useState } from "react";
import { MeetingsSubmenu } from "../../layout/subMenu";
import { useParams } from "react-router-dom";
import { useEffect } from "react";
import { Get } from "../../actions";
import { Get_All_Meeting_Todo, Get_All_SPV_Todos, Update_Todo_ListItem_Status } from "../../constants/apiUrls";
import AlertDialog from "../../components/DeleteDialog";


export const ToDos = () => {
    const columns = [
        {
            id: "meetingTitle",
            numeric: false,
            disablePadding: true,
            label: "Meeting"
        },
        {
            id: "personInchargeName",
            numeric: false,
            disablePadding: true,
            label: "Person Incharge"
        },
        {
            id: "dueDate",
            numeric: false,
            disablePadding: true,
            label: "Due Date",
            format:"date"
        },
        {
            id: "status",
            numeric: false,
            disablePadding: true,
            label: "Status",
            component:({row})=>row.id==changingStatus?.id?<CircularProgress size="20px"/> : <Checkbox checked={row?.status} onChange={(e)=>onCheckStatusChanged(row,e.target.checked)}/>
        },
    ];
    const [searchString, setSearchString] = useState("");
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [rows, setRows] = useState([]);
    const [loading, setLoading] = useState(false);
    const [changingStatus, setChangingStatus] = useState(null);
    const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
    const { managementCompanyId } = useParams();

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value));
        setPage(0);
    };

    const handleSearchUpcomingMeeting = (searchString) => {
        setSearchString(searchString);
    };

    useEffect(() => {
        setLoading(true)
        Get(
            {
                spvId: managementCompanyId
            },
            Get_All_SPV_Todos,
            null,
            resp => {
                console.log("jkagsjhasgdhgasd", resp?.data[0])
                setRows(resp?.data)
                setLoading(false)
            },
            error => {

            }
        )
    }, [])

    const onCheckStatusChanged = (row, newStatus) => {
        const model={ id: row.id, newStatus: newStatus }
        setChangingStatus(model)
        if (newStatus)
            handleStatusChange(model)
        else {
            setOpenConfirmDialog(true)
        }
    }

    const handleStatusChange=(model)=>{
        
        Get(
            {...model,status:model.newStatus},
            Update_Todo_ListItem_Status,
            null,
            resp => {
                const temp=rows;
                const index=temp.findIndex(x=>x.id==model.id)
                temp[index]={...temp[index],status:model.newStatus}
                setRows([...temp])
                setChangingStatus(null)
                setOpenConfirmDialog(false)
            },
            error => {
                setChangingStatus(null)
            }
        )
    }

    return <>
        <MeetingsSubmenu managementCompanyId={managementCompanyId} />
        <TableComponent
            loading={loading}
            rows={rows}
            colums={columns}
            sortBy="meetingTitle"
            count={rows?.length}
            page={page}
            rowsPerPage={rowsPerPage}
            handleChangePage={(event, newPage) => handleChangePage(event, newPage)}
            handleChangeRowsPerPage={(e) => handleChangeRowsPerPage(e)}
            viewSearch
            onSearch={(searchString) => handleSearchUpcomingMeeting(searchString)}
            title="To Do's"
        />
        <AlertDialog
        confirmButtonText="Confirm"
        onClose={()=>{
            setOpenConfirmDialog(false)
            setChangingStatus(null)
        }}
        onSubmit={()=>handleStatusChange(changingStatus)}
        title="Confirmation"
        text="Are you sure?"
        open={openConfirmDialog}
        // deleting={changingStatus!=null}
        />
    </>

}