import SearchBar from "material-ui-search-bar"
import { HtmlEditor } from "../../../../components/HtmlEditor"
import { parseMinutesReportHelper } from "../../../../heplers/parseMinutesReportHelper";
import { useState } from "react";
import { useEffect } from "react";

export const ItemEditor=({selectedAgenda,updateAgenda,openTodoDialog,disabled})=>{
  const [html, setHtml] = useState("");
  useEffect(() => {
    if (selectedAgenda?.comments)
      parseMinutesReportHelper(null, selectedAgenda?.comments, (newHtml) => {
        setHtml(newHtml);
      });
      else
      setHtml("")
  }, [selectedAgenda]);
  return (
    <>
      <SearchBar />
      <br />
      <HtmlEditor
      disabled={typeof selectedAgenda?.comments == "undefined" || disabled }
        isLoading={false}
        text={html}
        onChange={(text) => updateAgenda({ ...selectedAgenda, comments: text })}
        onClickEvent={(e) =>
          e.target.getAttribute("data-edit-todolist-id")
            ? openTodoDialog(e.target.getAttribute("data-edit-todolist-id"))
            : null
        }
      />
    </>
  );
}
