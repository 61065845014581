import { Box, Grid, IconButton } from "@material-ui/core";
import ButtonWithLoading from "../../../../components/ButtonWithLoading";
import ArrowLeftIcon from "@material-ui/icons/ArrowLeft";
import ArrowRightIcon from "@material-ui/icons/ArrowRight";
import { CreateField } from "../../../../components";
import { Cancel } from "@material-ui/icons";
import { Formik } from "formik";
const Previewform = ({
  fields,
  onNext,
  onPrevious,
  onSubmit,
  onCancel,
  disableConfig,
}) => {
  var _ = require("lodash");
  return (
    <Formik
    enableReinitialize
    initialValues={{
      fields:fields
    }}
    onSubmit={({fields},actions)=>{
      onSubmit(fields)
      actions.resetForm()
    }}
    >
      {({
          values,
          errors,
          touched,
          getFieldProps,
          setFieldValue,
          handleSubmit,
        }) => (
          values.fields?.length > 0 ? (
            <>
              <Grid container spacing={1}>
                {_.sortBy(values.fields, (field) => field.sort).map(
                  (field, index, array) => (
                    <Grid item xs={12} sm={12} md={field.dataType=='text' ? 12 : 6} lg={field.dataType=='text' ? 12 : 4}>
                      {disableConfig ? null : (
                        <Box
                          display="flex"
                          justifyContent="flex-end"
                          alignItems="flex-end"
                        >
                          <IconButton
                            onClick={() => {
                              onPrevious(array, index);
                            }}
                          >
                            {index === 0 ? null : <ArrowLeftIcon />}
                          </IconButton>
                          <IconButton
                            onClick={() => {
                              onNext(array, index);
                            }}
                          >
                            {index === fields.length - 1 ? null : (
                              <ArrowRightIcon />
                            )}
                          </IconButton>
                          <IconButton onClick={() => onCancel(field)}>
                            <Cancel />
                          </IconButton>
                        </Box>
                      )}
                      <CreateField
                        key={index}
                        label={field.title}
                        variant="outlined"
                        size="small"
                        type={field.dataType}
                        value={field.value}
                        fullWidth
                        onChange={(e)=>{
                          const arr=values.fields;
                          const itemIndex=arr.findIndex(x=>x.id==field.id);
                          arr[itemIndex].value=e.target.value;
                          setFieldValue("fields",arr);
                        }}
                      />
                    </Grid>
                  )
                )}
              </Grid>
              <br />
              <Box display="flex" justifyContent="center" alignItems="center">
                <ButtonWithLoading
                  title="Submit"
                  onClick={handleSubmit}
                  variant="contained"
                  color="primary"
                />
              </Box>
            </>
          ) : null
        )
                }
        </Formik>
  );
};
export default Previewform;
