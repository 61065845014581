import React, { useEffect, useState } from "react";
import Table from "../../../../components/table";
import DeleteDialog from "../../../../components/DeleteDialog";
import { Create, Get } from "../../../../actions";
import {
  Get_AllForms_URL,
  Get_AllTables_URL,
  Post_DeleteTable_URL,
  Post_UpsertTable_URL,
} from "../../../../constants/apiUrls";
import AddTable from "./tableAdd";
import { useSnackbar } from "notistack";
const columns = [
  {
    id: "title",
    numeric: false,
    disablePadding: true,
    label: "Table",
  },
  {
    id: "actions",
    numeric: true,
    disablePadding: false,
    align: "right",
    label: "Actions",
  },
];

const TableList = () => {
  ////////////// Form States //////////////

  const [forms, setForms] = useState([]);
  const [formLoading, setFormLoading] = useState(false);
  const [formSubmitting, setFormSubmitting] = useState(false);

  ///////// Delete Dialog ///////////////

  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [deleteRow, setDeleteRow] = useState(null);

  /////////////// Table States ////////////////
  const [tableList, setTableList] = useState([]);
  const [tableLoading, setTableLoading] = useState(false);
  const [editTable, setEditTable] = useState(null);

  /////////// handler states ///////////

  const [searchString, setSearchString] = useState("");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const { enqueueSnackbar } = useSnackbar();
  /////////////// Table Handlers ///////////////

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value));
    setPage(0);
  };
  const handleSearch = (searchString) => {
    setSearchString(searchString);
  };

  const handleDelete = () => {
    setIsDeleting(true);
    Create(
      { id: deleteRow.id },
      Post_DeleteTable_URL,
      null,
      (resp) => {
        var newTableList = tableList.filter(
          (table) => table.id !== deleteRow.id
        );
        setTableList(newTableList);
        setIsDeleting(false);
        setOpenDeleteDialog(false);
      },
      (error) => {
        setIsDeleting(false);
        setOpenDeleteDialog(false);
      }
    );
  };

  //////////// Load Forms ///////////////

  useEffect(() => {
    loadForms();
  }, []);
  const loadForms = () => {
    setFormLoading(true);
    Get(
      {},
      Get_AllForms_URL,
      null,
      (resp) => {
        setForms(resp.data);
        setFormLoading(false);
      },
      (error) => {
        setFormLoading(false);
        console.log(error);
      }
    );
  };

  //////////// Load Tables ///////////////

  useEffect(() => {
    loadTables();
  }, []);
  const loadTables = () => {
    setTableLoading(true);
    Get(
      {},
      Get_AllTables_URL,
      null,
      (resp) => {
        setTableList(resp.data);
        setTableLoading(false);
      },
      (error) => {
        setTableLoading(false);
        console.log(error);
      }
    );
  };
  const handleSubmitTable = (values, actions) => {
    setFormSubmitting(true);
    Create(
      values,
      Post_UpsertTable_URL,
      null,
      (resp) => {
        actions.setSubmitting(false);
        setFormSubmitting(false);
        setEditTable(null);
        enqueueSnackbar("Table Created Successfully", { variant: "success" });
        loadTables();
        actions.resetForm();
      },
      (error) => {
        setFormSubmitting(false);
        actions.setSubmitting(false);
        enqueueSnackbar("Error Creating Table", { variant: "error" });
      }
    );
  };

  return (
    <div>
      <AddTable
        editTable={editTable}
        formLoading={formLoading}
        formSubmitting={formSubmitting}
        forms={forms}
        onSubmit={handleSubmitTable}
        onCancel={() => setEditTable(null)}
      />
      <br />
      <br />
      <Table
        dense
        title="Table"
        colums={columns}
        sortBy="table"
        rows={tableList}
        count={tableList.length}
        page={page}
        rowsPerPage={rowsPerPage}
        handleChangePage={handleChangePage}
        handleChangeRowsPerPage={handleChangeRowsPerPage}
        viewSearch
        viewCreate
        onCreate={() => {}}
        viewDelete
        onDelete={(row) => {
          setDeleteRow(row);
          setOpenDeleteDialog(true);
        }}
        viewEdit
        onEdit={(row) => setEditTable(row)}
      />
      <DeleteDialog
        title="Delete Table"
        deleting={isDeleting}
        open={openDeleteDialog}
        onClose={() => setOpenDeleteDialog(false)}
        onSubmit={handleDelete}
      />
    </div>
  );
};

export default TableList;
