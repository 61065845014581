import React, { useState, useEffect } from "react";
import {
  CardContent,
  CardActions,
  Grid,
  TextField,
  Box,
  Typography,
  IconButton,
  Select,
  MenuItem,
} from "@material-ui/core";
import { Form, Formik } from "formik";
import { useSnackbar } from "notistack";
import Card from "../../../../components/Card";
import { makeStyles } from "@material-ui/core/styles";
import Autocomplete, {
  createFilterOptions,
} from "@material-ui/lab/Autocomplete";
import ButtonWithLoading from "../../../../components/ButtonWithLoading";
import { useDispatch, useSelector } from "react-redux";
import * as Yup from "yup";
import { useNavigate } from "react-router-dom";
import CircularProgress from "@material-ui/core/CircularProgress";
import { Create, Get } from "../../../../actions";
import {
  GET_AllContactList_URL,
  GET_AllContacts_URL,
  GET_AllUsersList_URL,
  GET_CompanyTeam_URL,
  Post_CompanyTeam_URL,
} from "../../../../constants/apiUrls";
import { getTranslation } from "../../../../heplers/translationHelper";
import { Cancel } from "@material-ui/icons";
import { CeresTeamMemberResponsibilites } from "../../../../constants/ceresTeamMemberResponsibilities";
import { Field, ErrorMessage } from "formik";

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

const representativeRoles = ["President", "Secretary", "Ordinary Member"];

const AddTeamMember = ({ team, onCancel, isSubmitting, editTeamMember, onSubmit }) => {

  console.log("team", team)
  const [contacts, setContacts] = useState([]);
  const { enqueueSnackbar } = useSnackbar();
  const [selectedContact, setSelectedContact] = useState(null);
  const [loadingContact, setLoadingContact] = useState(false);

  const history = useNavigate();
  const filter = createFilterOptions();


  useEffect(() => {
    loadContacts();
  }, []);

  const loadContacts = () => {
    setLoadingContact(true);
    Get(
      {},
      GET_AllUsersList_URL,
      history,
      (resp) => {

        setContacts(resp.data);
        setLoadingContact(false);
      },
      (error) => { }
    );
  };

  useEffect(() => {
    if (editTeamMember && contacts.length > 0) {
      const item = contacts.find(x => x.id == editTeamMember.appUserId)
      setSelectedContact(item)
    }
  }, [editTeamMember, contacts])

  const defaultResponsibility = CeresTeamMemberResponsibilites[0].id;

  const initialValues = {
    appUserId: "",
    responsibility: defaultResponsibility,
    name: "",
  };

  const basicValidationSchema = Yup.object().shape({
    appUserId: Yup.string().required(getTranslation("Contact is required", "Le contact est requis", "Kontakt ist erforderlich")),
    responsibility: Yup.string().required(getTranslation("Responsibility is required", "La responsabilité s'impose", "Verantwortung ist gefragt")),
  });

  const defaultValue = editTeamMember ? editTeamMember : initialValues;

  const checkIfResponsibilityExists = (team, responsibility) => {
    return team.some((member) => member.responsibility === responsibility);
  };

  return (
    <Card title="Add Team member">
      {/* <CardHeader title='Details' /> */}
      <Formik
        enableReinitialize
        initialValues={defaultValue}
        validationSchema={basicValidationSchema}
        onSubmit={(values, actions) => {
          const internalReviewExists = checkIfResponsibilityExists(team, "internalreview");
          const minutesExists = checkIfResponsibilityExists(team, "minutes");

          if (internalReviewExists && values.responsibility === "internalreview") {
            enqueueSnackbar("Internal review Responsibilty already exists in the team.", { variant: "error" });
          } else if (minutesExists && values.responsibility === "minutes") {
            enqueueSnackbar("Minutes review Responsibility already exists in the team.", { variant: "error" });
          } else {
            actions.setSubmitting(true);
            onSubmit(values, actions);
            actions.resetForm();
          }


        }}
      >
        {({
          errors,
          touched,
          values,
          handleSubmit,
          getFieldProps,
          setFieldValue,
          setTouched,
        }) => (
          <Form>
            <CardContent>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={12} md={4} lg={4}>
                  <Autocomplete
                    name="name"
                    value={selectedContact}
                    options={contacts}
                    getOptionLabel={(option) => option.name}
                    getOptionSelected={(option, value) => {
                      console.log("ajklshdjasdhasjkldha", option)
                      return value.id == editTeamMember?.appUserId
                    }}
                    size="small"
                    onChange={(e, value) => {
                      // setFieldValue("appUserId", value?.id);
                      // setFieldValue("name", value);
                      // setSelectedContact(value);
                      setSelectedContact(value); 
                      setFieldValue("appUserId", value ? value.id : ""); 
                      setFieldValue("name", value ? value.name : ""); 
                      setTouched("appUserId", true); 
                      setTouched("responsibility", true); 
                    }}
                    onBlur={() => {
                      setTouched("appUserId", true);
                      setTouched("responsibility", true);
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Select Contact"
                        variant="outlined"
                        error={(touched.appUserId || touched.responsibility) && Boolean(errors.appUserId || errors.responsibility)}
                        helperText={(touched.appUserId || touched.responsibility) && (errors.appUserId || errors.responsibility)}
                      />
                    )}
                  />

                  {/* <Autocomplete
  name="name"
  value={selectedContact}
  options={contacts}
  getOptionLabel={(option) => option.name}
  onChange={(event, value) => {
    setSelectedContact(value); // Set the selected contact
    setFieldValue("appUserId", value ? value.id : ""); // Set appUserId
    setFieldValue("name", value ? value.name : ""); // Set name
    setTouched("appUserId", true); // Mark appUserId as touched
    setTouched("responsibility", true); // Mark responsibility as touched
  }}
  onBlur={() => {
    // Manually trigger validation when the field loses focus
    setTouched("appUserId", true);
    setTouched("responsibility", true);
  }}
  renderInput={(params) => (
    <TextField
      {...params}
      label="Select Contact"
      variant="outlined"
      error={(touched.appUserId || touched.responsibility) && Boolean(errors.appUserId || errors.responsibility)}
      helperText={(touched.appUserId || touched.responsibility) && (errors.appUserId || errors.responsibility)}
    />
  )}
/> */}



                </Grid>
                {/* <Grid item xs={12} sm={12} md={4} lg={4}>
                  <Select
                  name="responsibility"
                  {...getFieldProps("responsibility")} 
                   title={getTranslation(
                    "Select Responsibility",
                    "Sélectionnez la responsabilité",
                    "Wählen Sie Verantwortung"
                  )}
                  style={{
                    marginRight: "20px",
                    height: "34px",
                    width:"100%"
                  }}
                    value={values.responsibility}
                    size="small"
                    onChange={(e) => {
                      setFieldValue("responsibility", e.target.value);
                    }}
                    // onChange={(e, value) => {
                    //   setFieldValue("responsibility", value);
                    // }}
                    error={
                      touched.responsibility &&
                      Boolean(errors.responsibility)
                    }
                    helperText={touched.responsibility && errors.responsibility}
                    
                  >
                     {CeresTeamMemberResponsibilites.map(category=><MenuItem value={category.id}>{getTranslation(category.defaultEnglish,category.defaultFrench,category.defaultGerman)}</MenuItem>)}
                       
                  </Select>
                </Grid> */}
                <Grid item xs={12} sm={12} md={4} lg={4}>
                  <Select
                    name="responsibility"
                    {...getFieldProps("responsibility")}
                    title={getTranslation(
                      "Select Responsibility",
                      "Sélectionnez la responsabilité",
                      "Wählen Sie Verantwortung"
                    )}
                    style={{
                      marginRight: "20px",
                      height: "34px",
                      width: "100%",
                    }}
                    value={values.responsibility}
                    onChange={(e) => {
                      setFieldValue("responsibility", e.target.value);
                    }}
                    error={touched.responsibility && Boolean(errors.responsibility)}
                    helperText={touched.responsibility && errors.responsibility}
                  >
                    {CeresTeamMemberResponsibilites.map((category) => (
                      <MenuItem key={category.id} value={category.id}>
                        {getTranslation(
                          category.defaultEnglish,
                          category.defaultFrench,
                          category.defaultGerman
                        )}
                      </MenuItem>
                    ))}
                  </Select>
                </Grid>
                <Grid item xs={12} sm={12} md={4} lg={4}>
                  <Box pr={1} pb={1} width="100%">
                    {editTeamMember ? <IconButton onClick={onCancel}>
                      <Cancel />
                    </IconButton> : null}
                    <ButtonWithLoading
                      title={
                        editTeamMember
                          ? getTranslation(
                            "Update",
                            "Mettre à jour",
                            "Aktualisieren"
                          )
                          : getTranslation("Add", "Ajouter", "Addieren")
                      }
                      size="small"
                      variant="contained"
                      color="primary"
                      loading={isSubmitting}
                      onClick={handleSubmit}
                    />
                  </Box>
                </Grid>
              </Grid>
            </CardContent>
          </Form>
        )}
      </Formik>
    </Card>
  );
};

export default AddTeamMember;
