import { Button, Dialog, Typography } from "@material-ui/core";
import { QRCodeReader } from "../../heplers/qrReader";
import { scanCircularResolution } from "./operations";
import { circularResolutionType } from "./scannerTypes";
import { useSnackbar } from "notistack";
import { useEffect, useState } from "react";
import { getTranslation } from "../../heplers/translationHelper";

export const ScanDocument = ({ isOpen, title, onClose }) => {
  const { enqueueSnackbar } = useSnackbar();

  const handleScanDocument = (result) => {
    if (result) {
      const data = JSON.parse(result);
      switch (data.type) {
        case circularResolutionType:
          scanCircularResolution(
            data.value,
            (resp) => {
              enqueueSnackbar("Circular Resolution has been signed.", {
                variant: "success",
              });
            },
            (error) => {
              enqueueSnackbar("Circular Resolution has not been signed.", {
                variant: "error",
              });
            }
          );
          break;
        default:
      }
    }
  };

  const onError = (error) => {
    if (error) {
      console.error(error);
    }
  };
  const [permission, setPermission] = useState(false);

  const checkPermissions = () => {
    const permissions = navigator.mediaDevices.getUserMedia({
      audio: false,
      video: true,
    });
    permissions
      .then((stream) => {
        alert("Permission granted");
        setPermission(true);
      })
      .catch((err) => {
        enqueueSnackbar("Not able to access to camera.", { variant: "error" });
      });
  };

  useEffect(() => {
    if (isOpen) checkPermissions();
  }, []);

  return (
    <>
      <Dialog open={isOpen} title={getTranslation("Scan Qr Document","Numériser un document Qr","Qr-Dokument scannen")} onClose={onClose}>
        <Typography style={{ textAlign: "center" }}>Scan QR Code</Typography>
        <QRCodeReader
          onScan={handleScanDocument}
          onError={onError}
          style={{
            width: "500px",
            height: "500px",
          }}
        />
        <Button
          style={{ width: "50%", height: "10%", margin: "25%" }}
          color="primary"
          variant="outlined"
          onClick={onClose}
        >
          Close Scanner
        </Button>
      </Dialog>
    </>
  );
};
