import React, { useState, useEffect, useRef } from "react";
//import MeetingInformation from '../MeetingInformation';
import { Box, Grid, Typography } from "@material-ui/core";
import { Card, CardContent } from "@material-ui/core";
import MeetingInformation from "../components/MeetingInformation";
import JoinMethod from "../components/joiningmethod";

import { useNavigate, useParams } from "react-router";
import { Get, Create } from "../../../actions";
import {
  Post_PowerOfAttorney_URL,
  Post_JoinMeeting_URL,
  Get_MeetingInfo_URL,
  Get_PowerOfAttorney_URL,
  Get_MeetingMember_URL,
} from "../../../constants/apiUrls";
import { useSnackbar } from "notistack";
import { InvitationPrint } from "..";
import { useReactToPrint } from "react-to-print";
import * as Yup from "yup";

export const JoinMeeting = () => {
  const [enablePrint, setEnablePrint] = useState(false);
  const { meetingId, meetingMemberId } = useParams();
  const [POId, setPOId] = useState();
  const { enqueueSnackbar } = useSnackbar();
  const [powerOfAttorney, setPowerOfAttorney] = useState();
  ///////////////////////////////////////////// Submit Join Meeting Method //////////////////////////////////
  const [printDocument, setPrintDocument] = useState(false);
  const handleSubmit = (values, actions) => {
    setPowerOfAttorney(values)
    Create(
      values,
      values.enablePowerOfAttorney
        ? Post_PowerOfAttorney_URL
        : Post_JoinMeeting_URL,
      history,
      (resp) => {
        setPOId(resp?.data);
        actions.setSubmitting(false);
        enqueueSnackbar(resp.data, { variant: "success" });
        if(values.enablePowerOfAttorney){
          setPrintDocument(true)
        }
      },
      (error) => {
        enqueueSnackbar(error?.data, { variant: "warning" });
        actions.setSubmitting(false);
      }
    );
  };

  useEffect(()=>{
    if(POId && printDocument){
      handlePrint();
    }
  },[POId,printDocument])

  /////////////////////////////////////////////////// Get Meeting Information  /////////////////////////////

  const [meetingInfo, setMeetingInfo] = useState();
  const history = useNavigate();

  useEffect(() => {
    loadMeetingInfo({
      meetingId: meetingId,
    });
  }, [meetingId]);
  const loadMeetingInfo = (values) => {
    Get(
      values,
      Get_MeetingInfo_URL,
      history,
      (resp) => {
        setMeetingInfo(resp?.data);
      },
      (error) => {}
    );
  };
  /////////////////////////////////////////////////// Get Personal Information  /////////////////////////////
  const [personalInfo, setPersonalInfo] = useState();
  useEffect(() => {
    loadPersonalInfo({
      id: meetingMemberId,
    });
  }, [meetingMemberId]);
  const loadPersonalInfo = (values) => {
    Get(
      values,
      Get_MeetingMember_URL,
      history,
      (resp) => {
        setPersonalInfo(resp?.data);
      },
      (error) => {
        enqueueSnackbar(error?.data, { variant: "error" });
      }
    );
  };
  
  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    documentTitle: "Invitation",
    content: () => componentRef.current,
  });
  return (
    <>
      <Card>
        <CardContent>
          <Typography
            variant="title"
            component="h3"
            align="center"
            style={{
              fontFamily: "Revalia",
            }}
          >
            Ceres
          </Typography>
          <MeetingInformation meetingId={meetingId} meetingInfo={meetingInfo} />
          <br />
          <br />
          <JoinMethod
            meetingMemberId={meetingMemberId}
            onSubmit={handleSubmit}
          />
        </CardContent>
      </Card>

      <div style={{display:"none"}} >
        <InvitationPrint
          ref={componentRef}
          meetingInfo={meetingInfo}
          personalInfo={personalInfo}
          powerOfAttorneyId={POId}
          powerOfAttorney={powerOfAttorney}
        />
      </div>
    </>
  );
};
