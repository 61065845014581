import {
  Box,
  CardContent,
  Grid,
  TextField,
  Typography,
} from "@material-ui/core";
import { useState } from "react";
import { useEffect } from "react";
import { Get } from "../../../../../../actions";
import ButtonWithLoading from "../../../../../../components/ButtonWithLoading";
import Card from "../../../../../../components/Card";
import {
  GET_AllContacts_URL,
  GET_AllTeamMembers_URL,
  Get_Spv_TeamMembers_URL
} from "../../../../../../constants/apiUrls";
import { getTranslation } from "../../../../../../heplers/translationHelper";
import { AddGuest } from "../guest/addGuest";
import { GuestList } from "../guest/guestList";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { CeresTeamMemberResponsibilites } from "../../../../../../constants/ceresTeamMemberResponsibilities";
import { useNavigate, useParams } from "react-router-dom";
import Table from "../../../../../../components/table";

const Minutes = ({ NextStep, BackStep, initialValues }) => {
  const columns = [
    {
      id: "name",
      numeric: false,
      disablePadding: true,
      label: getTranslation("Name", "Nom", "Name"),
    },
    {
      id: "responsibility",
      numeric: false,
      disablePadding: true,
      label: getTranslation(
        "Responsibility",
        "Responsabilité",
        "Verantwortung"
      ),
    },
  ];
  
  const {managementCompanyId} = useParams();
  const history = useNavigate();
  const [contacts, setContacts] = useState([]);
  const [team, setTeam] = useState({});
  const [loadingTeamMembers, setloadingTeamMembers] = useState(false);
  const [searchString, setSearchString] = useState("");
  const [page, setPage] = useState(0);
  const [createCompany, setCreateCompany] = useState(false);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [rows, setRows] = useState([]);

  const loadTeamMembers = () => {
    setloadingTeamMembers(true);
    Get(
      {
        id: managementCompanyId,
      },
      Get_Spv_TeamMembers_URL,
      history,
      (resp) => {
        setTeam(resp.data);
        setloadingTeamMembers(false);
      },
      (error) => {}
    );
  };
  useEffect(() => {
    loadTeamMembers();
    loadContacts();
  }, []);
  const loadContacts = () => {
    Get(
      {},
      GET_AllContacts_URL,
      null,
      (resp) => {
        const arr = [];
        resp?.data?.data?.forEach((item) => {
          item.contactId = item.id;
          // item.memberId=item.id;
          arr.push(item);
        });
        setContacts(arr);
      },
      (error) => {}
    );
  };

  const basicValidationSchema = Yup.object().shape({
    minutesFundManagerId: Yup.string().required("Field is required"),
    contacts: Yup.object().nullable().required("Field is required"),
  });
  

  console.log(contacts);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value));
    setPage(0);
  };

  const handleSearchManagementCompanyFund = (searchString) => {
    setSearchString(searchString);
  };

  return (
    <Card title={getTranslation("Minutes", "Minutes", "Protokoll")}>
      <CardContent>
        <Formik
          initialValues={initialValues}
          validationSchema={basicValidationSchema}
          onSubmit={(values) => {
            console.log(values);
             NextStep(values);
          }}
        >
          {({
            values,
            handleSubmit,
            setFieldValue,
            getFieldProps,
            setTouched,
            errors,
            touched,
          }) => (
            <Form>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={12} md={3} lg={3}>
                  <Autocomplete
                    fullWidth
                    id="combo-box-demo"
                    size="small"
                    options={contacts}
                    getOptionLabel={(option) => option?.fullName}
                    value={values.contacts}
                    onChange={(event, value) => {
                      setFieldValue("contacts", value);
                      setFieldValue("minutesFundManagerId", value?.id);
                      setTouched("reportFundManagerId", true); 
                      setTouched("contacts", true); 
                    }}
                    onBlur={() => {
                      setTouched("reportFundManagerId", true); 
                      setTouched("contacts", true); 
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label={getTranslation(
                          "Fund Manager",
                          "Le chef du financement",
                          "Fondsmanager"
                        )}
                        variant="outlined"
                        error={(touched.reportFundManagerId || touched.contacts) && Boolean(errors.reportFundManagerId || errors.contacts)}
                        helperText={(touched.reportFundManagerId || touched.contacts) && (errors.reportFundManagerId || errors.contacts)}
                      />
                    )}
                  />
                </Grid>
              </Grid>

              <br />
              <br />

              <Table
                dense
                loading={loadingTeamMembers}
                title={getTranslation(
                  "Team Members",
                  "Membres de l'équipe",
                  "Teammitglieder"
                )}
                colums={columns}
                sortBy="title"
                rows={team}
                count={team.length}
                page={page}
                rowsPerPage={rowsPerPage}
                handleChangePage={(event, newPage) =>
                  handleChangePage(event, newPage)
                }
                handleChangeRowsPerPage={(e) => handleChangeRowsPerPage(e)}
                viewSearch
                onSearch={(searchString) =>
                  handleSearchManagementCompanyFund(searchString)
                }
              />

              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="space-between"
                m={2}
              >
                <ButtonWithLoading
                  title={getTranslation("Back", "Précédent", "Zurück")}
                  color="primary"
                  variant="contained"
                  size="large"
                  onClick={() => BackStep()}
                />
                <ButtonWithLoading
                  title={getTranslation("Next", "Suivant", "Weiter")}
                  color="primary"
                  variant="contained"
                  size="large"
                  onClick={handleSubmit}
                />
              </Box>
            </Form>
          )}
        </Formik>
      </CardContent>
    </Card>
  );
};

export default Minutes;
