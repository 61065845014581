import React, { useState, useEffect } from "react";
import { Box, Paper, TextField } from "@material-ui/core";
import Table from "../../../components/table";
import { GetBelongsTo } from "../../../actions";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useSnackbar } from "notistack";
import checkResponse from "../../../heplers/responseHelper";
import DeleteDialog from "../../../components/DeleteDialog";
import { getTranslation } from "../../../heplers/translationHelper";

const columns = [
  {
    id: "companyName",
    numeric: false,
    disablePadding: true,
    label: getTranslation("Company Name","Nom de l'entreprise","Name der Firma"),
  },
  {
    id: "representativeRole",
    numeric: false,
    disablePadding: true,
    label: getTranslation("Role","Rôle","Rolle"),
  },
];
const belongsTo = [
  {
    comapanyName: "AC Balanced Real Return",
    role: "Manager",
    id: "123",
  },
  {
    comapanyName: "AC Balanced Real Return",
    role: "Assist Manager",
    id: "124",
  },
];

const ContactBelongsTo = ({ contactId }) => {
  const history = useNavigate();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const [open, setOpen] = useState(false);
  const [deleteRow, setDeleteRow] = useState({});
  const [searchString, setSearchString] = useState("");
  const [page, setPage] = useState(null);
  const [createCompany, setCreateCompany] = useState(false);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [rows, setRows] = useState([]);
  const { contacts, loading } = useSelector((state) => state.contacts);

  useEffect(() => {
    dispatch({
      type: "Get_Contact_BelongsTo",
      payload: null,
    });
    dispatch(
      GetBelongsTo(
        {
          contactId: contactId,
        },
        history
      )
    );
  }, [contactId]);

  const handleOpenDeleteDialog = (row) => {
    setDeleteRow(row);
    setOpen(true);
  };

  const handleDeleteActivity = (row) => {
    // const deleteArray = [];
    // deleteArray.push(row.id);
    // dispatch(deleteActivity(deleteArray, history)).then((response) => {
    //   const errors = checkResponse(response);
    //   if (!errors) {
    //     enqueueSnackbar("Activity  deleted", { variant: "success" });
    //     setOpen(false)
    //     dispatch(
    //       getActivities({
    //         searchString: "",
    //         pageNumber: 1,
    //         pageSize: 5,
    //       })
    //     );
    //   } else {
    //     errors.map((error) =>
    //       enqueueSnackbar(error.message, { variant: "error" })
    //     );
    //   }
    // });
  };

  const handleEditActivity = (row) => {
    history(`/companies/${row.id}/basic`);
    // dispatch(editActivityType(row));
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value));
    setPage(0);
  };

  const handleCreateCompany = (event) => {
    setCreateCompany(true);
  };
  const handleCreateCompanyClose = (event) => {
    setCreateCompany(false);
  };
  const handleSearchCompany = (searchString) => {
    setSearchString(searchString);
  };
  return (
    <div>
      <Table
        dense
        loading={loading}
        title={getTranslation("Belongs To","Appartient à","Gehört")}
        colums={columns}
        sortBy="comapanyName"
        // rows={activities?.data}
        // count={activities?.totalCount}
        rows={contacts}
        count={contacts?.length}
        page={page}
        rowsPerPage={rowsPerPage}
        handleChangePage={(event, newPage) => handleChangePage(event, newPage)}
        handleChangeRowsPerPage={(e) => handleChangeRowsPerPage(e)}
        viewSearch
      />
      <DeleteDialog
        open={open}
        title={getTranslation("Delete Company","Supprimer l'entreprise","Unternehmen löschen")}
        onClose={() => setOpen(false)}
        onSubmit={(e) => handleDeleteActivity(deleteRow)}
      />
    </div>
  );
};

export default ContactBelongsTo;
