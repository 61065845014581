import GenerateAdhoc from "./component/adhoc/generate";
import { useParams } from "react-router-dom";
import { Guest } from "./component/adhoc/guest";
import AdhocMeetingList from "./component/adhoc/list";
import UploadFiles from "./component/adhoc/upload";
import { Create, Get } from "../../actions";
import { useNavigate } from "react-router-dom";

import {
  Get_GetAllAdhocMeetingsWithPagination_URL,
  Get_ManagementCompanyFeeAgreement_URL,
  Post_GenerateAdhocMeetings_URL,
} from "../../constants/apiUrls";
import { useSnackbar } from "notistack";
import { useEffect, useState } from "react";
import { Collapser } from "../../components/Collapser";
import { MeetingAuditLog } from "./component/auditLog";
import { getTranslation } from "../../heplers/translationHelper";
import { MeetingsSubmenu } from "../../layout/subMenu";
import { Alert } from "@material-ui/lab";
import { StepperAdhocMeeting } from "./component";
export const AdhocMeeting = () => {
  const { managementCompanyId } = useParams();
  const { enqueueSnackbar } = useSnackbar();
  const [adhocMeetings, setAdhocMeetings] = useState([]);
  const [adhocMeetingsLoading, setAdhocMeetingsLoading] = useState(false);
  const [openMeetingLogDialog, setOpenMeetingLogDialog] = useState(false);
  const [meetingIdLog, setMeetingIdLog] = useState();
  const history = useNavigate();
  const [meeting, setMeeting] = useState();

  const handleShowMeetingLogs = (meetingId) => {
    setMeetingIdLog(meetingId);
    setOpenMeetingLogDialog(true);
  };

  const handleGenerateAdhocMeeting = (meeting, actions) => {
    Create(
      meeting,
      Post_GenerateAdhocMeetings_URL,
      null,
      (resp) => {
        actions.setSubmitting(false);
        enqueueSnackbar("Adhoc meeting has been generated.", {
          variant: "success",
        });
        actions.resetForm();
        loadAdhochMeetings();
      },
      (error) => {
        actions.setSubmitting(false);
        enqueueSnackbar(`${error.data}`, { variant: "error" });
      }
    );
  };

  const loadAdhochMeetings = (filter) => {
    setAdhocMeetingsLoading(true);
    Get(
      filter,
      Get_GetAllAdhocMeetingsWithPagination_URL,
      null,
      (resp) => {
        setAdhocMeetingsLoading(false);
        setAdhocMeetings(resp.data);
      },
      (error) => {
        setAdhocMeetingsLoading(false);
      }
    );
  };
  const handleMeetingDetails = (meeting) => {
    const url = `regular/${meeting.id}`;
    history(url);
  };

  ////////////// Fee agreement Status

  useEffect(() => {
    loadFeeAgreement({ id: managementCompanyId });
  }, [managementCompanyId]);

  const loadFeeAgreement = () => {
    Get(
      { id: managementCompanyId },
      Get_ManagementCompanyFeeAgreement_URL,
      null,
      (resp) => {
        setMeeting(resp.data);
      },
      (error) => {}
    );
  };
  return (
    <div>
      <MeetingsSubmenu managementCompanyId={managementCompanyId} />

      {meeting?.feeAgreementStatus === 7 ? (
        <Alert severity="error">
          {getTranslation(
            "Fee Agreement does not exist",
            "Le contrat d'honoraires n'existe pas",
            "Honorarvereinbarung existiert nicht"
          )}
        </Alert>
      ) : meeting?.services ? (
        <Alert severity="error">
          {getTranslation(
            "Fee Agreement not signed but you can generate meetings",
            "Accord d'honoraires non signé mais vous pouvez générer des rendez-vous",
            "Gebührenvereinbarung nicht unterzeichnet, aber Sie können Meetings generieren"
          )}
        </Alert>
      ) : null}
      <br />
      <br />
      <Collapser
        title={getTranslation(
          "Upcoming Ad Hoc Meetings",
          "Prochaines réunions ad hoc",
          "Anstehende Ad-hoc-Meetings"
        )}
      >
        <AdhocMeetingList
          managementCompanyId={managementCompanyId}
          meetings={adhocMeetings}
          onFilter={(filter) => {
            loadAdhochMeetings(filter);
          }}
          onAdd={handleMeetingDetails}
          loading={adhocMeetingsLoading}
          onShowlogs={handleShowMeetingLogs}
        />
      </Collapser>
      <br />
      {meeting?.feeAgreementStatus !== 7 ? (
        <Collapser
          title={getTranslation(
            "Generate Ad Hoc Meetings",
            "Générer des réunions ad hoc",
            "Generieren Sie Ad-hoc-Meetings"
          )}
        >
          <StepperAdhocMeeting managementCompanyId={managementCompanyId} />
        </Collapser>
      ) : null}
      <MeetingAuditLog
        open={openMeetingLogDialog}
        meetingId={meetingIdLog}
        onClose={() => {
          setOpenMeetingLogDialog(false);
          setMeetingIdLog(null);
        }}
      />
    </div>
  );
};
