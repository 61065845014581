import { Box, DialogActions, TextField } from "@material-ui/core"
import DialogComponent from "../../../../components/Dialog"
import { useEffect, useState } from "react"
import ButtonWithLoading from "../../../../components/ButtonWithLoading"
import { getTranslation } from "../../../../heplers/translationHelper"

export const DeclineCommentsDialog=({open,onSubmit,onClose})=>{
    const [comments,setComments]=useState("")

    return <DialogComponent title="Decline" open={open} maxWidth="md" fullWidth onClose={onClose}>
        <TextField
        fullWidth
        variant="outlined"
        label={getTranslation("Comments","Comments","Comments")}
        multiline
        minRows={5}
        value={comments}
        onChange={(e)=>setComments(e.target.value)}
        ></TextField>
        <DialogActions>
        <Box
                width="100%"
                display="flex"
                alignItems="center"
                justifyContent="center"
              >
                <ButtonWithLoading
                  style={{ margin: "15px" }}
                  title={getTranslation("Cancel","Cancel","Cancel")}
                  size="small"
                  variant="contained"
                  color="primary"
                //   loading={isSubmitting}
                  onClick={(e)=>{onClose()}}
                />
                <ButtonWithLoading
                  style={{ margin: "15px" }}
                  title={getTranslation("Confirm","Confirm","Confirm")}
                  size="small"
                  variant="contained"
                  color="default"
                //   loading={isSubmitting}
                  onClick={(e)=>{onSubmit(comments)}}
                />
               
              </Box>
        </DialogActions>
    </DialogComponent>
}