import React from 'react';
import Upload from "../assests/icons/uploadIcon.png"
import Add from '../assests/icons/addIcon.png'
import Close from '../assests/icons/closeIcon.png'
import Scanner from '../assests/icons/scannerIcon.png'
import managementCompanyIcon from '../assests/icons/managementCompanyIcon.svg'
import gearIconSvg from '../assests/icons/gear.svg'
import alarmIconSvg from '../assests/icons/alarm_clock.svg'
import assetsSvg from '../assests/icons/assets.svg'
import companiesSvg from '../assests/icons/companies.svg'
import contactsSvg from '../assests/icons/contacts.svg'
import fundsSvg from '../assests/icons/funds.svg'
import meetingsSvg from '../assests/icons/meetings.svg'
import serviceProviderSvg from '../assests/icons/serviceProvider.svg'
import servicesSvg from '../assests/icons/services.svg'
import settingsSvg from '../assests/icons/settings.svg'
import userManagementSvg from '../assests/icons/userManagement.svg'
import editsvg from "../assests/icons/editsvg.svg"

import Logo_English_Language from '../assests/images/english.png'
import Logo_French_Language from '../assests/images/french.png'
import Logo_German_Language from '../assests/images/german.png'


export const UploadIcon = (props) => {
  return <img alt="Logo" src={Upload} {...props} />;
};

export const IconAdd = (props) => {
  return <img alt="Logo" src={Add} {...props} style={{width:"22px",height:"22px"}}/>;
};

export const CloseIcon = (props) => {
  return <img alt="close" src={Close} {...props} />;
};

export const IconScanner = (props) => {
  return <img alt="close" src={Scanner} {...props} style={{height:"40px", width:"40px"}}/>;
};

export const ManagementCompaniesIcon = (props) => {
  return <img alt="close" src={managementCompanyIcon} {...props} />;
};
export const GearIcon = (props) => {
  return <img alt="close" src={gearIconSvg} {...props} />;
};

export const AlarmClockIcon = (props) => {
  return <img alt="close" src={alarmIconSvg} {...props} />;
};

export const EditSvg = (props) => {
  return <img alt="close" src={editsvg} {...props} />;
};

export const AssetsIcon = (props) => {
  return <img alt="close" src={assetsSvg} {...props} />;
};

export const CompaniesIcon = (props) => {
  return <img alt="close" src={companiesSvg} {...props} />;
};

export const ContactsIcon = (props) => {
  return <img alt="close" src={contactsSvg} {...props} />;
};

export const FundsIcon = (props) => {
  return <img alt="close" src={fundsSvg} {...props} />;
};

export const MeetingsIcon = (props) => {
  return <img alt="close" src={meetingsSvg} {...props} />;
};

export const ServiceProviderIcon = (props) => {
  return <img alt="close" src={serviceProviderSvg} {...props} />;
};

export const ServicesIcon = (props) => {
  return <img alt="close" src={servicesSvg} {...props} />;
};

export const SettingsIcon = (props) => {
  return <img alt="close" src={settingsSvg} {...props} />;
};

export const userManagementIcon = (props) => {
  return <img alt="close" src={userManagementSvg} {...props} />;
};

export const EnglishLanguageIcon = () => (
  <img
    alt="English"
    src={Logo_English_Language}
    width="20"
    height="20"
  />
);
export const FrenchLanguageIcon = () => (
  <img alt="French" src={Logo_French_Language} width="20" height="20" />
);
export const GermanLanguageIcon = () => (
  <img alt="German" src={Logo_German_Language} width="20" height="20" />
);