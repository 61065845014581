import React, { useState, useEffect } from "react";
import Table from "../../../../../../components/table";
import { useSnackbar } from "notistack";
import { getTranslation } from "../../../../../../heplers/translationHelper";

export const GuestList = ({ enableDelete, guests, onDelete }) => {
  const columns = [
    {
      id: "fullName",
      numeric: false,
      label: getTranslation("Name","Nom","Name"),
    },
    {
      id: "mail",
      numeric: false,
      label: getTranslation("Email","E-mail","Email"),
    },
    {
      id: "actions",
      numeric: false,
      disablePadding: true,
      align: "right",
      label: getTranslation("Actions","Actions","Aktionen"),
    },
  ];

  const { enqueueSnackbar } = useSnackbar();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [searchString, setSearchString] = useState("");

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value));
    setPage(0);
  };
  const handleSearchGuest = (searchString) => {
    setSearchString(searchString);
  };
  return (
    <>
      <Table
        auto
        size="small"
        colums={columns}
        sortBy="fullName"
        rows={guests}
        count={guests?.length}
        page={page}
        rowsPerPage={rowsPerPage}
        handleChangePage={(event, newPage) => handleChangePage(event, newPage)}
        handleChangeRowsPerPage={(e) => handleChangeRowsPerPage(e)}
        viewDelete
        onDelete={(row) => onDelete(row)}
        viewSearch
        onSearch={(searchString) => handleSearchGuest(searchString)}
      />
    </>
  );
};
