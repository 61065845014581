import React, { useState, useEffect } from "react";
import {
  CardContent,
  CardActions,
  Grid,
  TextField,
  Box,
} from "@material-ui/core";
import { Form, Formik } from "formik";
import { useSnackbar } from "notistack";
import Card from "../../../../components/Card";
import {
  Autocomplete,
  ToggleButton,
  ToggleButtonGroup,
} from "@material-ui/lab";

import ButtonWithLoading from "../../../../components/ButtonWithLoading";
import { useDispatch, useSelector } from "react-redux";
import * as Yup from "yup";
import { useNavigate, useParams } from "react-router-dom";
import { Get } from "../../../../actions";
import {
  Get_AllAssets_URL,
} from "../../../../constants/apiUrls";
import { getTranslation } from "../../../../heplers/translationHelper";
import { DatePicker } from "../../../../components/DatePicker";
import { paymentTypes } from "../../../../constants/typeOfPayment";
import { periodicityPeriods } from "../../../../constants/priodicityPeriods";
import { getDateIgnoreTimezone } from "../../../../heplers/dateHelper";

const CreateManagementCompanyAsset = ({
  isSubmitting,
  editOperation,
  onSubmit,
}) => {
  if (editOperation) {
    editOperation.paymentType = paymentTypes.find(
      (x) => x.id === editOperation.typeOfPayment
    );
    editOperation.periodicityPeriod = periodicityPeriods.find(
      (x) => x.value === editOperation.periodicity
    );
    editOperation.assetId = editOperation.asset.id;
  }

  const [assets, setAssets] = useState([]);
  const history = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const [amountType, setAmountType] = useState(0);
  useEffect(() => {
    if (editOperation?.amountIn === 0) {
      setAmountType(1);
    } else if (editOperation?.amountOut === 1) {
      setAmountType(0);
    } else {
      setAmountType(0);
    }
    Get(
      {},
      Get_AllAssets_URL,
      history,
      (response) => {
        setAssets(response.data);
      },
      (error) => {}
    );
  }, []);

  const defaultPaymentType = paymentTypes[0];
  const defaultPeriodicity = periodicityPeriods[0];
  const initialValues = {
    amountIn: 0,
    amountOut: 0,
    date: new Date(),
    endDate: new Date(),
    interestRate: 0,
    periodicity: "",
    typeOfPayment: -1,
    assetId: editOperation ? editOperation.asset.id : 0,
    // extra states for internal use not included in the form fields
    amountType: 0,
    asset: editOperation ? editOperation.asset : "",
    paymentType: editOperation ? editOperation.paymentType : defaultPaymentType,
    periodicityPeriod: editOperation ? editOperation.periodicity : defaultPeriodicity,
    isInterestRateRequired: false,
  };

  const amountInValidationSchema = Yup.object().shape({
    assetId: Yup.number().test('assetId', 'Asset is required', value => value !== 0),
    amountIn: Yup.number().min(1).required("AmountIn is required"),
    typeOfPayment: Yup.number().min(0, "Payment type is required"),
    periodicity: Yup.string().required("Periodicity type is required"),
  });
  const amountOutValidationSchema = Yup.object().shape({
    assetId: Yup.number().min(1,"Asset is required"),
    amountOut: Yup.number().min(1,"Amount Out is required"),
    typeOfPayment: Yup.number().min(0, "Payment type is required"),
  });

  const defaultValue = editOperation ? editOperation : initialValues;
  console.log("sdgfuisdfd",editOperation);
  return (
    <Card title="Add asset operation">
      <Formik
        enableReinitialize
        initialValues={defaultValue}
        validationSchema={
          amountType === 0
            ? amountInValidationSchema
            : amountOutValidationSchema
        }
        onSubmit={(values, actions) => {
          actions.setSubmitting(true);
          onSubmit(values, actions);
        }}
        
      >
        {({
          errors,
          touched,
          values,
          handleSubmit,
          getFieldProps,
          setFieldValue,
          validateField,
          setTouched
        }) => (
          <Form>
            {console.log("djklhfjdhgfsdf",values)}
            <CardContent>
              {!editOperation ? (
                <ToggleButtonGroup
                  value={amountType}
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                  exclusive
                  onChange={(e, value) => {
                    if (value !== null && value !== undefined) {
                      setAmountType(value);
                      setFieldValue("amountType", value);
                    }
                    setFieldValue("isInterestRateRequired", false);
                  }}
                  aria-label="text alignment"
                  name="amountType"
                  size="small"
                >
                  <ToggleButton value={0} aria-label="left aligned">
                    Amount In
                  </ToggleButton>
                  <ToggleButton value={1} aria-label="centered">
                    Amount Out
                  </ToggleButton>
                </ToggleButtonGroup>
              ) : editOperation?.amountIn === 0 ? (
                <ToggleButtonGroup
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <ToggleButton selected value={1}>
                    Amount Out
                  </ToggleButton>
                  {setAmountType(1)}
                </ToggleButtonGroup>
              ) : (
                <ToggleButtonGroup
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <ToggleButton
                    selected
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    Amount In
                  </ToggleButton>
                  {setAmountType(0)}
                </ToggleButtonGroup>
              )}
              <br />
              <Grid container spacing={2}>
                {/* Select Asset */}
                <Grid item xs={12} sm={12} md={4} lg={4}>
                  <Autocomplete
                    id="assetId"
                    name="assetId"
                    value={values?.asset}
                    options={assets}
                    getOptionLabel={(option) => option.title}
                    size="small"
                    onChange={(e, value) => {
                    //   setFieldValue("assetId", value?.id);
                    //   setFieldValue("asset", value);
                    // }}
                    setFieldValue("assetId", value ? value?.id : ""); 
                    setFieldValue("asset", value ? value : ""); 
                    setTouched("assetId", true); 
                    setTouched("asset", true); 
                  }}
                  onBlur={() => {
                    setTouched("assetId", true);
                    setTouched("asset", true);
                  }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label={getTranslation(
                          "Select Asset",
                          "Sélectionner une actif",
                          "Währung auswählen"
                        )}
                        variant="outlined"
                        error={touched.assetId && Boolean(errors.assetId)}
                        helperText={touched.assetId && errors.assetId ? errors.assetId : ""}
                      />
                    )}
                  />
                </Grid>
                {amountType === 0 ? (
                  <>
                    {/* Amount In */}
                    <Grid item xs={12} sm={12} md={4} lg={4}>
                      <TextField
                        fullWidth
                        label={getTranslation(
                          "Amount In",
                          "Montant d'entrée",
                          "Einzahlung"
                        )}
                        variant="outlined"
                        type="number"
                        validateField={"amountIn"}
                        name="amountIn"
                        value={values.amountIn}
                        size="small"
                        error={touched.amountIn && Boolean(errors.amountIn)}
                        helperText={touched.amountIn && errors.amountIn}
                        {...getFieldProps("amountIn")}
                      />
                    </Grid>
                    {/* payment type selection */}
                    <Grid item xs={12} sm={12} md={4} lg={4}>
                      <Autocomplete
                        name="paymentType"
                        value={values?.paymentType}
                        options={paymentTypes}
                        getOptionLabel={(option) =>
                          getTranslation(
                            option.defaultEnglish,
                            option.defaultFrench,
                            option.defaultGerman
                          )
                        }
                        size="small"
                        onChange={(e, type) => {
                          setFieldValue("paymentType", type);
                          setFieldValue("typeOfPayment", type?.id);
                          if (type?.value === "Loan" || type?.value === "Bond") {
                            setFieldValue("isInterestRateRequired", true);
                          } else {
                            setFieldValue("interestRate", 0);
                            setFieldValue("isInterestRateRequired", false);
                          }
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label={getTranslation(
                              "Select Payment Type",
                              "Sélectionnez le type de paiement",
                              "Wählen Sie Zahlungstyp aus"
                            )}
                            variant="outlined"
                          error={touched.typeOfPayment && Boolean(errors.typeOfPayment)}
                        />
                        )}
                      />
                    </Grid>
                    {/* if Payment type is loan then this field will be shown */}
                    {values.isInterestRateRequired ||(values.typeOfPayment===2||values.typeOfPayment===3) ? (
                      <Grid item xs={12} sm={12} md={4} lg={4}>
                        <TextField
                          fullWidth
                          label={getTranslation(
                            "Interest Rate",
                            "Taux d'intérêt",
                            "Zins"
                          )}
                          variant="outlined"
                          type="number"
                          name="interestRate"
                          value={values.interestRate}
                          size="small"
                          error={
                            touched.interestRate && Boolean(errors.interestRate)
                          }
                          {...getFieldProps("interestRate")}
                        />
                      </Grid>
                    ) : null}
                    {/* Priodicity */}
                    <Grid item xs={12} sm={12} md={4} lg={4}>
                      <Autocomplete
                        name="periodicity"
                        value={values?.periodicityPeriod}
                        options={periodicityPeriods}
                        getOptionLabel={(option) =>
                          getTranslation(
                            option.defaultEnglish,
                            option.defaultFrench,
                            option.defaultGerman
                          )
                        }
                        size="small"
                        onChange={(e, value) => {
                          setFieldValue("periodicity", value?.value);
                          setFieldValue("periodicityPeriod", value);
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label={getTranslation(
                              "Select Periodicity",
                              "Sélectionnez la périodicité",
                              "Wählen Sie die Periode"
                            )}
                            variant="outlined"
                            error={touched.periodicity && Boolean(errors.periodicity)}
                        />
                        )}
                      />
                    </Grid>
                  </>
                ) : amountType === 1 ? (
                  <>
                    {/* Amount Out */}
                    <Grid item xs={12} sm={12} md={4} lg={4}>
                      <TextField
                        fullWidth
                        label={getTranslation(
                          "Amount Out",
                          "Montant de sortie",
                          "Auszahlung"
                        )}
                        variant="outlined"
                        type="number"
                        name="amountOut"
                        value={values.amountOut}
                        size="small"
                        error={touched.amountOut && Boolean(errors.amountOut)}
                        helperText={touched.amountOut && errors.amountOut}
                        {...getFieldProps("amountOut")}
                      />
                    </Grid>
                     {/* payment type selection */}
                     <Grid item xs={12} sm={12} md={4} lg={4}>
                      <Autocomplete
                        name="paymentType"
                        value={values.paymentType}
                        options={paymentTypes}
                        getOptionLabel={(option) =>
                          getTranslation(
                            option.defaultEnglish,
                            option.defaultFrench,
                            option.defaultGerman
                          )
                        }
                        size="small"
                        onChange={(e, type) => {
                          setFieldValue("paymentType", type);
                          setFieldValue("typeOfPayment", type.id);
                          // if (type.value === "Loan" || type.value === "Bond") {
                          //   setFieldValue("isInterestRateRequired", true);
                          // } else {
                          //   setFieldValue("interestRate", 0);
                          //   setFieldValue("isInterestRateRequired", false);
                          // }
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label={getTranslation(
                              "Select Payment Type",
                              "Sélectionnez le type de paiement",
                              "Wählen Sie Zahlungstyp aus"
                            )}
                            variant="outlined"
                        error={touched.typeOfPayment && Boolean(errors.typeOfPayment)}
                        />
                        )}
                      />
                    </Grid>
                  </>
                ) : null}
                {/* Start Date */}
                <Grid item xs={12} sm={12} md={4} lg={4}>
                  <DatePicker
                    fullWidth
                    label={getTranslation(
                      "Start Date",
                      "Date de début",
                      "Startdatum"
                    )}
                    disabled={values.periodicity === "End Term"}
                    variant="outlined"
                    name="date"
                    value={values.date}
                    size="small"
                    error={touched.date && Boolean(errors.date)}
                    onChange={(date) => {
                      const theDate = getDateIgnoreTimezone(date)
                      setFieldValue("date", theDate);
                    }}
                  />
                </Grid>
                {/* End Date */}
                <Grid item xs={12} sm={12} md={4} lg={4}>
                  <DatePicker
                    fullWidth
                    label={getTranslation(
                      "End Date",
                      "Date de fin",
                      "Enddatum"
                    )}
                    variant="outlined"
                    name="endDate"
                    value={values.endDate}
                    size="small"
                    error={touched.endDate && Boolean(errors.endDate)}
                    onChange={(date) => {
                      const theDate = getDateIgnoreTimezone(date)
                      setFieldValue("endDate", theDate);
                    }}
                  />
                </Grid>
              </Grid>
            </CardContent>
            <CardActions>
              <Box
                pr={1}
                pb={1}
                width="100%"
                display="flex"
                alignItems="center"
                justifyContent="flex-end"
              >
                <ButtonWithLoading
                  title={
                    editOperation
                      ? getTranslation(
                          "Update",
                          "Mettre à jour",
                          "Aktualisieren"
                        )
                      : getTranslation("Submit", "Soumettre", "Absenden")
                  }
                  size="small"
                  variant="contained"
                  color="primary"
                  loading={isSubmitting}
                  onClick={handleSubmit}
                />
              </Box>
            </CardActions>
          </Form>
        )}
      </Formik>
    </Card>
  );
};

export default CreateManagementCompanyAsset;
