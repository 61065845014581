import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Grid,
  Typography,
} from "@material-ui/core";
import React, { useRef, useState, useEffect } from "react";
import ButtonWithLoading from "../../../components/ButtonWithLoading";
import { useReactToPrint } from "react-to-print";
import { PrintSignedCircularResolution } from "./printSignCircularResolution";
import { DeclineDialog } from "./DeclineDialog";
import {
  Get_CircularResolutionMemberContact_URL,
  Get_MemberContact_URL,
  Post_ConfirmCRByMember_URL,
  Post_UpdateMemberComment_URL,
} from "../../../constants/apiUrls";
import { Create, Get } from "../../../actions";
import { useSnackbar } from "notistack";
import { DraftSignedCircularResolution } from "./draftSignCircularResolution";

export const SignDecision = ({ circularResolution, personalInfo }) => {
  const { enqueueSnackbar } = useSnackbar();
  const [declineDialogState, setDeclineDialogState] = useState(false);
  const componentPrint = useRef();
  const componentDraft = useRef();

  const handlePrint = useReactToPrint({
    documentTitle: "Resolution Signed",
    content: () => componentPrint.current,
  });
  const handleDraft = useReactToPrint({
    documentTitle: "Resolution Draft",
    content: () => componentDraft.current,
  });

  const handleCancel = () => {
    setDeclineDialogState(false);
    //actions.resetForm();
  };
  //// Get Personal Info to print on signed/draft CR  Circular Resolution Confirmation ////

  ////////////////////// handle submit  Circular Resolution Confirmation ////////////////////
  const handleSubmit = (values, actions) => {
    Create(
      {
        memberId: values.memberId,
        circularResolutionId: values.circularResolutionId,
        descision: values.descision,
        comments: values.comments,
      },
      Post_ConfirmCRByMember_URL,
      null,
      (resp) => {
        actions.setSubmitting(false);
        enqueueSnackbar("Comments Added", { variant: "info" });
        actions.resetForm();
      },
      (error) => {
        actions.setSubmitting(false);
        enqueueSnackbar(error?.data, { variant: "error" });
      }
    );
  };
  const handleDecline = () => {
    setDeclineDialogState(true);
  };
  return (
    <Card>
      <CardContent>
        <Grid container spacing={5}>
          <Grid item md={4} lg={4}>
            <Card style={{ background: "#F6F4F8", margin: "50px" }}>
              <CardHeader
                title={""}
                style={{
                  textAlign: "center",
                  fontWeight: "bold",
                  fontSize: "18px",
                }}
              />
              <CardContent>
                <Typography style={{ textAlign: "center", fontWeight: "bold" }}>
                  Instructions
                </Typography>
                <Typography>
                  2018. Sab se Pehle to ap ne ghabrana ni he <br />
                  2019. Ap ne ghabrana ni he <br /> 2020. Ghabrana ni he <br />{" "}
                  2021. Ghabrao BSD walo
                </Typography>
              </CardContent>
              <CardActions>
                <Box
                  pr={1}
                  pb={1}
                  width="100%"
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                >
                  <Button
                    size="large"
                    variant="contained"
                    color="primary"
                    onClick={handleDraft}
                  >
                    Draft
                  </Button>
                </Box>
              </CardActions>
            </Card>
          </Grid>
          <Grid item md={4} lg={4}>
            <Card style={{ background: "#F6F4F8", margin: "50px" }}>
              <CardHeader
                title={""}
                style={{
                  textAlign: "center",
                  fontWeight: "bold",
                  fontSize: "18px",
                }}
              />
              <CardContent>
                <Typography style={{ textAlign: "center", fontWeight: "bold" }}>
                  Instructions
                </Typography>
                <Typography>
                  2018. Sab se Pehle to ap ne ghabrana ni he <br />
                  2019. Ap ne ghabrana ni he <br /> 2020. Ghabrana ni he <br />{" "}
                  2021. Ghabrao BSD walo
                </Typography>
              </CardContent>
              <CardActions>
                <Box
                  pr={1}
                  pb={1}
                  width="100%"
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                >
                  <Button
                    size="large"
                    variant="contained"
                    color="primary"
                    onClick={handlePrint}
                  >
                    Print/Sign
                  </Button>
                </Box>
              </CardActions>
            </Card>
          </Grid>
          <Grid item md={4} lg={4}>
            <Card style={{ background: "#F6F4F8", margin: "50px" }}>
              <CardHeader
                title={""}
                style={{
                  textAlign: "center",
                  fontWeight: "bold",
                  fontSize: "18px",
                }}
              />
              <CardContent>
                <Typography style={{ textAlign: "center", fontWeight: "bold" }}>
                  Instructions
                </Typography>
                <Typography>
                  2018. Sab se Pehle to ap ne ghabrana ni he <br />
                  2019. Ap ne ghabrana ni he <br /> 2020. Ghabrana ni he <br />{" "}
                  2021. Ghabrao BSD walo
                </Typography>
              </CardContent>
              <CardActions>
                <Box
                  pr={1}
                  pb={1}
                  width="100%"
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                >
                  <Button
                    size="large"
                    variant="contained"
                    color="primary"
                    onClick={() => handleDecline()}
                  >
                    Decline
                  </Button>
                </Box>
              </CardActions>
            </Card>
          </Grid>
        </Grid>
        <DeclineDialog
          memberId={personalInfo?.id}
          circularResolutionId={circularResolution?.id}
          isOpen={declineDialogState}
          onClose={handleCancel}
          onSubmit={handleSubmit}
        />
        <div style={{ display: "none" }}>
          {/* //circularResolution={circularResolution} */}
          <PrintSignedCircularResolution
            ref={componentPrint}
            personalInfo={personalInfo}
            circularResolution={circularResolution}
          />
        </div>
        <div style={{ display: "none" }}>
          <DraftSignedCircularResolution
            ref={componentDraft}
            personalInfo={personalInfo}
            circularResolution={circularResolution}
          />
        </div>
      </CardContent>
    </Card>
  );
};
