import React, { useState, useEffect } from "react";
import { Box, Paper, TextField, Typography } from "@material-ui/core";
import Table from "../../../../components/table";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useSnackbar } from "notistack";
import { Get, Create } from "../../../../actions";
import {
  GET_AllTeamMembers_URL,
  GET_CompanyTeam_URL,
  Post_DeleteTeamMember_URL,
  Post_InsertCompanyTeamMember_URL,
  Post_UpdateTeamMember_URL,
} from "../../../../constants/apiUrls";
import AddTeamMember from "./addTeamMember";
import DeleteDialog from "../../../../components/DeleteDialog";
import { getTranslation } from "../../../../heplers/translationHelper";
import { CeresTeamMemberResponsibilites } from "../../../../constants/ceresTeamMemberResponsibilities";

const Team = () => {
  const columns = [
    {
      id: "name",
      numeric: false,
      disablePadding: true,
      label:getTranslation("Name","Nom","Name"),
    },
    {
      id: "responsibility",
      numeric: false,
      disablePadding: true,
      label:getTranslation("Responsibility","Responsabilité","Verantwortung"),
      component:({row})=>{
  
        const resp=CeresTeamMemberResponsibilites.find(x=>x.id==row.responsibility)
        if(resp)
          return <Typography>{getTranslation(resp?.defaultEnglish,resp?.defaultFrench,resp?.defaultGerman)}</Typography>
        return <Typography>Not Found</Typography>
    }
    },

    {
      id: "actions",
      numeric: true,
      disablePadding: false,
      align: "right",
      label:getTranslation("Actions","Actions","Aktionen"),
    },
  ];

  const history = useNavigate();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const [open, setOpen] = useState(false);
  const [deleteRow, setDeleteRow] = useState({});
  const [searchString, setSearchString] = useState("");
  const [page, setPage] = useState(0);
  const [createCompany, setCreateCompany] = useState(false);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [rows, setRows] = useState([]);
  const [loadingTeamMembers, setloadingTeamMembers] = useState(false);
  const { companyId } = useParams();
  const [team, setTeam] = useState({});
  const [editTeam, setEditTeam] = useState(null);
  const [submitting, setSubmitting] = useState(false);

  useEffect(() => {
    loadTeamMembers();
  }, [companyId]);

  const loadTeamMembers = () => {
    setloadingTeamMembers(true);
    Get(
      {
        companyId: companyId,
      },
      GET_AllTeamMembers_URL,
      history,
      (resp) => {
        setTeam(resp.data);
        setloadingTeamMembers(false);
      },
      (error) => {}
    );
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value));
    setPage(0);
  };
  const handleDetailManagementCompanyFund = (row) => {
    history(`/managementCompanies/${row.id}/${row.companyId}/basic`);
  };
  const handleSearchManagementCompanyFund = (searchString) => {
    setSearchString(searchString);
  };
  const handleCreateCompany = (event) => {
    setCreateCompany(true);
  };
  const handleCreateCompanyClose = (event) => {
    setCreateCompany(false);
  };
  const handleInsertTeamMember = async (values, actions) => {
    setSubmitting(true);
    const payload =  { ...values, companyId: companyId }
    Create(
      payload,
      editTeam ? Post_UpdateTeamMember_URL : Post_InsertCompanyTeamMember_URL,
      history,
      (response) => {
        setSubmitting(false);
        enqueueSnackbar(getTranslation("Team member added","Membre de l'équipe ajouté","Teammitglied hinzugefügt"), {
          variant: "success",
        });
        loadTeamMembers();
        actions.resetForm();
        setEditTeam(null);
      },
      (error) => {
        setSubmitting(false);
        enqueueSnackbar(getTranslation("Something went wrong","Quelque chose s'est mal passé","Etwas ist schief gelaufen"), {
          variant: "error",
        });
      }
    );
  };

  const handleOpenDeleteDialog = (row) => {
    setDeleteRow(row);
    setOpen(true);
  };

  const handleDeleteActivity = (row) => {
    const deleteArray = [];
    deleteArray.push(row.id);
    Create(
      { ids: deleteArray },
      Post_DeleteTeamMember_URL,
      history,
      (resp) => {
        setOpen(false);
        enqueueSnackbar(getTranslation("Team member Delete","Membre de l'équipe supprimé","Teammitglied gelöscht"), {
          variant: "success",
        });
        loadTeamMembers();
      },
      (error) => {
        enqueueSnackbar(
          getTranslation("Unable to delete team member","Impossible de supprimer le membre de l'équipe","Teammitglied konnte nicht gelöscht werden"),
          {
            variant: "error",
          }
        );
      }
    );
  };

  const handleEditTeamMember=(row)=>{
    setEditTeam(row)
  }
  return (
    <div>
      <AddTeamMember team={team} onCancel={()=>setEditTeam(null)} isSubmitting={submitting} editTeamMember={editTeam} onSubmit={handleInsertTeamMember}/>

      <Table
        dense
        loading={loadingTeamMembers}
        title={getTranslation("Team Members","Membres de l'équipe","Teammitglieder")}
        colums={columns}
        sortBy="title"
        rows={team}
        count={team.length}
        page={page}
        rowsPerPage={rowsPerPage}
        handleChangePage={(event, newPage) => handleChangePage(event, newPage)}
        handleChangeRowsPerPage={(e) => handleChangeRowsPerPage(e)}
        viewSearch
        onSearch={(searchString) =>
          handleSearchManagementCompanyFund(searchString)
        }
        viewEdit
        onEdit={(row)=>handleEditTeamMember(row)}
        viewDelete
        onDelete={(row) => handleOpenDeleteDialog(row)}
      />

      <DeleteDialog
        open={open}
        title={getTranslation("Delete Person","Supprimer la personne","Person löschen")}
        onClose={() => setOpen(false)}
        onSubmit={(e) => handleDeleteActivity(deleteRow)}
      />
    </div>
  );
};

export default Team;
