import React, { useState, useEffect } from "react";
import {
  CardContent,
  CardActions,
  Grid,
  TextField,
  Box,
  Typography,
} from "@material-ui/core";
import Card from "../../../../components/Card";
import { Get } from "../../../../actions";
import { Get_ShareholderStat_URL } from "../../../../constants/apiUrls";
import { useNavigate } from "react-router-dom";
import { getTranslation } from "../../../../heplers/translationHelper";

const Stats = ({ companyId }) => {
  const history = useNavigate();
  const [shareholderStat, setShareholderStat] = useState({});
  useEffect(() => {
    Get(
      {
        id: companyId,
      },
      Get_ShareholderStat_URL,
      history,
      (resp) => {
        setShareholderStat(resp.data);
      },
      (error) => {}
    );
  }, []);

  return (
    <>
      <Card title={getTranslation("Statistics","Statistiques","Statistiken")}>
        <CardContent>
          <Grid container spacing={5}>
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <Grid container spacing={3}>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <Typography variant="h4">{getTranslation("Capital","Capitale","Hauptstadt")}</Typography>
                </Grid>

                <Grid item xs={6} sm={6} md={6} lg={6}>
                  <Typography variant="subtitle1" component="h5">
                    {getTranslation("Capital:","Capitale","Hauptstadt")}
                  </Typography>
                </Grid>

                <Grid item xs={6} sm={6} md={6} lg={6}>
                  <Typography variant="body2">
                    {shareholderStat.totalCapital}
                  </Typography>
                </Grid>

                <Grid item xs={6} sm={6} md={6} lg={6}>
                  <Typography variant="subtitle1" component="h5">
                    {getTranslation("Shares:","Actions","Anteile")}
                  </Typography>
                </Grid>

                <Grid item xs={6} sm={6} md={6} lg={6}>
                  <Typography variant="body2">
                    {shareholderStat.totalShares}
                  </Typography>
                </Grid>

                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <Typography variant="h4">Status 1: activity</Typography>
                </Grid>

                <Grid item xs={6} sm={6} md={6} lg={6}>
                  <Typography variant="subtitle1" component="h5">
                    {getTranslation("Ceres Team:","L'équipe Cérès","Ceres-Team")}
                  </Typography>
                </Grid>

                <Grid item xs={6} sm={6} md={6} lg={6}>
                  <Typography variant="body2">{"Some Team Data"}</Typography>
                </Grid>

                <Grid item xs={6} sm={6} md={6} lg={6}>
                  <Typography variant="subtitle1" component="h5">
                    {getTranslation("Minutes Responsilbity:","Responsabilité du procès-verbal","Minuten Verantwortung")}
                  </Typography>
                </Grid>

                <Grid item xs={6} sm={6} md={6} lg={6}>
                  <Typography variant="body2">{"Some Data"}</Typography>
                </Grid>

                <Grid item xs={6} sm={6} md={6} lg={6}>
                  <Typography variant="subtitle1" component="h5">
                    {getTranslation("Internal Review:","Examen interne","Interne Überprüfung")}
                  </Typography>
                </Grid>

                <Grid item xs={6} sm={6} md={6} lg={6}>
                  <Typography variant="body2">
                    {getTranslation("Someone Interviewer","Quelqu'un intervieweur","Jemand Interviewer")}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </>
  );
};

export default Stats;
