import { Box, CardContent, Grid } from "@material-ui/core";
import { useState } from "react";
import { useEffect } from "react";
import { Get } from "../../../../../../actions";
import ButtonWithLoading from "../../../../../../components/ButtonWithLoading";
import Card from "../../../../../../components/Card";
import { GET_AllContacts_URL } from "../../../../../../constants/apiUrls";
import { getTranslation } from "../../../../../../heplers/translationHelper";
import { AddGuest } from "../guest/addGuest";
import { GuestList } from "../guest/guestList";

const SelectGuests = ({ NextStep, BackStep,initialValues }) => {
    const [contacts, setContacts] = useState([]);
    const [guests, setGuests] = useState([]);
    useEffect(()=>{
        setGuests(initialValues.guests)
    },[initialValues.guests])
    useEffect(() => {
        loadContacts()
    }, [])
    const loadContacts = () => {
        Get(
            {},
            GET_AllContacts_URL,
            null,
            (resp) => {
                const arr = [];
                resp?.data?.data?.forEach((item) => {
                    item.contactId = item.id;
                    // item.memberId=item.id;
                    arr.push(item);
                });
                setContacts(arr);
            },
            (error) => {
            }
        );
    };

    return (
        <Card title={getTranslation("Guests", "Invitées", "Gäste")}>
            <CardContent>
                <AddGuest
                    contactList={contacts}
                    persons={guests}
                    onPersonChanged={(persons) => {
                        setGuests(persons);
                    }}
                    onSubmitGuest={(guest) => {
                        guest.contactId = guest.id;
                        setGuests([...guests, guest]);
                        setContacts([...contacts, guest])
                    }}
                />
                <GuestList
                    guests={guests}
                    onDelete={(row) => {
                        setGuests(guests.filter((x) => x.id !== row.id)
                        );
                    }}
                />
                <Box
                    display="flex"
                    justifyContent="space-between"
                    alignItems="space-between"
                    m={2}
                >
                    <ButtonWithLoading
                        title={getTranslation("Back", "Précédent", "Zurück")}
                        color="primary"
                        variant="contained"
                        size="large"
                        onClick={() => BackStep()}
                    />
                    <ButtonWithLoading
                        title={getTranslation("Next", "Suivant", "Weiter")}
                        color="primary"
                        variant="contained"
                        size="large"
                        onClick={()=>NextStep({...initialValues,guests:guests})}
                    />
                </Box>
            </CardContent>
        </Card>
    )
}

export default SelectGuests;