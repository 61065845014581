import React, { useState, useEffect } from "react";
import Table from "../../../../components/table";
import { getTranslation } from "../../../../heplers/translationHelper";
import { Typography } from "@material-ui/core";
import { representativeRoles } from "../../../../constants/representativeRoles";

const columns = [
  {
    id: "companyName",
    numeric: false,
    disablePadding: true,
    label:getTranslation("Company","Compagnie","Gesellschaft"),
  },
  {
    id: "representativeRole",
    numeric: false,
    disablePadding: true,
    label:getTranslation("Role","Rôle","Rolle"),
    component:({row})=>{
    
      const selectedRepresentative=representativeRoles.find(
          (x) => x.value == row.representativeRole || x.id == row.representativeRole
        );
      return <Typography>{selectedRepresentative?.value}</Typography>}
  },
  {
    id: "contactName",
    numeric: false,
    disablePadding: true,
    label:getTranslation("Represented By","Représenté par","Vertreten durch"),
  },
  {
    id: "actions",
    numeric: true,
    disablePadding: false,
    align: "right",
    label:getTranslation("Actions","Actions","Aktionen"),
  },
];

const Companies = ({ onDelete, representative, onEdit, loading }) => {
  const [searchString, setSearchString] = useState("");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value));
    setPage(1);
  };

  return (
    <>
      <Table
        dense
        auto
        title={getTranslation("Companies", "Entreprises", "Firmen")}
        colums={columns}
        loading={loading}
        sortBy="company"
        rows={representative?.representedByCompanies}
        count={representative?.representedByCompanies?.length}
        page={page}
        rowsPerPage={rowsPerPage}
        handleChangePage={(event, newPage) => handleChangePage(event, newPage)}
        handleChangeRowsPerPage={(e) => handleChangeRowsPerPage(e)}
        viewDelete
        onDelete={(row) => onDelete(row)}
        viewSearch
        onSearch={(searchString) => setSearchString(searchString)}
        viewEdit
        onEdit ={(row) => onEdit(row)}
      />
    </>
  );
};

export default Companies;
