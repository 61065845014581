
import React from 'react';
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
} from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { AccessTime } from '@material-ui/icons';

export const MyTimePicker = ({ id, label, value, error, required, onChange, ...rest }) => {
  // seeting the by default time if id is duration
  const defaultTime = id === "meetingDuration" ? new Date(0, 0, 0, 1, 0) : null;

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <KeyboardTimePicker
        ampm={false}
        fullWidth
        showToolbar
        inputVariant="outlined"
        size="small"
        label={label}
        value={id === "meetingDuration" ? defaultTime : value}
        onChange={onChange}
        KeyboardButtonProps={{
          "aria-label": label,
        }}
        InputLabelProps={{
          shrink: true,
        }}
        required={required}
        error={error}
        {...rest}
        keyboardIcon={<AccessTime />}
      />
    </MuiPickersUtilsProvider>
  );
};
