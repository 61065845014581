/* eslint-disable no-use-before-define */
/* eslint-disable react/prop-types */
import { Box, Drawer, List, ListSubheader, Divider } from "@material-ui/core";
import React, { useEffect } from "react";
import PerfectScrollbar from "react-perfect-scrollbar";
import { matchPath } from "react-router";
import { Link as RouterLink, useLocation } from "react-router-dom";
import NavItem from "./NavItem";
import navConfig from "./Menu";
import { LogoAlternate } from "../../components/Logo";
import useStyles from "../../assests/styles/layout/sidebar";
import IconButton from "@material-ui/core/IconButton";

import { useTheme } from "@material-ui/core/styles";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import { Copyright } from "@material-ui/icons";
import { ImpactSoftLogo } from "../../icons";
function renderNavItems({ items, depth, pathname, ...rest }) {
  return (
    <List disablePadding>
      {items.reduce(
        (acc, item) => reduceChildRoutes({ acc, pathname, item, ...rest }),
        []
      )}
    </List>
  );
}

function reduceChildRoutes({ acc, pathname, item, depth = 0 }) {
  const key = item.title + depth;

  if (item.items) {
    const open = matchPath(pathname, {
      path: item.href,
      exact: false,
    });

    acc.push(
      <NavItem
        depth={depth}
        icon={item.icon}
        key={key}
        info={item.info}
        open={Boolean(open)}
        title={item.title}
      >
        <Box pl={5}>
          {renderNavItems({
            depth: depth + 3,
            pathname,
            items: item.items,
          })}
        </Box>
      </NavItem>
    );
  } else {
    acc.push(
      <NavItem
        open={true}
        depth={depth}
        href={item.href}
        icon={item.icon}
        key={key}
        info={item.info}
        title={item.title}
      />
    );
  }

  return acc;
}

function Sidebar({ openMobile, onMobileClose }) {
  const classes = useStyles();
  const location = useLocation();
  const theme = useTheme();

  const handleDrawerClose = () => {
    onMobileClose();
  };

  const content = (
    <Box
      height="100%"
      display="flex"
      flexDirection="column"
      style={{ backgroundColor: "rgb(35, 48, 68)" }}
    >
      <PerfectScrollbar options={{ suppressScrollX: true }}>
        <Box p={2} mt={1} display="flex" justifyContent="center">
          <RouterLink to="/">
            <LogoAlternate />
          </RouterLink>
        </Box>

        <Divider style={{ backgroundColor: "white" }} />
        <Box px={2.8} py={1.5}>
          {navConfig.map((config) => (
            <List
              key={config.subheader}
              subheader={
                <ListSubheader disableGutters disableSticky>
                  {config.subheader}
                </ListSubheader>
              }
            >
              {renderNavItems({
                items: config.items,
                pathname: location.pathname,
              })}
            </List>
          ))}
        </Box>
      </PerfectScrollbar>
      <Box flexGrow={1} />
      <div
        style={{
          margin: "auto",
          marginBottom: "15px",
          color: "#ffffff",
        }}
      >
        <ImpactSoftLogo /><br/>
        &nbsp; © {new Date().getFullYear()} Impact Soft &nbsp;&nbsp;2.2
      </div>
    </Box>
  );

  return (
    <>
      <Drawer
        className={classes.drawer}
        variant="persistent"
        anchor="left"
        open={openMobile}
        classes={{
          paper: classes.drawerPaper,
        }}
      >
        {content}
        <div className={classes.drawerHeader}>
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === "ltr" ? (
              <ChevronLeftIcon />
            ) : (
              <ChevronRightIcon />
            )}
          </IconButton>
        </div>
        
      </Drawer>
    </>
  );
}

export default Sidebar;
