import { Box, TextField } from "@material-ui/core";
import { DatePicker } from "./DatePicker";

const CreateField = ({
  key,
  type,
  size,
  variant,
  label,
  name,
  value,
  hasPrevious,
  onChange,
  fullWidth,
  required,
  error,
  ...rest
}) => {
  console.log("hasjkhdjkasdjkasdhasjkl",type)
  return (
    <>
      <Box
        display="flex"
        flexDirection="column"
        alignItems="flex-end"
        justifyContent="center"
      >
        {type !== "date" ? (
          <TextField
            type={type}
            size={size}
            variant={variant}
            label={label}
            name={name}
            value={value}
            onChange={onChange}
            fullWidth={fullWidth}
            required={required}
            error={error}
            multiline
            {...rest}
          />
        ) : (
          <DatePicker
            type={type}
            size={size}
            variant={variant}
            label={label}
            name={name}
            value={value}
            onChange={onChange}
            fullWidth={fullWidth}
            required={required}
            error={error}
            {...rest}
          />
        )}
      </Box>
    </>
  );
};
export default CreateField;
