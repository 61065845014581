import { Box, CardContent, Checkbox, FormControl, FormControlLabel, Grid } from "@material-ui/core";
import { Form, Formik } from "formik";
import { useSnackbar } from "notistack";
import { useEffect } from "react";
import { useState } from "react";
import { Get } from "../../../../../../actions";
import ButtonWithLoading from "../../../../../../components/ButtonWithLoading";
import Card from "../../../../../../components/Card";
import { GET_AllContacts_URL } from "../../../../../../constants/apiUrls";
import { getTranslation } from "../../../../../../heplers/translationHelper";
import { DocumentList } from "../documents/documentList";

const AskDocuments = ({ NextStep, BackStep , initialValues}) => {
    const { enqueueSnackbar } = useSnackbar();
    const [contacts, setContacts] = useState([]);

    useEffect(() => {
        loadContacts()
    }, [])
    const loadContacts = () => {
        Get(
            {},
            GET_AllContacts_URL,
            null,
            (resp) => {
                const arr = [];
                resp?.data?.data?.forEach((item) => {
                    item.contactId = item.id;
                    // item.memberId=item.id;
                    arr.push(item);
                });
                setContacts(arr);
            },
            (error) => {
            }
        );
    };
    // const initialValues = {
    //     meetingDocuments: [],
    //     documents: [],
    //     isDocumentRequired: false,
    // }
    return (
        <Formik
            initialValues={initialValues}
            onSubmit={(values, actions) => {
                NextStep(values);
            }}
        >
            {({
                errors,
                touched,
                values,
                handleSubmit,
                isSubmitting,
                getFieldProps,
                setFieldValue,
                handleChange,
            }) => (
                <Form>
                    {console.log("hjgsdfsdf", values)}
                    <Grid container spacing={2} style={{ marginBottom: "10px" }}>
                        {/* Step 3 */}
                        <Grid item xs={12} sm={12} md={12} lg={12}>
                            <Card title={getTranslation("Documents", "Documents", "Unterlagen")}>
                                <CardContent>
                                    <FormControl>
                                        <FormControlLabel
                                            label={getTranslation("Ask For Documents (optional)", "Demander des documents (en option)", "Dokumente anfordern (freiwillig)")}
                                            labelPlacement="start"
                                            control={
                                                <Checkbox
                                                    id="isDocumentRequired"
                                                    checked={values.isDocumentRequired}
                                                    onChange={handleChange}
                                                />
                                            }
                                        />
                                    </FormControl>
                                    <br />
                                    <br />
                                    {values.isDocumentRequired ? (
                                        <DocumentList
                                            contacts={contacts}
                                            documents={values.meetingDocuments}
                                            onAddNewDocument={(document) => {
                                                if (
                                                    values.meetingDocuments.findIndex(
                                                        (x) => x.contactId === document.contactId
                                                    ) < 0
                                                ) {
                                                    document.id = new Date().getTime();
                                                    const arr = [
                                                        ...values.meetingDocuments,
                                                        document,
                                                    ];
                                                    setFieldValue("meetingDocuments", arr);
                                                } else {
                                                    enqueueSnackbar("Already Exists", {
                                                        variant: "error",
                                                    });
                                                }
                                            }}
                                            onDeleteDocument={(document) =>
                                                setFieldValue(
                                                    "meetingDocuments",
                                                    values.meetingDocuments.filter(
                                                        (x) => x.contactId !== document.contactId
                                                    )
                                                )
                                            }
                                        />
                                    ) : null}
                                </CardContent>
                            </Card>
                        </Grid>
                    </Grid>
                    <Box
                        display="flex"
                        justifyContent="space-between"
                        alignItems="space-between"
                        m={2}
                    >
                        <ButtonWithLoading
                            title={getTranslation("Back", "Précédent", "Zurück")}
                            color="primary"
                            variant="contained"
                            size="large"
                            onClick={() => BackStep()}
                        />
                        <ButtonWithLoading
                            title={getTranslation("Next", "Suivant", "Weiter")}
                            color="primary"
                            variant="contained"
                            size="large"
                            onClick={handleSubmit}
                        />
                    </Box>
                </Form>
            )}
        </Formik>
    )
}
export default AskDocuments;