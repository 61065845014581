import { Get } from "../actions";
import {
  Get_ReportDataCollected_URL,
  Get_ReportingUser_URL,
  Get_SectionById_URL,
} from "../constants/apiUrls";
import _ from 'lodash'
import ReactDOMServer from 'react-dom/server'
export const parseHelper = async (meetingId,html, onChange) => {
  var parser = new DOMParser();
  var htmlDoc = parser.parseFromString(html, "text/html");
  var sectionList = htmlDoc.querySelectorAll("[data-section-id]");

  await parseSection(sectionList);
  var formList = htmlDoc.querySelectorAll("[data-form-id]");
  await parseForm(meetingId,formList);
  var tableList = htmlDoc.querySelectorAll("[data-table-id]");
  await parseTable(meetingId,tableList)
  var XML = new XMLSerializer();
  var divNode = document.createElement("div");
  divNode.innerHTML = htmlDoc.body.innerHTML;
  divNode.style = `width:100%;`;
  onChange(XML.serializeToString(htmlDoc));
};

const parseSection = async (sectionHtmlDOM) => {
  for await (const x of sectionHtmlDOM) {
    await Get(
      {
        id: x.getAttribute("data-section-id"),
      },
      Get_SectionById_URL,
      null,
      (resp) => {
        x.innerHTML = resp.data.sectionHtml;
        x.style = "";
      },
      (error) => {
        console.log("jhsdbgfsdfg error", error.data);
      }
    );
  }
};

const parseForm = async (meetingId,formElementList) => {
  const formsReportingUsers=[];
  for await (const x of _.uniqBy(formElementList,e=>e.getAttribute("data-form-id"))) {
    if(formsReportingUsers.findIndex(user=>user.id==parseInt(x.getAttribute("data-form-id")))==-1)
    {
      await Get(
        {
          templateId: parseInt(x.getAttribute("data-form-id")),
          meetingId: meetingId,
          reportingType:"form"
        },
        Get_ReportDataCollected_URL,
        null,
        (resp) => {
          formsReportingUsers.push(resp.data)
        },
        (error) => {
        }
      );
    }
  };

  formsReportingUsers.forEach(reportingUser => {
    const elements = _.filter(formElementList,element => element.getAttribute("data-form-id") == reportingUser.templateId)
    const reportingValues = reportingUser?.reportingValues
    elements.forEach(
      element => {

        const fieldId = element.getAttribute("data-field-id")
        const field = reportingValues?.find(value => value.id == fieldId)
        if (field && field.value != "") {
          element.innerHTML = field.value
        }
      }
    )
  });
};

const parseTable = async (meetingId,tablelementList) => {
  const tableReportingUsers=[];
  for await (const x of _.uniqBy(tablelementList,e=>e.getAttribute("data-table-id"))) {
    if(tableReportingUsers.findIndex(user=>user.id==parseInt(x.getAttribute("data-table-id")))==-1)
    {
      await Get(
        {
          templateId: parseInt(x.getAttribute("data-table-id")),
          meetingId: meetingId,
          reportingType:"table"
        },
        Get_ReportDataCollected_URL,
        null,
        (resp) => {
          tableReportingUsers.push(resp.data)
        },
        (error) => {
        }
      );
    }
  };

  tableReportingUsers.forEach(reportingUser => {
    const elements = _.filter(tablelementList,element => element.getAttribute("data-table-id") == reportingUser.templateId)
    const reportingValues = reportingUser?.reportingValues
    elements.forEach(
      element => {
        // tbody element
        element.innerHTML= renderTable(reportingValues)
        console.log("ajkgsdkjkhasdghdgas",element)
        // const fieldId = element.getAttribute("data-field-id")
        // const field = reportingValues?.find(value => value.id == fieldId)
        // if (field && field.value != "") {
        //   element.innerHTML = field.value
        // }
      }
    )
  });
};

const renderTable = (reportingValues) => {
  return ReactDOMServer.renderToString(<thead>
    <tr style={{ backgroundColor: "rgba(240, 240, 240, 0.8)" }}>
      {reportingValues[0].map(column => {
        return <th style={{
          border: '1px solid rgba(0, 0, 0, 0.2)',
          padding: '6px',
          textAlign: 'center',
          fontWeight: 'bold'
        }}>{column.title}</th>
      })}
    </tr>
    {reportingValues.map(columns => {
      return <tr>
        {columns.map(column => <td style={{
          border: "1px solid rgba(0, 0, 0, 0.2)",
          padding: "6px",
          textAlign: "center",
        }}>{column.value}</td>)}
      </tr>
    })}
  </thead>)
}

export const getReportingUsers = async (
  html,
  managementCompanyId,
  onUsersChange
) => {
  var parser = new DOMParser();
  var htmlDoc = parser.parseFromString(html, "text/html");
  var sectionList = htmlDoc.querySelectorAll("[data-section-id]");

  await parseSection(sectionList);
  var formList = htmlDoc.querySelectorAll("[data-form-id]");
  var tableList = htmlDoc.querySelectorAll("[data-table-id]");
  const users = [];

  for await (const x of _.uniqBy(formList,form=>form.getAttribute("data-form-id"))) {
    const user = await getFormUsers(x.getAttribute("data-form-id"), managementCompanyId);
    users.push(user);
  }
  for await (const table of _.uniqBy(tableList,tab=>tab.getAttribute("data-table-id"))) {
    const user = await getTableUsers(
      parseInt(table.getAttribute("data-table-id")),
      managementCompanyId
    );
    users.push(user);
  }

  onUsersChange(users);
};

const getFormUsers = async (formId, managementCompanyId) => {
  var user;
  await Get(
    {
      id: formId,
      managementCompanyId: managementCompanyId,
      type: "form",
    },
    Get_ReportingUser_URL,
    null,
    (resp) => {
      user = resp.data;
      console.log("jsgdfsdgfsdfg", resp);
    },
    (error) => {}
  );
  return user;
};

const getTableUsers = async (tableId, managementCompanyId) => {
  var user;
  await Get(
    {
      id: tableId,
      managementCompanyId: managementCompanyId,
      type: "table",
    },
    Get_ReportingUser_URL,
    null,
    (resp) => {
      user = resp.data;
    },
    (error) => {}
  );
  return user;
};
