import React, { useState, useEffect } from "react";
import {
  CardContent,
  CardActions,
  Grid,
  TextField,
  Box,
} from "@material-ui/core";
import { ErrorMessage, Form, Formik } from "formik";
import { useSnackbar } from "notistack";
import Card from "../../../../components/Card";
import { Autocomplete, ToggleButton, ToggleButtonGroup } from "@material-ui/lab";
import ButtonWithLoading from "../../../../components/ButtonWithLoading";
import * as Yup from "yup";
import { useNavigate, useParams } from "react-router-dom";
import { Get } from "../../../../actions";
import { Get_AllFunds_URL } from "../../../../constants/apiUrls";
import { getTranslation } from "../../../../heplers/translationHelper";
import { DatePicker } from "../../../../components/DatePicker";
import { paymentTypes } from "../../../../constants/typeOfPayment";
import { periodicityPeriods } from "../../../../constants/priodicityPeriods";
import { getDateIgnoreTimezone } from "../../../../heplers/dateHelper";

const CreateManagementCompanyFund = ({
  isSubmitting,
  onSubmit,
  editOperation,
}) => {
  if (editOperation) {
    editOperation.paymentType = paymentTypes.find(
      (x) => x.id === editOperation.typeOfPayment
    );
    editOperation.periodicityPeriods = periodicityPeriods.find(
      (x) => x.value === editOperation.periodicity
    );
    editOperation.fundId = editOperation.fund.id;
  }

  const [funds, setFunds] = useState([]);
  const history = useNavigate();
  const { managementCompanyId } = useParams();
  const [amountType, setAmountType] = useState(0);

  useEffect(() => {
    if (editOperation?.amountIn === 0) {
      setAmountType(1);
    } else if (editOperation?.amountOut === 1) {
      setAmountType(0);
    } else {
      setAmountType(0);
    }
    Get(
      {},
      Get_AllFunds_URL,
      history,
      (response) => {
        setFunds(response.data);
      },
      (error) => {}
    );
  }, []);

  const defaultPaymentType = paymentTypes[0];
  const defaultPeriodicity = periodicityPeriods[0];

  const initialValues = {
    amountIn: 0,
    amountOut: 0,
    date: new Date(),
    endDate: new Date(),
    interestRate: 0,
    periodicity: "",
    typeOfPayment: -1,
    fundManagementCompanyId: managementCompanyId,
    fundId: editOperation ? editOperation.fund.id : 0,
    //////// Extra states to manage the form

    fund: editOperation ? editOperation.fund : null,
    paymentType: editOperation ? editOperation.paymentType : defaultPaymentType,
    periodicityPeriods: editOperation ? editOperation.periodicityPeriods : defaultPeriodicity,
    isInterestRateRequired: false,
  };

  const amountInValidationSchema = Yup.object().shape({
    fundId: Yup.number().min(1,"Fund is required").required("Fund is required"),
    amountIn: Yup.number().min(1, "AmountIn must be greater than 0"),
    typeOfPayment: Yup.number().min(0, "Payment type is required"),
    periodicity: Yup.string().required("Periodicity type is required"),

  });
  const amountOutValidationSchema = Yup.object().shape({
    fundId: Yup.number().min(1,"Fund is required"),
    amountOut: Yup.number().min(1, "Amount Out must be greater than 0"),
    typeOfPayment: Yup.number().min(0, "Payment type is required"),
  });

  const defaultValue = editOperation ? editOperation : initialValues;
  return (
    <Card title="Add Fund Operation">
      {/* <CardHeader title='Details' /> */}
      <Formik
        enableReinitialize
        initialValues={defaultValue}
        validationSchema={
          amountType === 0
            ? amountInValidationSchema
            : amountOutValidationSchema
        }
        onSubmit={(values, actions) => {
          onSubmit(values, actions);
          actions.resetForm();
        }}
      >
        {({
          errors,
          touched,
          values,
          handleSubmit,
          getFieldProps,
          setFieldValue,
          setFieldTouched,
          setTouched
        }) => (
          <Form>
            {/* {editOperation?(values.fundId=editOperation.fund.id):null} */}
            <CardContent>
              {!editOperation ? (
                <ToggleButtonGroup
                  value={amountType}
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                  exclusive
                  onChange={(e, value) => {
                    if (value !== null && value !== undefined) {
                      setAmountType(value);
                    }
                    setFieldValue("isInterestRateRequired", false);
                  }}
                  aria-label="text alignment"
                  name="typeOfPayment"
                  size="small"
                >
                  <ToggleButton value={0} aria-label="left aligned">
                    Amount In
                  </ToggleButton>
                  <ToggleButton value={1} aria-label="centered">
                    Amount Out
                  </ToggleButton>
                </ToggleButtonGroup>
              ) : editOperation?.amountIn === 0 ? (
                <ToggleButtonGroup
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <ToggleButton selected value={1}>
                    Amount Out
                  </ToggleButton>
                  {setAmountType(1)}
                </ToggleButtonGroup>
              ) : (
                <ToggleButtonGroup
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <ToggleButton
                    selected
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    Amount In
                  </ToggleButton>
                  {setAmountType(0)}
                </ToggleButtonGroup>
              )}
              <br />
              <Grid container spacing={2}>
                {/* Select Fund */}
                <Grid item xs={12} sm={12} md={4} lg={4}>
                  {/* <Autocomplete
                    name="fundId"
                    value={values.fund}
                    options={funds}
                    getOptionLabel={(option) => option?.title}
                    size="small"
                    onChange={(e, value) => {
                      setFieldValue("fundId", value.id);
                      setFieldValue("fund", value);
                    }}
                    renderInput={(params) => (
                      <div>
                        <TextField
                          {...params}
                          label={getTranslation(
                            "Select Funds",
                            "Sélectionner une actif",
                            "Währung auswählen"
                          )}
                          variant="outlined"
                          error={touched.fundId && Boolean(errors.fundId)}
                        />
                        <ErrorMessage
                        name="fundId"
                        component='Typography'
                        style={{color:"#f44336",fontSize:'13px',margin:"4px 0px 0px 4px"}}
                         />
                      </div>
                    )}
                  /> */}
<Autocomplete
  name="fundId"
  value={values.fund}
  options={funds}
  getOptionLabel={(option) => option?.title}
  size="small"
  onChange={(e, value) => {
  //   setFieldValue("fundId", value ? value.id : null);
  //   setFieldValue("fund", value);
  //   setFieldTouched("fundId", true);
  // }}
  // onBlur={() => setFieldTouched("fundId", true)}

  setFieldValue("fundId", value ? value?.id : ""); 
  setFieldValue("fund", value ? value : ""); 
  setTouched("fundId", true); 
  setTouched("fund", true); 
}}
onBlur={() => {
  setTouched("fundId", true);
  setTouched("fund", true);
}}
  renderInput={(params) => (
    <div>
      <TextField
        {...params}
        label={getTranslation(
          "Select Funds",
          "Sélectionner une actif",
          "Währung auswählen"
        )}
        variant="outlined"
        error={touched.fundId && Boolean(errors.fundId)}
        helperText={touched.fundId && errors.fundId ? errors.fundId : ""}
      />
    </div>
  )}
/>



                </Grid>
                {amountType === 0 ? (
                  <>
                    {/* Amount In */}
                    <Grid item xs={12} sm={12} md={4} lg={4}>
                      <TextField
                        fullWidth
                        label={getTranslation(
                          "Amount In",
                          "Montant d'entrée",
                          "Einzahlung"
                        )}
                        variant="outlined"
                        type="number"
                        name="amountIn"
                        value={values.amountIn}
                        size="small"
                        error={touched.amountIn && Boolean(errors.amountIn)}
                        helperText={touched.amountIn && errors.amountIn}
                        {...getFieldProps("amountIn")}
                      />
                    </Grid>
                    {/* payment type selection */}
                    <Grid item xs={12} sm={12} md={4} lg={4}>
                      <Autocomplete
                        name="paymentType"
                        value={values.paymentType}
                        options={paymentTypes}
                        getOptionLabel={(option) =>
                          getTranslation(
                            option.defaultEnglish,
                            option.defaultFrench,
                            option.defaultGerman
                          )
                        }
                        size="small"
                        onChange={(e, value) => {
                          setFieldValue("paymentType", value);
                          setFieldValue("typeOfPayment", value?.id);
                          if (value?.value === "Loan"||value?.value === "Bond") {
                            setFieldValue("isInterestRateRequired", true);
                          } else {
                            setFieldValue("interestRate", 0);
                            setFieldValue("isInterestRateRequired", false);
                          }
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label={getTranslation(
                              "Select Payment Type",
                              "Sélectionnez le type de paiement",
                              "Wählen Sie Zahlungstyp aus"
                            )}
                            variant="outlined"
                        error={touched.typeOfPayment && Boolean(errors.typeOfPayment)}
                        />
                        )}
                      />
                    </Grid>
                    {/* if Payment type is loan then this field will be shown */}
                    {values.isInterestRateRequired ||(values.typeOfPayment===2||values.typeOfPayment===3)  ? (
                      <Grid item xs={12} sm={12} md={4} lg={4}>
                        <TextField
                          fullWidth
                          label={getTranslation(
                            "Interest Rate",
                            "Taux d'intérêt",
                            "Zins"
                          )}
                          variant="outlined"
                          type="number"
                          name="interestRate"
                          value={values.interestRate}
                          size="small"
                          error={
                            touched.interestRate && Boolean(errors.interestRate)
                          }
                          {...getFieldProps("interestRate")}
                        />
                      </Grid>
                    ) : null}
                    {/* Priodicity */}
                    <Grid item xs={12} sm={12} md={4} lg={4}>
                      <Autocomplete
                        name="periodicity"
                        value={values.periodicityPeriods}
                        options={periodicityPeriods}
                        getOptionLabel={(option) =>
                          getTranslation(
                            option.defaultEnglish,
                            option.defaultFrench,
                            option.defaultGerman
                          )
                        }
                        size="small"
                        onChange={(e, value) => {
                          setFieldValue("periodicityPeriods", value);
                          setFieldValue("periodicity", value?.value);
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label={getTranslation(
                              "Select Periodicity",
                              "Sélectionnez la périodicité",
                              "Wählen Sie die Periode"
                            )}
                            variant="outlined"
                        error={touched.periodicity && Boolean(errors.periodicity)}
                        />
                        )}
                      />
                    </Grid>
                  </>
                ) : amountType === 1 ? (
                  <>
                    {/* Amount Out */}
                    <Grid item xs={12} sm={12} md={4} lg={4}>
                      <TextField
                        fullWidth
                        label={getTranslation(
                          "Amount Out",
                          "Montant de sortie",
                          "Auszahlung"
                        )}
                        variant="outlined"
                        type="number"
                        name="amountOut"
                        value={values.amountOut}
                        size="small"
                        error={touched.amountOut && Boolean(errors.amountOut)}
                        helperText={touched.amountOut && errors.amountOut}
                        {...getFieldProps("amountOut")}
                      />
                    </Grid>
                      {/* payment type selection */}
                      <Grid item xs={12} sm={12} md={4} lg={4}>
                      <Autocomplete
                        name="paymentType"
                        value={values.paymentType}
                        options={paymentTypes}
                        getOptionLabel={(option) =>
                          getTranslation(
                            option.defaultEnglish,
                            option.defaultFrench,
                            option.defaultGerman
                          )
                        }
                        size="small"
                        onChange={(e, value) => {
                          setFieldValue("paymentType", value);
                          setFieldValue("typeOfPayment", value.id);
                          // if (value.value === "Loan"||value.value === "Bond") {
                          //   setFieldValue("isInterestRateRequired", true);
                          // } else {
                          //   setFieldValue("interestRate", 0);
                          //   setFieldValue("isInterestRateRequired", false);
                          // }
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label={getTranslation(
                              "Select Payment Type",
                              "Sélectionnez le type de paiement",
                              "Wählen Sie Zahlungstyp aus"
                            )}
                            variant="outlined"
                        error={touched.typeOfPayment && Boolean(errors.typeOfPayment)}
                        />
                        )}
                      />
                    </Grid>
                  </>
                ) : null}

                {/* Start Date */}
                <Grid item xs={12} sm={12} md={4} lg={4}>
                  <DatePicker
                    fullWidth
                    disabled={values.periodicity === "End Term"}
                    label={getTranslation(
                      "Start Date",
                      "Date de début",
                      "Startdatum"
                    )}
                    variant="outlined"
                    name="date"
                    value={values.date}
                    size="small"
                    error={touched.date && Boolean(errors.date)}
                    onChange={(date) => {
                      const theDate = getDateIgnoreTimezone(date)
                      setFieldValue("date", theDate);
                    }}
                  />
                </Grid>
                {/* End Date */}
                <Grid item xs={12} sm={12} md={4} lg={4}>
                  <DatePicker
                    fullWidth
                    label={getTranslation(
                      "End Date",
                      "Date de fin",
                      "Enddatum"
                    )}
                    variant="outlined"
                    name="endDate"
                    value={values.endDate}
                    size="small"
                    error={touched.endDate && Boolean(errors.endDate)}
                    onChange={(date) => {
                      const theDate = getDateIgnoreTimezone(date)
                      setFieldValue("endDate", theDate);
                    }}
                  />
                </Grid>
              </Grid>
            </CardContent>
            <CardActions>
              <Box
                pr={1}
                pb={1}
                width="100%"
                display="flex"
                alignItems="center"
                justifyContent="flex-end"
              >
                <ButtonWithLoading
                  title={
                    editOperation
                      ? getTranslation(
                          "Update",
                          "Mettre à jour",
                          "Aktualisieren"
                        )
                      : getTranslation("Submit", "Soumettre", "Absenden")
                  }
                  size="small"
                  variant="contained"
                  color="primary"
                  loading={isSubmitting}
                  onClick={handleSubmit}
                />
              </Box>
            </CardActions>
          </Form>
        )}
      </Formik>
    </Card>
  );
};

export default CreateManagementCompanyFund;
