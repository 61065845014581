// import React, { useState, useEffect } from "react";
// import { useParams } from "react-router";
// import {
//   CardContent,
//   CardActions,
//   Grid,
//   TextField,
//   Box,
//   Typography,
//   IconButton,
// } from "@material-ui/core";
// import { ErrorMessage, Formik } from "formik";
// import { useSnackbar } from "notistack";
// import Card from "../../../components/Card";
// import { makeStyles } from "@material-ui/core/styles";
// import { Autocomplete } from "@material-ui/lab";
// import ButtonWithLoading from "../../../components/ButtonWithLoading";
// import { useDispatch, useSelector } from "react-redux";
// import * as Yup from "yup";
// import { useNavigate } from "react-router-dom";
// import CircularProgress from "@material-ui/core/CircularProgress";
// import { Get } from "../../../actions";
// import { GET_AllCompaniesList_URL } from "../../../constants/apiUrls";
// import {
//   MuiPickersUtilsProvider,
//   KeyboardDatePicker,
// } from "@material-ui/pickers";
// import DateFnsUtils from "@date-io/date-fns";
// import { getTranslation } from "../../../heplers/translationHelper";
// import { AssetSubmenu, CompanySubmenu, FundSubmenu, ManagementCompaniesSubmenu } from "../../../layout/subMenu";
// import { Cancel } from "@material-ui/icons";

// const useStyles = makeStyles((theme) => ({
//   formControl: {
//     margin: theme.spacing(1),
//     minWidth: 120,
//   },
//   selectEmpty: {
//     marginTop: theme.spacing(2),
//   },
// }));

// const representativeRoles = ["President", "Secretary", "Ordinary Member"];

// const CreateShareholder = ({onCancel, isSubmitting, fundId,managementCompanyId,companyId,onSubmit,editShareHolder}) => {
//   console.log("jhdkjfgdskfsdf",editShareHolder);
//   console.log("Company1111",companyId)
//   const params = useParams()
//   const [companyRepresentatvies, setCompanyRepresentatvies] = useState([]);
//   const [companies, setCompanies] = useState([]);
//   const [selectedCompany, setSelectedCompany] = useState("");
//   const history = useNavigate();
//   const dispatch = useDispatch();
//   const getSelectedCompany = (company) => {};
//   const { enqueueSnackbar } = useSnackbar();
//   useEffect(() => {
//     Get(
//       {},
//       GET_AllCompaniesList_URL,
//       history,
//       (response) => {
//         setCompanies(response.data);
//       },
//       (error) => {}
//     );
//   }, []);

//   const initialValues = {
//     companyId: companyId,
//     shareholderCompanyId: "",
//     share: 0,
//     capital: 0,
//     date: new Date(),
//     shareholderCompany:{},
//   };

//   const basicValidationSchema = Yup.object().shape({
//     shareholderCompanyId: Yup.string().required("Company is required"),
//     share: Yup.number().required("Share is required"),
//     capital: Yup.number().min(1).required("Minimum Capital is 1"),
//     date: Yup.date().required("Date is required"),
//   });
//   const defaultValue = editShareHolder ? editShareHolder : initialValues;
//   return (
//     <>  {params.assetId ? (
//       <AssetSubmenu  assetId={params.assetId} companyId={params.companyId} />
//     ) : params.fundId ? (
//       <FundSubmenu fundId={params.fundId} companyId={params.companyId} />
//     ) : params.managementCompanyId ? (
//       <ManagementCompaniesSubmenu managementCompanyId={params.managementCompanyId} companyId={companyId} />
//     ) : (
//       <CompanySubmenu  companyId={companyId} />
//     )}
//     <Card title={getTranslation("Add Shareholder","Ajouter un actionnaire","Aktionär hinzufügen")}>
//       {/* <CardHeader title='Details' /> */}
//       <Formik
//         enableReinitialize
//         initialValues={defaultValue}
//         validationSchema={basicValidationSchema}
//         onSubmit={(values, actions) => {
//           actions.setSubmitting(true);
//           onSubmit(values, actions);
//           actions.resetForm();
//         }}
//       >
//         {({
//           errors,
//           touched,
//           values,
//           handleSubmit,
//           getFieldProps,
//           setFieldValue,
//         }) => (
//           <form>
            
//             <CardContent>
//               <Grid container spacing={2}>
//                 <Grid item xs={12} sm={12} md={4} lg={4}>
//                   <Autocomplete
//                     name="shareholderCompanyId"
//                     value={values.shareholderCompany}
//                     options={companies}
//                     getOptionLabel={(option) => option.companyName}
//                     size="small"
//                     onChange={(e, value) => {
//                       setFieldValue("shareholderCompany", value);
//                       setFieldValue("shareholderCompanyId", value?.id);
//                     }}
//                     //   onInputChange={(e, value) => setSearchCompaniesString(value)}
//                     renderInput={(params) => (
//                       <div>
//                         <TextField
//                           {...params}
//                           label={getTranslation("Select Company","Sélectionnez l'entreprise","Unternehmen auswählen")}
//                           variant="outlined"
//                         />
//                         <ErrorMessage 
//                         name="shareholderCompanyId"
//                         component="div"
//                         style={{color:'#f44336',fontSize:'14px',margin:'4px 0px 4px 0px'}}
//                         />
//                       </div>
//                     )}
//                   />
//                 </Grid>
//                 <Grid item xs={12} sm={12} md={4} lg={4}>
//                   <TextField
//                     fullWidth
//                     label={getTranslation("Share","Partager","Teilen")}
//                     type="number"
//                     required
//                     size="small"
//                     variant="outlined"
//                     name="share"
//                     {...getFieldProps("share")}
//                     error={touched.share && Boolean(errors.share)}
//                     helperText={touched.share && errors.share}
//                     InputLabelProps={{
//                       shrink: true,
//                     }}
//                   />
//                 </Grid>
//                 <Grid item xs={12} sm={12} md={4} lg={4}>
//                   <TextField
//                     fullWidth
//                     label={getTranslation("Capital","Capitale","Hauptstadt")}
//                     type="number"
//                     required
//                     size="small"
//                     variant="outlined"
//                     name="capital"
//                     {...getFieldProps("capital")}
//                     error={touched.capital && Boolean(errors.capital)}
//                     helperText={touched.capital && errors.capital}
//                     InputLabelProps={{
//                       shrink: true,
//                     }}
//                   />
//                 </Grid>
//                 <Grid item xs={12} sm={12} md={4} lg={4}>
//                   <MuiPickersUtilsProvider utils={DateFnsUtils}>
//                     <KeyboardDatePicker
//                       fullWidth
//                       disableToolbar
//                       variant="inline"
//                       format="dd/MM/yyyy"
//                       size="small"
//                       id="Incorporation-Date-inline"
//                       label={getTranslation("Date","Date","Datum")}
//                       autoOk
//                       value={values.date}
//                       onChange={(value) => {
//                         setFieldValue("date", value);
//                       }}
//                       KeyboardButtonProps={{
//                         "aria-label": "Date",
//                       }}
//                       InputLabelProps={{
//                         shrink: true,
//                       }}
//                       required
//                       error={touched.date && Boolean(errors.date)}
//                      // helperText={touched.date && errors.date}
//                     />
//                   </MuiPickersUtilsProvider>
//                 </Grid>
//               </Grid>
//             </CardContent>
//             <CardActions>
//               <Box
//                 pr={1}
//                 pb={1}
//                 width="100%"
//                 display="flex"
//                 alignItems="center"
//                 justifyContent="flex-end"
//               >
//                 {editShareHolder?
//                 <IconButton onClick={onCancel}>
//                   <Cancel/>
//                 </IconButton>:null}
//                 <ButtonWithLoading
//                   title={editShareHolder? getTranslation("Update") : getTranslation("Create","Créer","Schaffen")}
//                   size="small"
//                   variant="contained"
//                   color="primary"
//                   loading={isSubmitting}
//                   //onClick={handleSubmit}
//                   onClick={() => {
//                    handleSubmit();
//                   }}
//                 />
//               </Box>
//             </CardActions>
//           </form>
//         )}
//       </Formik>
//     </Card>
//     </>
//   );
// };

// export default CreateShareholder;



import React, { useState, useEffect } from "react";
import { useParams } from "react-router";
import {
  CardContent,
  CardActions,
  Grid,
  TextField,
  Box,
  Typography,
  IconButton,
} from "@material-ui/core";
import { ErrorMessage, Formik } from "formik";
import { useSnackbar } from "notistack";
import Card from "../../../components/Card";
import { makeStyles } from "@material-ui/core/styles";
import { Autocomplete } from "@material-ui/lab";
import ButtonWithLoading from "../../../components/ButtonWithLoading";
import { useDispatch, useSelector } from "react-redux";
import * as Yup from "yup";
import { useNavigate } from "react-router-dom";
import CircularProgress from "@material-ui/core/CircularProgress";
import { Get } from "../../../actions";
import { GET_AllCompaniesList_URL } from "../../../constants/apiUrls";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import { getTranslation } from "../../../heplers/translationHelper";
import { AssetSubmenu, CompanySubmenu, FundSubmenu, ManagementCompaniesSubmenu } from "../../../layout/subMenu";
import { Cancel } from "@material-ui/icons";

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

const representativeRoles = ["President", "Secretary", "Ordinary Member"];

const CreateShareholder = ({onCancel, isSubmitting, fundId,managementCompanyId,companyId,onSubmit,editShareHolder}) => {
  console.log("jhdkjfgdskfsdf",editShareHolder);
  console.log("Company1111",companyId)
  const params = useParams()
  const [companyRepresentatvies, setCompanyRepresentatvies] = useState([]);
  const [companies, setCompanies] = useState([]);
  const [selectedCompany, setSelectedCompany] = useState("");
  const history = useNavigate();
  const dispatch = useDispatch();
  const getSelectedCompany = (company) => {};
  const { enqueueSnackbar } = useSnackbar();
  useEffect(() => {
    Get(
      {},
      GET_AllCompaniesList_URL,
      history,
      (response) => {
        setCompanies(response.data);
      },
      (error) => {}
    );
  }, []);

  const initialValues = {
    companyId: companyId,
    shareholderCompanyId: "",
    share: 0,
    capital: 0,
    date: new Date(),
    shareholderCompany:{},
  };

  const basicValidationSchema = Yup.object().shape({
    shareholderCompanyId: Yup.string().required("Company is required"),
    share: Yup.number().required("Share is required"),
    capital: Yup.number().min(1).required("Minimum Capital is 1"),
    date: Yup.date().required("Date is required"),
  });
  const defaultValue = editShareHolder ? editShareHolder : initialValues;
  return (
    <>  {params.assetId ? (
      <AssetSubmenu  assetId={params.assetId} companyId={params.companyId} />
    ) : params.fundId ? (
      <FundSubmenu fundId={params.fundId} companyId={params.companyId} />
    ) : params.managementCompanyId ? (
      <ManagementCompaniesSubmenu managementCompanyId={params.managementCompanyId} companyId={companyId} />
    ) : (
      <CompanySubmenu  companyId={companyId} />
    )}
    <Card title={getTranslation("Add Shareholder","Ajouter un actionnaire","Aktionär hinzufügen")}>
      {/* <CardHeader title='Details' /> */}
      <Formik
        enableReinitialize
        initialValues={defaultValue}
        validationSchema={basicValidationSchema}
        onSubmit={(values, actions) => {
          actions.setSubmitting(true);
          onSubmit(values, actions);
          actions.resetForm();
        }}
      >
        {({
          errors,
          touched,
          values,
          handleSubmit,
          getFieldProps,
          setFieldValue,
        }) => (
          <form>
            
            <CardContent>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={12} md={4} lg={4}>
                  <Autocomplete
                    name="shareholderCompanyId"
                    value={values.shareholderCompany}
                    options={companies}
                    getOptionLabel={(option) => option.companyName}
                    size="small"
                    onChange={(e, value) => {
                      setFieldValue("shareholderCompany", value);
                      setFieldValue("shareholderCompanyId", value?.id);
                    }}
                    //   onInputChange={(e, value) => setSearchCompaniesString(value)}
                    renderInput={(params) => (
                      <div>
                        <TextField
                          {...params}
                          label={getTranslation("Select Company","Sélectionnez l'entreprise","Unternehmen auswählen")}
                          variant="outlined"
                        />
                        <ErrorMessage 
                        name="shareholderCompanyId"
                        component="div"
                        style={{color:'#f44336',fontSize:'14px',margin:'4px 0px 4px 0px'}}
                        />
                      </div>
                    )}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={4} lg={4}>
                  <TextField
                    fullWidth
                    label={getTranslation("Share","Partager","Teilen")}
                    type="number"
                    required
                    size="small"
                    variant="outlined"
                    name="share"
                    {...getFieldProps("share")}
                    error={touched.share && Boolean(errors.share)}
                    helperText={touched.share && errors.share}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={4} lg={4}>
                  <TextField
                    fullWidth
                    label={getTranslation("Capital","Capitale","Hauptstadt")}
                    type="number"
                    required
                    size="small"
                    variant="outlined"
                    name="capital"
                    {...getFieldProps("capital")}
                    error={touched.capital && Boolean(errors.capital)}
                    helperText={touched.capital && errors.capital}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={4} lg={4}>
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <KeyboardDatePicker
                      fullWidth
                      disableToolbar
                      variant="inline"
                      format="dd/MM/yyyy"
                      size="small"
                      id="Incorporation-Date-inline"
                      label={getTranslation("Date","Date","Datum")}
                      autoOk
                      value={values.date}
                      onChange={(value) => {
                        setFieldValue("date", value);
                      }}
                      KeyboardButtonProps={{
                        "aria-label": "Date",
                      }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      required
                      error={touched.date && Boolean(errors.date)}
                     // helperText={touched.date && errors.date}
                    />
                  </MuiPickersUtilsProvider>
                </Grid>
              </Grid>
            </CardContent>
            <CardActions>
              <Box
                pr={1}
                pb={1}
                width="100%"
                display="flex"
                alignItems="center"
                justifyContent="flex-end"
              >
                {editShareHolder?
                <IconButton onClick={onCancel}>
                  <Cancel/>
                </IconButton>:null}
                <ButtonWithLoading
                  title={editShareHolder? getTranslation("Update") : getTranslation("Create","Créer","Schaffen")}
                  size="small"
                  variant="contained"
                  color="primary"
                  loading={isSubmitting}
                  //onClick={handleSubmit}
                  onClick={() => {
                   handleSubmit();
                  }}
                />
              </Box>
            </CardActions>
          </form>
        )}
      </Formik>
    </Card>
    </>
  );
};

export default CreateShareholder;

