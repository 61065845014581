import { Box, CardContent, Checkbox, FormControl, FormControlLabel, Grid, TextField } from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import { Form, Formik } from "formik";
import { useState } from "react";
import { useEffect } from "react";
import { Get } from "../../../../../../actions";
import ButtonWithLoading from "../../../../../../components/ButtonWithLoading";
import Card from "../../../../../../components/Card";
import { Get_AgendaTemplateList_URL, Get_AllAgendaTemplateItems_URL } from "../../../../../../constants/apiUrls";
import { getTranslation } from "../../../../../../heplers/translationHelper";
import { AskAgenda } from "../../../../../public";
import { AskingAgendaList } from "../agenda/askingAgendaList";

const AskForAgenda = ({ NextStep, BackStep , initialValues}) => {
    const [templates, setTemplates] = useState([]);
    const [templatesLoading, setTemplatesLoading] = useState(false);

    useEffect(() => {
        loadTemplates()
    }, [])

    const loadTemplates = () => {
        setTemplatesLoading(true);
        Get(
            {},
            Get_AgendaTemplateList_URL,
            null,
            (resp) => {
                setTemplates(resp.data);
                setTemplatesLoading(false);
            },
            (error) => { }
        );
    };
    const loadTemplateItems = (templateId, callback) => {
        // setTemplatesLoading(true);
        Get(
            {
                agendaTemplateId: templateId,
            },
            Get_AllAgendaTemplateItems_URL,
            null,
            (resp) => {
                callback(resp.data);
            },
            (error) => { }
        );
    };

    // const initialValues = {
    //     meetingReminders: [],
    //     reminders: [],
    //     isReminderRequired: false,
    //     agendas: [],

    // }
    return (
        <Formik
            initialValues={initialValues}
            onSubmit={(values, actions) => {
                NextStep(values);
            }}
        >
            {({
                errors,
                touched,
                values,
                handleSubmit,
                isSubmitting,
                getFieldProps,
                setFieldValue,
                handleChange,
            }) => (
                <Form>
                    <Grid container spacing={2} style={{ marginBottom: "10px" }}>
                        {/* Step 4 */}
                        <Grid item xs={12} sm={12} md={12} lg={12}>
                            <Card title={getTranslation("Agenda", "Ordre du jour", "Agenda")}>
                                <CardContent>
                                    <FormControl>
                                        <FormControlLabel
                                            label={getTranslation("Asking For Agenda", "Demander l'ordre du jour", "Tagesordnung erfragen")}
                                            labelPlacement="start"
                                            control={
                                                <Checkbox
                                                    id="isAskingAgendaAllowed"
                                                    checked={values.isAskingAgendaAllowed}
                                                    onChange={handleChange}
                                                />
                                            }
                                        />
                                    </FormControl>
                                    {values.isAskingAgendaAllowed ? (
                                        <>
                                            <br />
                                            <br />
                                            <TextField
                                                id="askingAgendaInterval"
                                                label={getTranslation("Interval (days before)", "Intervalle (jours avant)", "Intervall (Tage vorher)")}
                                                required
                                                size="small"
                                                variant="outlined"
                                                name="periodicity"
                                                type="number"
                                                {...getFieldProps("askingAgendaInterval")}
                                                error={touched.askingAgendaInterval && Boolean(errors.askingAgendaInterval)}
                                                helperText={touched.askingAgendaInterval && errors.askingAgendaInterval}
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                                style={{ width: "400px" }}
                                            />
                                            <br />
                                            <br />
                                            <Autocomplete
                                                style={{ width: "400px" }}
                                                options={templates}
                                                getOptionLabel={(option) => option?.name}
                                                size="small"
                                                onChange={(e, value) => {
                                                    loadTemplateItems(value?.id, (agendas) => {
                                                        setFieldValue("agendas", agendas);
                                                    });
                                                }}
                                                renderInput={(params) => (
                                                    <TextField
                                                        required
                                                        {...params}
                                                        label={getTranslation("Template", "Modèle", "Vorlage")}
                                                        variant="outlined"
                                                        error={
                                                            touched.template && Boolean(errors.template)
                                                        }
                                                        helperText={touched.template && errors.template}
                                                    />
                                                )}
                                            />
                                            <br />
                                            <AskingAgendaList
                                                agendas={values.agendas}
                                                onAgendaRemoved={(agenda)=>{
                                                    const arr=values.agendas.filter(x=>x!=agenda);
                                                    setFieldValue("agendas",arr)
                                                }}
                                                onNewAgendaAdded={(agenda)=>{
                                                    const arr=values.agendas;
                                                    arr.push(agenda)
                                                    setFieldValue("agendas",arr)
                                                }}
                                                onUpdateAgenda={(index,agenda)=>{
                                                    const arr=values.agendas;
                                                    arr[index]=agenda
                                                    setFieldValue("agendas",arr)
                                                }}
                                            />
                                        </>
                                    ) : null}
                                </CardContent>
                            </Card>
                        </Grid>
                    </Grid>
                    <Box
                        display="flex"
                        justifyContent="space-between"
                        alignItems="space-between"
                        m={2}
                    >
                        <ButtonWithLoading
                            title={getTranslation("Back", "Précédent", "Zurück")}
                            color="primary"
                            variant="contained"
                            size="large"
                            onClick={() => BackStep()}
                        />
                        <ButtonWithLoading
                            title={getTranslation("Next", "Suivant", "Weiter")}
                            color="primary"
                            variant="contained"
                            size="large"
                            onClick={handleSubmit}
                        />
                    </Box>
                </Form>
            )}
        </Formik>
    )
}
export default AskForAgenda;