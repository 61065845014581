import QRCode from 'qrcode'
import QrReader from 'react-qr-reader'
export async function GenerateQRCode(data){
    try{
        return await QRCode.toDataURL(JSON.stringify(data));
    }catch{
        return 'Error'
    }
}

