import React, { useState, useEffect } from "react";
import Table from "../../components/table";
// import {
//   getActivities,
//   deleteActivity
// } from "../../actions";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useSnackbar } from "notistack";
import { Get, Create } from "../../actions";
import {
  Get_AllManagementCompaniesWithPagination_URL,
} from "../../constants/apiUrls";
import { getTranslation } from "../../heplers/translationHelper";
import { MeetingSubMenu } from "../../layout/subMenu";
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';

const columns = [
  {
    id: "isMeetingPlanned",
    component: ({row}) => (row.isMeetingPlanned) ? <FiberManualRecordIcon style={{
      color:"green"
    }}/>:
    <FiberManualRecordIcon style={{
      color: 'red',
    }}/>
  },  
  {
    id: "title",
    numeric: false,
    disablePadding: true,
    label:getTranslation("Title","Titre","Titel"),
  },
  {
    id: "investorRelation",
    numeric: false,
    disablePadding: true,
    label:getTranslation("Investors Relation","Relation Investisseurs","Investors-Relation"),
  },
  {
    id: "feeAgreementStatusTitle",
    numeric: false,
    disablePadding: true,
    label:getTranslation("FeeAgreement Status","Statut de l'accord de frais","Status der Gebührenvereinbarung"),
  },

];

const ListManagementCompanies = () => {
  const history = useNavigate();
  const dispatch = useDispatch();
  const [searchString, setSearchString] = useState("");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [rows, setRows] = useState([]);
  const [loadingFunds, setloadingFunds] = useState(false);
  useEffect(() => {
    dispatch({ type: "Clear_All_BreadCrumb" });
    dispatch({
      type: "Add_BreadCrumb",
      payload: { title: "Meetings", url: "/meetings" },
    });
  }, []);

  useEffect(() => {
    loadManagementCompanies();
  }, [page, rowsPerPage, searchString]);

  const loadManagementCompanies = () => {
    setloadingFunds(true);
    Get(
      {
        searchString,
        pageNumber: page,
        pageSize: rowsPerPage,
      },
      Get_AllManagementCompaniesWithPagination_URL,
      history,
      (resp) => {
        setRows(resp.data);
        setloadingFunds(false);
      },
      (error) => {}
    );
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value));
    setPage(0);
  };
  const handleDetailCompany = (row) => {
      //dispatch({ type: Show_Meeting_Submenu, payload: row.id });
      const url = `/meetings/${row.id}/regular`;
      history(url);
      dispatch({
        type: "Add_BreadCrumb",
        payload: { title: row.title, url: url },
      });
  };

  const handleSearchFund = (searchString) => {
    setSearchString(searchString);
  };

  const [selectedMeetings, setSelectedMeetings] = useState([]);

  const handleSelectionChange = (items) => {
    //    Filter Removed ones

    //    Filter New Ones

    // Triggered apis if length > 0

    // Update State

    setSelectedMeetings(items);
  };

  return (
    <div>
      <MeetingSubMenu/>
      <Table
        dense
        loading={loadingFunds}
        title="SPV"
        colums={columns}
        sortBy="title"
        rows={rows?.data}
        count={rows?.totalCount}
        page={page}
        rowsPerPage={rowsPerPage}
        handleChangePage={(event, newPage) => handleChangePage(event, newPage)}
        handleChangeRowsPerPage={(e) => handleChangeRowsPerPage(e)}
        viewEdit
        onEdit={(row) => handleDetailCompany(row)}
        viewSearch
        onSearch={(searchString) => handleSearchFund(searchString)}
        // enableCheckbox
        // selected={selectedMeetings}
        // onSelectionChange={handleSelectionChange}
      />

      {/* <DeleteDialog
            open={open}                               
            title="Delete Fund"
            onClose={() => setOpen(false)}
            onSubmit={(e) => handleDeleteActivity(deleteRow)}
        /> */}
    </div>
  );
};

export default ListManagementCompanies;
