export const formCategories=[
    {
        value: 'board',
        defaultEnglish: 'Board',
        defaultFrench: 'Tableau',
        defaultGerman: 'Tafel',
    },
    // {
    //     value:"shareholder",
    //     defaultEnglish: 'Shareholder',
    //     defaultFrench: 'Particulier',
    //     defaultGerman: 'Partner',
    // },
    {
        value:"manager",
        defaultEnglish: 'Manager',
        defaultFrench: 'Gestionnaire',
        defaultGerman: 'Manager',
    },
    {
        value:"ceres team member",
        defaultEnglish: 'Ceres team member',
        defaultFrench: 'Membre du personnel de ceres',
        defaultGerman: 'Ceres Teammitglied',
    }

]
