import React, { useState, useEffect } from "react";
import Table from "../../components/table";
import { useNavigate, useParams } from "react-router-dom";
import { useSnackbar } from "notistack";
import { Get, Create } from "../../actions";
import {
  Post_DeleteAsset_URL,
  Get_AssetOperationsSummary_URL,
} from "../../constants/apiUrls";
import {
  AssetOperations,
} from "./component/asset";
import DeleteDialog from "../../components/DeleteDialog";
import { getTranslation } from "../../heplers/translationHelper";
import { AssetSubmenu, CompanySubmenu, ManagementCompaniesSubmenu,FundSubmenu } from "../../layout/subMenu/meeting";
import { ToggleButton, ToggleButtonGroup } from "@material-ui/lab";
const Fund = () => {
  const columns = [
    {
      id: "title",
      numeric: false,
      disablePadding: true,
      label:getTranslation("Asset Title","Titre de l'actif","Asset-Titel"),
    },
    {
      id: "backPayment",
      numeric: true,
      disablePadding: true,
      label:getTranslation("Back Payment","Remboursement","Rückerstattung"),
    },

    {
      id: "invested",
      numeric: true,
      disablePadding: true,
      label:getTranslation("Invested","Investi","Investiert"),
    },

    {
      id: "balance",
      numeric: true,
      disablePadding: true,
      label:getTranslation("Balance","Solde","Balance"),
    },
  ];

  const history = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const [open, setOpen] = useState(false);
  const [deleteRow, setDeleteRow] = useState({});
  const [searchString, setSearchString] = useState("");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [switchValue, setSwitchValue] = useState(0);
  const [rows, setRows] = useState([]);
  const [summaryLoading, setSummaryLoading] =
    useState(false);
  const params = useParams();
  useEffect(() => {
    loadManagementCompanyFunds();
  }, [page, rowsPerPage, searchString,switchValue]);

  const loadManagementCompanyFunds = () => {
    setSummaryLoading(true);
    Get(
      {
        managementCompanyId: params.managementCompanyId,
        searchString,
        pageNumber: page,
        pageSize: rowsPerPage,
      },
      Get_AssetOperationsSummary_URL,
      history,
      (resp) => {
        setRows(resp.data);
        setSummaryLoading(false);
      },
      (error) => {
        enqueueSnackbar("Summary loading error: "+error?.data, {variant: "error"});
      }
    );
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value));
    setPage(0);
  };
  const handleSearchManagementCompanyFund = (searchString) => {
    setSearchString(searchString);
  };


  const handleOpenDeleteDialog = (row) => {
    setDeleteRow(row);
    setOpen(true);
  };

  const handleDeleteActivity = (row) => {
    const deleteArray = [];
    deleteArray.push(row.id);
    Create(
      { ids: deleteArray },
      Post_DeleteAsset_URL,
      history,
      (resp) => {
        setOpen(false);
        enqueueSnackbar("Asset Deleted", {
          variant: "success",
        });
      },
      (error) => {
        enqueueSnackbar(
          "Unable to delete asset because its being used by some company",
          {
            variant: "error",
          }
        );
      }
    );
  };


  return (
    <div>
      {params.assetId ? (
        <AssetSubmenu  assetId={params.assetId} />
      ) : params.fundId ? (
        <FundSubmenu fundId={params.fundId} />
      ) : params.managementCompanyId ? (
        <ManagementCompaniesSubmenu managementCompanyId={params.managementCompanyId} companyId={params.companyId} />
      ) : (
        <CompanySubmenu companyId={"9999"} />
      )}
         <ToggleButtonGroup 
      size="small"
      style={{
        margin:"10px",
        display:"flex",
        flexDirection:"row",
        justifyContent:"center",
        alignItems:"center",
      }}
      exclusive
      value={switchValue}
      onChange={(e,value)=>{
        if(value!==null && typeof value!=="undefined"){
        setSwitchValue(value);
      }
      }}
      >
        <ToggleButton value={0}>
          {getTranslation("Asset Operations","Opérations sur les actifs","Asset-Operationen")}
        </ToggleButton>
        <ToggleButton value={1}>
          {getTranslation("Summary","Résumé","Zusammenfassung")}
        </ToggleButton>
      </ToggleButtonGroup>
      {switchValue===0?(
      <AssetOperations />):(
      <Table
        dense
        loading={summaryLoading}
        title={getTranslation("Summary", "Résumé", "Zusammenfassung")}
        colums={columns}
        sortBy="title"
        rows={rows}
        count={rows?.length}
        page={page}
        rowsPerPage={rowsPerPage}
        handleChangePage={(event, newPage) => handleChangePage(event, newPage)}
        handleChangeRowsPerPage={(e) => handleChangeRowsPerPage(e)}
        viewDelete
        onDelete={(row) => handleOpenDeleteDialog(row)}
        onDelet
        viewSearch
        onSearch={(searchString) =>
          handleSearchManagementCompanyFund(searchString)
        }
      />
      )}

      <DeleteDialog
        open={open}
        title={getTranslation(
          "Delete Person",
          "Supprimer la personne",
          "Person löschen"
        )}
        onClose={() => setOpen(false)}
        onSubmit={(e) => handleDeleteActivity(deleteRow)}
      />
    </div>
  );
};

export default Fund;
