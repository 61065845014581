import React, { useState, useEffect } from "react";
import {
  CardContent,
  CardActions,
  Grid,
  TextField,
  Box,
  Typography,
  IconButton,
} from "@material-ui/core";
import { Form, Formik } from "formik";
import Card from "../../components/Card";
import Table from "../../components/table";
import * as Yup from "yup";
import ButtonWithLoading from "../../components/ButtonWithLoading";
import DeleteDialog from "../../components/DeleteDialog";

import Autocomplete, {
  createFilterOptions,
} from "@material-ui/lab/Autocomplete";
import { useNavigate, useParams } from "react-router-dom";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import { Get, Create } from "../../actions";
import {
  Post_UpdateFeeAgreement_URL,
  Get_AssetFeeAgreement_URL,
  Get_AllCeresServices_URL,
  Post_InsertFeeAgreementCeresService_URL,
  Get_FundFeeAgreement_URL,
  Get_ManagementCompanyFeeAgreement_URL,
  Post_DeleteFeeAgreementCeresService_URL,
  Post_UpdateFeeAgreementCeresService_URL,
  Post_CancelAssetFeeAgreement_URL,
  Post_CancelFundFeeAgreement_URL,
  Post_CancelManagementCompanyFeeAgreement_URL,
} from "../../constants/apiUrls";
import { useSnackbar } from "notistack";
import Switch from "../../components/Switch";
import { getTranslation } from "../../heplers/translationHelper";
import { UploadFiles } from "../../components/Upload";
import { DeleteButton } from "../../components/ButttonsWithIcons";
import FeeAgreementHistory from "./component/feeAgreementHistory";

import _ from "lodash";
import {
  AssetSubmenu,
  CompanySubmenu,
  FundSubmenu,
  ManagementCompaniesSubmenu,
} from "../../layout/subMenu";
import { Cancel } from "@material-ui/icons";
const FeeAgreement = () => {
  const [feeAgreement, setFeeAgreement] = useState({});
  const [ceresServices, setCeresServices] = useState([]);
  const [loadingFeeAgreement, setLoadingFeeAgreement] = useState(true);
  const { enqueueSnackbar } = useSnackbar();
  const history = useNavigate();
  const { assetId, fundId, managementCompanyId,companyId } = useParams();
  const [additionalPage, setAdditionalPage] = useState(0);
  const [regularPage, setRegularPage] = useState(0);
  const [deleteRow, setDeleteRow] = useState({});
  const [openDelete, setOpenDelete] = useState(false);
  const [openCancelDialog, setOpenCancelDialog] = useState(false);
  const [deleteProgress, setDeleteProgress] = useState(false);
  const [editRegularService, setEditRegularService] = useState(null);
  const [additionalRowsPerPage, setAdditionalRowsPerPage] = useState(10);
  const [regularRowsPerPage, setRegularRowsPerPage] = useState(10);
  const [loadingFeeAgreementHistory, setLoadingFeeAgreementHistory] =
    useState(true);
  useEffect(() => {
    loadFeeAgreement();
    loadCeresServices();
  }, []);
  const loadFeeAgreement = async () => {
    setLoadingFeeAgreement(true);
    let id = 0;
    let url = "";

    if (assetId) {
      id = assetId;
      url = Get_AssetFeeAgreement_URL;
    } else if (fundId) {
      id = fundId;
      url = Get_FundFeeAgreement_URL;
    } else if (managementCompanyId) {
      id = managementCompanyId;
      url = Get_ManagementCompanyFeeAgreement_URL;
    }

    await Get(
      { id },
      url,
      history,
      (resp) => {
        setFeeAgreement(resp?.data);
        setLoadingFeeAgreement(false);
      },
      (error) => {
        setLoadingFeeAgreement(false);
      }
    );
  };
  const loadCeresServices = async () => {
    setLoadingFeeAgreement(true);

    await Get(
      {},
      Get_AllCeresServices_URL,
      history,
      (resp) => {
        setCeresServices(resp.data);
        setLoadingFeeAgreement(false);
      },
      (error) => {
        setLoadingFeeAgreement(false);
      }
    );
  };
  const handleSubmitFeeAgreement = async (values, actions) => {
    await Create(
      values,
      Post_UpdateFeeAgreement_URL,
      history,
      (resp) => {
        enqueueSnackbar("Fee Agreement Updated", { variant: "success" });
        loadFeeAgreement();

        actions.setSubmitting(false);
      },
      (error) => {
        enqueueSnackbar(error.data, { variant: "error" });
        actions.setSubmitting(false);
      }
    );
  };
  const handleAddService = async (values, actions) => {
    let url = Post_InsertFeeAgreementCeresService_URL;
    if (editRegularService) {
      url = Post_UpdateFeeAgreementCeresService_URL;
    }
    await Create(
      values,
      url,
      history,
      (resp) => {
        if (editRegularService) {
          setEditRegularService(null);
          enqueueSnackbar("Ceres Service Updated", { variant: "success" });
        } else enqueueSnackbar("Ceres Service Added", { variant: "success" });
        actions.setSubmitting(false);
        loadFeeAgreement();
      },
      (error) => {
        enqueueSnackbar("Something went wrong", { variant: "error" });
        actions.setSubmitting(false);
      }
    );
  };
  const handleDeleteDialog = (deleteItem) => {
    setOpenDelete(true);
    setDeleteRow(deleteItem);
  };
  const onDelete = async () => {
    setDeleteProgress(true);
    await Create(
      {
        id: deleteRow.id,
      },
      Post_DeleteFeeAgreementCeresService_URL,
      history,
      (resp) => {
        enqueueSnackbar("Ceres Service Deleted", { variant: "success" });
        loadFeeAgreement();
        setDeleteProgress(false);
        setOpenDelete(false);
      },
      (error) => {
        enqueueSnackbar("Something went wrong", { variant: "error" });
        setDeleteProgress(false);
      }
    );
  };
  const feeAgreementStatus = [
    // no agreement is saved as terminated in backend
    { id: 7, title: "No Agreement" },
    { id: 0, title: "Open" },
    { id: 2, title: "Drafting" },
    { id: 4, title: "Internal Review" },
    { id: 3, title: "Client Review" },
    { id: 5, title: "Internal Signing" },
    { id: 6, title: "External Signing" },
    { id: 1, title: "Closed" },
    // { id: 7, title: "Terminated" },
    
  ];
  const getSelectedFeeAgreementStatus = (id) => {
    return feeAgreementStatus.find((x) => x.id === id);
  };
  const handleEditRegularService = (row) => {
    if (feeAgreement.feeAgreementStatus == 1) {
      enqueueSnackbar("Closed Fee agreement cannot be updated", {
        variant: "warning",
      });

      return;
    }

    if (feeAgreement.feeAgreementStatus == 7) {
      enqueueSnackbar("Terminated Fee agreement cannot be updated", {
        variant: "warning",
      });
      return;
    }
    setEditRegularService(row);
  };
  const initialValues = {
    id: 0,
    comments: "",
    dateOfAgreement: new Date(),
    feeAgreementStatus: 7,
    uploadedContract: null,
  };
  const basicValidationSchema = Yup.object().shape({
    feeAgreementStatus: Yup.number().required(
      "Fee Agreement Status is required"
    ),
  });
  const serviceInvoicePeriod = [
    { title: "Monthly", id: 0 },
    { title: "Trimester", id: 1 },
    { title: "Yearly", id: 2 },
  ];
  const initialServiceValues = {
    quantity: 0,
    amount: 0,
    feeType: 1,
    ceresServiceId: 0,
    ceresService: {},
    comment: "",
    isFlatQuantity: true,
    //isFlexibleAmount: false,
    FeeAgreementId: feeAgreement?.id,
    serviceInvoicePeriod: 0,
  };
  const regularService = editRegularService
    ? editRegularService
    : initialServiceValues;
  const serviceValidationSchema = Yup.object().shape({
    quantity: Yup.number().when("isFlatQuantity",{is:false, then:Yup.number().min(1).required("Quantity is required")}),
    amount: Yup.number().min(1).required("Amount is required"),
    ceresServiceId: Yup.number().test('ceresServiceId', 'Ceres Service is required', value => value !== 0),
  });
  const defaultValue = () => {
    if (loadingFeeAgreement) return initialValues;
    return feeAgreement;
  };

  const regularFees = () => {
    return feeAgreement?.services?.filter((x) => x.feeType == 1);
  };

  const additionalFees = () => {
    return feeAgreement?.services?.filter((x) => x.feeType == 0);
  };

  const columns = [
    {
      id: "ceresServiceTitle",
      numeric: false,
      disablePadding: true,
      label: getTranslation("Type", "Taper", "Art"),
    },
    {
      id: "quantity",
      numeric: false,
      disablePadding: true,
      label: "#",
    },
    {
      id: "amount",
      numeric: false,
      disablePadding: true,
      label: getTranslation("Amount", "Montante", "Menge"),
    },
    {
      id: "comment",
      numeric: false,
      disablePadding: true,
      label: getTranslation("Comment", "Commenter", "Kommentar"),
    },
    {
      id: "invoicePeriodText",
      numeric: false,
      disablePadding: true,
      label: getTranslation(
        "Invoice Period",
        "Période de facturation",
        "Rechnungszeitraum"
      ),
    },
    {
      id: "actions",
      numeric: true,
      disablePadding: false,
      align: "right",
      label: getTranslation("Actions", "Actions", "Aktionen"),
    },
  ];

  const onCancelAgreement = () => {
    let model = {
      feeAgreementId: feeAgreement.id,
    };
    let targetUrl = "";
    if (assetId) {
      model["assetId"] = assetId;
      targetUrl = Post_CancelAssetFeeAgreement_URL;
    } else if (fundId) {
      model["fundId"] = fundId;
      targetUrl = Post_CancelFundFeeAgreement_URL;
    } else if (managementCompanyId) {
      model["managementCompanyId"] = managementCompanyId;
      targetUrl = Post_CancelManagementCompanyFeeAgreement_URL;
    }

    Create(
      model,
      targetUrl,
      history,
      (resp) => {
        enqueueSnackbar("Active Fee agreement is canceled.", {
          variant: "success",
        });
        loadFeeAgreement();
        setDeleteProgress(false);
        setOpenCancelDialog(false);
      },
      (error) => {
        enqueueSnackbar("Something went wrong", { variant: "error" });
        setDeleteProgress(false);
      }
    );
  };
  return (
    <>
      {assetId ? (
        <AssetSubmenu assetId={assetId} companyId={companyId}/>
      ) : fundId ? (
        <FundSubmenu fundId={fundId} companyId={companyId}/>
      ) : managementCompanyId ? (
        <ManagementCompaniesSubmenu managementCompanyId={managementCompanyId} companyId={companyId} />
      ) : (
        <CompanySubmenu  companyId={"1111"}/>
      )}
      <Card title="Add Fee Agreement">
        {/* <CardHeader title='Details' /> */}
        <Formik
          enableReinitialize
          initialValues={defaultValue()}
          validationSchema={basicValidationSchema}
          onSubmit={(values, actions) => {
            actions.setSubmitting(true);
            handleSubmitFeeAgreement(values, actions);
            actions.resetForm();
          }}
        >
          {({
            errors,
            touched,
            values,
            handleSubmit,
            isSubmitting,
            getFieldProps,
            setFieldValue,
          }) => (
            <Form>
              <CardContent>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={12} md={4} lg={4}>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                      <KeyboardDatePicker
                        fullWidth
                        disableToolbar
                        variant="inline"
                        format="dd/MM/yyyy"
                        size="small"
                        id="dateofAgreement-Date-inline"
                        label={getTranslation(
                          "Date of agreement",
                          "Date de l'accord",
                          "Datum der Vereinbarung"
                        )}
                        value={values?.dateOfAgreement}
                        onChange={(value) => {
                          setFieldValue("dateOfAgreement", value);
                        }}
                        KeyboardButtonProps={{
                          "aria-label": "Date of agreement",
                        }}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        required
                        error={
                          touched.dateOfAgreement &&
                          Boolean(errors.dateOfAgreement)
                        }
                      />
                    </MuiPickersUtilsProvider>
                  </Grid>

                  <Grid item xs={12} sm={12} md={4} lg={4}>
                    <Autocomplete
                      disabled={feeAgreement.feeAgreementStatus === 1}
                      name="feeAgreementStatus"
                      value={getSelectedFeeAgreementStatus(
                        values?.feeAgreementStatus
                      )}
                      options={feeAgreementStatus}
                      getOptionLabel={(option) => option.title}
                      getOptionSelected={(option, value) => {
                        return option.id === value?.id;
                      }}
                      size="small"
                      onChange={(e, value) => {
                        setFieldValue("feeAgreementStatus", value.id);
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label={getTranslation("Status", "Statut", "Status")}
                          variant="outlined"
                          error={
                            touched.feeAgreementStatus &&
                            Boolean(errors.feeAgreementStatus)
                          }
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={4} lg={4}>
                    <TextField
                      fullWidth
                      label={getTranslation(
                        "Comment",
                        "Commenter",
                        "Kommentar"
                      )}
                      size="small"
                      variant="outlined"
                      name="comments"
                      {...getFieldProps("comments")}
                      error={touched.comments && Boolean(errors.comments)}
                      helperText={touched.comments && errors.comments}
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={12} lg={12}>
                    <Typography variant="h5">Contract</Typography>
                    <br />

                    <UploadFiles
                      multiple={false}
                      uploadedFiles={
                        values.uploadedContract ? [values.uploadedContract] : []
                      }
                      onAddFile={(file) => {
                        setFieldValue("uploadedContract", file);
                      }}
                      onDeleteFile={(file) => {
                        setFieldValue("uploadedContract", null);
                      }}
                      getFileName={(file) => file}
                    ></UploadFiles>
                  </Grid>
                </Grid>
              </CardContent>
              <CardActions>
                <Box
                  pr={1}
                  pb={1}
                  width="100%"
                  display="flex"
                  alignItems="center"
                  justifyContent="flex-end"
                >
                  {feeAgreement.feeAgreementStatus === 1 ? (
                    <ButtonWithLoading
                      title={getTranslation(
                        "Close Current Agreement",
                        "Fermer l'accord actuel",
                        "Aktuelle Vereinbarung schließen"
                      )}
                      size="small"
                      loading={isSubmitting}
                      onClick={() => setOpenCancelDialog(true)}
                      variant="contained"
                      color="red"
                      style={{
                        background: "darkred",
                        color: "white",
                        marginRight: "20px",
                      }}
                    />
                  ) : (
                    <ButtonWithLoading
                      title={getTranslation(
                        "Update",
                        "Mettre à jour",
                        "Aktualisieren"
                      )}
                      size="small"
                      variant="contained"
                      color="primary"
                      loading={isSubmitting}
                      onClick={handleSubmit}
                    />
                  )}
                </Box>
              </CardActions>
            </Form>
          )}
        </Formik>
      </Card>
      <br></br>
      {feeAgreement.feeAgreementStatus !== 1 ? (
        <Card
          title={getTranslation(
            "Add Regular Service",
            "Ajouter un service régulier",
            "Regelmäßigen Dienst hinzufügen"
          )}
        >
          {/* <CardHeader title='Details' /> */}
          <Formik
            enableReinitialize
            initialValues={regularService}
            validationSchema={serviceValidationSchema}
            onSubmit={(values, actions) => {
              actions.setSubmitting(true);
              values.feeType = 1;
              handleAddService(values, actions);
              actions.resetForm();
            }}
          >
            {({
              errors,
              touched,
              values,
              handleSubmit,
              isSubmitting,
              getFieldProps,
              setFieldValue,
              handleChange,
            }) => (
              <form>
                <CardContent>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={12} md={6} lg={6}>
                      <Autocomplete
                        name="ceresService"
                        value={values?.ceresService}
                        options={ceresServices}
                        getOptionLabel={(option) => option.title}
                        size="small"
                        onChange={(e, value) => {
                          console.log("kojshdfjiohsdofsdf", value);
                          setFieldValue("ceresServiceId", value?.id);
                          setFieldValue("ceresService", value);
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label={getTranslation(
                              "Ceres Service",
                              "Service Cérès",
                              "Ceres-Dienst"
                            )}
                            variant="outlined"
                            error={
                              touched.ceresService &&
                              Boolean(errors.ceresService)
                            }
                          />
                        )}
                      />
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={6}>
                      <Autocomplete
                        name="serviceInvoicePeriod"
                        value={
                          serviceInvoicePeriod[values?.serviceInvoicePeriod]
                        }
                        options={serviceInvoicePeriod}
                        getOptionLabel={(option) => option.title}
                        size="small"
                        onChange={(e, value) => {
                          setFieldValue("serviceInvoicePeriod", value.id);
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label={getTranslation(
                              "Invoice Period",
                              "Période de facturation",
                              "Rechnungszeitraum"
                            )}
                            variant="outlined"
                            error={
                              touched.serviceInvoicePeriod &&
                              Boolean(errors.serviceInvoicePeriod)
                            }
                          />
                        )}
                      />
                    </Grid>

                    <Grid item xs={12} sm={12} md={6} lg={6}>
                      <Box>
                        <TextField
                          fullWidth
                          disabled={values.isFlatQuantity}
                          value={
                            values.isFlatQuantity
                              ? (values.quantity = 0)
                              : values.quantity
                          }
                          label={getTranslation(
                            "Quantity",
                            "Quantité",
                            "Menge"
                          )}
                          required
                          size="small"
                          variant="outlined"
                          name="quantity"
                          {...getFieldProps("quantity")}
                          error={touched.quantity && Boolean(errors.quantity)}
                          helperText={touched.quantity && errors.quantity}
                          InputLabelProps={{
                            shrink: true,
                          }}
                        />
                        <br />
                        <br />
                        <Typography>
                          Flat Quantity? &nbsp;&nbsp;
                          <Switch
                            style={{ marginLeft: "5px" }}
                            id="isFlatQuantity"
                            checked={values.isFlatQuantity}
                            onChange={handleChange}
                          />
                        </Typography>
                      </Box>
                    </Grid>
                    <Grid item spacing={2} xs={12} sm={12} md={6} lg={6}>
                      <TextField
                        fullWidth
                        label={getTranslation("Amount", "Montante", "Menge")}
                        required
                        size="small"
                        variant="outlined"
                        name="amount"
                        {...getFieldProps("amount")}
                        error={touched.amount && Boolean(errors.amount)}
                        helperText={touched.amount && errors.amount}
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    </Grid>

                    <Grid item xs={12} sm={12} md={12} lg={12}>
                      <TextField
                        fullWidth
                        label={getTranslation(
                          "Comment",
                          "Commenter",
                          "Kommentar"
                        )}
                        required
                        size="small"
                        variant="outlined"
                        name="comment"
                        {...getFieldProps("comment")}
                        error={touched.comment && Boolean(errors.comment)}
                        helperText={touched.comment && errors.comment}
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    </Grid>
                  </Grid>
                </CardContent>
                <CardActions>
                  <Box
                    pr={1}
                    pb={1}
                    width="100%"
                    display="flex"
                    alignItems="center"
                    justifyContent="flex-end"
                  >
                    {editRegularService ? (
                      <IconButton onClick={()=>setEditRegularService(null)}>
                        <Cancel />
                      </IconButton>
                    ) : null}
                    <ButtonWithLoading
                      title={
                        editRegularService
                          ? getTranslation(
                              "Update",
                              "Mettre à jour",
                              "Aktualisieren"
                            )
                          : getTranslation("Add", "Ajouter", "Addieren")
                      }
                      size="small"
                      variant="contained"
                      color="primary"
                      loading={isSubmitting}
                      onClick={handleSubmit}
                    />
                  </Box>
                </CardActions>
              </form>
            )}
          </Formik>
        </Card>
      ) : null}
      <Table
        dense
        auto
        title={getTranslation(
          "Regular Services",
          "Services réguliers",
          "Regelmäßige Dienste"
        )}
        colums={columns}
        loading={loadingFeeAgreement}
        // rows={activities?.data}
        // count={activities?.totalCount}
        sortBy="company"
        rows={regularFees()}
        count={regularFees()?.length}
        page={regularPage}
        rowsPerPage={regularRowsPerPage}
        handleChangeRowsPerPage={(event) => {
          setRegularRowsPerPage(parseInt(event.target.value));
          setRegularPage(0);
        }}
        viewEdit
        onEdit={(row) => handleEditRegularService(row)}
        handleChangePage={(event, newPage) => setRegularPage(newPage)}
        viewDelete={feeAgreement.feeAgreementStatus !== 1}
        onDelete={(row) => handleDeleteDialog(row)}
        viewSearch
      />
      <Card>
        <Box
          py={5}
          display="flex"
          justifyContent="center"
          alignItems="center"
          width="100%"
        >
          {/* <Typography variant="h4" style={{ marginRight: "20px" }}>
            Total Quantity:{" "}
          </Typography>
          <Typography variant="h4" style={{ marginRight: "20px" }}>
            {_.sumBy(regularFees(), "quantity")}{" "}
          </Typography> */}

          <Typography variant="h4" style={{ marginRight: "20px" }}>
            Total Amount:{" "}
          </Typography>
          <Typography variant="h4">
            {_.sumBy(regularFees(), "amount")}{" "}
          </Typography>
        </Box>
      </Card>
      <br></br>
      {feeAgreement.feeAgreementStatus !== 1 ? (
        <Card
          title={getTranslation(
            "Add Additional Service",
            "Ajouter un service supplémentaire",
            "Zusatzdienst hinzufügen"
          )}
        >
          {/* <CardHeader title='Details' /> */}
          <Formik
            enableReinitialize
            initialValues={regularService}
            validationSchema={serviceValidationSchema}
            onSubmit={(values, actions) => {
              actions.setSubmitting(true);
              values.feeType = 0;
              handleAddService(values, actions);
              actions.resetForm();
            }}
          >
            {({
              errors,
              touched,
              values,
              handleSubmit,
              isSubmitting,
              getFieldProps,
              setFieldValue,
              setTouched
            }) => (
              <Form>
                <CardContent>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={12} md={4} lg={4}>
                      <Autocomplete
                        name="ceresService"
                        value={values?.ceresService}
                        options={ceresServices}
                        getOptionLabel={(option) => option.title}
                        size="small"
                        onChange={(e, value) => {
                        //   setFieldValue("ceresServiceId", value?.id);
                        //   setFieldValue("ceresService", value);
                        // }}
                        setFieldValue("ceresServiceId", value ? value.id : ""); 
                        setFieldValue("ceresService", value ? value : ""); 
                        setTouched("ceresServiceId", true); 
                        setTouched("ceresService", true); 
                      }}
                      onBlur={() => {
                        setTouched("ceresServiceId", true);
                        setTouched("ceresService", true);
                      }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label={getTranslation(
                              "Ceres Service",
                              "Service Cérès",
                              "Ceres-Dienst"
                            )}
                            variant="outlined"
                            error={(touched.ceresServiceId || touched.ceresService) && Boolean(errors.ceresServiceId || errors.ceresService)}
                            helperText={(touched.ceresServiceId || touched.ceresService) && (errors.ceresServiceId || errors.ceresService)}
                          />
                        )}
                      />
                    </Grid>
                    <Grid item xs={12} sm={12} md={4} lg={4}>
                      <TextField
                        fullWidth
                        label={getTranslation("Quantity", "Quantité", "Menge")}
                        required
                        size="small"
                        variant="outlined"
                        name="quantity"
                        {...getFieldProps("quantity")}
                        error={touched.quantity && Boolean(errors.quantity)}
                        helperText={touched.quantity && errors.quantity}
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} sm={12} md={4} lg={4}>
                      <TextField
                        fullWidth
                        label={getTranslation("Amount", "Montante", "Menge")}
                        required
                        size="small"
                        variant="outlined"
                        name="amount"
                        {...getFieldProps("amount")}
                        error={touched.amount && Boolean(errors.amount)}
                        helperText={touched.amount && errors.amount}
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} sm={12} md={4} lg={4}>
                      <TextField
                        fullWidth
                        label={getTranslation(
                          "Comment",
                          "Commenter",
                          "Kommentar"
                        )}
                        size="small"
                        variant="outlined"
                        name="comment"
                        {...getFieldProps("comment")}
                        error={touched.comment && Boolean(errors.comment)}
                        helperText={touched.comment && errors.comment}
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    </Grid>
                  </Grid>
                </CardContent>
                <CardActions>
                  <Box
                    pr={1}
                    pb={1}
                    width="100%"
                    display="flex"
                    alignItems="center"
                    justifyContent="flex-end"
                  >
                    <ButtonWithLoading
                      title={getTranslation("Add", "Ajouter", "Addieren")}
                      size="small"
                      variant="contained"
                      color="primary"
                      loading={isSubmitting}
                      onClick={() => {
                        if (Object.keys(errors).length > 0) {
                          enqueueSnackbar("Some fields are missing.", {
                            variant: "error",
                          });
                        } else handleSubmit();
                      }}
                    />
                  </Box>
                </CardActions>
              </Form>
            )}
          </Formik>
        </Card>
      ) : null}
      <Table
        dense
        auto
        title={getTranslation(
          "Additional Services",
          "Des services supplémentaires",
          "Zusatzleitungen"
        )}
        colums={columns}
        loading={loadingFeeAgreement}
        rowsPerPage={additionalRowsPerPage}
        page={additionalPage}
        handleChangeRowsPerPage={(event) => {
          setAdditionalRowsPerPage(parseInt(event.target.value));
          setAdditionalPage(0);
        }}
        handleChangePage={(event, newPage) => setAdditionalPage(newPage)}
        sortBy="company"
        rows={additionalFees()}
        count={additionalFees()?.length}
        viewDelete={feeAgreement.feeAgreementStatus !== 1}
        onDelete={(row) => handleDeleteDialog(row)}
        viewSearch
      />
      <FeeAgreementHistory
        managementCompanyId={managementCompanyId}
        assetId={assetId}
        fundId={fundId}
      />
      <DeleteDialog
        open={openDelete}
        deleting={deleteProgress}
        title={getTranslation(
          "Delete Company",
          "Supprimer l'entreprise",
          "Unternehmen löschen"
        )}
        onClose={() => setOpenDelete(false)}
        onSubmit={(e) => {
          onDelete();
        }}
      />

      <DeleteDialog
        open={openCancelDialog}
        deleting={deleteProgress}
        title={getTranslation(
          "Cancel Active Fee Agreement",
          "Annuler l'accord de frais actif",
          "Aktive Gebührenvereinbarung kündigen"
        )}
        text="All auto services against current fee agreement will be stopped. Are you sure to close current fee agreement?"
        confirmButtonText="Close Current Agreement"
        onClose={() => setOpenCancelDialog(false)}
        onSubmit={(e) => {
          onCancelAgreement();
        }}
      />
    </>
  );
};

export default FeeAgreement;
