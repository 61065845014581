import { Box, CardContent, Checkbox, FormControl, FormControlLabel, Grid, TextField } from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import { Form, Formik } from "formik";
import { useState } from "react";
import { useEffect } from "react";
import { Get } from "../../../../../../actions";
import ButtonWithLoading from "../../../../../../components/ButtonWithLoading";
import Card from "../../../../../../components/Card";
import { Get_AgendaTemplateList_URL, Get_AllAgendaTemplateItems_URL } from "../../../../../../constants/apiUrls";
import { getTranslation } from "../../../../../../heplers/translationHelper";
import { AskAgenda } from "../../../../../public";
import { SelectionCcList } from "../reminders/selectionCcList";

const AskReminders = ({ NextStep, BackStep,initialValues }) => {
    const [templates, setTemplates] = useState([]);
    const [templatesLoading, setTemplatesLoading] = useState(false);

    useEffect(() => {
        loadTemplates()
    }, [])

    const loadTemplates = () => {
        setTemplatesLoading(true);
        Get(
            {},
            Get_AgendaTemplateList_URL,
            null,
            (resp) => {
                setTemplates(resp.data);
                setTemplatesLoading(false);
            },
            (error) => { }
        );
    };
    const loadTemplateItems = (templateId, callback) => {
        // setTemplatesLoading(true);
        Get(
            {
                agendaTemplateId: templateId,
            },
            Get_AllAgendaTemplateItems_URL,
            null,
            (resp) => {
                callback(resp.data);
            },
            (error) => { }
        );
    };

    // const initialValues = {
    //     meetingMembers: [],
    //     meetingReminders: [],
    //     reminders: [],
    //     isReminderRequired: false,
    //     agendas: [],

    // }
    // const basicValidationSchema = Yup.object().shape({
    //     subject: Yup.string().required("Field is required"),
    //     address: Yup.string().required("Field is required"),
    //     meetingTime: Yup.string().required("Field Time is requied"),
    //     meetingDate: Yup.string().required("Field is required"),
    //     language: Yup.string().required("Field is required"),
    //     periodicity: Yup.number()
    //         .min(10, "Minimum 10 days allowed.")
    //         .required("Field is required"),
    //     personInchargeId: Yup.string().required("Field is required"),
    //     companyCeresServiceId: Yup.number().min(1).required("Field is required"),
    // });
    return (
        <Formik
            initialValues={initialValues}
            onSubmit={(values, actions) => {
                NextStep(values);
            }}
        >
            {({
                errors,
                touched,
                values,
                handleSubmit,
                isSubmitting,
                getFieldProps,
                setFieldValue,
                handleChange,
            }) => (
                <Form>
                    <Grid container spacing={2} style={{ marginBottom: "10px" }}>
                        {/* Step 4 */}
                        <Grid item xs={12} sm={12} md={12} lg={12}>
                            <Card title={getTranslation("Reminders", "Rappels", "Erinnerungen")}>
                                <CardContent>
                                    <FormControl>
                                        <FormControlLabel
                                            label={getTranslation("Send Reminder", "Envoyer un rappel", "Eine Erinnerung senden")}
                                            labelPlacement="start"
                                            control={
                                                <Checkbox
                                                    id="isReminderEnabled"
                                                    checked={values.isReminderEnabled}
                                                    onChange={handleChange}
                                                />
                                            }
                                        />
                                    </FormControl>
                                    {values.isReminderEnabled ? (
                                        <>
                                            <br />
                                            <br />
                                            <TextField
                                                style={{ width: "400px" }}
                                                id="reminderInterval"
                                                label={getTranslation("Interval (days)", "Intervalle (jours)", "Intervall (Tage)")}
                                                required
                                                size="small"
                                                type="number"
                                                variant="outlined"
                                                {...getFieldProps("reminderInterval")}
                                                error={
                                                    touched.reminderInterval &&
                                                    Boolean(errors.reminderInterval)
                                                }
                                                helperText={
                                                    touched.reminderInterval &&
                                                    errors.reminderInterval
                                                }
                                            // InputLabelProps={{}}
                                            />
                                            <br />
                                            <br />
                                            <TextField
                                                fullWidth
                                                id="reminderText"
                                                label={getTranslation("Text", "Texte", "Text")}
                                                required
                                                size="small"
                                                variant="outlined"
                                                {...getFieldProps("reminderText")}
                                                error={
                                                    touched.reminderText &&
                                                    Boolean(errors.reminderText)
                                                }
                                                helperText={
                                                    touched.reminderText && errors.reminderText
                                                }
                                                InputLabelProps={{}}
                                            />
                                            <br />
                                            <br />

                                            <SelectionCcList
                                                selectedMembers={values?.meetingMembers?.filter(
                                                    (x) => x.sendReminderCC
                                                )}
                                                members={values.meetingMembers}
                                                onAddCC={(member) => {
                                                    const index = values.meetingMembers.findIndex(
                                                        (x) => x == member
                                                    );
                                                    const arr = values.meetingMembers;
                                                    member.sendReminderCC = true;
                                                    arr[index] = member;
                                                    setFieldValue("meetingMembers", arr);
                                                }}
                                                onRemoveCC={(member) => {
                                                    const index = values.meetingMembers.findIndex(
                                                        (x) => x == member
                                                    );
                                                    const arr = values.meetingMembers;
                                                    member.sendReminderCC = false;
                                                    arr[index] = member;
                                                    setFieldValue("meetingMembers", arr);
                                                }}
                                            />
                                        </>
                                    ) : null}
                                </CardContent>
                            </Card>
                        </Grid>
                    </Grid>
                    <Box
                        display="flex"
                        justifyContent="space-between"
                        alignItems="space-between"
                        m={2}
                    >
                        <ButtonWithLoading
                            title={getTranslation("Back", "Précédent", "Zurück")}
                            color="primary"
                            variant="contained"
                            size="large"
                            onClick={() => BackStep()}
                        />
                        <ButtonWithLoading
                            title={getTranslation("Next", "Suivant", "Weiter")}
                            color="primary"
                            variant="contained"
                            size="large"
                            onClick={handleSubmit}
                        />
                    </Box>
                </Form>
            )}
        </Formik>
    )
}
export default AskReminders;