import {
  Card,
  CardContent,
  CardHeader,
  Grid,
  Typography,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import {
  Get_CircularResolutionUploadedFiles_URL,
  Get_DecisionMakers_URL,
  Get_NotifyeeList_URL,
  Get_SingleCircularResolution_URL,
} from "../../../constants/apiUrls";
import { Get } from "../../../actions";
import { SelectMember } from "../../meeting/component/circularResolution/components/SelectMembers";
import { useSnackbar } from "notistack";
import { parseHTML } from "../../../heplers/format";
import { UploadFiles } from "../../../components/Upload";
import { getTranslation } from "../../../heplers/translationHelper";

export const CircularResolutionInfoBox = ({
  circularResolutionId,
  circularResolution,
}) => {
  const [decisionMakers, setDecisionMakers] = useState([]);
  const [filesList, setfilesList] = useState([]);
  const [notifyeeList, setNotifyeeList] = useState();
  const { enqueueSnackbar } = useSnackbar();

  ///////////////////////////////// load Decision Makers /////////////////////////
  useEffect(() => {
    loadDecisionMakers({ CrId: circularResolutionId });
  }, [circularResolutionId]);
  const loadDecisionMakers = (values) => {
    Get(
      values,
      Get_DecisionMakers_URL,
      null,
      (resp) => {
        setDecisionMakers(resp?.data);
      },
      (error) => {
        enqueueSnackbar(error?.data, { variant: "warning" });
      }
    );
  };

  ///////////////////////////////// load Files /////////////////////////
  useEffect(() => {
    loadFilesList({ CrId: circularResolutionId });
  }, [circularResolutionId]);
  const loadFilesList = (values) => {
    Get(
      values,
      Get_CircularResolutionUploadedFiles_URL,
      null,
      (resp) => {
        setfilesList(resp?.data);
      },
      (error) => {
        enqueueSnackbar(error?.data, { variant: "warning" });
      }
    );
  };
  ///////////////////////////////// Get NotifyeesList Files /////////////////////////
  useEffect(() => {
    loadNotifyeeList({ CrId: circularResolutionId });
  }, [circularResolutionId]);
  const loadNotifyeeList = (values) => {
    Get(
      values,
      Get_NotifyeeList_URL,
      null,
      (resp) => {
        setNotifyeeList(resp?.data);
      },
      (error) => {
        enqueueSnackbar(error?.data, { variant: "warning" });
      }
    );
  };

  return (
    <div>
      <Grid align="center">
        <Typography
          style={{
            fontFamily: "Revalia",
            fontStyle: "normal",
            fontSize: "48px",
            color: "#233044",
          }}
        >
          Ceres
        </Typography>
      </Grid>
      <Card>
        <CardHeader
          title={getTranslation(
            "Circular Resolution",
            "Résolution circulaire",
            "Zirkularer Beschluss"
          )}
        />
        <CardContent style={{ marginLeft: "100px" }}>
          <Grid container>
            <Grid item xs={4} sm={4} md={2} lg={2}>
              <Typography>Subject:</Typography>
            </Grid>
            <Grid item xs={8} sm={8} md={10} lg={10}>
              <Typography>{circularResolution.subject}</Typography>
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs={4} sm={4} md={2} lg={2}>
              <Typography>Resolution:</Typography>
            </Grid>
            <Grid item xs={8} sm={8} md={10} lg={10}>
              <Typography>
                {parseHTML(circularResolution.resolution)}
              </Typography>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
      <br />
      <Card>
        <CardHeader
          title={getTranslation(
            "Uploaded Files",
            "Fichiers téléchargés",
            "Hochgeladene Dateien"
          )}
        />
        <CardContent>
          {filesList ? (
            <UploadFiles
              disabled
              uploadedFiles={filesList}
              getFileName={(file) => {
                return file.fileName;
              }}
            />
          ) : (
            "No files found for this Circular Resolution"
          )}
        </CardContent>
      </Card>
      <br />
      <Card>
        <CardHeader
          title={getTranslation(
            "Desicion Makers",
            "Décideurs",
            "Entscheidungsträger"
          )}
        />
        <CardContent>
          <SelectMember
            title={getTranslation(
              "Decision Makers",
              "Décideurs",
              "Entscheidungsträger"
            )}
            options={decisionMakers}
            isApproved
          />
        </CardContent>
      </Card>
      <br />
      <Card>
        <CardContent>
          <SelectMember
            title={getTranslation("Notifyees", "Notifiés", "Benachrichtigte")}
            options={notifyeeList}
            isApproved
          />
        </CardContent>
      </Card>
      <br />
      <br />
    </div>
  );
};
