import React, { useState, useEffect } from "react";
import Table from "../../../../../../components/table";

import { useSnackbar } from "notistack";
import { Get, Create } from "../../../../../../actions";
import DeleteDialog from "../../../../../../components/DeleteDialog";
import { AddDocument } from "./addDocument";
import { getTranslation } from "../../../../../../heplers/translationHelper";

const columns = [
  {
    id: "name",
    numeric: false,
    disablePadding: true,
    label: getTranslation("Name", "Nom", "Name"),
  },
  {
    id: "interval",
    numeric: false,
    disablePadding: true,
    label: getTranslation("Interval", "Intervalle", "Intervall"),
  },
  {
    id: "comments",
    numeric: false,
    disablePadding: true,
    label: getTranslation("Comments", "Commentaires", "Comments"),
  },
  {
    id: "email",
    numeric: false,
    disablePadding: true,
    label: getTranslation("Email", "E-mail", "Email"),
  },
  {
    id: "actions",
    numeric: true,
    disablePadding: false,
    align: "right",
    label: getTranslation("Actions", "Actions", "Aktionen"),
  },
];

export const DocumentList = ({
  contacts,
  documents,
  onAddNewDocument,
  onDeleteDocument,
}) => {
  console.log("ksjghfisdfggf", documents);
  const [openDelete, setOpenDelete] = useState(false);
  const [deleteProgress, setDeleteProgress] = useState(false);
  const [deleteRow, setDeleteRow] = useState([]);
  const { enqueueSnackbar } = useSnackbar();
  const [searchString, setSearchString] = useState("");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [rows, setRows] = useState([]);
  /////////////////// Handlers ////////////
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value));
    setPage(0);
  };
  const handleSearchAgendaTemplate = (searchString) => {
    setSearchString(searchString);
  };

  const handleOpenDeleteDialog = (deleteItem) => {
    setOpenDelete(true);
    setDeleteRow(deleteItem);
  };
  const handleDeleteDocument = (deleteItem) => {
    // setOpenDelete(true);
    // setDeleteRow(deleteItem);
  };
  const handleEditAgendaTemplate = (row) => {};
  ////////////////////// delete /////////////////////////
  // const onDelete = async (id) => {
  //   setDeleteProgress(true);
  //   await Create(
  //     {
  //       ids: [deleteRow.id],
  //     },
  //     null,
  //     //Post_DeleteAgendaTemplateItem_URL,
  //     null,
  //     (resp) => {
  //       //loadAgendaTemplateListItems(agendaTemplateId)
  //       setDeleteProgress(false);
  //       setOpenDelete(false);
  //       enqueueSnackbar("Template Deleted", { variant: "success" });
  //     },
  //     (error) => {
  //       enqueueSnackbar("Template not deleted", { variant: "error" });
  //       setDeleteProgress(false);
  //     }
  //   );
  // };

  //////////////////////

  const handleSubmitAgendaTemplate = async (values, actions) => {
    await Create(
      values,
      null,
      //Post_InsertAgendaTemplateItem_URL,
      null,
      (resp) => {
        enqueueSnackbar("Template Added", { variant: "success" });
        //loadAgendaTemplateListItems(values.agendaTemplateId);
        actions.resetForm();
      },
      (error) => {
        enqueueSnackbar("Template not Added", { variant: "error" });
      }
    );
  };

  return (
    <div>
      <AddDocument contacts={contacts} onAddNewDocument={onAddNewDocument} />
      <br />
      <Table
        auto
        //loading={agendaTemplateListLoading}
        colums={columns}
        sortBy="meetingDate"
        rows={documents}
        count={documents.length}
        page={page}
        rowsPerPage={rowsPerPage}
        handleChangePage={(event, newPage) => handleChangePage(event, newPage)}
        handleChangeRowsPerPage={(e) => handleChangeRowsPerPage(e)}
        viewDelete
        onDelete={(row) => onDeleteDocument(row)}
        viewSearch
        onSearch={(searchString) => handleSearchAgendaTemplate(searchString)}
      />
    </div>
  );
};
