import {
  Box,
  Typography,
  CardActions,
  Card,
  CardContent,
  Grid,
} from "@material-ui/core";

import React, { useEffect, useState } from "react";
import ReactWaterMark from "react-watermark-component";
import { parseHTML } from "../../../heplers/format";
import { GenerateQRCode } from "../../../heplers/generateQR";
import { circularResolutionType } from "../../scanner/scannerTypes";
export const PrintSignedCircularResolution = React.forwardRef(
  ({ personalInfo, circularResolution }, ref) => {
    const [QrImage, setQRImage] = useState();

    useEffect(() => {
      if (personalInfo) {
        GenerateQRCode({
          type: circularResolutionType,
          value: {
            memberId: personalInfo.id,
          },
        })
          .then((resp) => {
            setQRImage(resp);
          })
          .catch((error) => {});
      }
    }, [personalInfo]);

    return (
      <div
        ref={ref}
        height="313mm"
        style={{
          background: "#FFFF",
          backgroundPosition: "center",
          height: "100%",
          padding: "30px",
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
        }}
      >
        <Box width="100%" display="flex" justifyContent="center">
          <div>
            <Typography
              style={{
                fontFamily: "Revalia",
                fontSize: "36px",
                fontStyle: "normal",
                textAlign: "center",
                top: "24px",
              }}
            >
              Ceres
            </Typography>
            <Typography
              style={{
                fontFamily: "Sacramento",
                fontSize: "24px",
                fontStyle: "normal",
                textAlign: "center",
              }}
            >
              Circular Resolution
            </Typography>
          </div>
        </Box>
        <Card
          style={{
            height: "auto",
            background: "#233044",
            color: "#FFFFFF",
            borderRadius: "8px",
          }}
        >
          <CardContent>
            <Grid container>
              {/*Grid 1 */}
              <Grid item sm={2}>
                <Typography
                  style={{
                    fontFamily: "Ruluko",
                    fontSize: "14px",
                    fontStyle: "normal",
                    textAlign: "left",
                  }}
                >
                  Personal Info
                </Typography>
              </Grid>
              {/*Grid 2 */}
              <Grid item xs={12} sm={12} md={12}>
                <Typography
                  style={{
                    fontFamily: "Roboto",
                    fontSize: "10px",
                    fontStyle: "normal",
                    textAlign: "left",
                    marginLeft: "20px",
                  }}
                >
                  {personalInfo?.fullName}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={12} md={12}>
                <Typography
                  style={{
                    fontFamily: "Roboto",
                    fontSize: "10px",
                    fontStyle: "normal",
                    textAlign: "left",
                    marginLeft: "20px",
                  }}
                >
                  {personalInfo?.mail}
                </Typography>
              </Grid>
              {/*Grid 5 */}
              <Grid item xs={12} sm={12} md={12}>
                <Typography
                  style={{
                    fontFamily: "Roboto",
                    fontSize: "10px",
                    fontStyle: "normal",
                    textAlign: "left",
                    marginLeft: "20px",
                  }}
                >
                  {personalInfo?.city}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={12} md={12}>
                <Typography
                  style={{
                    fontFamily: "Roboto",
                    fontSize: "10px",
                    fontStyle: "normal",
                    textAlign: "left",
                    marginLeft: "20px",
                  }}
                >
                  {personalInfo?.phone1}
                </Typography>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
        <Grid
          style={{
            height: "auto",
            right: "32px",
          }}
        >
          <Typography
            component="h2"
            style={{
              fontWeight: "bold",
            }}
          >
            Subject:
          </Typography>
          <Typography component="p">{circularResolution?.subject}</Typography>
          <Typography
            style={{
              fontWeight: "bold",
            }}
            component="h2"
          >
            Resolution
          </Typography>
          {parseHTML(circularResolution?.resolution)}
          <Typography component="p"></Typography>
        </Grid>
        <Grid
          container
          spacing={10}
          style={{
            margin: "25px",
            borderRadius: "8px",
          }}
        >
          <Grid item xs={4} sm={4} md={4}>
            <Typography>
              _________________
              <h4>&emsp;Sign.</h4>
              <Typography style={{ fontSize: "9px" }}>
                Please Sign the document and upload.
              </Typography>
            </Typography>
          </Grid>
          <Grid item xs={4} sm={4} md={4}>
            <br />

            <Typography style={{ fontSize: "10px" }}>
              This QR code is system generated and is only for Ceres employes
            </Typography>
          </Grid>
          <Grid item xs={4} sm={4} md={4} style={{ marginLeft: "auto" }}>
            <div>
              {" "}
              {QrImage ? (
                <img
                  src={QrImage}
                  alt="Image"
                  style={{ width: "100px", height: "100px" }}
                />
              ) : null}
            </div>
          </Grid>
        </Grid>
      </div>
    );
  }
);
