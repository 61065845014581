import React, { useState, useEffect } from "react";
import {
  CardContent,
  Grid,
  TextField,
  Box,
  Typography,
  CardHeader,
  Checkbox,
  FormControl,
  FormControlLabel,
} from "@material-ui/core";
import { Form, Formik } from "formik";
import Card from "../../../../components/Card";
import { makeStyles } from "@material-ui/core/styles";
import Autocomplete, {
  createFilterOptions,
} from "@material-ui/lab/Autocomplete";
import ButtonWithLoading from "../../../../components/ButtonWithLoading";
import { FormLoader } from "../../../../components/FormLoader";

import { useDispatch, useSelector } from "react-redux";
import * as Yup from "yup";
import { useNavigate } from "react-router-dom";
import CircularProgress from "@material-ui/core/CircularProgress";
import { DatePicker } from "../../../../components/DatePicker";
import {
  GetAllCompanyGroups,
  GetAllGeneralPartners,
  Create,
  Get,
} from "../../../../actions";
import {
  Create_CompanyGroup_URL,
  GET_AllCompanies_URL,
  Get_AssetDocuments_URL,
  Get_Asset_URL,
  Get_BasicLegalFormByCountry_URL,
  Post_DeleteAssetDocument_URL,
  Post_InsertAssetDocument_URL,
  Post_UpdateAsset_URL,
} from "../../../../constants/apiUrls";
import { CountrySelection } from "../../../../constants/countries";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import { getTranslation } from "../../../../heplers/translationHelper";
import { ToggleButton, ToggleButtonGroup } from "@material-ui/lab";
import { GET_AllContactList_URL } from "../../../../constants/apiUrls";
import { UploadFiles } from "../../../../components/Upload";
import { useParams } from "react-router";
import { GetAllCompanyBelongsTo } from "./getCompanyBelongsTo";
import {
  AssetSubmenu,
  CompanySubmenu,
  FundSubmenu,
  ManagementCompaniesSubmenu,
} from "../../../../layout/subMenu";
import { useSnackbar } from "notistack";
import { getDateIgnoreTimezone } from "../../../../heplers/dateHelper";
import moment from "moment";
const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    "& > * + *": {
      marginLeft: theme.spacing(2),
    },
  },
}));
const numbering = ["First", "Second", "Third", "Fourth", "Fifth"];
const days = [
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
  "Sunday",
];
const months = [
  "N/A",
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];
const Info = ({ assetId, isNewRecord, onSubmit, company }) => {
  const params = useParams();
  const { generalPartners, companyGroups, companyGrouploading, loading } =
    useSelector((state) => state.companies);
  const [legalForms, setLegalForms] = useState([]);
  const [legalFormsLoading, setLegalFormsLoading] = useState(false);
  const [searchCompaniesString, setSearchCompaniesString] = useState("");
  const [contactList, setContactList] = useState([]);
  const [agmType, setAgmType] = useState(0);
  const [companies, setCompanies] = useState([]);
  const { enqueueSnackbar } = useSnackbar();
  const history = useNavigate();
  const dispatch = useDispatch();
  const filter = createFilterOptions();
  const classes = useStyles();
  if(company?.basic?.agmType==1)
    company.basic.dateAGMNumberMonth = company?.basic?.dateAGMMonth=="" ? 0:parseInt(company?.basic?.dateAGMMonth)

  useEffect(() => {
    Get(
      {},
      GET_AllCompanies_URL,
      history,
      (response) => {
        setCompanies(response.data);
      },
      (error) => {}
    );
  }, []);
  //////////// I am modified this code ///////////////
  useEffect(() => {
    dispatch(GetAllCompanyGroups({}, history));
  }, []);

  useEffect(() => {
    Get(
      {},
      GET_AllContactList_URL,
      null,
      (resp) => {
        setContactList(resp?.data);
      },
      (error) => {}
    );
  }, []);

  useEffect(() => {
    dispatch({
      type: "GetAllGeneralPartners",
      payload: null,
    });
    dispatch(GetAllGeneralPartners({}, history));
  }, [searchCompaniesString]);

  const createNewCompanyGroup = async (value, onSuccess) => {
    await Create(
      { title: value },
      Create_CompanyGroup_URL,
      history,
      (resp) => {
        dispatch(GetAllCompanyGroups({}, history));
        onSuccess(resp);
      },
      (error) => {}
    );
  };

  useEffect(()=>{
    if(company?.basic){
    loadLegalFormByCountry(company?.basic?.country);
    }else
    {
      loadLegalFormByCountry("Luxembourg");
    }

  },[company?.basic?.country])
  const loadLegalFormByCountry = (country) => {
    setLegalFormsLoading(true);
    Get(
      { country: country },
      Get_BasicLegalFormByCountry_URL,
      null,
      (resp) => {
        setLegalFormsLoading(false);
        setLegalForms(resp?.data);
      },
      (error) => {
        setLegalFormsLoading(false);
      }
    );
  };

  const initialValues = {
    companyName: "",
    companyGroupId: 0,
    companyGroup: {},
    basic: {
      referenceCode: "",
      rcsNumber: "",
      interestRelation: "",
      incorporationDate: new Date(),
      rbe: "",
      city: "",
      zipCode: "",
      street: "",
      country: "Luxembourg",
      generalPartnerId: null,
      generalPartner: null,
      basicLegalFormId: null,
      basicLegalForm: null,
      finnancialYearEnds: "December",
      liquidateDate: "0001-01-01T00:00:00",
      dateAGMDay: "Monday",
      dateAGMDayNumber: "First",
      dateAGMMonth: "Janurary",
      dateAGMNumberMonth: 6,
      agmType: 1,
      investorRelationId: 0,
      investorRelation: {},
      agmMeetingType: 0,
      monthlyAGM: 0,
      vat: "",
      comments: "",
      // capacity: "",
      // subsidies: "",
      // files: [],
    },
    investorRelation: {},
  };
  const basicValidationSchema = Yup.object().shape({
    companyName: Yup.string().required("Company name is required"),
    companyGroupId: Yup.number().min(1,"Company group is required"),
    
    basic: Yup.object({
    country: Yup.string().required("Country is required"),
      rcsNumber: Yup.string().when("country", {
        is: (country) => country === "Luxembourg",
        then: Yup.string().required("RCS Number is required"),
      }),
      referenceCode: Yup.string().when("country", {
        is: (country) => country === "Luxembourg",
        then: Yup.string().required("Reference Code is required"),
      }),
      incorporationDate: Yup.string().when("country", {
        is: (country) => country === "Luxembourg",
        then: Yup.string().required("Incorporation Date is required"),
      }),
      city: Yup.string().when("country", {
        is: (country) => country === "Luxembourg",
        then: Yup.string().required("City Code is required"),
      }),
      zipCode: Yup.string().when("country", {
        is: (country) => country === "Luxembourg",
        then: Yup.string().required("Zip Code is required"),
      }),
      street: Yup.string().when("country", {
        is: (country) => country === "Luxembourg",
        then: Yup.string().required("Zip Code is required"),
      }),
      rbe: Yup.string().when("country", {
        is: (country) => country === "Luxembourg",
        then: Yup.string().required("RBE is required"),
      }),
      dateAGMDay: Yup.string().when("country", {
        is: (country) => country === "Luxembourg",
        then: Yup.string().required("dateAGMDay is required"),
      }),
      dateAGMDayNumber: Yup.string().when("country", {
        is: (country) => country === "Luxembourg",
        then: Yup.string().required("date AGM Day Number is required"),
      }),
      comments: Yup.string().when("country", {
        is: (country) => country === "Luxembourg",
        then: Yup.string().required("Comments are required"),
      }),
      dateAGMMonth:Yup.string().when("agmType", {
        is: (agmType) => agmType === 0,
        then: Yup.string().required("Month is required"),
      }),
      dateAGMNumberMonth:Yup.number().when("agmType", {
        is: (agmType) => agmType === 1,
        then: Yup.number().min(1,"Month is required"),
      }),
    }),
  });
  const defaultValue = isNewRecord
    ? initialValues
    : company?.basic
    ? company
    : initialValues;
  return (
    <>
      
      <FormLoader progress={loading}>
        <Formik
          enableReinitialize={company?.basic}
          initialValues={defaultValue}
          validationSchema={basicValidationSchema}
          onSubmit={(values, actions) => {
            actions.setSubmitting(true);
            onSubmit(values, actions);
          }}
        >
          {({
            errors,
            touched,
            values,
            handleSubmit,
            isSubmitting,
            getFieldProps,
            setFieldValue,
          }) => (
            <Form>
              {assetId ? (
                <AssetBasicInfo
                  assetId={assetId}
                  enqueueSnackbar={enqueueSnackbar}
                />
              ) : null}
              <Card
                title={getTranslation(
                  "Basic Information",
                  "Informations de base",
                  "Grundinformation"
                )}
              >
                <CardContent>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={12} md={4} lg={4}>
                      <TextField
                        fullWidth
                        label={getTranslation(
                          "Company Name",
                          "Nom de l'entreprise",
                          "Name der Firma"
                        )}
                        size="small"
                        required
                        variant="outlined"
                        name="companyName"
                        {...getFieldProps("companyName")}
                        error={
                          touched.companyName && Boolean(errors.companyName)
                        }
                        helperText={touched.companyName && errors.companyName}
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} sm={12} md={4} lg={4}>
                      
                      <Autocomplete
                        name="companyGroupId"
                        size="small"
                        value={values.companyGroup}
                        options={companyGroups}
                        getOptionLabel={(option) => option.title}
                        //   style={{ width: 300 }}
                        onChange={(e, value) => {
                          if (value && value.inputValue) {
                            // Create a new value from the user input
                            createNewCompanyGroup(value.inputValue, (resp) => {
                              setFieldValue("companyGroupId", resp?.id);
                              setFieldValue("companyGroup", resp);
                            });
                          } else {
                            setFieldValue("companyGroupId", value?.id);
                            setFieldValue("companyGroup", value);
                          }
                        }}
                        filterOptions={(options, params) => {
                          const filtered = filter(options, params);

                          // Suggest the creation of a new value
                          if (
                            params.inputValue != "" &&
                            companyGroups.find(
                              (x) => x.title == params.inputValue
                            ) == null
                          ) {
                            filtered.push({
                              inputValue: params.inputValue,
                              title: `Add new group "${params.inputValue}"`,
                            });
                          }

                          return filtered;
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label={getTranslation(
                              "Select Group",
                              "Selectionnez le groupe",
                              "Wehle die Gruppe"
                            )}
                            variant="outlined"
                            error={
                              touched.companyGroupId &&
                              Boolean(errors.companyGroupId)
                            }
                            InputProps={{
                              ...params.InputProps,
                              endAdornment: (
                                <React.Fragment>
                                  {companyGrouploading ? (
                                    <CircularProgress
                                      color="inherit"
                                      size={20}
                                    />
                                  ) : null}
                                  {params.InputProps.endAdornment}
                                </React.Fragment>
                              ),
                            }}
                          />
                        )}
                      />
                    </Grid>
                    <Grid item xs={12} sm={12} md={4} lg={4}>
                      <CountrySelection
                        label={getTranslation(
                          "Select Country",
                          "Choisissez le pays",
                          "Land auswhlen"
                        )}
                        name="basic.country"
                        required
                        selected={values.basic?.country}
                        onChange={(e, value) => {
                          setFieldValue("basic.country", value);
                          setFieldValue("basic.basicLegalForm", null);
                          loadLegalFormByCountry(value);
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} sm={12} md={4} lg={4}>
                      <Autocomplete
                        id="basic.investorRelation"
                        name="basic.investorRelationId"
                        size="small"
                        value={values?.basic?.investorRelation}
                        options={contactList}
                        getOptionLabel={(option) => option.fullName}
                        
                    
                        onChange={(e, investorRelation) => {
                          console.log("ajklsdhajksdhasjkld",investorRelation)
                          setFieldValue(
                            "basic.investorRelationId",
                            investorRelation?.id
                          );
                          setFieldValue(
                            "basic.investorRelation",
                            investorRelation
                          );
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label={getTranslation("Investor Relation")}
                            variant="outlined"
                         
                          />
                        )}
                      />
                    </Grid>
                    <Grid item xs={12} sm={12} md={4} lg={4}>
                      <TextField
                        fullWidth
                        label={getTranslation(
                          "Company Reference Code",
                          "Code de reference de l'entreprise",
                          "Referenzcode des Unternehmens"
                        )}
                        size="small"
                        variant="outlined"
                        name="basic.referenceCode"
                        {...getFieldProps("basic.referenceCode")}
                        error={
                          touched.basic?.referenceCode &&
                          Boolean(errors.basic?.referenceCode)
                        }
                        helperText={
                          touched.basic?.referenceCode &&
                          errors.basic?.referenceCode
                        }
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} sm={12} md={4} lg={4}>
                      <TextField
                        fullWidth
                        label={getTranslation(
                          "RCS Number",
                          "Numero RCS",
                          "RCS-Nummer"
                        )}
                        size="small"
                        variant="outlined"
                        name="basic.rcsNumber"
                        {...getFieldProps("basic.rcsNumber")}
                        error={
                          touched.basic?.rcsNumber &&
                          Boolean(errors.basic?.rcsNumber)
                        }
                        helperText={
                          touched.basic?.rcsNumber && errors.basic?.rcsNumber
                        }
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} sm={12} md={4} lg={4}>
                      <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <KeyboardDatePicker
                          fullWidth
                          disableToolbar
                          inputVariant="outlined"
                          format="dd/MM/yyyy"
                          size="small"
                          id="Incorporation-Date-inline"
                          label={getTranslation(
                            "Incorporation Date",
                            "Date de constitution",
                            "Grandungsdatum"
                          )}
                          value={values.basic?.incorporationDate}
                          onChange={(value) => {
                            setFieldValue("basic.incorporationDate", value);
                          }}
                          KeyboardButtonProps={{
                            "aria-label": "Incorporation Date",
                          }}
                          InputLabelProps={{
                            shrink: true,
                          }}
                          error={
                            touched.basic?.incorporationDate &&
                            Boolean(errors.basic?.incorporationDate)
                          }
                        />
                      </MuiPickersUtilsProvider>
                    </Grid>
                    <Grid item xs={12} sm={12} md={4} lg={4}>
                      <TextField
                        fullWidth
                        label={getTranslation("Street", "rue", "Stra�e")}
                        size="small"
                        variant="outlined"
                        name="basic.street"
                        {...getFieldProps("basic.street")}
                        error={
                          touched.basic?.street && Boolean(errors.basic?.street)
                        }
                        helperText={
                          touched.basic?.street && errors.basic?.street
                        }
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} sm={12} md={4} lg={4}>
                      <TextField
                        fullWidth
                        label={getTranslation("City", "Ville", "Stadt")}
                        size="small"
                        variant="outlined"
                        name="basic.city"
                        {...getFieldProps("basic.city")}
                        error={
                          touched.basic?.city && Boolean(errors.basic?.city)
                        }
                        helperText={touched.basic?.city && errors.basic?.city}
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} sm={12} md={4} lg={4}>
                      <TextField
                        fullWidth
                        label={getTranslation(
                          "Zip",
                          "Code postal",
                          "Postleitzahl"
                        )}
                        type="number"
                        size="small"
                        variant="outlined"
                        name="basic.zipCode"
                        {...getFieldProps("basic.zipCode")}
                        error={
                          touched.basic?.zipCode &&
                          Boolean(errors.basic?.zipCode)
                        }
                        helperText={
                          touched.basic?.zipCode && errors.basic?.zipCode
                        }
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} sm={12} md={4} lg={4}>
                      <TextField
                        fullWidth
                        label="RBE"
                        size="small"
                        variant="outlined"
                        name="basic.rbe"
                        {...getFieldProps("basic.rbe")}
                        error={touched.basic?.rbe && Boolean(errors.basic?.rbe)}
                        helperText={touched.basic?.rbe && errors.basic?.rbe}
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} sm={12} md={4} lg={4}>
                      <Autocomplete
                        name="basic.date"
                        value={values?.basic?.finnancialYearEnds}
                        options={months}
                        size="small"
                        onChange={(e, value) => {
                          setFieldValue("basic.finnancialYearEnds", value);
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label={getTranslation("Financial Year Ends")}
                            variant="outlined"
                            error={
                              touched.basic?.finnancialYearEnds &&
                              Boolean(errors.basic?.finnancialYearEnds)
                            }
                          />
                        )}
                      />
                    </Grid>
                    <Grid item xs={12} sm={12} md={4} lg={4}>
                      <Autocomplete
                        name="basic.basicLegalForm"
                        value={values?.basic?.basicLegalForm}
                        options={legalForms}
                        getOptionLabel={(option) => option.legalForm}
                        size="small"
                        onChange={(e, value) => {
                          if (value || value?.isGeneralPartnerNeeded === false)
                            setFieldValue("basic.generalPartner", null);
                          setFieldValue("basic.basicLegalForm", value);
                          setFieldValue("basic.basicLegalFormId", value?.id);
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label={getTranslation(
                              "Legal Form",
                              "Forme juridique",
                              "Rechtsform"
                            )}
                            variant="outlined"
                            error={
                              touched.basic?.basicLegalForm &&
                              Boolean(errors.basic?.basicLegalForm)
                            }
                            InputProps={{
                              ...params.InputProps,
                              endAdornment: (
                                <React.Fragment>
                                  {legalFormsLoading ? (
                                    <CircularProgress
                                      color="inherit"
                                      size={20}
                                    />
                                  ) : null}
                                  {params.InputProps.endAdornment}
                                </React.Fragment>
                              ),
                            }}
                          />
                        )}
                      />
                    </Grid>
                    <Grid item xs={12} sm={12} md={4} lg={4}>
                      <TextField
                        id="basic.vat"
                        size="small"
                        label="VAT"
                        fullWidth
                        variant="outlined"
                        color="primary"
                        value={values.basic?.vat}
                        name="basic.vat"
                        {...getFieldProps("basic.vat")}
                      />
                    </Grid>
                    <Grid item xs={12} sm={12} md={4} lg={4}>

                      <FormControlLabel
                        control={<Checkbox
                          checked={values?.basic?.liquidateDate != "0001-01-01T00:00:00"}
                          onChange={(e, checked) => {
                            if (checked) {
                              setFieldValue("basic.liquidateDate", new Date())
                            } else {
                              setFieldValue("basic.liquidateDate", "0001-01-01T00:00:00")
                            }
                          }}
                          name="checkedA" />
                        }
                        label={getTranslation(
                          "Liquidation Date",
                          "Date de liquidation",
                          "Abrechnungsdatum"
                        )}
                      />

                      {moment(values?.basic?.liquidateDate).format("DD/MM/YYYY")=="01/01/0001" ?null:
                      <DatePicker
                        fullWidth
                        label={getTranslation(
                          "Liquidation Date",
                          "Date de liquidation",
                          "Abrechnungsdatum"
                        )}
                        variant="outlined"
                        name="basic.liquidateDate"
                        value={values?.basic?.liquidateDate}
                        size="small"
                        error={
                          touched.basic?.liquidateDate &&
                          Boolean(errors.basic?.liquidateDate)
                        }
                        onChange={(date) => {
                          const theDate = getDateIgnoreTimezone(date);
                          setFieldValue("basic.liquidateDate", moment(date).format("DD/MM/YYYY"));
                        }}
                      />}
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={12}>
                      <TextField
                        multiline
                        id="basic.comments"
                        label="Comments"
                        fullWidth
                        variant="outlined"
                        color="primary"
                        value={values.basic?.comments}
                        name="basic.comments"
                        {...getFieldProps("basic.comments")}
                        error={
                          touched.basic?.comments &&
                          Boolean(errors.basic?.comments)
                        }
                        helperText={
                          touched.basic?.comments && errors.basic?.comments
                        }
                      />
                    </Grid>
                    <Grid item xs={12} sm={12} md={4} lg={4}>
                      {values?.basic?.basicLegalForm?.isGeneralPartnerNeeded ? (
                        <Autocomplete
                          name="basic.generalPartner"
                          value={values?.basic?.generalPartner}
                          options={generalPartners}
                          getOptionLabel={(option) => option.companyName}
                          size="small"
                          onChange={(e, value) => {
                            setFieldValue("basic.generalPartner", value);
                            setFieldValue("basic.generalPartnerId", value?.id);
                          }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label={getTranslation(
                                "General Partner",
                                "Partenaire général",
                                "Komplementär"
                              )}
                              variant="outlined"
                              error={
                                touched.basic?.generalPartner &&
                                Boolean(errors.basic?.generalPartner)
                              }
                              InputProps={{
                                ...params.InputProps,
                                endAdornment: (
                                  <React.Fragment>
                                    {loading ? (
                                      <CircularProgress
                                        color="inherit"
                                        size={20}
                                      />
                                    ) : null}
                                    {params.InputProps.endAdornment}
                                  </React.Fragment>
                                ),
                              }}
                            />
                          )}
                        />
                      ) : (
                        ""
                      )}
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={12}>
                      <Card>
                        <CardHeader
                          title={getTranslation(
                            "Date AGM",
                            "Date AGA",
                            "Datum AGV"
                          )}
                        />
                        <CardContent>
                          <Box
                            style={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                              marginBottom: "30px",
                            }}
                          >
                            <ToggleButtonGroup
                              size="small"
                              style={{
                                marginLeft: "20px",
                              }}
                              id="inputMode"
                              value={values.basic.agmType}
                              exclusive
                              onChange={(event, type) => {
                                if (type !== null) {
                                  setAgmType(type);
                                  setFieldValue("basic.agmType", type);
                                  console.log("jkashdjkasdjkals",type == 0 ? "Janurary" : 6)
                                  setFieldValue(
                                    "basic.dateAGMMonth",
                                    type == 0 ? "Janurary" : 6
                                  );
                                  setFieldValue(
                                    "basic.dateAGMNumberMonth",
                                    6
                                  );
                                }
                              }}
                            >
                              <ToggleButton value={0} aria-label="left aligned">
                                <Typography>Formula</Typography>
                              </ToggleButton>
                              <ToggleButton value={1} aria-label="centered">
                                <Typography>Monthly Period</Typography>
                              </ToggleButton>
                            </ToggleButtonGroup>
                          </Box>
                          <Grid container spacing={2}>
                            {values.basic?.agmType === 0 ? (
                              <>
                                <Grid item xs={12} sm={12} md={4} lg={4}>
                                  <Autocomplete
                                    name="basic.date"
                                    value={values?.basic?.dateAGMDayNumber}
                                    options={numbering}
                                    size="small"
                                    onChange={(e, value) => {
                                      // if(value || value.isGeneralPartnerNeeded===false)
                                      setFieldValue(
                                        "basic.dateAGMDayNumber",
                                        value
                                      );

                                      // setFieldValue("basic.basicLegalForm", value)
                                      // setFieldValue("basic.basicLegalFormId", value?.id)
                                    }}
                                    renderInput={(params) => (
                                      <TextField
                                        {...params}
                                        label={getTranslation(
                                          "Select Day Number",
                                          "Sélectionnez le numéro du jour",
                                          "Wählen Sie Tagesnummer"
                                        )}
                                        variant="outlined"
                                        error={
                                          touched.basic?.dateAGMDayNumber &&
                                          Boolean(
                                            errors.basic?.dateAGMDayNumber
                                          )
                                        }
                                      />
                                    )}
                                  />
                                </Grid>
                                <Grid item xs={12} sm={12} md={4} lg={4}>
                                  <Autocomplete
                                    name="basic.dateAGMDay"
                                    value={values?.basic?.dateAGMDay}
                                    options={days}
                                    size="small"
                                    onChange={(e, value) => {
                                      setFieldValue("basic.dateAGMDay", value);
                                      // if(value || value.isGeneralPartnerNeeded===false)
                                      //   setFieldValue("basic.generalPartner",null)

                                      // setFieldValue("basic.basicLegalForm", value)
                                      // setFieldValue("basic.basicLegalFormId", value?.id)
                                    }}
                                    renderInput={(params) => (
                                      <TextField
                                        {...params}
                                        label={getTranslation(
                                          "Select Day",
                                          "Sélectionnez le jour",
                                          "Tag auswählen"
                                        )}
                                        variant="outlined"
                                        error={
                                          touched.basic?.dateAGMDay &&
                                          Boolean(errors.basic?.dateAGMDay)
                                        }
                                      />
                                    )}
                                  />
                                </Grid>
                                <Grid item xs={12} sm={12} md={4} lg={4}>
                                  <Autocomplete
                                    name="basic.dateAGMMonth"
                                    value={values?.basic?.dateAGMMonth}
                                    options={months}
                                    size="small"
                                    onChange={(e, value) => {
                                      setFieldValue(
                                        "basic.dateAGMMonth",
                                        value
                                      );
                                      // if(value || value.isGeneralPartnerNeeded===false)
                                      //   setFieldValue("basic.generalPartner",null)

                                      // setFieldValue("basic.basicLegalForm", value)
                                      // setFieldValue("basic.basicLegalFormId", value?.id)
                                    }}
                                    renderInput={(params) => (
                                      <TextField
                                        {...params}
                                        label={getTranslation(
                                          "Select Month",
                                          "Wähle einen Monat",
                                          "Wähle einen Monat"
                                        )}
                                        variant="outlined"
                                        error={
                                          touched.basic?.dateAGMMonth &&
                                          Boolean(errors.basic?.dateAGMMonth)
                                        }
                                      />
                                    )}
                                  />
                                </Grid>
                              </>
                            ) : (
                              <Grid item xs={12} sm={12} md={6} lg={6}>
                                  <TextField
                                    variant="outlined"
                                    id="basic.dateAGMNumberMonth"
                                    label="Select Number of Months"
                                    size="small"
                                    fullWidth
                                    value={values?.basic?.dateAGMMonth}
                                    onChange={(e) => {
                                      setFieldValue(
                                        "basic.dateAGMMonth",
                                        e.target.value
                                      );
                                      setFieldValue(
                                        "basic.dateAGMNumberMonth",
                                        e.target.value
                                      );
                                    }}
                                    type="number"
                                    error={
                                      touched.basic?.dateAGMNumberMonth &&
                                      Boolean(errors.basic?.dateAGMNumberMonth)
                                    }
                                    helperText={
                                      touched.basic?.dateAGMNumberMonth &&
                                      errors.basic?.dateAGMNumberMonth
                                    }
                                  />
                                </Grid>
                            )}
                          </Grid>
                        </CardContent>
                      </Card>
                      <Box
                        mt={1}
                        pr={1}
                        pb={1}
                        width="100%"
                        display="flex"
                        alignItems="center"
                        justifyContent="center"
                      >
                        <ButtonWithLoading
                          title={
                            isNewRecord === true
                              ? getTranslation("Create", "Crer", "Schaffen")
                              : getTranslation(
                                  "Update",
                                  "Mettre jour",
                                  "Aktualisieren"
                                )
                          }
                          size="medium"
                          variant="contained"
                          color="primary"
                          loading={isSubmitting}
                          onClick={() => {
                            handleSubmit();
                          }}
                        />
                      </Box>
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            </Form>
          )}
        </Formik>
      </FormLoader>
    </>
  );
};

export default Info;

const AssetBasicInfo = ({ assetId, enqueueSnackbar }) => {
  const [asset, setAsset] = useState({});
  const [assetDocuments, setAssetDocuments] = useState([]);

  const [assetLoading, setAssetLoading] = useState(true);

  useEffect(() => {
    Get(
      { id: assetId },
      Get_Asset_URL,
      null,
      (response) => {
        setAsset(response.data);
        setAssetLoading(false);
      },
      (error) => {}
    );

    Get(
      { assetId },
      Get_AssetDocuments_URL,
      null,
      (response) => {
        setAssetDocuments(response.data);
      },
      (error) => {}
    );
  }, []);

  // Asset Document Operations

  const insertAssetDocument = async (fileName) => {
    await Create(
      { fileName: fileName, assetId: assetId },
      Post_InsertAssetDocument_URL,
      null,
      (resp) => {},
      (error) => {}
    );
  };

  const deleteAssetDocument = async (fileName) => {
    await Create(
      { fileName },
      Post_DeleteAssetDocument_URL,
      null,
      (resp) => {},
      (error) => {}
    );
  };

  const onSubmit = async (values, actions) => {
    await Create(
      values,
      Post_UpdateAsset_URL,
      null,
      (resp) => {
        actions.setSubmitting(false);
        enqueueSnackbar("Asset basic information updated", { variant: "info" });
      },
      (error) => {}
    );
  };

  return (
    <FormLoader progress={assetLoading}>
      <Formik
        enableReinitialize
        initialValues={asset}
        onSubmit={(values, actions) => {
          actions.setSubmitting(true);
          onSubmit(values, actions);
        }}
      >
        {({
          errors,
          touched,
          values,
          handleSubmit,
          isSubmitting,
          getFieldProps,
          setFieldValue,
        }) => (
          <Form>
            <Card
              title={getTranslation(
                "Asset Basic Info",
                "Informations de base sur l'actif",
                "Grundlegende Informationen zu Vermögenswerten"
              )}
            >
              <CardContent>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={12} md={6} lg={6}>
                    <TextField
                      multiline
                      rows={5}
                      fullWidth
                      label={getTranslation(
                        "Capacity",
                        "Capacité",
                        "Kapazität"
                      )}
                      size="small"
                      variant="outlined"
                      name="capacity"
                      id="capacity"
                      {...getFieldProps("capacity")}
                      error={touched.capacity && Boolean(errors.capacity)}
                      helperText={touched.capacity && errors.capacity}
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={6} lg={6}>
                    <TextField
                      multiline
                      rows={5}
                      fullWidth
                      label={getTranslation(
                        "Subsidies",
                        "Subventions",
                        "Subventionen"
                      )}
                      size="small"
                      variant="outlined"
                      name="subsidies"
                      {...getFieldProps("subsidies")}
                      error={touched.subsidies && Boolean(errors.subsidies)}
                      helperText={touched.subsidies && errors.subsidies}
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={12} lg={12}>
                    <UploadFiles
                      multiple
                      uploadedFiles={assetDocuments}
                      history={null}
                      onAddFile={insertAssetDocument}
                      //onAddFile={handleAddFile}
                      onDeleteFile={deleteAssetDocument}
                      getFileName={(file) => file.fileName}
                    />
                  </Grid>
                </Grid>
              </CardContent>
              <Box
                mt={1}
                pr={1}
                pb={1}
                width="100%"
                display="flex"
                alignItems="center"
                justifyContent="center"
              >
                <ButtonWithLoading
                  title={getTranslation(
                    "Update",
                    "Mettre jour",
                    "Aktualisieren"
                  )}
                  size="medium"
                  variant="contained"
                  color="primary"
                  loading={isSubmitting}
                  onClick={handleSubmit}
                />
              </Box>
            </Card>
          </Form>
        )}
      </Formik>
    </FormLoader>
  );
};
