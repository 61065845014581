import {
  Box,
  Grid,
  IconButton,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@material-ui/core";
import { Form, Formik } from "formik";
import { useState } from "react";

import * as Yup from "yup";
import AddField from "./addField";
import Previewform from "./previewForm";
import ButtonWithLoading from "../../../../components/ButtonWithLoading";
import { useSnackbar } from "notistack";
import { useEffect } from "react";
import { Close } from "@material-ui/icons";
import { formCategories } from "../../../../constants/formCategories";
import { getTranslation } from "../../../../heplers/translationHelper";
import { representativeRoles } from "../../../../constants/representativeRoles";
import { CeresTeamMemberResponsibilites } from "../../../../constants/ceresTeamMemberResponsibilities";
const AddForm = ({ editForm, submitting, onSubmit, onCancel }) => {
  var _ = require("lodash");
  const { enqueueSnackbar } = useSnackbar();
  const [fields, setFields] = useState({});

  //////////////// Preview Form handlers ////////////////

  useEffect(() => {
    if (editForm) {
      setFields(editForm);
    } else {
      setFields({ formFields: [] });
    }
  }, [editForm]);

  const onNext = (arr, index) => {
    console.log("ngsdjfdsf", arr);
    var currArr = arr[index];
    var target = arr[index + 1];
    //// Swapiing sort value
    var temp = currArr.sort;
    currArr.sort = target.sort;
    target.sort = temp;

    arr[index] = currArr;
    arr[index + 1] = target;

    return setFields({ ...fields, formFields: arr });
  };

  const onPrevious = (arr, index) => {
    console.log("ngsdjfdsf", arr);
    var currArr = arr[index];
    var target = arr[index - 1];
    //// Swapiing sort value
    var temp = currArr.sort;
    currArr.sort = target.sort;
    target.sort = temp;

    arr[index] = currArr;
    arr[index - 1] = target;

    return setFields({ ...fields, formFields: arr });
  };

  const handleRemoveField = (field) => {
    console.log("fielsadfd", fields.formFields, field);
    var newFields = fields.formFields.filter((f) => f.sort !== field.sort);
    setFields({ ...fields, formFields: newFields });
    console.log("hjsdgfdsf", newFields);
  };

  const [sortNumber, setSortNumber] = useState(0);
  const handleAddField = (values, actions) => {
   
  };
  const initialValues = {
    id: 0,
    title: "",
    category: "",
    role: "",
    formFields: [],
  };
  const validationSchema = Yup.object({
    title: Yup.string().required("Title is required"),
    category: Yup.string().required("Category is required"),
    role: Yup.string().required("Role is required"),
  });

  const [defaultValues,setDefaultValues] = useState(initialValues);
  useEffect(() => {
    if (editForm)
      setDefaultValues(editForm)
    else
      setDefaultValues(initialValues)
  },[editForm])
  return (
    <>
      <Formik
        enableReinitialize
        initialValues={defaultValues}
        validationSchema={validationSchema}
        onSubmit={(values, actions) => {
          actions.setSubmitting(true);
          onSubmit(values, actions);
          setDefaultValues(initialValues)
    }}
      >
        {({
          values,
          errors,
          touched,
          getFieldProps,
          setFieldValue,
          handleSubmit,
        }) => (
          <Form>
            <Box
              style={{
                backgroundColor: "white",
                padding: "20px",
              }}
            >
              <Grid container spacing={2}>
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={12}
                  lg={5}
                  style={{
                    borderRight: "1px solid #e0e0e0",
                  }}
                >
                  <Typography
                    variant="h4"
                    style={{
                      marginBottom: "10px",
                    }}
                  >
                    {editForm ? "Update Form" : "Add Form"}
                  </Typography>
                  <Grid container spacing={1}>
                    <Grid item xs={12} sm={12} md={6} lg={4}>
                      <TextField
                        fullWidth
                        label="Heading"
                        value={values.title}
                        required
                        size="small"
                        variant="outlined"
                        name="title"
                        {...getFieldProps("title")}
                        error={touched.title && Boolean(errors.title)}
                        helperText={touched.title && errors.title}
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={4}>
                      <Select
                        title={"Category"}
                        style={{
                          marginRight: "20px",
                          height: "34px",
                        }}
                        error={touched.category && Boolean(errors.category)}
                        helperText={touched.category && errors.category}
                        value={values.category}
                        id="category"
                        fullWidth
                        variant="outlined"
                        size="small"
                        onChange={(e) => {
                          setFieldValue("category", e.target.value);
                        }}
                      >
                        {formCategories.map(category=><MenuItem value={category.value}>{getTranslation(category.defaultEnglish,category.defaultFrench,category.defaultGerman)}</MenuItem>)}
                       
                      </Select>
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={4}>
                      <Select
                        style={{
                          marginRight: "20px",

                          height: "34px",
                        }}
                        error={touched.role && Boolean(errors.role)}
                        helperText={touched.role && errors.role}
                        value={values.role}
                        id="role"
                        label="Field Type"
                        fullWidth
                        variant="outlined"
                        size="small"
                        onChange={(e) => {
                          setFieldValue("role", e.target.value);
                        }}
                      >
                        {representativeRoles.map(category=><MenuItem value={category.id}>{getTranslation(category.defaultEnglish,category.defaultFrench,category.defaultGerman)}</MenuItem>)}
                        {CeresTeamMemberResponsibilites.map(category=><MenuItem value={category.id}>{getTranslation(category.defaultEnglish,category.defaultFrench,category.defaultGerman)}</MenuItem>)}
                       
                      </Select>
                    </Grid>
                    {/* Form Fields components */}
                    <Grid item xs={12} sm={12} md={12} lg={12}>
                      <AddField
                        onSubmit={(item, actions) => {
                          setSortNumber(sortNumber + 1);
                          const arr = values.formFields;
                          arr.push({
                            ...item,
                            sort: sortNumber,
                          });
                          // setFields({
                          //   ...fields,
                          //   formFields: arr,
                          // });
                          setFieldValue("formFields",arr)
                          actions.setSubmitting(false);
                          // handleAddField(values, actions);
                        }}
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={6}>
                  <Typography variant="h4">Preview Form</Typography>
                  <Previewform
                    fields={values.formFields}
                    onNext={onNext}
                    onPrevious={onPrevious}
                    isSubmitting={submitting}
                    onSubmit={(formFields) => {
                      setFieldValue("formFields", formFields);
                      enqueueSnackbar("Preview Form Successfully Created", {
                        variant: "info",
                      });
                    }}
                    onCancel={(field) => handleRemoveField(field)}
                  />
                </Grid>
              </Grid>
            </Box>
            <Box
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                margin: "20px",
              }}
            >
              {editForm ? (
                <IconButton onClick={onCancel}>
                  <Close />
                </IconButton>
              ) : null}
              <ButtonWithLoading
                title={editForm ? "Update Form" : "Create Form"}
                loading={submitting}
                onClick={handleSubmit}
                variant="contained"
                color="primary"
              />
            </Box>
          </Form>
        )}
      </Formik>
    </>
  );
};
export default AddForm;
