import Card from "../../../../../../components/Card"
import { CardContent, Typography } from "@material-ui/core";
const UpdateReportComments = ({comments}) => {
    return (
        <Card title="Comments">
            <CardContent>
                <Typography>
                    {comments=='' || comments==null ? "No Comments" :comments}
                </Typography>
            </CardContent>
        </Card>
    )
}
export default UpdateReportComments;