import { useState } from "react";
import { useEffect } from "react";
import { useParams } from "react-router";
import { Create, Get } from "../../../../actions";
import {
  Get_AllForms_URL,
  Get_FormReportCollection_URL,
  Get_MeetingInfo_URL,
  Get_TableReportCollection_URL,
  Post_SubmitFormReportCollection_URL,
} from "../../../../constants/apiUrls";
import MeetingInformation from "../../components/MeetingInformation";
import FormList from "./formList";
import { TableList } from "./tableList";

const ReportDataCollection = ({isContact}) => {
  const { meetingId,memberId } = useParams();
  const [formLoading, setFormLoading] = useState(false);
  const [tableLoading, setTableLoading] = useState(false);
  const [formList, setFormList] = useState([]);
  const [tableList, setTableList] = useState([]);
  const [meetingInfo, setMeetingInfo] = useState();
  useEffect(() => {
    loadMeetingInfo();
    loadAllForms();
    loadAllTables();
  }, []);
  const loadAllForms = () => {
    setFormLoading(true);
    Get(
      {
        meetingId:meetingId,
        contactId:isContact ? memberId:null,
        ceresMemberId:isContact ==false ? memberId:null
      },
      Get_FormReportCollection_URL,
      null,
      (resp) => {
        console.log("jksdbhfksdmfsdf", resp.data);
        setFormList(resp.data);
    setFormLoading(false);
  },
      (error) => {
    setFormLoading(false);

      }
    );
  };

  const loadAllTables = () => {
    setTableLoading(true);
    Get(
      {
        meetingId: meetingId,
        contactId:isContact ? memberId:null,
        ceresMemberId:isContact ==false ? memberId:null
      },
      Get_TableReportCollection_URL,
      null,
      (resp) => {
        setTableList(resp.data);
        setTableLoading(false);
      },
      (error) => {
        setTableLoading(false);
      }
    );
  };

  const loadMeetingInfo = () => {
    Get(
      {
        meetingId: meetingId,
      },
      Get_MeetingInfo_URL,
      null,
      (resp) => {
        setMeetingInfo(resp.data);
      },
      (error) => {}
    );
  };

  const handleSubmitForm=(form,index)=>{
    Create(form,
      Post_SubmitFormReportCollection_URL,
      null,
      resp => {
        const arr = formList;
        form.isSubmited = true;
        arr[index] = form;
        setFormList([...arr])
      },
      error => {

      }
    )
  }
  return (
    <div style={{ paddingBottom: "200px" }}>
      <MeetingInformation meetingInfo={meetingInfo} />
      <br />
      <br />
      <FormList forms={formList} disableFieldsConfig onSubmit={handleSubmitForm} />
      <br />
      <br />
      <TableList tables={tableList} />
    </div>
  );
};
export default ReportDataCollection;
