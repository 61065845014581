import React, { useState } from "react";
import QrReader from "react-qr-reader";

export const QRCodeReader = ({ onScan, onError, style }) => {
  const handleErrorFile = (error) => {
    if (onError)
      onError(error)
    else
      alert(error)
  };
  const handleScanFile = (result) => {
    if (result)
      onScan(result);
  };
  return (
    <QrReader
      delay={500}
      style={style}
      onError={handleErrorFile}
      onScan={handleScanFile}

    ></QrReader>
  );
};
