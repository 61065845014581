import { Box, colors, Grid, IconButton, Table, TableBody, TableCell, TableHead, TableRow, withStyles } from "@material-ui/core";
import { Formik } from "formik";
import { useSnackbar } from "notistack";
import { useEffect } from "react";
import { useState } from "react";
import { Create } from "../../../../actions";
import ButtonWithLoading from "../../../../components/ButtonWithLoading";
import { DeleteButton, EditButton } from "../../../../components/ButttonsWithIcons";
import { Collapser } from "../../../../components/Collapser";
import TableComponent from "../../../../components/table";
import { Post_SubmitTableReportCollection_URL } from "../../../../constants/apiUrls";
import { PreviewTable } from "../../../reports";
import Previewform from "../../../reports/form/components/previewForm";

export const TableList=({ tables, disableFieldsConfig,onSubmit })=>{
    
    return (
        <>
          {tables?.map((table,index) => {
                       
            return (<CollectTable table={table}/>);
          })}
        </>
      );
}

const CollectTable=({table})=>{
    
    const [rows, setRows] = useState();
    const [columns, setColumns] = useState();
    let title = table?.title ? table?.title : table?.reportingTitle;
    const submitedTitle = table.isSubmited ? " Submited" : "";
    title = title + submitedTitle
useEffect(()=>{
    
    const newColumns = [];
    table?.reportingValues[0]?.forEach(reportingValue => {
        reportingValue.label = reportingValue?.title
        newColumns.push({...reportingValue,label : reportingValue?.title,value:""})
    });

    setColumns(newColumns);
    setEditReportingValue(newColumns);
    setRows(table?.reportingValues)
},[table])

   
    const [editReportingValue, setEditReportingValue] = useState();
    const [editReportingValueIndex, setEditReportingValueIndex] = useState(-1);
    const [isSubmitting, setIsSubmitting] = useState(false);

    const handleEdit=(reportingValue,index)=>{
        setEditReportingValue(reportingValue)
        setEditReportingValueIndex(index)
    }

    const {enqueueSnackbar} =useSnackbar();

    const handleSubmit=()=>{
        setIsSubmitting(true)
        Create(
            {...table,reportingValues:rows},
            Post_SubmitTableReportCollection_URL,
            null,
            resp=>{
                table.isSubmited=true
                setIsSubmitting(false)
                enqueueSnackbar("Table data submited",{variant:"success"})
            },
            error=>{
                setIsSubmitting(false)
                enqueueSnackbar("Table data not submited due to an error. please try again",{variant:'error'})
            }
            )
    }

    const handleDelete=(reportingValue,index)=>{
        setRows(rows.filter((val,i)=>i!=index))
    }

    return <Collapser title={title} >
        <CollectData
            editReportingValue={editReportingValue}
            onSubmit={(values) => {
                if (editReportingValueIndex > -1) {
                    rows[editReportingValueIndex] = values;
                    setRows([...rows])
                }
                else if (rows)
                    setRows([...rows, values])
                else
                    setRows([values])

                const newColumns=[]
                columns.forEach(column=>{
                    newColumns.push({...column,value:''})
                })
                setEditReportingValue([...newColumns])
                setEditReportingValueIndex(-1)

            }} />
        <br />
        <br />
        <Table style={{ marginBottom: "100px" }}>
            <TableHead>
                <StyledTableHeadRow>
                    {columns?.map(item => {
                        return <StyledTableCell>{item.title}</StyledTableCell>
                    })}
                    <StyledTableCell>Actions</StyledTableCell>
                </StyledTableHeadRow>
            </TableHead>
            <TableBody>
                {rows?.map((item,index) => {
                    return <StyledTableRow> {columns?.map(column => {
                        const reportingValue = item?.find(x => x.id == column.id)
                        return <StyledTableCell>{reportingValue?.value}</StyledTableCell>
                    })}
                        <StyledTableCell>
                            <EditButton onClick={()=>handleEdit(item,index)}/>
                            <DeleteButton onClick={()=>handleDelete(item,index)}/>
                        </StyledTableCell>
                    </StyledTableRow>
                })}
            </TableBody>
        </Table>

        <Box
                  pr={1}
                  pb={1}
                  width="100%"
                  display="flex"
                  alignItems="center"
                  justifyContent="flex-end"
                >
                  <ButtonWithLoading
                    title={"Submit Changes"}
                    size="small"
                    variant="contained"
                    color="primary"
                    disabled={rows?.length==0}
                    loading={isSubmitting}
                    onClick={handleSubmit}
                  />
                </Box>
</Collapser>
}

const CollectData=({editReportingValue,onSubmit})=>{
    return <Previewform fields={editReportingValue} disableConfig onSubmit={onSubmit}    />
}

const StyledTableCell = withStyles((theme) => ({
    head: {
      backgroundColor: theme.palette.common.black,
      color: theme.palette.common.white,
    },
    body: {
      fontSize: 14,
    },
  }))(TableCell);
  
  const StyledTableRow = withStyles((theme) => ({
    root: {
      '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
      },
    },
  }))(TableRow);

  const StyledTableHeadRow = withStyles((theme) => ({
    root: {
        backgroundColor: theme.palette.secondary.main,
        color: theme.palette.common.white,
    },
  }))(TableRow);