import { Box, Grid, TextField, Typography } from "@material-ui/core";
import {
  Autocomplete,
  ToggleButton,
  ToggleButtonGroup,
} from "@material-ui/lab";
import { Formik } from "formik";
import { FormLoader } from "../../../../../components/FormLoader";
import * as Yup from "yup";
import { useEffect, useState } from "react";
import {
  GET_AllUsersWithoutAuthorization_URL,
  Get_AllManagementCompanyFeeServicesByType_URL,
  Post_UpdateMeetingInfo_URL,
  Get_CeresService_URL,
  Get_CompanyCeresService_URL,
} from "../../../../../constants/apiUrls";
import { Create, Get } from "../../../../../actions";
import { LanguageSelection } from "../../../../../constants/languages";
import { MyTimePicker } from "../../../../../components/TimePicker";
import { DatePicker } from "../../../../../components/DatePicker";
import ButtonWithLoading from "../../../../../components/ButtonWithLoading";
import { useSnackbar } from "notistack";
import { BoardMembers } from "../boardMembers";
import { getTranslation } from "../../../../../heplers/translationHelper";
import { getDateIgnoreTimezone } from "../../../../../heplers/dateHelper";
export const UpdateRegularMeetingInfo = ({
  meetingInfo,
  loading,
  managementCompanyId,
}) => {
  console.log("kdskhdgsdg", meetingInfo);
  const { enqueueSnackbar } = useSnackbar();

  const [ceresServices, setCeresServices] = useState([]);
  const [ceresServiceLoading, setCeresServiceLoading] = useState(false);
  const [selectedCeresService, setSelectedCeresService] = useState({});
  const [selectedPersonIncharge, setSelectedPersonIncharge] = useState({});

  const [userList, setUserList] = useState([]);
  const [userListLoading, setUserListLoading] = useState(false);

  const loadCeresServices = (id) => {
    setCeresServiceLoading(true);
    Get(
      {
        id: id,
      },
      Get_CompanyCeresService_URL,
      null,
      (resp) => {
        setSelectedCeresService(resp.data)
        // setCeresServices(resp.data);
        // setCeresServiceLoading(false);
      },
      (error) => {}
    );
  };

  useEffect(() => {
    if (meetingInfo) {
      loadCeresServices(meetingInfo?.companyCeresServiceId)
    }
  }, [meetingInfo]);

  useEffect(() => {
    setUserListLoading(true);
    Get(
      {},
      GET_AllUsersWithoutAuthorization_URL,
      null,
      (resp) => {
        setUserList(resp.data);
        setUserListLoading(false);
      },
      (error) => {}
    );
  }, []);
  useEffect(() => {
    if (userList.length > 0 && userListLoading === false && meetingInfo) {
      const user = userList.find((x) => x.id === meetingInfo?.personInchargeId);
      setSelectedPersonIncharge(user);
    }
  }, [userList, meetingInfo]);

  const basicValidationSchema = Yup.object().shape({
    subject: Yup.string().required("Field is required"),
    meetingTime: Yup.string().required("Field Time is requied"),
    meetingDate: Yup.string().required("Field is required"),
    language: Yup.string().required("Field is required"),
    personInchargeId: Yup.string().required("Field is required"),
    companyCeresServiceId: Yup.number().min(1).required("Field is required"),
    // zipCode:Yup.string().required("Zip code is required"),
    // tow:Yup.string().required("Town is required"),
    // address:Yup.string().required("Address is required"),
  });
  return (
    <FormLoader progress={loading}>
      {/* <CardHeader title='Details' /> */}
      <Formik
        enableReinitialize
        initialValues={meetingInfo ? meetingInfo : {}}
        validationSchema={basicValidationSchema}
        onSubmit={(values, actions) => {
          actions.setSubmitting(true);
          Create(
            values,
            Post_UpdateMeetingInfo_URL,
            null,
            (resp) => {
              actions.setSubmitting(false);
              enqueueSnackbar("Meetings info is updated.", {
                variant: "success",
              });
            },
            (error) => {
              actions.setSubmitting(false);
              enqueueSnackbar(error.data, {
                variant: "error",
              });
            }
          );
          actions.resetForm();
        }}
      >
        {({
          errors,
          touched,
          values,
          handleSubmit,
          isSubmitting,
          getFieldProps,
          setFieldValue,
          handleChange,
        }) => (
          <>
            <Grid container spacing={2} style={{ margin: "10px" }}>
              <Grid
                item
                xs={12}
                sm={12}
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <ToggleButtonGroup
                  size="small"
                  id="meetingType"
                  value={values.meetingType}
                  exclusive
                  disabled
                >
                  {values.meetingType === 0  ? (
                    <ToggleButton value={0} aria-label="left aligned">
                      <Typography>Board</Typography>
                    </ToggleButton>
                  ) : values.meetingType === 1 ? (
                    <ToggleButton value={1} aria-label="centered">
                      <Typography>Shareholder</Typography>
                    </ToggleButton>
                  ) : values.meetingType === 2 ? (
                    <ToggleButton value={2} aria-label="left aligned">
                      <Typography>Board</Typography>
                    </ToggleButton>
                  ) : (
                    <ToggleButton value={1} aria-label="centered">
                      <Typography>Shareholder</Typography>
                    </ToggleButton>
                  )}
                </ToggleButtonGroup>
              </Grid>
              <Grid item xs={12} sm={12} md={3} lg={3}>
                <TextField 
                  variant="outlined"
                  value={selectedCeresService?.ceresServiceTitle} 
                  size="small"
                  label="Ceres Service"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  fullWidth
                  disabled
                  />
              </Grid>
              {/* Subject */}
              <Grid item xs={12} sm={12} md={3} lg={3}>
                <TextField
                  fullWidth
                  id="subject"
                  label="Subject"
                  required
                  size="small"
                  variant="outlined"
                  name="subject"
                  {...getFieldProps("subject")}
                  error={touched.subject && Boolean(errors.subject)}
                  helperText={touched.subject && errors.subject}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Grid>
              {/* Person Incharge */}
              <Grid item xs={12} sm={12} md={3} lg={3}>
                <Autocomplete
                  options={userList}
                  value={selectedPersonIncharge}
                  getOptionLabel={(option) => option.name}
                  size="small"
                  onChange={(e, value) => {
                    setFieldValue("personInchargeId", value?.id);
                    setSelectedPersonIncharge(value);
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Person Incharge"
                      variant="outlined"
                      error={
                        touched.personInchargeId &&
                        Boolean(errors.personInchargeId)
                      }
                    />
                  )}
                />
              </Grid>
              {/* Language */}
              <Grid item xs={12} sm={12} md={3} lg={3}>
                <LanguageSelection
                  fullWidth
                  id="language"
                  label="Language"
                  required
                  size="small"
                  variant="outlined"
                  name="language"
                  selected={values.language}
                  onChange={(e, language) =>
                    setFieldValue("language", language)
                  }
                  error={touched.language && Boolean(errors.language)}
                  helperText={touched.language && errors.language}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Grid>
              {/* Meeting Date */}
              <Grid item xs={12} sm={12} md={3} lg={3}>
                <DatePicker
                fullWidth
                  label="Meeting Date"
                  value={values.meetingDate}
                  onChange={(value) => {
                    setFieldValue("meetingDate", value);
                  }}
                  required
                  error={touched.meetingDate && Boolean(errors.meetingDate)}
                />
              </Grid>
              {/* Meeting Time */}
              <Grid item xs={12} sm={12} md={3} lg={3}>
                <MyTimePicker
                  fullWidth
                  id="meetingTime"
                  variant="inline"
                  size="small"
                  label={getTranslation("Time", "Temps", "Zeit")}
                  name="meetingTime"
                  value={values.meetingTime}
                  onChange={(value) => {
                    const theDate = getDateIgnoreTimezone(value)
                    console.log("akjldljkashdjasd",theDate)
                    setFieldValue("meetingTime", theDate);
                  }}
                  KeyboardButtonProps={{
                    "aria-label": "Meeting Time",
                  }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  required
                  error={touched.meetingTime && Boolean(errors.meetingTime)}
                />
              </Grid>
              
              <Box
                mt={5}
                pr={1}
                pb={1}
                width="100%"
                display="flex"
                alignItems="center"
                justifyContent="center"
              >
                <ButtonWithLoading
                  title={getTranslation(
                    "Update",
                    "Mettre à jour",
                    "Aktualisieren"
                  )}
                  size="small"
                  variant="contained"
                  color="primary"
                  loading={isSubmitting}
                  onClick={handleSubmit}
                />
              </Box>
            </Grid>
          </>
        )}
      </Formik>
    </FormLoader>
  );
};
