import { Button, ButtonGroup } from "@material-ui/core";
import { getTranslation } from "../../../../heplers/translationHelper";

export const TagButtons = ({appendTemplate,classes,values,setFieldValue}) => {
    return <ButtonGroup
        className={classes.responsiveButtonGroup}
        style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-start",
            margin: "5px",
        }}
    >
        <Button
            className={classes.fixedButton}
            size="medium"
            style={{
                marginRight: "5px",
                border: "1px solid",
            }}
            onClick={() => {
                appendTemplate(
                    values.template,
                    "<span>[Meeting_Title]</span>",
                    setFieldValue
                );
                // <HtmlEditor forms={values.template} text={"<span>[tenant_name]</span>"}/>
            }}
        >
            {getTranslation(
                "Meeting Title",
                "Titre de la réunion",
                "Besprechungstitel"
            )}
        </Button>
        <Button
            className={classes.fixedButton}
            size="medium"
            style={{
                marginRight: "5px",
                border: "1px solid",
            }}
            onClick={() =>
                appendTemplate(
                    values.template,
                    "<span>[Meeting_Address]</span>",
                    setFieldValue
                )
            }
        >
            {getTranslation(
                "Meeting Address",
                "Adresse de réunion",
                "Besprechungsadresse"
            )}
        </Button>
        <Button
            className={classes.fixedButton}
            size="medium"
            style={{
                marginRight: "5px",
                border: "1px solid",
            }}
            onClick={() =>
                appendTemplate(
                    values.template,
                    "<span>[Meeting_DateTime]</span>",
                    setFieldValue
                )
            }
        >
            {getTranslation(
                "Meeting DateTime",
                "DateHeureRéunion",
                "MeetingDateTime"
            )}
        </Button>
        <Button
            className={classes.fixedButton}
            size="medium"
            style={{
                marginRight: "5px",
                border: "1px solid",
            }}
            onClick={() =>
                appendTemplate(
                    values.template,
                    "<span>[Meeting_Type_]</span>",
                    setFieldValue
                )
            }
        >
            {getTranslation(
                "Meeting Type",
                "Type de réunion",
                "Besprechungstyp"
            )}
        </Button>
        <Button
            className={classes.fixedButton}
            size="medium"
            style={{
                marginRight: "5px",
                border: "1px solid",
            }}
            onClick={() =>
                appendTemplate(
                    values.template,
                    "<span>[Meeting_Language]</span>",
                    setFieldValue
                )
            }
        >
            {getTranslation(
                "Meeting Language ",
                "RéunionLangue",
                "Besprechungssprache"
            )}
        </Button>
        <Button
            className={classes.fixedButton}
            size="medium"
            style={{
                marginRight: "5px",
                border: "1px solid",
            }}
            onClick={() =>
                appendTemplate(
                    values.template,
                    "<span>[Meeting_Person_Incharge_Name]</span>",
                    setFieldValue
                )
            }
        >
            {getTranslation(
                "Meeting Person InchargeName",
                "Meeting Person InChargeName",
                "Meeting Person InchargeName"
            )}{" "}
            :
        </Button>
        <Button
            className={classes.fixedButton}
            size="medium"
            style={{
                marginRight: "5px",
                border: "1px solid",
            }}
            onClick={() =>
                appendTemplate(
                    values.template,
                    "<span>[Company_Name]</span>",
                    setFieldValue
                )
            }
        >
            {getTranslation(
                "Company Name",
                "Nom de l'entreprise",
                "Name der Firma"
            )}
        </Button>
        <Button
            className={classes.fixedButton}
            size="medium"
            style={{
                marginRight: "5px",
                border: "1px solid",
            }}
            onClick={() =>
                appendTemplate(
                    values.template,
                    "<span>[Company_Reference_Code]</span>",
                    setFieldValue
                )
            }
        >
            {getTranslation(
                "Company Reference Code",
                "Company Reference Code",
                "Company Reference Code"
            )}
        </Button>
        <Button
            className={classes.fixedButton}
            size="medium"
            style={{
                marginRight: "5px",
                border: "1px solid",
            }}
            onClick={() =>
                appendTemplate(
                    values.template,
                    "<span>[Company_RCS_Number]</span>",
                    setFieldValue
                )
            }
        >
            {getTranslation(
                "Company RCS Number ",
                "Société RCS Numéro",
                "Company RCS Number"
            )}
        </Button>
        <Button
            className={classes.fixedButton}
            size="medium"
            style={{
                marginRight: "5px",
                border: "1px solid",
            }}
            onClick={() =>
                appendTemplate(
                    values.template,
                    "<span>[Company_Incorporation_Date]</span>",
                    setFieldValue
                )
            }
        >
            {getTranslation(
                "Company Incorporation Date",
                "Date de constitution de la société",
                "Firmengründungsdatum"
            )}
        </Button>
        <Button
            className={classes.fixedButton}
            size="medium"
            style={{
                marginRight: "5px",
                border: "1px solid",
            }}
            onClick={() =>
                appendTemplate(
                    values.template,
                    "<span>[Company_Street]</span>",
                    setFieldValue
                )
            }
        >
            {getTranslation(
                "Company Street",
                "Entreprise Rue",
                "Firmenstraße"
            )}
        </Button>

        <Button
            className={classes.fixedButton}
            size="medium"
            style={{
                marginRight: "5px",
                border: "1px solid",
            }}
            onClick={() =>
                appendTemplate(
                    values.template,
                    "<span>[Company_City]</span>",
                    setFieldValue
                )
            }
        >
            {getTranslation(
                "Company City",
                "Entreprise Ville",
                "UnternehmenStadt"
            )}
        </Button>

        <Button
            className={classes.fixedButton}
            size="medium"
            style={{
                marginRight: "5px",
                border: "1px solid",
            }}
            onClick={() =>
                appendTemplate(
                    values.template,
                    "<span>[Company_ZipCode]</span>",
                    setFieldValue
                )
            }
        >
            {getTranslation(
                "Company ZipCode",
                "Code postal de la société",
                "FirmaPostleitzahl"
            )}
        </Button>

        <Button
            className={classes.fixedButton}
            size="medium"
            style={{
                marginRight: "5px",
                border: "1px solid",
            }}
            onClick={() =>
                appendTemplate(
                    values.template,
                    "<span>[Company_Country]</span>",
                    setFieldValue
                )
            }
        >
            {getTranslation(
                " Company Country ",
                "SociétéPays",
                "UnternehmenLand"
            )}
        </Button>

        <Button
            className={classes.fixedButton}
            size="medium"
            style={{
                marginRight: "5px",
                border: "1px solid",
            }}
            onClick={() =>
                appendTemplate(
                    values.template,
                    "<span>[Company_RBE]</span>",
                    setFieldValue
                )
            }
        >
            {getTranslation(
                "Company RBE ",
                "Société RBE",
                "Unternehmen RBE"
            )}
        </Button>

        <Button
            className={classes.fixedButton}
            size="medium"
            style={{
                marginRight: "5px",
                border: "1px solid",
            }}
            onClick={() =>
                appendTemplate(
                    values.template,
                    "<span>[Company_LegalForm]</span>",
                    setFieldValue
                )
            }
        >
            {getTranslation(
                "Company Legal Form",
                "SociétéFormulaire Juridique",
                "CompanyLegalForm"
            )}
        </Button>

        <Button
            className={classes.fixedButton}
            size="medium"
            style={{
                marginRight: "5px",
                border: "1px solid",
            }}
            onClick={() =>
                appendTemplate(
                    values.template,
                    "<span>[Contact_Name]</span>",
                    setFieldValue
                )
            }
        >
            {getTranslation(
                "Contact Name",
                "Nom du contact",
                "Kontaktname"
            )}
        </Button>

        <Button
            className={classes.fixedButton}
            size="medium"
            style={{
                marginRight: "5px",
                border: "1px solid",
            }}
            onClick={() =>
                appendTemplate(
                    values.template,
                    "<span>[Contact_Email]</span>",
                    setFieldValue
                )
            }
        >
            {getTranslation(
                "Contact Email ",
                "Email du contact",
                "Kontakt E-mail"
            )}
        </Button>

        <Button
            className={classes.fixedButton}
            size="medium"
            style={{
                marginRight: "5px",
                border: "1px solid",
            }}
            onClick={() =>
                appendTemplate(
                    values.template,
                    "<span>[Contact_Phone]</span>",
                    setFieldValue
                )
            }
        >
            {getTranslation(
                " Contact Phone ",
                "Numéro du contact",
                "Kontakt-Telefon"
            )}
        </Button>

        <Button
            className={classes.fixedButton}
            size="medium"
            style={{
                marginRight: "5px",
                border: "1px solid",
            }}
            onClick={() =>
                appendTemplate(
                    values.template,
                    "<span>[Contact_City]</span>",
                    setFieldValue
                )
            }
        >
            {getTranslation(
                "Contact City",
                "Contact Ville",
                "KontaktStadt"
            )}
        </Button>

        <Button
            className={classes.fixedButton}
            size="medium"
            style={{
                marginRight: "5px",
                border: "1px solid",
            }}
            onClick={() =>
                appendTemplate(
                    values.template,
                    "<span>[Contact_Mail]</span>",
                    setFieldValue
                )
            }
        >
            {getTranslation(
                "Contact Mail ",
                "Contact Mail",
                "Kontakt Mail"
            )}
        </Button>

        <Button
            className={classes.fixedButton}
            size="medium"
            style={{
                marginRight: "5px",
                border: "1px solid",
            }}
            onClick={() =>
                appendTemplate(
                    values.template,
                    "<span>[Contact_Address]</span>",
                    setFieldValue
                )
            }
        >
            {getTranslation(
                "Contact Address ",
                "Adresse de contact",
                "Kontakt Adresse"
            )}
        </Button>
        <Button
        className={classes.fixedButton}
        size="medium"
        style={{
          marginRight: "5px",
          border: "1px solid",
        }}
        onClick={() =>
          appendTemplate(
            values.template,
            "<span>[Submit_URL]</span>",
            setFieldValue
          )
        }
      >
        {getTranslation("Submit Button")}
      </Button>
    </ButtonGroup>
}