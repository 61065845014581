import React, { useEffect, useState } from "react";
import {
  Card,
  CardHeader,
  CardActions,
  CardContent,
  TextField,
  Box,
  Typography,
  Grid,
} from "@material-ui/core";
import ButtonWithLoading from "../../../components/ButtonWithLoading";
import {
  Get_SingleCircularResolution_URL,
  Post_UpdateSponserComment_URL,
  Post_UpdateStatusBySponser_URL,
} from "../../../constants/apiUrls";
import { Create, Get } from "../../../actions";
import { useSnackbar } from "notistack";
import { useNavigate, useParams } from "react-router";
import { CircularResolutionInfoBox } from "./circularResolutionInfoBox";
import { getTranslation } from "../../../heplers/translationHelper";
export const CircularResolutionConfirmation = () => {
  const { circularResolutionId } = useParams();
  const { enqueueSnackbar } = useSnackbar();

  ////////////////////// Post Update Comment //////////////////
  // missing Sponser Id
  const [comments, setComments] = useState("");
  const [submitComments, setSubmitComments] = useState(false);
  const [submitForApprove, setSubmitForApprove] = useState(false);
  const [submited, setSubmited] = useState(false);

  const handleUpdateComment = () => {
    setSubmitComments(true);
    Create(
      {
        CrId: circularResolutionId,
        comments: comments,
      },
      Post_UpdateSponserComment_URL,
      null,
      (resp) => {
        enqueueSnackbar("Comments updated", { variant: "success" });
        setSubmitComments(false);
        setSubmited(true);
      },
      (error) => {
        enqueueSnackbar(error?.data, { variant: "error" });
        setSubmitComments(false);
      }
    );
  };
  ///////////////// approve button ////////////////
  const handleStatus = () => {
    setSubmitForApprove(true);
    Create(
      {
        CrId: circularResolutionId,
        sponsorId: 2,
      },
      Post_UpdateStatusBySponser_URL,
      null,
      (resp) => {
        enqueueSnackbar("Approved", { variant: "success" });
        setSubmitForApprove(false);
        setSubmited(true);
      },
      (error) => {
        enqueueSnackbar(error?.data, { variant: "error" });
        setSubmitForApprove(false);
      }
    );
  };
  const [circularResolution, setCircularResolution] = useState({});
  const history = useNavigate();
  useEffect(() => {
    loadCircularResolution({ id: circularResolutionId });
  }, [circularResolutionId]);
  const loadCircularResolution = (values) => {
    Get(
      values,
      Get_SingleCircularResolution_URL,
      null,
      (resp) => {
        resp?.data?.alreadyCommented || resp?.data?.status
          ? setSubmited(true)
          : setCircularResolution(resp?.data);
      },
      (error) => {
        enqueueSnackbar(error?.data, { variant: "warning" });
      }
    );
  };

  const redirectUserToNotFound = () => {
    history("notfound");
  };
  return circularResolution?.status ? (
    redirectUserToNotFound()
  ) : submited ? (
    <Grid
      container
      spacing={0}
      direction="column"
      alignItems="center"
      justify="center"
      style={{ minHeight: "100vh" }}
    >
      <Grid item xs={3}>
        <Typography fontSize="24px">Thanks for your feedback.</Typography>
      </Grid>
    </Grid>
  ) : (
    <div>
      <CircularResolutionInfoBox
        circularResolution={circularResolution}
        isApproved={circularResolution?.status}
        circularResolutionId={circularResolutionId}
      />

      <Card>
        <CardHeader
          title={getTranslation(
            "Do you want to make some change in the Resolution ?",
            "Souhaitez-vous apporter des modifications à la résolution ?",
            "Möchten Sie etwas an der Auflösung ändern?"
          )}
        />
        <CardContent>
          <TextField
            id="comments"
            fullWidth
            label={getTranslation("Comments", "commentaires", "Bemerkungen")}
            required
            size="large"
            variant="outlined"
            name="comments"
            onChange={(e) => {
              setComments(e.target.value);
            }}
          />
        </CardContent>
        <CardActions>
          <Box
            pr={1}
            pb={1}
            width="100%"
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            <ButtonWithLoading
              loading={submitComments}
              title={getTranslation("Update", "Mettre à jour", "Aktualisieren")}
              size="large"
              variant="contained"
              color="primary"
              onClick={handleUpdateComment}
            />
          </Box>
        </CardActions>
      </Card>
      <br />
      <Card>
        <CardHeader
          title={getTranslation(
            "Don't you want to make modifications?",
            "Vous ne souhaitez pas apporter de modifications ?",
            "Möchten Sie keine Änderungen vornehmen?"
          )}
        />
        <CardActions>
          <Box
            width="100%"
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            <ButtonWithLoading
              loading={submitForApprove}
              title={getTranslation("Approve", "Approuver", "Genehmigen")}
              size="large"
              variant="contained"
              color="primary"
              onClick={handleStatus}
            />
          </Box>
        </CardActions>
      </Card>
      <br />
      <br />
      <br />
      <br />
    </div>
  );
};
