import React, { useState, useEffect } from "react";
import { Box, Paper, TextField } from "@material-ui/core";
import Table from "../../components/table";
import { GetAllContacts, DeleteContact, CreateNewContact, Create } from "../../actions";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useSnackbar } from "notistack";
import checkResponse from "../../heplers/responseHelper";
import DeleteDialog from "../../components/DeleteDialog";
import { CreateContact } from "./create";
import { getTranslation } from "../../heplers/translationHelper";
import { ContactSubmenu } from "../../layout/subMenu/meeting";
import { DELETE_Contact_URL } from "../../constants/apiUrls";
const columns = [
  {
    id: "firstName",
    numeric: false,
    disablePadding: true,
    label:getTranslation("First Name","Prénom","Vorname"),
  },
  {
    id: "name",
    numeric: false,
    disablePadding: true,
    label:getTranslation("Last Name","Nom de famille","Familienname, Nachname"),
  },
  {
    id: "address",
    numeric: false,
    disablePadding: true,
    label:getTranslation("Address","Adresse","Adresse"),
  },
  {
    id: "zipCode",
    numeric: false,
    disablePadding: false,
    align: "left",
    label:getTranslation("Zip","Code postal","Postleitzahl"),
  },
  {
    id: "city",
    numeric: false,
    disablePadding: false,
    align: "left",
    label:getTranslation("City","Ville","Stadt"),
  },
  {
    id: "phone1",
    numeric: false,
    disablePadding: false,
    align: "left",
    label:getTranslation("Phone","Téléphoner","Telefon"),
  },
  {
    id: "mail",
    numeric: false,
    disablePadding: false,
    align: "left",
    label:getTranslation("Mail","Courrier","Post"),
  },
  {
    id: "actions",
    numeric: true,
    disablePadding: false,
    align: "right",
    label:getTranslation("Actions","Actions","Aktionen"),
  },
];

const ListContacts = () => {
  const history = useNavigate();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const [open, setOpen] = useState(false);
  const [deleteRow, setDeleteRow] = useState({});
  const [searchString, setSearchString] = useState("");
  const [page, setPage] = useState(0);
  const [createContact, setCreateContact] = useState(false);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [rows, setRows] = useState([]);
  const [isDeleting, setIsDeleting] = useState(false);
  const { contacts, loading } = useSelector((state) => state.contacts);

  useEffect(() => {
    dispatch({ type: "Clear_All_BreadCrumb" });
    dispatch({
      type: "Add_BreadCrumb",
      payload: { title: "Contacts", url: "/contacts" },
    });
  }, []);
  //endpoint data loading (commented by shakir for self understanding)
  useEffect(() => {
    dispatch({
      type: "GET_AllContacts",
      payload: null,
    });
    dispatch(
      GetAllContacts(
        {
          searchString: searchString,
          pageNumber: page,
          pageSize: rowsPerPage,
        },
        history
      )
    );
  }, [page, rowsPerPage, searchString]);

  const handleOpenDeleteDialog = (row) => {
    setDeleteRow(row);
    setOpen(true);
  };

  const handleDeleteActivity = (row) => {
    setIsDeleting(true);
    Create(
      {ids:[row.id]},
      DELETE_Contact_URL,
      null,
      resp=>{
        setIsDeleting(false);
        enqueueSnackbar("Contact deleted successfully", { variant: "success" });
        setOpen(false);
        GetAllContacts({
          searchString: searchString,
          pageNumber: page,
          pageSize: rowsPerPage,

        })
      },
      error=>{
        setIsDeleting(false);
        setOpen(false);
        enqueueSnackbar("Can't delete this contact as it is being used by some comapny", { variant: "error" });
      }
    )
    // dispatch(
    //   DeleteContact(
    //     {
    //       ids: [row.id],
    //     },
    //     history
    //   )
    // ).then((response) => {
    //   const errors = checkResponse(response);
    //   if (!errors) {
    //     enqueueSnackbar(getTranslation("Contact deleted","Contact supprimé","Kontakt gelöscht"), { variant: "success" });
    //     setOpen(false);

    //     dispatch(
    //       GetAllContacts({
    //         searchString: searchString,
    //         pageNumber: page,
    //         pageSize: rowsPerPage,
    //       })
    //     );
    //   } else {
    //     errors.map((error) => enqueueSnackbar(error.message, { variant: "error" }));
    //   }
    // });
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value));
    setPage(0);
  };
  const handleDetailCompany = (row) => {
    const url = `/contacts/${row.id}`;
    history(url);
    dispatch({
      type: "Add_BreadCrumb",
      payload: { title: row.firstName + " " + row.name, url: url },
    });
  };
  const handleSearchPerson = (searchString) => {
    setSearchString(searchString);
  };

  const handleCreateContact = (event) => {
    setCreateContact(true);
  };
  const handleCloseContact = (event) => {
    setCreateContact(false);
  };

  const handleCreateSubmitContact = (values, actions) => {
    dispatch(CreateNewContact(values, history)).then((response) => {
      const errors = checkResponse(response);
      if (!errors) {
        enqueueSnackbar(getTranslation("Contact Created","Contact créé","Kontakt erstellt"), { variant: "success" });

        dispatch(
          GetAllContacts({
            searchString: searchString,
            pageNumber: page,
            pageSize: rowsPerPage,
          })
        );
        setCreateContact(false);
      } else {
        // let error="";
        // errors.map((err) =>
        // error+=err.message
        // );
        // enqueueSnackbar(getTranslation(error, { variant: "error" })
      }
      actions.setSubmitting(false);
    });
  };

  return (
    <div>
      <Table
        dense
        loading={loading}
        title={getTranslation("Contacts","Contacts","Kontakte")}
        colums={columns}
        sortBy="firstName"
        rows={contacts?.data}
        count={contacts?.totalCount}
        page={page}
        rowsPerPage={rowsPerPage}
        handleChangePage={(event, newPage) => handleChangePage(event, newPage)}
        handleChangeRowsPerPage={(e) => handleChangeRowsPerPage(e)}
        viewEdit
        onEdit={(row) => handleDetailCompany(row)}
        viewDelete
        onDelete={(row) => handleOpenDeleteDialog(row)}
        viewSearch
        onSearch={(searchString) => handleSearchPerson(searchString)}
        viewCreate
        onCreate={() => handleCreateContact()}
      />
      <DeleteDialog
        open={open}
        title={getTranslation("Delete Person","Supprimer la personne","Person löschen")}
        onClose={() => setOpen(false)}
        onSubmit={(e) => handleDeleteActivity(deleteRow)}
        deleting={isDeleting}
      />
      <CreateContact
        isOpen={createContact}
        onClose={handleCloseContact}
        onSubmit={handleCreateSubmitContact}
      />
    </div>
  );
};

export default ListContacts;
