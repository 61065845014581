import { useSnackbar } from "notistack";
import { useEffect, useState } from "react";
import { useParams } from "react-router";
import { Create, Get } from "../../actions";
import { UploadFiles } from "../../components/Upload";
import { Get_MeetingInfo_URL, Post_UploadMeetingDocument_URL } from "../../constants/apiUrls";
import MeetingInformation from "./components/MeetingInformation";

export const AskForDocument = () => {
  const { meetingId, meetingDocumentId } = useParams();

  /////////////////////////////////////       States        /////////////////////////

  const [meetingInfo, setMeetingInfo] = useState();
  const [fileList, setFileList] = useState([]);

  /////////////////////////////////////////////////// Get Meeting Information  /////////////////////////////

  useEffect(() => {
    loadMeetingInfo({
      meetingId: meetingId,
    });
  }, [meetingId]);
  const loadMeetingInfo = (values) => {
    Get(
      values,
      Get_MeetingInfo_URL,
      null,
      (resp) => {
        setMeetingInfo(resp?.data);
      },
      (error) => { }
    );
  };

  /////////////////////////////////////////////////// Get Meeting Ask for document Information  /////////////////////////////
  // useEffect(() => {
  //   loadAskForDocumentInfo({
  //     askForDocumentId: meetingDocumentId,
  //   });
  // }, [meetingDocumentId]);

  // const loadAskForDocumentInfo = (values) => {
  //   Get(
  //     values,
  //     Get_MeetingInfo_URL,
  //     null,
  //     (resp) => {
  //       setMeetingInfo(resp?.data);
  //     },
  //     (error) => {}
  //   );
  // };
  const {enqueueSnackbar} =useSnackbar()
  const handleAddNewFile = (file) => {
    Create(
      {
        fileName: file,
        meetingId: meetingId,
        meetingDocumentId: meetingDocumentId
      },
      Post_UploadMeetingDocument_URL,
      null,
      resp=>{
        enqueueSnackbar("New file uploaded.",{variant:"success"})
      },
      error=>{
        enqueueSnackbar("System Upload error.",{variant:"error"})
      }
    )
  }
  return (
    <>
      <MeetingInformation meetingId={meetingId} meetingInfo={meetingInfo} />
      <br />
      <UploadFiles
        onAddFile={(file) => {
          handleAddNewFile(file)
        }}
        getFileName={(file) => file.fileName}
      />
    </>
  );
};
