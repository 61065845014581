import Table from "../../../../../../components/table";
import EmailOutlinedIcon from "@material-ui/icons/EmailOutlined";
import { IconButton } from "@material-ui/core";
import EditOutlinedIcon from "@material-ui/icons/EditOutlined";
import { SelectContactDialog } from "../../components/regularMeeting";
import { useEffect, useState } from "react";
import NotificationDialog from "./notification";
import { useParams } from "react-router";
import { Create, Get } from "../../../../../../actions";
import {
  Get_MeetingReportUsers_URL,
  Get_ReportingUser_URL,
  Post_ChangeReportingUser_URL,
  Post_SendNotification_URL,
} from "../../../../../../constants/apiUrls";
import { useSnackbar } from "notistack";

const columns = [
  {
    id: "reportingTitle",
    disablePadding: false,
    numeric: false,
    label: "Title",
  },
  {
    id: "name",
    disablePadding: false,
    numeric: false,
    label: "Name",
  },
  {
    id: "email",
    disablePadding: false,
    numeric: false,
    label: "E-Mail",
  },
  {
    id: "actions",
    disablePadding: false,
    numeric: false,
    align: "right",
    label: "Actions",
  },
];

const UpdateReportTable = () => {
  const { meetingId } = useParams();
  const [openContactDialog, setOpenContactDialog] = useState(false);
  const [openNotificationDialog, setOpenNotificationDialog] = useState(false);
  const [reportingUserList, setReportingUserList] = useState([]);
  const [reportingUserLoading, setReportingUserLoading] = useState(false);
  const [updateRow, setUpdateRow] = useState(null);
  const { enqueueSnackbar } = useSnackbar();
  console.log("jkxhgfsfdgdfsg", meetingId);
  useEffect(() => {
    loadReportingUsers();
  }, [meetingId]);

  const loadReportingUsers = () => {
    setReportingUserLoading(true);
    Get(
      { meetingId: meetingId },
      Get_MeetingReportUsers_URL,
      null,
      (resp) => {
        setReportingUserLoading(false);
        setReportingUserList(resp?.data);
      },
      (error) => {
        setReportingUserLoading(false);
        console.log(error);
      }
    );
  };

  const handleUpdateReportingUser = (values) => {
    console.log("ihsgdfisdfsdf", values);
    var payload;
    if (values.userType === "contacts") {
      payload = {
        id: updateRow.id,
        contactId: values.reportingUser.id,
        isContact: true,
      };
    } else {
      payload = {
        id: updateRow.id,
        ceresMemberId: values.reportingUser.id,
        isContact: false,
      };
    }

    Create(
      payload,
      Post_ChangeReportingUser_URL,
      null,
      (resp) => {
        var temp = reportingUserList;
        var index = temp.findIndex((x) => x.id === updateRow.id);
        temp[index] = resp.data;
        setReportingUserList(temp);
        setOpenContactDialog(false);
      },
      (error) => {
        setOpenContactDialog(false);
        console.log("ihsgdfisdfsdf", error);
      }
    );
  };

  const handleSendNotifications = (values, actions) => {
    console.log("jksdgfsdfsdf", values, updateRow);
    Create(
      {
        id: updateRow?.id,
        notification: values.message,
      },
      Post_SendNotification_URL,
      null,
      (resp) => {
        setOpenNotificationDialog(false);
        enqueueSnackbar(resp.data, {
          variant: "success",
        });
      },
      (error) => {
        setOpenNotificationDialog(false);
        enqueueSnackbar("Notification not sent. Please try again later", {
          variant: "error",
        });
      }
    );
  };

  return (
    <>
      <Table
        dense
        loading={reportingUserLoading}
        colums={columns}
        sortBy="title"
        rows={reportingUserList}
        count={reportingUserList.length}
        page={0}
        rowsPerPage={10}
        viewSearch
        onSearch={() => {}}
        actions={[
          {
            component: (rowData) => (
              <IconButton
                onClick={() => {
                  setUpdateRow(rowData);
                  setOpenNotificationDialog(true);
                }}
              >
                <EmailOutlinedIcon fontSize="medium" />
              </IconButton>
            ),
          },
          {
            component: (rowData) => (
              <IconButton
                onClick={() => {
                  setUpdateRow(rowData);
                  setOpenContactDialog(true);
                }}
              >
                <EditOutlinedIcon fontSize="medium" color="red" />
              </IconButton>
            ),
          },
        ]}
      />
      <SelectContactDialog
        open={openContactDialog}
        onClose={() => setOpenContactDialog(false)}
        onSubmit={(values) => handleUpdateReportingUser(values)}
      />
      <NotificationDialog
        open={openNotificationDialog}
        onClose={() => setOpenNotificationDialog(false)}
        onSubmit={(values, actions) => handleSendNotifications(values, actions)}
      />
    </>
  );
};
export default UpdateReportTable;
