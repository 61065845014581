import React from "react";
import clsx from "clsx";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import { HtmlEditor } from "../../../../components/HtmlEditor";
import { useEffect } from "react";
import { Get } from "../../../../actions";
import {
  Get_AllSections_URL,
  Post_UpsertReport_URL,
} from "../../../../constants/apiUrls";
import SelectSection from "./selectSection";
import { useState } from "react";
import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Dialog,
  Slide,
  TextField,
} from "@material-ui/core";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import ButtonWithLoading from "../../../../components/ButtonWithLoading";
import { useSnackbar } from "notistack";
import CloseIcon from "@material-ui/icons/Close";

const drawerWidth = 500;

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  appBar: {
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  title: {
    flexGrow: 1,
  },

}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const ReportTemplateList = ({
  editReportTemplate,
  open,
  onClose,
  onSubmit,
}) => {
  const classes = useStyles();
  const theme = useTheme();
  const [openDrawer, setOpenDrawer] = useState(true);
  const [isSectionLoading, setIsSectionLoading] = useState(true);

  const [sections, setSections] = useState([]);
  //const [htmlCode, setHtmlCode] = useState(!editReportTemplate ? `<p>&nbsp;</p>` : editReportTemplate.reportHtml);
  const { enqueueSnackbar } = useSnackbar();

  const handleDrawer = () => {
    setOpenDrawer(!openDrawer);
  };

  ////////// Load Sections //////////
  useEffect(() => {
    loadSection();
  }, []);
  const loadSection = () => {
    setIsSectionLoading(true)
    Get(
      {},
      Get_AllSections_URL,
      null,
      (resp) => {
        setSections(resp.data);
        setIsSectionLoading(false)
      },
      (error) => {
        console.log(error);
        setIsSectionLoading(false)
      }
    );
  };


  /////////////// Submit Report ///////////////

  const initialValues = {
    id: 0,
    title: "",
    reportHtml: "",
  };
  const basicValidationSchema = Yup.object().shape({
    title: Yup.string().required("Title is required"),
    reportHtml: Yup.string().required("Report HTML is required"),
  });
  const defaultValues = editReportTemplate ? editReportTemplate : initialValues;
  return (
    <Dialog
      fullScreen
      open={open}
      onClose={onClose}
      TransitionComponent={Transition}
    >
      <Formik
        initialValues={defaultValues}
        validationSchema={basicValidationSchema}
        onSubmit={(values, actions) => {
          onSubmit(values, actions);
        }}
      >
        {({
          values,
          errors,
          handleChange,
          isSubmitting,
          handleSubmit,
          getFieldProps,
          setFieldValue,
        }) => (
          <Form>
            <div className={classes.root}>
              <AppBar
                position="fixed"
                className={classes.appBar}
              >
                <Toolbar>
                  <IconButton edge="start" color="inherit" onClick={onClose} aria-label="close">
                    <CloseIcon />
                  </IconButton>
                  <Typography variant="h6" className={classes.title}>
                    {editReportTemplate ? "Edit Template" : "Add Template"}
                  </Typography>
                </Toolbar>
              </AppBar>
              <main
                className={clsx(classes.content, {
                  [classes.contentShift]: openDrawer,
                })}
              >
                <div className={classes.drawerHeader} />
                <Card>
                  <CardHeader
                    title={
                      editReportTemplate
                        ? "Update Report Template"
                        : "Generate Report"
                    }
                  />
                  <CardContent>
                  <TextField
                      id="outlined-basic"
                      label="Subject"
                      size="small"
                      {...getFieldProps("title")}
                      variant="outlined"
                      fullWidth
                    />
                    <HtmlEditor
                      text={values.reportHtml}
                      onChange={(html) => {
                        setFieldValue("reportHtml", html);
                        handleSubmit();
                      }}
                      isLoading={isSectionLoading}
                      sections={sections}
                    />
                  </CardContent>
                </Card>
              </main>
             
            </div>
          </Form>
        )}
      </Formik>
    </Dialog>
  );
};
export default ReportTemplateList;
