import { useParams } from "react-router"
import MeetingInformation from "../public/components/MeetingInformation"
import { useEffect } from "react";
import { Create, Get } from "../../actions";
import { Get_AllMeetingDocuments_URL, Get_CompanyInfo_URL, Get_Files_URL, Get_MeetingInfo_URL, Post_MeetingAcceptMinutes_URL, Post_UploadMeetingDocument_URL } from "../../constants/apiUrls";
import { useState } from "react";
import { Box, CardContent, CardHeader, Typography } from "@material-ui/core";
import { getTranslation } from "../../heplers/translationHelper";
import CardComponent from "../../components/Card";
import { CompanyInformation } from "../public/components";
import { UploadFiles } from "../../components/Upload";
import { ManageAgendas } from "./component/manageAgendas";
import { MeetingsSubmenu } from "../../layout/subMenu";
import ButtonWithLoading from "../../components/ButtonWithLoading";
import { Save } from "@material-ui/icons";
import { useSnackbar } from "notistack";

export const GenerateMinutesReport = () => {
    const { meetingId, managementCompanyId } = useParams();
    const [meetingInfo, setMeetingInfo] = useState({});
    const [companyInfo, setCompanyInfo] = useState({});
    const [meetingDocuments, setMeetingDocuments] = useState([]);
    const [submitingForReview, setSubmitingForReview] = useState(false);
    const {enqueueSnackbar}=useSnackbar()

    useEffect(() => {
        Get(
            {
                meetingId: meetingId,
            },
            Get_MeetingInfo_URL,
            null,
            (resp) => {
                if(resp?.data?.minuteReportStatus>1){
                    enqueueSnackbar("Minutes report cannot be modified now as its submited for review.",{variant:"info"})

                }
                setMeetingInfo(resp?.data);
            },
            (error) => { }
        );

        Get(
            {
                meetingId: meetingId,
            },
            Get_CompanyInfo_URL,
            null,
            (resp) => {
                setCompanyInfo(resp?.data);
            },
            (error) => { }
        );
        Get(
            {
                meetingId: meetingId,
            },
            Get_Files_URL,
            null,
            (resp) => {
                setMeetingDocuments(resp?.data);
            },
            (error) => { }
        );
    }, [meetingId])

    const handleSubmitForReview=()=>{
        setSubmitingForReview(true)
        Create(
            {
                meetingId:meetingId,
                minuteReportStatus:1
            },
            Post_MeetingAcceptMinutes_URL,
            null,
            resp=>{
                enqueueSnackbar("Minutes report submited for internal review.",{variant:"success"})
                setSubmitingForReview(false)

            },
            error=>{
                enqueueSnackbar(error?.data,{variant:"error"})
                setSubmitingForReview(false)

            }
        )
    }



    return <CardComponent style={{ paddingTop: "30px", paddingLeft: "20px", paddingRight: "20px", paddingBottom: "30px" }}>
        <MeetingsSubmenu managementCompanyId={managementCompanyId}/>
        <Typography variant="h4" align="center">{getTranslation("Minutes Report")}</Typography>
        <br />
        <br />
        <Box display="flex" alignItems={"flex-end"} justifyContent="flex-end">
        <ButtonWithLoading
              title={
                <>
                  <Typography>Submit for internal review</Typography>
                </>
              }
              variant="contained"
              color="primary"
              loading={submitingForReview}
              onClick={() => handleSubmitForReview()}
              disabled={meetingInfo?.minuteReportStatus>1}
            />
        </Box>
        <br />
        <br />
        <MeetingInformation meetingInfo={meetingInfo} />
        <br />
        <br />
        <CompanyInformation companyInfo={companyInfo} />
        <br />
        <br />
        <CardComponent>
            <CardHeader
                title={getTranslation(
                    "Uploaded Documents",
                    "Uploaded Documents",
                    "Uploaded Documents"
                )}
            />
            <CardContent>
                <UploadFiles
                    uploadedFiles={meetingDocuments}

                    getFileName={(file) => {
                        return file.fileName;
                    }}
                    onAddFile={(file) => {
                        console.log(file);
                    }}
                    disabled
                />
            </CardContent>
        </CardComponent>
        <br />
        <br />
        <ManageAgendas meetingId={meetingId} disabled={meetingInfo?.minuteReportStatus>1}/>
    </CardComponent>


}