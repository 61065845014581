import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useSnackbar } from "notistack";
import { Get, Create } from "../../../../actions";
import Table from "../../../../components/table";
import { CreateService } from "./component";
import {
  Get_AllCompanyServicesWithPagination_URL,
  Post_ExternalServiceProvider_URL,
  Post_UpdateExternalServiceProvider_URL,
  Post_DeleteCompanyExternalServiceProvider_URL,
} from "../../../../constants/apiUrls";
import DeleteDialog from "../../../../components/DeleteDialog";
import { getTranslation } from "../../../../heplers/translationHelper";

const columns = [
  {
    id: "title",
    numeric: false,
    disablePadding: true,
    label: getTranslation(
      "Service Provider",
      "Les fournisseurs de services",
      "Dienstleister"
    ),
  },
  {
    id: "serviceTypeTitle",
    numeric: false,
    disablePadding: true,
    label: getTranslation("Type", "Taper", "Art"),
  },
  {
    id: "name",
    numeric: false,
    disablePadding: true,
    label: getTranslation("Representative", "Titre", "Titel"),
  },
  {
    id: "representativeRole",
    numeric: false,
    disablePadding: true,
    label: getTranslation(
      "Representative Role",
      "Rôle de représentant",
      "Repräsentative Rolle"
    ),
  },

  {
    id: "actions",
    numeric: true,
    disablePadding: false,
    align: "right",
    label: getTranslation("Actions", "Actions", "Aktionen"),
  },
];

const ManageService = () => {
  const history = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const { companyId } = useParams();
  const [open, setOpen] = useState(false);
  const [deleteRow, setDeleteRow] = useState({});
  const [searchString, setSearchString] = useState("");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [rows, setRows] = useState([]);
  const [loadingCeresServices, setloadingCeresServices] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [editExternalServiceProvider, setEditExternalServiceProvider] =
    useState(null);

  useEffect(() => {
    loadCeresServices();
  }, [page, rowsPerPage, searchString]);

  const loadCeresServices = () => {
    setloadingCeresServices(true);
    Get(
      {
        companyId: companyId,
        searchString,
        pageNumber: page,
        pageSize: rowsPerPage,
      },
      Get_AllCompanyServicesWithPagination_URL,
      history,
      (resp) => {
        setRows(resp.data);
        setloadingCeresServices(false);
      },
      (error) => {}
    );
  };
  // const { activities } = useSelector((state) => state.activities);
  const handleOpenDeleteDialog = (row) => {
    setDeleteRow(row);
    setOpen(true);
  };

  const handleDeleteActivity = (row) => {
    Create(
      { id: row.id },
      Post_DeleteCompanyExternalServiceProvider_URL,
      history,
      (resp) => {
        setOpen(false);
        enqueueSnackbar(
          getTranslation("Service provider deleted successfully", "Fournisseur de services supprimé avec succès", "Dienstanbieter erfolgreich gelöscht"),
          {
            variant: "success",
          }
        );
        loadCeresServices();
      },
      (error) => {
        enqueueSnackbar(
          getTranslation(
            "Sometihing went wrong",
            "Quelque chose s'est mal passé",
            "Etwas ist schief gelaufen"
          ),
          {
            variant: "error",
          }
        );
      }
    );
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value));
    setPage(0);
  };
  const handleDetailCompany = (row) => {
    setEditExternalServiceProvider(row);
    //history(`/operations/${row.id}/basic`);
  };
  const handleSearchCeresService = (searchString) => {
    setSearchString(searchString);
  };
  const handleCreate = async (values, actions) => {
    setSubmitting(true);
    Create(
      values,
      editExternalServiceProvider
        ? Post_UpdateExternalServiceProvider_URL
        : Post_ExternalServiceProvider_URL,
      history,
      (response) => {
        actions.setSubmitting(false);
        enqueueSnackbar(
          getTranslation(
            "Operation Saved",
            "Opération créée",
            "Vorgang erstellt"
          ),
          {
            variant: "success",
          }
        );
        setEditExternalServiceProvider(null);
        setSubmitting(false);
        loadCeresServices();
      },
      (error) => {
        actions.setSubmitting(false);
        setSubmitting(false);
        enqueueSnackbar(error.data, {
          variant: "error",
        });
      }
    );
  };

  return (
    <div>
      <CreateService
        onSubmit={handleCreate}
        companyId={companyId}
        editExternalServiceProvider={editExternalServiceProvider}
        isSubmitting={submitting}
        onCancel={() => setEditExternalServiceProvider(null)}
      />
      <Table
        dense
        loading={loadingCeresServices}
        title={getTranslation(
          "Service Providers",
          "Les fournisseurs de services",
          "Dienstleister"
        )}
        colums={columns}
        sortBy="name"
        rows={rows?.data}
        peration
        count={rows?.totalCount}
        page={page}
        rowsPerPage={rowsPerPage}
        handleChangePage={(event, newPage) => handleChangePage(event, newPage)}
        handleChangeRowsPerPage={(e) => handleChangeRowsPerPage(e)}
        viewEdit
        onEdit={(row) => handleDetailCompany(row)}
        viewDelete
        onDelete={(row) => handleOpenDeleteDialog(row)}
        viewSearch
        onSearch={(searchString) => handleSearchCeresService(searchString)}
      />
      <DeleteDialog
        open={open}
        title={getTranslation(
          "Delete Asset",
          "Supprimer l'actif",
          "Asset löschen"
        )}
        onClose={() => setOpen(false)}
        onSubmit={(e) => handleDeleteActivity(deleteRow)}
      />
    </div>
  );
};

export default ManageService;
