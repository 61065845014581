import { Post_UpdateMemberDecision_URL } from "../../constants/apiUrls";
import { Create } from "../../actions";

export function scanCircularResolution(data, onSuccess, onError) {
  Create(
    { memberId: data.memberId },
    Post_UpdateMemberDecision_URL,
    null,
    onSuccess,
    onError
  );
}
