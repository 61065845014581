import { CardContent, CardHeader, Grid, Typography } from "@material-ui/core"
import CardComponent from "../../../components/Card"
import { getTranslation } from "../../../heplers/translationHelper"
import { format } from "../../../heplers/format"

export const CompanyInformation = ({companyInfo}) => {

    return <CardComponent >
        <CardHeader title={getTranslation("Company Information", "Company Information", "Company Information")} />
        <CardContent>
        <Grid container spacing={3} style={{ margin: "20px" }}>
            <Grid item lg={3} md={3}>
              <Typography variant="subtitle2" display="inline">
                Company Name:
              </Typography>
              <Typography variant="overline">{companyInfo?.companyName}</Typography>
            </Grid>
            <Grid item lg={3} md={3}>
              <Typography variant="subtitle2" display="inline">
                Company Reference Code:
              </Typography>
              <Typography variant="overline">{companyInfo?.basic?.referenceCode}</Typography>
            </Grid>
            <Grid item lg={3} md={3}>
              <Typography variant="subtitle2" display="inline">
                Investor Relation:
              </Typography>
              <Typography variant="overline">{companyInfo?.basic?.investorRelationName}</Typography>
            </Grid>
            <Grid item lg={3} md={3}>
              <Typography variant="subtitle2" display="inline">
                RCS Number:
              </Typography>
              <Typography variant="overline">{companyInfo?.basic?.rcsNumber}</Typography>
            </Grid>
            <Grid item lg={3} md={3}>
              <Typography variant="subtitle2" display="inline">
                InCorporation Date:
              </Typography>
              <Typography variant="overline">{format("date",companyInfo?.basic?.incorporationDate)}</Typography>
            </Grid>
            <Grid item lg={3} md={3}>
              <Typography variant="subtitle2" display="inline">
                Street:
              </Typography>
              <Typography variant="overline">{companyInfo?.basic?.street}</Typography>
            </Grid>
            <Grid item lg={3} md={3}>
              <Typography variant="subtitle2" display="inline">
                City:
              </Typography>
              <Typography variant="overline">{companyInfo?.basic?.city}</Typography>
            </Grid>
            <Grid item lg={3} md={3}>
              <Typography variant="subtitle2" display="inline">
                Zipcode:
              </Typography>
              <Typography variant="overline">{companyInfo?.basic?.zipCode}</Typography>
            </Grid>
            <Grid item lg={3} md={3}>
              <Typography variant="subtitle2" display="inline">
                Country:
              </Typography>
              <Typography variant="overline">{companyInfo?.basic?.country}</Typography>
            </Grid>
            <Grid item lg={3} md={3}>
              <Typography variant="subtitle2" display="inline">
                RBE:
              </Typography>
              <Typography variant="overline">{companyInfo?.basic?.rbe}</Typography>
            </Grid>
            <Grid item lg={3} md={3}>
              <Typography variant="subtitle2" display="inline">
                Group:
              </Typography>
              <Typography variant="overline">{companyInfo?.companyGroup?.title}</Typography>
            </Grid>
            <Grid item lg={3} md={3}>
              <Typography variant="subtitle2" display="inline">
              Finnancial Year Ends:
              </Typography>
              <Typography variant="overline">{companyInfo?.basic?.finnancialYearEnds}</Typography>
            </Grid>
            <Grid item lg={12} md={12}>
              <Typography variant="subtitle2" display="inline">
              Legal Form:
              </Typography>
              <Typography variant="overline">{companyInfo?.basic?.basicLegalForm?.legalForm}</Typography>
            </Grid>
            </Grid>
        </CardContent>
    </CardComponent>
}