import { Box, Grid, TextField } from "@material-ui/core";
import { Form, Formik } from "formik";
import React from "react";
import ButtonWithLoading from "../../../../../../components/ButtonWithLoading";
import * as Yup from "yup";
import { Autocomplete } from "@material-ui/lab";
import { getTranslation } from "../../../../../../heplers/translationHelper";
export const AddDocument = ({ contacts, onAddNewDocument }) => {
  const initialValues = {
    contactId: 0,
    contact: {},
    interval: "",
    comments: "",
  };
  const basicValidationSchema = Yup.object().shape({
    contactId: Yup.number().min(1).required("Contact is required."),
    interval: Yup.number().min(1).required("Interval is required."),
    comments: Yup.string().required("Comment is required."),
  });

  return (
    <div>
      <Formik
        enableReinitialize
        initialValues={initialValues}
        validationSchema={basicValidationSchema}
        onSubmit={(values, actions) => {
          actions.setSubmitting(true);
          onAddNewDocument(values, actions);
          actions.setSubmitting(false);
          actions.resetForm();
        }}
      >
        {({
          errors,
          touched,
          values,
          handleSubmit,
          isSubmitting,
          getFieldProps,
          setFieldValue,
          handleChange,
        }) => (
          <Form>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12} md={3} lg={3}>
                <Autocomplete
                  name="person"
                  options={contacts}
                  getOptionLabel={(option) => option.fullName}
                  //value={values.companyCeresService}
                  size="small"
                  onChange={(e, value) => {
                    if (value) {
                      setFieldValue("contact", value);
                      setFieldValue("name", value.fullName);
                      setFieldValue("email", value.mail);
                      setFieldValue("contactId", value.id);
                    }
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label={getTranslation("Person", "La personne", "Person")}
                      variant="outlined"
                      required
                      error={touched.person && Boolean(errors.person)}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={3} lg={3}>
                <TextField
                  fullWidth
                  label={getTranslation("interval", "intervalle", "Intervall")}
                  required
                  size="small"
                  variant="outlined"
                  name="interval"
                  type="number"
                  {...getFieldProps("interval")}
                  error={touched.interval && Boolean(errors.interval)}
                  helperText={touched.interval && errors.interval}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={4} lg={4}>
                <TextField
                  fullWidth
                  label={getTranslation(
                    "Comments",
                    "commentaires",
                    "Bemerkungen"
                  )}
                  required
                  size="small"
                  variant="outlined"
                  name="comments"
                  {...getFieldProps("comments")}
                  error={touched.comments && Boolean(errors.comments)}
                  helperText={touched.comments && errors.comments}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={1} lg={1}>
                <Box
                  display="flex"
                  alignItems="flex-start"
                  justifyContent="flex-start"
                >
                  <ButtonWithLoading
                    title={getTranslation("Add", "Ajouter", "Addieren")}
                    size="small"
                    variant="contained"
                    color="primary"
                    // loading={isSubmitting}
                    onClick={handleSubmit}
                  />
                </Box>
              </Grid>
            </Grid>
          </Form>
        )}
      </Formik>
    </div>
  );
};
