import { ContactEdit } from "./component";
import Dialog from "../../components/Dialog";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useTheme } from "@material-ui/core/styles";
import { getTranslation } from "../../heplers/translationHelper";
const handleOnCloseDialog = (event, onClose) => {
  onClose();
};

export const CreateContact = (props) => {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  const { isOpen, onClose, onSubmit } = props;
  return (
    <Dialog
      open={isOpen}
      title={getTranslation("Create Contact","Créer un contact","Kontakt erstellen")}
      onClose={onClose}
      maxWidth="md"
    >
      <ContactEdit onSubmit={onSubmit} isNewRecord={true} />
    </Dialog>
  );
};
