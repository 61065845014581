import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  CircularProgress,
  Grid,
  Typography,
} from "@material-ui/core";
import { useSnackbar } from "notistack";
import React, { useEffect, useRef, useState } from "react";
import { useParams } from "react-router";
import { useReactToPrint } from "react-to-print";
import { Create, Get } from "../../../actions";
import {
  Get_CircularResolutionMemberContact_URL,
  Get_SingleCircularResolution_URL,
  Post_ConfirmCRByMember_URL,
} from "../../../constants/apiUrls";
import { CircularResolutionInfoBox } from "./circularResolutionInfoBox";
import { DraftSignedCircularResolution } from "./draftSignCircularResolution";
import { PrintSignedCircularResolution } from "./printSignCircularResolution";
import { SignDecision } from "./signDecision";
export const CircularResolutionDecision = () => {
  const { circularResolutionId } = useParams();
  const { memberId } = useParams();
  const [circularResolution, setCircularResolution] = useState({});
  const [personalInfo, setPersonalInfo] = useState({});
  const enqueueSnackbar = useSnackbar();
  useEffect(() => {
    loadCircularResolution({ id: circularResolutionId });
  }, [circularResolutionId]);
  const loadCircularResolution = (values) => {
    Get(
      values,
      Get_SingleCircularResolution_URL,
      null,
      (resp) => {
        setCircularResolution(resp?.data);
      },
      (error) => {
        enqueueSnackbar(error?.data, { variant: "warning" });
      }
    );
  };

  useEffect(() => {
    loadPersonalInfo({ memberId: memberId });
  }, [memberId]);
  const loadPersonalInfo = (values) => {
    Get(
      values,
      Get_CircularResolutionMemberContact_URL,
      null,
      (resp) => {
        setPersonalInfo(resp?.data);
      },
      (error) => {
        enqueueSnackbar(error?.data, { variant: "error" });
      }
    );
  };

  return (
    <>
      {personalInfo?.status === false ? (
        <>
          <CircularResolutionInfoBox
            circularResolutionId={circularResolutionId}
            circularResolution={circularResolution}
            memberId={memberId}
          />
          <SignDecision
            personalInfo={personalInfo}
            circularResolution={circularResolution}
          />
        </>
      ) : personalInfo?.status === true ? (
        <Grid
          container
          spacing={0}
          direction="column"
          alignItems="center"
          justify="center"
          style={{ minHeight: "100vh" }}
        >
          <Grid item xs={3}>
            <Typography fontSize="24px">Thanks for your feedback.</Typography>
          </Grid>
        </Grid>
      ) : (
        <Grid
          container
          spacing={0}
          direction="column"
          alignItems="center"
          justify="center"
          style={{ minHeight: "100vh" }}
        >
          <CircularProgress />
        </Grid>
      )}
    </>
  );
};
