import Card from "../../../../../components/Card";
import { Box, CardContent } from "@material-ui/core";
import {
  UpdateReportComments,
  UpdateReportTable,
  MeetingReports,
} from "./components";
import CardComponent from "../../../../../components/Card";
import { useEffect } from "react";
import { parseHelper } from "../../../../../heplers/parserHelper";
import { useParams } from "react-router";
import { Get } from "../../../../../actions";
import { Get_GetReportInfo_URL, Get_SendNotificationForReview_URL } from "../../../../../constants/apiUrls";
import { useState } from "react";
import { useSnackbar } from "notistack";
import ButtonWithLoading from "../../../../../components/ButtonWithLoading";
const UpdateReport = () => {
  const { meetingId } = useParams();
  const [meetingReport, setMeetingReport] = useState();
  useEffect(() => {
    loadReportInfo();
  }, []);
  const loadReportInfo = () => {
    Get(
      { meetingId: meetingId },
      Get_GetReportInfo_URL,
      null,
      (resp) => {
        setMeetingReport(resp.data);
      },
      (error) => {}
    );
  };

  const [isSendingNotification, setIsSendingNotification] = useState(false);
  const {enqueueSnackbar} = useSnackbar()
  const sendNotificationForReview = () => {
    setIsSendingNotification(true);
    Get(
      { meetingId: meetingId },
      Get_SendNotificationForReview_URL,null,
      resp => {
        setIsSendingNotification(false);
        enqueueSnackbar(resp.data, { variant: "success" })

      }, error => {
        setIsSendingNotification(false);
        enqueueSnackbar(error.data, { variant: "error" })

      })
  }

  return (
    <Card title="Update Report Data">
      <CardContent>
        <UpdateReportTable />
        <br />
        <UpdateReportComments comments={meetingReport?.reportDeclineComments} />
        <br />
        <Box
                  pr={1}
                  pb={1}
                  width="100%"
                  display="flex"
                  alignItems="center"
                  justifyContent="flex-end"
                >
                  <ButtonWithLoading
                    title={"Send to fund manager for review"}
                    size="small"
                    variant="contained"
                    color="primary"
                    loading={isSendingNotification}
                    onClick={sendNotificationForReview}
                  />
                </Box>
        <MeetingReports meetingReport={meetingReport} meetingId={meetingId} />
      </CardContent>
    </Card>
  );
};
export default UpdateReport;
