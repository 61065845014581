import React, { Suspense } from "react";
import GlobalStyles from "./components/GlobalStyles";
import { BrowserRouter, Routes } from "react-router-dom";
import "./App.css";
import AppRoutes from "./routes";
const App = () => {
  return (
    <React.Fragment>
      <GlobalStyles />
      <BrowserRouter>
      <Suspense fallback={<h1>loading...</h1>}>
      <Routes>
        {AppRoutes()}
      </Routes>
      </Suspense>
      </BrowserRouter>
    </React.Fragment>
  );
};

export default App;
