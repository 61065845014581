import React, { useState, useEffect } from "react";
import Table from "../../../../../../components/table";

import { useSnackbar } from "notistack";
import { Get, Create } from "../../../../../../actions";
import DeleteDialog from "../../../../../../components/DeleteDialog";
import { AddAskingAgenda } from "./addAskingAgenda";
import { getTranslation } from "../../../../../../heplers/translationHelper";

const columns = [
  {
    id: "name",
    numeric: false,
    disablePadding: true,
    label: getTranslation("Person", "La personne", "Person"),
  },
  {
    id: "subject",
    numeric: false,
    disablePadding: true,
    label: getTranslation("Subject", "Sujette", "Fach"),
  },
  {
    id: "comments",
    numeric: false,
    disablePadding: true,
    label: getTranslation("Comments", "commentaires", "Bemerkungen"),
  },
  {
    id: "actions",
    numeric: true,
    disablePadding: false,
    align: "right",
    label: getTranslation("Actions", "Actions", "Aktionen"),
  },
];

export const AskingAgendaList = ({
  agendas,
  onNewAgendaAdded,
  onAgendaRemoved,
  onUpdateAgenda,
}) => {
  const [openDelete, setOpenDelete] = useState(false);
  const [deleteProgress, setDeleteProgress] = useState(false);
  const [deleteRow, setDeleteRow] = useState([]);
  const { enqueueSnackbar } = useSnackbar();
  const [searchString, setSearchString] = useState("");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [rows, setRows] = useState([]);
  const [editRow, setEditRow] = useState();
  const [editRowIndex, setEditRowIndex] = useState();

  const [guestList, setGuestList] = useState([]);
  const onAddGuest = (values, actions) => {
    // setGuestList([...guestList,values])
    onNewAgendaAdded(values);
    actions.setSubmitting(false);
  };
  /////////////////// Handlers ////////////
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value));
    setPage(0);
  };
  const handleSearchAgendaTemplate = (searchString) => {
    setSearchString(searchString);
  };

  const handleOpenDeleteDialog = (deleteItem) => {
    setOpenDelete(true);
    setDeleteRow(deleteItem);
  };
  const handleEditAgendaTemplate = (row) => {
    setEditRow(row);
    const index = agendas.findIndex((x) => x == row);
    setEditRowIndex(index);
  };
  return (
    <div>
      <AddAskingAgenda
        onAddGuest={onAddGuest}
        agenda={editRow}
        onUpdate={(agenda) => {
          onUpdateAgenda(editRowIndex, agenda);
          setEditRowIndex(null);
          setEditRow(null);
        }}
      />
      <br />
      <Table
        auto
        //loading={agendaTemplateListLoading}
        colums={columns}
        sortBy="meetingDate"
        rows={agendas}
        count={agendas?.length}
        page={page}
        rowsPerPage={rowsPerPage}
        handleChangePage={(event, newPage) => handleChangePage(event, newPage)}
        handleChangeRowsPerPage={(e) => handleChangeRowsPerPage(e)}
        viewEdit
        onEdit={(row) => handleEditAgendaTemplate(row)}
        viewDelete
        onDelete={(row) => onAgendaRemoved(row)}
        viewSearch
        onSearch={(searchString) => handleSearchAgendaTemplate(searchString)}
      />
      {/* <DeleteDialog
        open={openDelete}
        deleting={deleteProgress}
        title="Delete Template"
        onClose={() => setOpenDelete(false)}
        onSubmit={(e) => {
          onDelete();
        }}
      /> */}
    </div>
  );
};
