import Table from "../../../../../../components/table";
import { Formik, Form } from "formik";
import {
  Box,
  CardContent,
  Grid,
  IconButton,
  TextField,
  Typography,
} from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { EditButton } from "../../../../../../components/ButttonsWithIcons";
import { useState } from "react";
import SelectContactDialog from "./selectContactDialog";
import ButtonWithLoading from "../../../../../../components/ButtonWithLoading";
import { getTranslation } from "../../../../../../heplers/translationHelper";
import { useEffect } from "react";
import { Get } from "../../../../../../actions";
import {
  Get_AllReports_URL,
  GET_AllUsersWithoutAuthorization_URL,
} from "../../../../../../constants/apiUrls";
import { getReportingUsers } from "../../../../../../heplers/parserHelper";
import Card from "../../../../../../components/Card";
import { useParams } from "react-router";
import * as Yup from "yup";
import { useSnackbar } from "notistack";

const columns = [
  {
    id: "reportingTitle",
    numeric: false,
    disablePadding: true,
    label: "Title",
  },
  {
    id: "name",
    numeric: false,
    disablePadding: true,
    label: "Name",
  },
  {
    id: "email",
    numeric: false,
    disablePadding: true,
    label: "Email",
  },
  {
    id: "status",
    numeric: false,
    disablePadding: true,
    label: "Status",
    component: ({ row }) =>
      row.status ? (
        <Typography>Ok</Typography>
      ) : (
        <Typography>Not Ok</Typography>
      ),
  },
  {
    id: "actions",
    label: "Action",
    align: "right",
  },
];

const AttachReport = ({ NextStep, BackStep,initialValues }) => {
  const [openContactDialog, setOpenContactDialog] = useState(false);
  const [reportTemplateList, setReportTemplateList] = useState([]);
  const [fundManager, setFundManager] = useState([]);
  const [reportingUsers, setReportingUsers] = useState([]);
  const [updateRow, setUpdateRow] = useState(null);
  const { managementCompanyId } = useParams();
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    getReportTemplateList();
    loadFundManagers();
    setReportingUsers(initialValues.reportingUsers)
  }, []);

  const getReportTemplateList = () => {
    Get(
      {},
      Get_AllReports_URL,
      null,
      (resp) => {
        setReportTemplateList(resp.data);
      },
      (error) => {
        console.log(error);
      }
    );
  };

  const loadFundManagers = () => {
    Get(
      {},
      GET_AllUsersWithoutAuthorization_URL,
      null,
      (resp) => {
        setFundManager(resp.data);
      },
      (error) => {
        console.log(error);
      }
    );
  };

  const updateReportingUsers = (values) => {
    console.log("kjgsdfgsdfg report", values);
    var tempArr = reportingUsers;
    var index = tempArr.findIndex((x) => x === updateRow);
    if (values.userType === "contacts") {
      tempArr[index].name = values.reportingUser.fullName;
      tempArr[index].isContact = true;
      tempArr[index].email = values.reportingUser.mail;
      tempArr[index].contactId = values.reportingUser.id;
    } else {
      tempArr[index].name = values.reportingUser.name;
      tempArr[index].email = values.reportingUser.email;
      tempArr[index].isContact = false;
      tempArr[index].ceresMemberId = values.reportingUser.id;
    }
    tempArr[index].status = true;
    setReportingUsers(tempArr);
    setOpenContactDialog(false);
  };

  // const initialValues = {
  //   reportFundManagerId: 0,
  //   reportIntervalDays: 0,
  //   reportCollectDataBefore: 0,
  //   reportTemplateId: 0,
  //   fundManager: {},
  // };
  const basicValidationSchema = Yup.object().shape({
    reportTemplateId: Yup.number().min(1,"Field is required"),
    reportCollectDataBefore: Yup.number().min(1,"Field is required"),
    reportIntervalDays: Yup.number().min(1,"Field is required"),
   // reportFundManagerId: Yup.string().required("Field is required")
   reportFundManagerId: Yup.string().required("Field is Required"),
   fundManager: Yup.object().required("Field is required"),
  });
  return (
    <Card title={getTranslation("Reporting", "Rapports", "Berichterstattung")}>
      <CardContent>
        <Formik
          initialValues={initialValues}
          validationSchema={basicValidationSchema}
          onSubmit={(values) => {
            const failed= reportingUsers.filter(x=>x.contactId==null && x.ceresMemberId==null)
            if(failed.length>0)
            {
              enqueueSnackbar("System has found a missing reporting user against "+failed[0].reportingTitle+", Please correct this error to continue.",{variant:"error"})
              return;
            }
            values.reportingUsers = reportingUsers;
            NextStep(values);
          }}
        >
          {({ values, handleSubmit, setFieldValue, setTouched,getFieldProps,errors,touched }) => (
            <Form>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={12} md={3} lg={3}>
                  <Autocomplete
                    id="combo-box-demo"
                    size="small"
                    options={reportTemplateList}
                    value={values.reportTemplate}
                    getOptionLabel={(option) => option.title}
                    onChange={(event, value) => {
                      if(value!=null){
                      setFieldValue("reportTemplateId", value.id);
                      setFieldValue("reportTemplate", value);
                      getReportingUsers(
                        value.reportHtml,
                        managementCompanyId,
                        (users) => {
                          console.log("asdhasjkdhaskjdas", users);
                          setReportingUsers([...new Set(users)]);
                        }
                      );
                    }else{
                      setFieldValue("reportTemplateId", 0);
                      setFieldValue("reportTemplate", null);
                      setReportingUsers([]);
                    }
                    }}
                    renderInput={(params) => (
                      <TextField
                        fullWidth
                        {...params}
                        label={getTranslation(
                          "Select Report Template",
                          "Sélectionnez le modèle de rapport",
                          "Wählen Sie Berichtsvorlage aus"
                        )}
                        variant="outlined"
                        error={touched.reportTemplateId && Boolean(errors.reportTemplateId)}
                        helperText={touched.reportTemplateId && errors.reportTemplateId}

                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={3} lg={3}>
                  <TextField
                    fullWidth
                    label={getTranslation(
                      "Collect Data Before (Days)",
                      "Collecter les données avant (jours)",
                      "Daten sammeln vor (Tage)"
                    )}
                    name="reportCollectDataBefore"
                    {...getFieldProps("reportCollectDataBefore")}
                    id="reportCollectDataBefore"
                    variant="outlined"
                    type="number"
                    size="small"
                    error={touched.reportCollectDataBefore && Boolean(errors.reportCollectDataBefore)}
                    helperText={touched.reportCollectDataBefore && errors.reportCollectDataBefore}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={3} lg={3}>
                  <TextField
                    fullWidth
                    label={getTranslation(
                      "Deadline Interval (Days)",
                      "Intervalle de délai (jours)",
                      "Fristenintervall (Tage)"
                    )}
                    name="reportIntervalDays"
                    {...getFieldProps("reportIntervalDays")}
                    id="reportIntervalDays"
                    variant="outlined"
                    type="number"
                    size="small"
                    error={touched.reportIntervalDays && Boolean(errors.reportIntervalDays)}
                    helperText={touched.reportIntervalDays && errors.reportIntervalDays}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={3} lg={3}>
                  <Autocomplete
                    fullWidth
                    id="combo-box-demo"
                    size="small"
                    options={fundManager}
                    getOptionLabel={(option) => option?.name}
                    value={values.fundManager}
                    onChange={(event, value) => {
                      setFieldValue("fundManager", value);
                      setFieldValue("reportFundManagerId", value?.id);
                      setTouched("reportFundManagerId", true); 
                      setTouched("fundManager", true); 
                    }}
                    onBlur={() => {
                      setTouched("reportFundManagerId", true); 
                      setTouched("fundManager", true); 
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label={getTranslation(
                          "Fund Manager",
                          "Le chef du financement",
                          "Fondsmanager"
                        )}
                        variant="outlined"
                        error={(touched?.reportFundManagerId || touched?.fundManager) && Boolean(errors.reportFundManagerId || errors.fundManager)}
                        helperText={(touched?.reportFundManagerId || touched?.fundManager) && (errors.reportFundManagerId || errors.fundManager)}

                      />
                    )}
                  />
                </Grid>
              </Grid>
              <Table
                dense
                colums={columns}
                sortBy="title"
                rows={reportingUsers}
                count={reportingUsers?.length}
                page={0}
                rowsPerPage={10}
                viewSearch
                onSearch={(row) => {}}
                actions={[
                  {
                    component: (row) => (
                      <IconButton
                        onClick={() => {
                          setUpdateRow(row);
                          setOpenContactDialog(true);
                        }}
                      >
                        <EditButton />
                      </IconButton>
                    ),
                  },
                ]}
              />
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="space-between"
                m={2}
              >
                <ButtonWithLoading
                  title={getTranslation("Back", "Précédent", "Zurück")}
                  color="primary"
                  variant="contained"
                  size="large"
                  onClick={() => BackStep()}
                />
                <ButtonWithLoading
                  title={getTranslation("Next", "Suivant", "Weiter")}
                  color="primary"
                  variant="contained"
                  size="large"
                  onClick={handleSubmit}
                />
              </Box>
            </Form>
          )}
        </Formik>

        <SelectContactDialog
          open={openContactDialog}
          selectedContact={updateRow}
          onClose={() => setOpenContactDialog(false)}
          onSubmit={(values) => updateReportingUsers(values)}
        />
      </CardContent>
    </Card>
  );
};
export default AttachReport;
