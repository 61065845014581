import { Box, Grid, TextField, Typography } from "@material-ui/core";
import {
  Autocomplete,
  ToggleButton,
  ToggleButtonGroup,
} from "@material-ui/lab";
import { Form, Formik } from "formik";
import { DatePicker } from "../../../../../../components/DatePicker";
import { MyTimePicker } from "../../../../../../components/TimePicker";
import { LanguageSelection } from "../../../../../../constants/languages";
import { getTranslation } from "../../../../../../heplers/translationHelper";
import { BoardMembers } from "../../boardMembers";
import * as Yup from "yup";
import { useEffect } from "react";
import { Get } from "../../../../../../actions";
import {
  Get_AllManagementCompanyFeeServicesByType_URL,
  GET_AllUsersWithoutAuthorization_URL,
} from "../../../../../../constants/apiUrls";
import { useSnackbar } from "notistack";
import { useState } from "react";
import ButtonWithLoading from "../../../../../../components/ButtonWithLoading";
import { getDateIgnoreTimezone } from "../../../../../../heplers/dateHelper";
import moment from "moment";
const AddRegularMeetingInfo = ({ managementCompanyId, NextStep,initialValues }) => {
  const { enqueueSnackbar } = useSnackbar();
  const [ceresServices, setCeresServices] = useState([]);
  const [userList, setUserList] = useState([]);
  useEffect(() => {
    Get(
      {},
      GET_AllUsersWithoutAuthorization_URL,
      null,
      (resp) => {
        setUserList(resp.data);
      },
      (error) => {}
    );
  }, []);
  useEffect(() => {
    loadCeresServices();
  }, [managementCompanyId]);

  const loadCeresServices = () => {
    Get(
      {
        id: managementCompanyId,
        feeType: 1,
      },
      Get_AllManagementCompanyFeeServicesByType_URL,
      null,
      (resp) => {
        console.log("kjdhfdsf", resp);
        setCeresServices(resp.data);
      },
      (error) => {
        enqueueSnackbar(error.data, { variant: "error" });
      }
    );
  };

  // const initialValues = {
  //   subject: "",
  //   address: "",
  //   meetingTime: new Date(),
  //   meetingDuration: new Date(),
  //   meetingDate: new Date(),
  //   language: "",
  //   personInchargeId: "",
  //   meetingType: 0,
  //   meetingMembers: [],
  // };
  const basicValidationSchema = Yup.object().shape({
    subject: Yup.string().required("Field is required"),
    address: Yup.string().required("Field is required"),
    meetingTime: Yup.string().required("Field Time is requied"),
    meetingDate: Yup.string().required("Field is required"),
    language: Yup.string().required("Field is required"),
    periodicity: Yup.number()
      .min(10, "Minimum 10 days allowed.")
      .required("Field is required"),
    personInchargeId: Yup.string().required("Field is required"),
    companyCeresServiceId: Yup.number().min(1).required("Field is required"),
  });
  return (
    <Formik
      initialValues={initialValues}
      validationSchema={basicValidationSchema}
      onSubmit={(values, actions) => {
        if(values.meetingMembers.length==0){
          enqueueSnackbar("At least 1 member should be selected.",{variant:"error"})
          return;
        }
        values.meetingDate=moment(values.meetingDate).format("MM-DD-YYYY")
        values.meetingTime=moment(values.meetingTime).format("MM-DD-YYYY HH:mm")
        values.meetingDuration=moment(values.meetingDuration).format("MM-DD-YYYY HH:mm")
        NextStep(values);
      }}
    >
      {({
        errors,
        touched,
        values,
        handleSubmit,
        isSubmitting,
        getFieldProps,
        setFieldValue,
        handleChange,
      }) => (
        <Form>
          <Grid container spacing={2} style={{ marginBottom: "10px" }}>
            {/* Step 1 */}
            <Grid
              item
              xs={12}
              sm={12}
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <ToggleButtonGroup
                size="small"
                id="meetingType"
                value={values.meetingType}
                exclusive
                onChange={(event, newAlignment) => {
                  if (newAlignment) {
                    setFieldValue("meetingType", newAlignment);
                    setFieldValue("meetingMembers", []);
                  } else {
                    setFieldValue("meetingType", 0);
                  }
                }}
              >
                <ToggleButton value={0} aria-label="left aligned">
                  <Typography>
                    {getTranslation("Board", "Planche", "Tafel")}
                  </Typography>
                </ToggleButton>
                <ToggleButton value={1} aria-label="centered">
                  <Typography>
                    {getTranslation("Shareholder", "Actionnaire", "Aktionär")}
                  </Typography>
                </ToggleButton>
              </ToggleButtonGroup>
            </Grid>
            <Grid item xs={12} sm={12} md={3} lg={3}>
              <Autocomplete
                options={ceresServices}
                getOptionLabel={(option) => option.ceresServiceTitle}
                size="small"
                value={values.companyCeresService}
                onChange={(e, value) => {
                  setFieldValue("companyCeresServiceId", value?.id);
                  setFieldValue("companyCeresService", value);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={getTranslation(
                      "Ceres Service",
                      "Service Cérès",
                      "Ceres-Dienst"
                    )}
                    variant="outlined"
                    error={
                      touched.companyCeresServiceId &&
                      Boolean(errors.companyCeresServiceId)
                    }
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={3} lg={3}>
              <TextField
                fullWidth
                id="subject"
                label={getTranslation("Subject", "Sujette", "Fach")}
                required
                size="small"
                variant="outlined"
                name="subject"
                {...getFieldProps("subject")}
                error={touched.subject && Boolean(errors.subject)}
                helperText={touched.subject && errors.subject}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={3} lg={3}>
              <Autocomplete
                options={userList}
                getOptionLabel={(option) => option.name}
                size="small"
                onChange={(e, value) => {
                  setFieldValue("personInchargeId", value?.id);
                  setFieldValue("personIncharge", value);
                }}
                value={values.personIncharge}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={getTranslation(
                      "Person Incharge",
                      "Personne en charge",
                      "Verantwortlicher"
                    )}
                    variant="outlined"
                    error={
                      touched.personInchargeId &&
                      Boolean(errors.personInchargeId)
                    }
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={3} lg={3}>
              <LanguageSelection
                fullWidth
                id="language"
                label={getTranslation("Language", "Langue", "Sprache")}
                required
                size="small"
                variant="outlined"
                name="language"
                selected={values.language}
                onChange={(e, language) => setFieldValue("language", language)}
                error={touched.language && Boolean(errors.language)}
                helperText={touched.language && errors.language}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={3} lg={3}>
              <TextField
                fullWidth
                id="periodicity"
                label={getTranslation(
                  "Periodicity (Days)",
                  "Périodicité (jours)",
                  "Periodizität (Tage)"
                )}
                required
                size="small"
                variant="outlined"
                name="periodicity"
                type="number"
                {...getFieldProps("periodicity")}
                error={touched.periodicity && Boolean(errors.periodicity)}
                helperText={touched.periodicity && errors.periodicity}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={3} lg={3}>
              <DatePicker
                fullWidth
                variant="outlined"
                label={getTranslation(
                  "Meeting Date",
                  "Date de la réunion",
                  "Datum des Treffens"
                )}
                value={values.meetingDate}
                onChange={(value) => {
                  setFieldValue("meetingDate", value);
                }}
                required
                error={touched.meetingDate && Boolean(errors.meetingDate)}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={3} lg={3}>
              <MyTimePicker
                fullWidth
                id="meetingTime"
                variant="inline"
                size="small"
                // id="Incorporation-Date-inline"
                label={getTranslation("Time", "Temps", "Zeit")}
                name="meetingTime"
                value={values.meetingTime}
                onChange={(value) => {
                  setFieldValue("meetingTime", value);
                }}
                KeyboardButtonProps={{
                  "aria-label": "Meeting Time",
                }}
                InputLabelProps={{
                  shrink: true,
                }}
                required
                error={touched.meetingTime && Boolean(errors.meetingTime)}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={3} lg={3}>
              <MyTimePicker
                fullWidth
                id="meetingDuration"
                variant="inline"
                size="small"
                label={getTranslation(
                  "Meeting Duration (Hours)",
                  "Durée de la réunion (Heures)",
                  "Treffendauer (Std.)"
                )}
                name="meetingDuration"
                value={values.meetingDuration}
                onChange={(value) => {
                  setFieldValue("meetingDuration", value);
                }}
                KeyboardButtonProps={{
                  "aria-label": "Meeting Duration",
                }}
                InputLabelProps={{
                  shrink: true,
                }}
                required
                error={
                  touched.meetingDuration && Boolean(errors.meetingDuration)
                }
              />
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <TextField
                fullWidth
                multiline
                label="Address"
                required
                size="small"
                variant="outlined"
                {...getFieldProps("address")}
                error={touched.address && Boolean(errors.address)}
                helperText={touched.address && errors.address}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12}>
              {values.meetingType === 0 ? (
                <BoardMembers
                  title={getTranslation(
                    "Board Members",
                    "Membres du conseil d'administration",
                    "Vorstandsmitglieder"
                  )}
                  meetingType={0}
                  MCId={managementCompanyId}
                  onSelectionChange={(members) =>
                    setFieldValue("meetingMembers", members)
                  }
                  selectedMembers={values.meetingMembers}
                />
              ) : (
                <BoardMembers
                  title={getTranslation(
                    "Shareholder Members",
                    "Membres actionnaires",
                    "Aktionärsmitglieder"
                  )}
                  meetingType={1}
                  MCId={managementCompanyId}
                  onSelectionChange={(members) =>
                    setFieldValue("meetingMembers", members)
                  }
                  selectedMembers={values.meetingMembers}
                />
              )}
            </Grid>
          </Grid>
          <Box
            display="flex"
            justifyContent="flex-end"
            alignItems="flex-end"
            m={2}
          >
            <ButtonWithLoading
              title={getTranslation("Next", "Suivant", "Weiter")}
              color="primary"
              variant="contained"
              size="large"
              onClick={handleSubmit}
            />
          </Box>
        </Form>
      )}
    </Formik>
  );
};
export default AddRegularMeetingInfo;
