import { Get } from "../actions";
import { Get_ToDoListItems_URL } from "../constants/apiUrls";
import { format } from "./format";
import ReactDOMServer from 'react-dom/server'
import EditIcon from '@material-ui/icons/Edit';
import {AlarmClockIcon} from "../components/Icons"
import { EditSvg } from "../components/Icons";

export const parseMinutesReportHelper = async (meetingId,html, onChange) => {
    var parser = new DOMParser();
    var htmlDoc = parser.parseFromString(html, "text/html");
    var todolist = htmlDoc.querySelectorAll("[data-todolist-id]");
  
    await parseTodo(todolist);
    var XML = new XMLSerializer();
    var divNode = document.createElement("div");
    divNode.innerHTML = htmlDoc.body.innerHTML;
    divNode.style = `width:100%;`;
    onChange(XML.serializeToString(htmlDoc));
  };

const parseTodo = async (sectionHtmlDOM) => {
    for await (const x of sectionHtmlDOM) {
    
    await Get(
            {
                toDoId: x.getAttribute("data-todolist-id"),
            },
            Get_ToDoListItems_URL,
            null,
            (resp) => {
                x.innerHTML = renderTable(resp?.data,x.getAttribute("data-todolist-id"));
                x.style = "";
            },
            (error) => {
                console.log("jhsdbgfsdfg error", error);
            }
        );
    }

  
  };

  const renderTable = (toDoItems,todoId) => {

    return ReactDOMServer.renderToString(<div><h3 >To Do list  </h3> <EditSvg data-edit-todolist-id={todoId}/> <table  style={{width:"100%",borderCollapse:'collapse'}}><thead>
      <tr style={{ backgroundColor: "rgba(240, 240, 240, 0.8)",borderCollapse:'collapse' }}>
      <th style={{
            border: '1px solid rgba(0, 0, 0, 0.2)',
            padding: '6px',
            textAlign: 'center',
            fontWeight: 'bold',
            borderCollapse:'collapse'
          }}>Person Incharge</th>
      <th style={{
            border: '1px solid rgba(0, 0, 0, 0.2)',
            padding: '6px',
            textAlign: 'center',
            fontWeight: 'bold',
            borderCollapse:'collapse'
          }}>Description</th>
      <th style={{
            border: '1px solid rgba(0, 0, 0, 0.2)',
            padding: '6px',
            textAlign: 'center',
            fontWeight: 'bold',
            borderCollapse:'collapse'
          }}>Due Date</th>
      </tr>
      {toDoItems.map(item => {
        return <tr>
          <td style={{
            border: "1px solid rgba(0, 0, 0, 0.2)",
            padding: "6px",
            textAlign: "center",
            borderCollapse:'collapse'
          }}>{item.personInchargeName}</td>
          <td style={{
            border: "1px solid rgba(0, 0, 0, 0.2)",
            padding: "6px",
            textAlign: "center",
            borderCollapse:'collapse'
          }}>{item.description}</td>
          <td style={{
            border: "1px solid rgba(0, 0, 0, 0.2)",
            padding: "6px",
            textAlign: "center",
            borderCollapse:'collapse'
          }}>{format("date",item.dueDate)}</td>
        </tr>
      })}
    </thead></table></div>)
  }
  