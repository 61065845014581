import { useEffect } from "react";
import { useState } from "react";
import { AddReportTemplateDialog } from ".";
import Table from "../../../../components/table";
import { Get_AllReports_URL, Post_DeleteReport_URL } from "../../../../constants/apiUrls";
import { Create, Get } from '../../../../actions';
import { Post_UpsertReport_URL } from '../../../../constants/apiUrls';
import { useSnackbar } from "notistack"
import DeleteDialog from "../../../../components/DeleteDialog";

const columns = [
  {
    id: "title",
    numeric: false,
    disablePadding: true,
    label: "Reports",
  },
  {
    id: "actions",
    numeric: true,
    disablePadding: false,
    align: "right",
    label: "Actions",
  },
];

const ReportTemplateList = () => {
  const { enqueueSnackbar } = useSnackbar();

  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(0);
  const [searchString, setSearchString] = useState("");
  const [rowData, setRowData] = useState([]);
  const [rowDataLoading, setRowDataLoading] = useState(false);

  ////////////// Dialog handlers //////////////
  const [openDialog, setOpenDialog] = useState(false);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [editReport, setEditReport] = useState(null);

  const [deleteRow, setDeleteRow] = useState(null);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value));
    setPage(0);
  };
  const handleSearch = (searchString) => {
    setSearchString(searchString);
  };

  useEffect(() => {
    loadReportTemplates();
  }, [])
  const loadReportTemplates = () => {
    setRowDataLoading(true);
    Get(
      {},
      Get_AllReports_URL,
      null,
      resp => {
        setRowDataLoading(false);
        setRowData(resp.data);
      },
      error => {
        setRowDataLoading(false);

      }
    )
  }
  const submitReport = (values, actions) => {
    Create(
      values,
      Post_UpsertReport_URL,
      null,
      resp => {
        enqueueSnackbar("Report Saved Successfully", {
          variant: "success",
        });
        actions.setSubmitting(false);
        actions.resetForm();
        setEditReport(null);
        setOpenDialog(false);
        loadReportTemplates();
      },
      error => {
        actions.setSubmitting(false);
        enqueueSnackbar("Error Creating Report", {
          variant: "error",
        });
      }
    )
  }

  const handleDeleteReport = () => {
    setIsDeleting(true);
    Create(
      { id: deleteRow.id },
      Post_DeleteReport_URL,
      null,
      resp => {
        setIsDeleting(false);
        loadReportTemplates();
        setOpenDeleteDialog(false);
        setDeleteRow(null);
      },
      error => {
        setIsDeleting(false);
        setOpenDeleteDialog(false);
        setDeleteRow(null);
      }
    )
  }
  return (
    <>
      <Table
        title="Report Templates"
        dense
        auto
        laoding={rowDataLoading}
        colums={columns}
        sortBy="title"
        rows={rowData}
        count={rowData?.length}
        page={page}
        rowsPerPage={rowsPerPage}
        handleChangePage={handleChangePage}
        handleChangeRowsPerPage={handleChangeRowsPerPage}
        viewSearch
        handleSearch={handleSearch}
        viewEdit
        onEdit={(row) => {
          setEditReport(row);
          setOpenDialog(true);
        }}
        viewDelete
        onDelete={(row) => {
          setOpenDeleteDialog(true);
          setDeleteRow(row);
        }}
        viewCreate
        onCreate={() => {
          setEditReport(null);
          setOpenDialog(true)
        }}
      />
      <DeleteDialog
        deleting={isDeleting}
        open={openDeleteDialog}
        onClose={() => setOpenDeleteDialog(false)}
        onSubmit={handleDeleteReport}
      />
      <AddReportTemplateDialog editReportTemplate={editReport} onSubmit={submitReport} open={openDialog} onClose={() => setOpenDialog(false)} />
    </>
  )
}
export default ReportTemplateList