import React, { useState, useEffect } from "react";
import { Grid, TextField, IconButton } from "@material-ui/core";
import { Form, Formik } from "formik";
import { useSnackbar } from "notistack";
import ButtonWithLoading from "../../../../../../components/ButtonWithLoading";

import * as Yup from "yup";
import { Create } from "../../../../../../actions";
import { SAVE_Contact_URL } from "../../../../../../constants/apiUrls";
import { Autocomplete } from "@material-ui/lab";
import { IconAdd } from "../../../../../../components/Icons";
import { Close } from "@material-ui/icons";
import { getTranslation } from "../../../../../../heplers/translationHelper";

export const AddGuest = ({
  loading,
  contactList,
  persons,
  onPersonChanged,
  onSubmitGuest,
  onAddNewGuest,
}) => {
  const [enableAddNew, setEnableAddNew] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  const initialValues = {
    addNewPerson: false,
    name: "",
    firstName: "",
    mail: "",
  };

  const basicValidationSchema = Yup.object().shape({
    name: Yup.string().required("Field is required"),
    firstName: Yup.string().required("Field is required"),
    mail: Yup.string().email().required("Field is required"),
  });

  const handleSubmitGuest = async (values, actions) => {
    await Create(
      values,
      SAVE_Contact_URL,
      null,
      (resp) => {
        actions.setSubmitting(false);
        enqueueSnackbar("Guest Added", { variant: "success" });
        onSubmitGuest(resp?.data);
      },
      (error) => {
        actions.setSubmitting(false);
        enqueueSnackbar("Guest not added", { variant: "error" });
      }
    );
  };
  return (
    <>
      <Grid container>
        <Grid xs={12} sm={12} md={3} lg={3}>
          <Autocomplete
            loading={loading}
            options={contactList}
            getOptionLabel={(option) => option.fullName}
            size="small"
            onChange={(e, value) => {
              if (value) {
                value.contactId = value.id;
                if (onAddNewGuest) onAddNewGuest(value.id);
                if (persons) {
                  onPersonChanged([...persons, value]);
                }
              }
            }}
            renderInput={(params) => (
              <TextField {...params}
                label={getTranslation("Select Guest")}
                variant="outlined" />
            )}
          />
        </Grid>
        <br />
        <br />
        <Grid
          xs={1}
          sm={1}
          md={1}
          lg={1}
          style={{
            display: "flex",
            alignItems: "flex-start",
            justifyContent: "flex-start",
            marginLeft: "10px",
            marginTop: "5px",
          }}
        >
          {enableAddNew === false ? (
            <IconAdd onClick={() => setEnableAddNew(true)} />
          ) : null}
        </Grid>
      </Grid>
      <br />
      {enableAddNew ? (
        <Formik
          initialValues={initialValues}
          validationSchema={basicValidationSchema}
          onSubmit={(values, actions) => {
            actions.setSubmitting(true);
            handleSubmitGuest(values, actions);
            actions.resetForm();
          }}
        >
          {({
            errors,
            touched,
            values,
            handleSubmit,
            isSubmitting,
            getFieldProps,
            setFieldValue,
            handleChange,
          }) => (
            <Form>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={12} md={3} lg={3}>
                  <TextField
                    fullWidth
                    id="name"
                    label={getTranslation("First Name", "Prénom", "Vorname")}
                    required
                    size="small"
                    variant="outlined"
                    name="name"
                    {...getFieldProps("name")}
                    error={touched.name && Boolean(errors.name)}
                    helperText={touched.name && errors.name}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={3} lg={3}>
                  <TextField
                    fullWidth
                    id="firstName"
                    label={getTranslation("Last Name", "Nom de famille", "Familienname, Nachname")}
                    required
                    size="small"
                    variant="outlined"
                    name="firstName"
                    {...getFieldProps("firstName")}
                    error={touched.firstName && Boolean(errors.firstName)}
                    helperText={touched.firstName && errors.firstName}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={3} lg={3}>
                  <TextField
                    fullWidth
                    id="mail"
                    label={getTranslation("Email", "E-mail", "Email")}
                    required
                    size="small"
                    variant="outlined"
                    name="mail"
                    {...getFieldProps("mail")}
                    error={touched.mail && Boolean(errors.mail)}
                    helperText={touched.mail && errors.mail}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={3} lg={3}>
                  <ButtonWithLoading
                    title={getTranslation("Add", "Ajouter", "Addieren")}
                    size="medium"
                    variant="contained"
                    color="primary"
                    loading={isSubmitting}
                    onClick={handleSubmit}
                  />
                  <IconButton
                    style={{ marginLeft: "10px" }}
                    onClick={() => setEnableAddNew(false)}
                  >
                    {" "}
                    <Close />{" "}
                  </IconButton>
                </Grid>
              </Grid>
              <br />
            </Form>
          )}
        </Formik>
      ) : null}
    </>
  );
};
