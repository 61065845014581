import React, { useState, useEffect } from "react";
import Table from "../../../../../../components/table";
import { Get, Create } from "../../../../../../actions";
import { useSnackbar } from "notistack";
import { AddReminder } from "./addReminer";
import { Typography } from "@material-ui/core";
import { getTranslation } from "../../../../../../heplers/translationHelper";

export const SelectionCcList = ({
  members,
  selectedMembers,
  enableCheckbox,
  onAddCC,
  onRemoveCC,
}) => {
  const columns = [
    {
      id: "fullName",
      numeric: false,
      disablePadding: true,
      label: getTranslation("Name", "Nom", "Name"),
    },
    {
      id: "mail",
      numeric: false,
      disablePadding: true,
      label: getTranslation("Email", "E-mail", "Email"),
    },
  ];
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [searchString, setSearchString] = useState("");

  const handleNewItem = async (item) => {};
  const handleChangePage = (e, newPage) => {
    setPage(0);
  };
  const handleChangeRowsPerPage = (e, rowsPerPage) => {
    setRowsPerPage(rowsPerPage);
  };

  return (
    <>
      {/* <AddReminder/> */}
      <Typography variant="ہ4">CC</Typography>
      <br />
      <br />
      <Table
        auto
        size="small"
        dense
        //loading={membersLoading}
        // title={title}
        colums={columns}
        //sortBy="fullName"
        rows={members}
        count={members?.length}
        page={page}
        rowsPerPage={rowsPerPage}
        handleChangePage={(event, newPage) => handleChangePage(event, newPage)}
        handleChangeRowsPerPage={(e) => handleChangeRowsPerPage(e)}
        viewSearch
        enableCheckbox
        selected={selectedMembers}
        onSelectionChecked={onAddCC}
        onSelectionUnChecked={onRemoveCC}
        onSelectionChange={(selected) => {
          // if (selected.length > 0) {
          //   selected.forEach((item) => {
          //     onAddCC(item);
          //   });
          // } else {
          //   members.forEach((item) => {
          //     onRemoveCC(item);
          //   });
          // }
        }}
      />
    </>
  );
};
