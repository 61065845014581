import React, { useEffect, useState } from "react";
import {
  AppBar,
  Box,
  Container,
  CssBaseline,
  IconButton,
  MenuItem,
  Select,
  Toolbar,
  makeStyles,
} from "@material-ui/core";
import { Outlet } from "react-router";

import Sidebar from "./sidebar";
import TopBar from "./topbar";
import Breadcrumbs from "../components/BreadCrumbs";
import useStyles from "../assests/styles/layout/main";
import clsx from "clsx";
import { useDispatch, useSelector } from "react-redux";
import { Link as RouterLink, useLocation, useParams } from "react-router-dom";
import { Logo } from "../components/Logo";
import {
  EnglishLanguageIcon,
  FrenchLanguageIcon,
  GermanLanguageIcon,
} from "../components/Icons";
import { LogoAlternate } from "../components/Logo";
import { CheckBox, DoneAll, DoneAllOutlined } from "@material-ui/icons";
import SubMenus from "./topbar/submenu";
import {
  Button,
  CardContent,
  CardHeader,
  CircularProgress,
  Grid,
  Typography,
  TextField,
  CardActions,
} from "@material-ui/core";
import { Formik } from "formik";
import * as Yup from "yup";

import {
  Post_GenerateAuthCode_URL,
  Post_VerifyAuthCode_URL,
} from "../constants/apiUrls";

import { Get, Create } from "../actions";
import { useSnackbar } from "notistack";
import ButtonWithLoading from "../components/ButtonWithLoading";
import { getTranslation } from "../heplers/translationHelper";
import { useNavigate } from "react-router-dom";




export default function PublicLayout(props) {
  const { menu } = useSelector((state) => state.publicActionButtons);
  const { meetingId, contactId, teamMemberId, meetingMemberId, meetingDocumentId, circularResolutionId } = useParams();
  const navigate = useNavigate();
  let { pathname: path } = useLocation()
  let values = path.split('/').filter(item => item.trim() !== '');
  console.log("values", values)


  const [isAuthenticated, setAuthenticated] = useState(false);
  const [resourceUnAvailable, setResourceUnAvailable] = useState(false);
  const [invalidData, setInvalidData] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isFormVisible, setFormVisibility] = useState(true);
  const [timer, setTimer] = useState(60);
  const [isTimerRunning, setIsTimerRunning] = useState(true);
  const [isButtonEnabled, setIsButtonEnabled] = useState(false);



  const { enqueueSnackbar } = useSnackbar();



  const getResourceFromPath = () => {
    if (path.includes("/public/askfordocument/")) {
      return "askingMeetingDocument";
    } else if (path.includes("/public/joinmeeting/")) {
      return "meetingInvitationEmail";
    } else if (path.includes("/public/askagenda/")) {
      return "askingMeetingAgenda";
    } else if (path.includes("/public/minutes/")) {
      return "askingMeetingMinutesAndComments";
    } else if (path.includes("/public/comments/")) {
      return "askingMeetingMinutesAndComments";
    }
    return "";
  };


  useEffect(() => {
    let timerInterval;

    if (isTimerRunning && timer > 0) {
      timerInterval = setInterval(() => {
        setTimer(prevTimer => (prevTimer > 0 ? prevTimer - 1 : prevTimer));
      }, 1000);
    } else {
      clearInterval(timerInterval);
      setIsButtonEnabled(true);
    }

    return () => {
      clearInterval(timerInterval);
    };
  }, [isTimerRunning, timer]);

  const handleResendClick = () => {
    GenerteAuthCode()
    setTimer(60);
    setIsTimerRunning(true);
    setIsButtonEnabled(false);
  };

  useEffect(() => {

    // Check the route for outlet true 
    if (path.includes("/public/form")) {
      setFormVisibility(false)
    } else if (path.includes("/public/table")) {
      setFormVisibility(false)
    } else if (path.includes("/public/notification")) {
      setFormVisibility(false)
    }


    if (meetingId || contactId || teamMemberId || meetingMemberId || meetingDocumentId || circularResolutionId) {
      GenerteAuthCode()
    }
  }, []);







  const useStyles = makeStyles({
    select: {
      "&:before": {
        borderColor: "white",
      },
      "&:after": {
        borderColor: "white",
      },
      "&:not(.Mui-disabled):hover::before": {
        borderColor: "white",
      },
    },
    icon: {
      fill: "white",
    },
    root: {
      color: "white",
    },
  });
  const classes = useStyles();

  const handleLanguageSelection = (e) => {
    localStorage.setItem("lang", e.target.value);
    window.location.reload(true);
  };

  const initialValues = {
    otp: "",
  };

  const basicValidationSchema = Yup.object().shape({
    otp: Yup.string().required("otp is required"),
  });


  const GenerteAuthCode = () => {
    const resource = getResourceFromPath();
    //setLoading(true);
    Create(
      {
        meetingId: meetingId,
        contactId: contactId,
        teamMemberId: teamMemberId,
        memberId: meetingMemberId,
        circularResolutionId: circularResolutionId,
        meetingDocumentId: meetingDocumentId,
        resource: resource,
      },
      Post_GenerateAuthCode_URL,
      null,
      (resp) => {
        enqueueSnackbar(resp.data, { variant: "success" });
        console.log("success", resp.data);
        setLoading(false);
      },
      (error) => {
        // commnet becouse of change requirement
        // if (error.data == "This resource is currently not available.") {
        //   setLoading(false);
        //   return setResourceUnAvailable(true);
        // }
        setLoading(false);
        setInvalidData(true);
        enqueueSnackbar(error.data, { variant: "error" });
        navigate("/invalidData");
      }
    );
  }

  const VerifyOtp = (values, actions) => {
    const { otp } = values;

    const resource = getResourceFromPath();

    const payload = {
      meetingId: meetingId,
      contactId: contactId,
      memberId: meetingMemberId,
      teamMemberId: teamMemberId,
      circularResolutionId: circularResolutionId,
      meetingDocumentId: meetingDocumentId,
      resource: resource,
      code: otp,
    };
    Create(
      payload,
      Post_VerifyAuthCode_URL,
      null,
      (resp) => {
        enqueueSnackbar(resp.data, { variant: "success" });
        setAuthenticated(true);
        actions.setSubmitting(false);
        // setBusy(false);
        // setOpenDeclineCommentsDialog(false);
        setFormVisibility(false)
        console.log("success", resp.data);
      },
      (error) => {
        enqueueSnackbar(error.data, { variant: "error" });
        actions.setSubmitting(false);
        //setFormVisibility(false)
      }
    );
  };

  return (
    <>
      {" "}
      <AppBar>
        <Toolbar>
          <LogoAlternate />
          {/* <IconButton
        color="inherit"
        aria-label="open drawer"
        onClick={handleDrawerOpen}
        edge="start"
        className={clsx(classes.menuButton, navOpen && classes.hide)}
      >
        <MenuIcon />
      </IconButton>
      <Box width="100%">
        <SubMenus />
      </Box> */}
          <Box
            width="100%"
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            {menu}
          </Box>
          <Box display="flex" alignItems="right" justifyContent="align-right">
            <Select
              // style={{
              //   marginRight: "20px",
              //   width: "160px",
              //   height: "34px",
              //   borderRadius: "17px",
              //   color: 'white',
              //   borderColor: 'white'
              // }}
              inputProps={{
                classes: {
                  icon: classes.icon,
                  root: classes.root,
                },
              }}
              className={classes.select}
              variant="standard"
              defaultValue={localStorage.getItem("lang")}
              onChange={(e) => handleLanguageSelection(e)}
            >
              <MenuItem value="en" style={{ borderColor: "white" }}>
                <IconButton style={{ marginRight: "10px" }}>
                  <EnglishLanguageIcon />
                </IconButton>
                English
              </MenuItem>
              <MenuItem value="fr" style={{ borderColor: "white" }}>
                <IconButton style={{ marginRight: "10px" }}>
                  <FrenchLanguageIcon />
                </IconButton>
                French
              </MenuItem>
              <MenuItem value="gr" style={{ borderColor: "white" }}>
                <IconButton style={{ marginRight: "10px" }}>
                  <GermanLanguageIcon />
                </IconButton>
                German
              </MenuItem>
            </Select>
          </Box>
        </Toolbar>
      </AppBar>
      <Container component="main" width="100%" style={{ marginTop: "100px" }}>
        <CssBaseline />
        <Box width="100%" display="flex" justifyContent="center" m={1} p={1}>
          {/* <Logo height="70px" /> */}
        </Box>

        {loading ? (
          <Box display="flex" alignItems="center" justifyContent="center" minHeight="200px">
            <CircularProgress color="primary" />
          </Box>
        ) : resourceUnAvailable ? (
          <h1>Thank you for your Time, Your Response Already Generated</h1>
        ) : (
          <>
            {isFormVisible && (
              <div style={{ marginLeft: '10px', textAlign: 'center', padding: '20px', backgroundColor: '#fff', borderRadius: '10px', boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)' }}>
                <div style={{ marginLeft: "10px" }}>
                  <Typography align="center" variant="h1" style={{
                    fontSize: "3rem",
                    fontWeight: "bold",
                    textAlign: "center",
                    textTransform: "uppercase",
                    letterSpacing: "2px",
                  }}>
                    Not Authorized
                  </Typography>
                </div>

                <Typography align="center" variant="h3" style={{ fontSize: '1.5rem', marginBottom: '20px', marginTop: '20px' }}>
                  Aureto has sent you a Verification Code. Please Enter your Code to get Authenticated.
                </Typography>

                <Formik
                  enableReinitialize
                  initialValues={initialValues}
                  validationSchema={basicValidationSchema}
                  onSubmit={(values, actions) => {
                    VerifyOtp(values, actions);
                  }}
                >
                  {({
                    errors,
                    touched,
                    values,
                    handleSubmit,
                    isSubmitting,
                    getFieldProps,
                    setFieldValue,
                  }) => (
                    <form>
                      <CardContent style={{ padding: '0' }}>
                        <Grid container justify="center">
                          <Grid item xs={12} sm={12} md={6} lg={4}>
                            <TextField
                              fullWidth
                              label={getTranslation("Otp", "Otp", "Otp")}
                              required
                              size="small"
                              variant="outlined"
                              name="otp"
                              {...getFieldProps("otp")}
                              error={touched.otp && Boolean(errors.otp)}
                              helperText={touched.otp && errors.otp}
                              InputLabelProps={{
                                shrink: true,
                              }}
                              style={{ marginBottom: '20px' }}
                            />
                          </Grid>
                        </Grid>
                      </CardContent>
                      <CardActions>
                        <Box
                          pr={1}
                          pb={1}
                          width="100%"
                          display="flex"
                          alignItems="center"
                          justifyContent="center"
                        >
                          <ButtonWithLoading
                            title={getTranslation("Send", "Send", "Send")}
                            size="large"
                            variant="contained"
                            color="primary"
                            loading={isSubmitting}
                            onClick={handleSubmit}
                          // style={{ borderRadius: '25px' }}
                          />
                          <div style={{ marginLeft: "10px" }}>
                            <Button
                              onClick={handleResendClick}
                              size="large"
                              variant="contained"
                              color="primary"
                              disabled={!isButtonEnabled} // Disable the button based on isButtonEnabled
                            >
                              {!isButtonEnabled ? `Resend in ${timer} secs` : getTranslation("ReSend Code", "ReSend Code", "ReSend Code")}
                            </Button>
                          </div>

                        </Box>
                      </CardActions>
                    </form>
                  )}
                </Formik>
              </div>
            )}
          </>

        )}

        {!isFormVisible && <Outlet />}
      </Container>
    </>
  );
}
