import { useEffect } from "react";
import { parseMinutesReportHelper } from "../../../../heplers/parseMinutesReportHelper";
import renderHTML from "react-render-html";
import { useState } from "react";

export const RenderAgendaItem=({agendaItem})=>{
    const [html,setHtml]=useState();
    useEffect(()=>{
        parseMinutesReportHelper(null,agendaItem?.comments,newHtml=>{
            setHtml(newHtml)
        })
    },[agendaItem])

    return <div>
    {html? renderHTML(html) : null} 
    </div>
}