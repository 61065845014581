import { createRef, useRef, useState } from "react";
import { useParams } from "react-router";
import { useEffect } from "react";
import CardComponent from "../../components/Card";
import {
  Box,
  Button,
  CardContent,
  CardHeader,
  CircularProgress,
  Grid,
  IconButton,
  Typography,
  TextField,
  CardActions,
} from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";

import { Formik } from "formik";
import * as Yup from "yup";
import { UploadFiles } from "../../components/Upload";
import {
  Get_AllMeetingAgendas_URL,
  Get_CompanyInfo_URL,
  Get_Files_URL,
  Get_MeetingInfo_URL,
  Post_MeetingAcceptMinutes_URL,
  Post_MeetingDeclineMinutes_URL,
  Post_GenerateAuthCode_URL,
  Post_VerifyAuthCode_URL,
} from "../../constants/apiUrls";
import { getTranslation } from "../../heplers/translationHelper";
import { DeclineCommentsDialog, ReviewAgenda } from "./component/minutes";
import { useDispatch } from "react-redux";
import { Add_Public_ActionButtons } from "../../constants/types";
import { Close, DoneAll } from "@material-ui/icons";

import jsPDF from "jspdf";
import { useSnackbar } from "notistack";
import { Get, Create } from "../../actions";
import { CompanyInformation, MeetingInformation } from "../public/components";
import ButtonWithLoading from "../../components/ButtonWithLoading";

export const ReviewMinutes = ({ isValidateAuthenticateEnabled }) => {
  console.log("isValid", isValidateAuthenticateEnabled);
  const { meetingId, contactId } = useParams();
  console.log("insideassna", meetingId);

  const [meetingInfo, setMeetingInfo] = useState({});
  const [companyInfo, setCompanyInfo] = useState({});
  const [meetingDocuments, setMeetingDocuments] = useState([]);
  const [meetingAgendas, setMeetingAgendas] = useState([]);
  const [isBusy, setBusy] = useState(false);
  const [openDeclineCommentsDialog, setOpenDeclineCommentsDialog] =
    useState(false);

  const [isAuthenticated, setAuthenticated] = useState(false);
  const [resourceUnAvailable, setResourceUnAvailable] = useState(false);
  const [loading, setLoading] = useState(true);

  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    setLoading(true);

    Get(
      {
        meetingId: meetingId,
      },
      Get_MeetingInfo_URL,
      null,
      (resp) => {
        setMeetingInfo(resp?.data);
      },
      (error) => {}
    );

    Get(
      {
        meetingId: meetingId,
      },
      Get_CompanyInfo_URL,
      null,
      (resp) => {
        setCompanyInfo(resp?.data);
      },
      (error) => {}
    );
    Get(
      {
        meetingId: meetingId,
      },
      Get_Files_URL,
      null,
      (resp) => {
        setMeetingDocuments(resp?.data);
      },
      (error) => {}
    );
    Get(
      {
        meetingId: meetingId,
      },
      Get_AllMeetingAgendas_URL,
      null,
      (resp) => {
        setMeetingAgendas(resp?.data);
      },
      (error) => {}
    );

    // if (isValidateAuthenticateEnabled) {
    //   Create(
    //     {
    //       meetingId: meetingId,
    //       contactId: 97,
    //       resource: "approveMinutesReport",
    //     },
    //     Post_GenerateAuthCode_URL,
    //     null,
    //     (resp) => {
    //       enqueueSnackbar(resp.data, { variant: "success" });
    //       // setBusy(false);
    //       // setOpenDeclineCommentsDialog(false);
    //       console.log("success", resp.data);
    //       setLoading(false);
    //     },
    //     (error) => {
    //       if (error.data == "This resource is currently not available.") {
    //         setLoading(false);
    //         return setResourceUnAvailable(true);
    //       }
    //       setLoading(false);
    //       enqueueSnackbar(error.data, { variant: "error" });
    //       // <Alert severity="error">This is an error alert — check it out!</Alert>
    //     }
    //   );
    // }
  }, [meetingId]);

  const accept = async () => {
    const content = printComponent.current;
    setBusy(true);
    var newHtmldoc = new jsPDF({
      orientation: "p",
      unit: "pt",
      format: "a4",
    });
    newHtmldoc.html(content, {
      callback: (doc) => {
        var base64 = doc.output("datauristring");

        Create(
          {
            meetingId: meetingId,
            minuteReportStatus: meetingInfo?.minuteReportStatus,
            minutes: content,
            minutes: base64.split(",")[1],
          },
          Post_MeetingAcceptMinutes_URL,
          null,
          (resp) => {
            enqueueSnackbar(resp.data, { variant: "success" });
            setBusy(false);
          },
          (error) => {
            enqueueSnackbar(error.data, { variant: "error" });
            setBusy(false);
          }
        );
        // console.log(base64);
        //   doc.save("Meeting Report.pdf")
        //   setIsPrinting(false)
      },
    });
    // Create(
    //     {
    //         meetingId: meetingId,
    //         minutes:html
    //     },

    // )
  };

  const decline = (comments) => {
    setBusy(true);
    Create(
      {
        meetingId: meetingId,
        comments: comments,
      },
      Post_MeetingDeclineMinutes_URL,
      null,
      (resp) => {
        enqueueSnackbar(resp.data, { variant: "success" });
        setBusy(false);
        setOpenDeclineCommentsDialog(false);
      },
      (error) => {
        enqueueSnackbar(error.data, { variant: "error" });
        setBusy(false);
      }
    );
  };

  const initialValues = {
    otp: "",
  };

  const basicValidationSchema = Yup.object().shape({
    otp: Yup.string().required("otp is required"),
  });

  const printComponent = useRef();

  const dispatch = useDispatch();

  const VerifyOtp = (values, actions) => {
    const { otp } = values;
    const payload = {
      meetingId: meetingId,
      contactId: 97,
      resource: "approveMinutesReport",
      code: otp,
    };
    Create(
      payload,
      Post_VerifyAuthCode_URL,
      null,
      (resp) => {
        enqueueSnackbar(resp.data, { variant: "success" });
        setAuthenticated(true);
        actions.setSubmitting(false);
        // setBusy(false);
        // setOpenDeclineCommentsDialog(false);
        console.log("success", resp.data);
      },
      (error) => {
        enqueueSnackbar(error.data, { variant: "error" });
        actions.setSubmitting(false);
      }
    );
  };



  return (
    <>
      {loading ? (
        <></>
      ) : resourceUnAvailable ? (
        <h1>Thank you for your Time Your Response Already Generated</h1>
      ) : (
        <CardComponent
          style={{
            paddingTop: "30px",
            paddingLeft: "20px",
            paddingRight: "20px",
            paddingBottom: "30px",
          }}
        >
          {isValidateAuthenticateEnabled && !isAuthenticated ? (
            <>
              <div style={{ marginLeft: "10px" }}>
                <Typography align="center" variant="h4">
                  Not Authorized
                </Typography>
              </div>
              <br />
              <br />
              <br />

              <Typography align="center" variant="h3">
                Aureto has send you a varification code please enter Your Code
                to get Authenticated
              </Typography>

              <br />

              <Typography variant="h4">Enter OTP Verifcation Number</Typography>
              <br />

              <Formik
                enableReinitialize
                initialValues={initialValues}
                validationSchema={basicValidationSchema}
                onSubmit={(values, actions) => {
                  VerifyOtp(values, actions);
                }}
              >
                {({
                  errors,
                  touched,
                  values,
                  handleSubmit,
                  isSubmitting,
                  getFieldProps,
                  setFieldValue,
                }) => (
                  <form>
                    <CardContent style={{ padding: 0 }}>
                      <Grid container>
                        <Grid item xs={12} sm={12} md={8} lg={8}>
                          <TextField
                            fullWidth
                            label={getTranslation("Title", "Titre", "Titel")}
                            required
                            size="small"
                            variant="outlined"
                            name="otp"
                            {...getFieldProps("otp")}
                            error={touched.otp && Boolean(errors.otp)}
                            helperText={touched.otp && errors.otp}
                            InputLabelProps={{
                              shrink: true,
                            }}
                          />
                        </Grid>
                      </Grid>
                    </CardContent>
                    <CardActions>
                      <Box
                        pr={1}
                        pb={1}
                        width="100%"
                        display="flex"
                        alignItems="center"
                        justifyContent="flex-end"
                      >
                        <ButtonWithLoading
                          title={getTranslation("Create", "Créer", "Erstellen")}
                          size="small"
                          variant="contained"
                          color="primary"
                          loading={isSubmitting}
                          onClick={handleSubmit}
                        />
                      </Box>
                    </CardActions>
                  </form>
                )}
              </Formik>
            </>
          ) : (
            <>
              <Typography variant="h4" align="center">
                {getTranslation("Minutes Report")}
              </Typography>
              <br />
              <br />
              <Box
                display="flex"
                alignItems={"flex-end"}
                justifyContent="flex-end"
              >
                <ButtonWithLoading
                  title={
                    <>
                      <DoneAll style={{ color: "green" }} />
                      &nbsp;&nbsp;&nbsp;
                      <Typography>Mark as Approved</Typography>
                    </>
                  }
                  variant="contained"
                  color="primary"
                  loading={isBusy}
                  onClick={() => accept()}
                  // disabled={meetingInfo?.minuteReportStatus!=2}
                  disabled={
                    meetingInfo?.minuteReportStatus === 0 ||
                    meetingInfo?.minuteReportStatus === 1
                  }
                />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                <ButtonWithLoading
                  title={
                    <>
                      <Close style={{ color: "red" }} />
                      &nbsp;&nbsp;&nbsp;
                      <Typography>Mark as Declined</Typography>
                    </>
                  }
                  variant="contained"
                  color="default"
                  loading={isBusy}
                  onClick={() => decline()}
                  //disabled={meetingInfo?.minuteReportStatus!=2}
                  disabled={
                    meetingInfo?.minuteReportStatus === 0 ||
                    meetingInfo?.minuteReportStatus === 1
                  }
                />
              </Box>
              <br />
              <br />
              <MeetingInformation meetingInfo={meetingInfo} />
              <br />
              <br />
              <CompanyInformation companyInfo={companyInfo} />
              <br />
              <br />
              <CardComponent>
                <CardHeader
                  title={getTranslation(
                    "Uploaded Documents",
                    "Uploaded Documents",
                    "Uploaded Documents"
                  )}
                />
                <CardContent>
                  <UploadFiles
                    uploadedFiles={meetingDocuments}
                    getFileName={(file) => {
                      return file.fileName;
                    }}
                    onAddFile={(file) => {
                      console.log(file);
                    }}
                    disabled
                  />
                </CardContent>
              </CardComponent>
              <br />
              <br />
              <ReviewAgenda agendas={meetingAgendas} ref={printComponent} />
              <DeclineCommentsDialog
                open={openDeclineCommentsDialog}
                onClose={() => setOpenDeclineCommentsDialog(false)}
                onSubmit={decline}
              />
            </>
          )}
        </CardComponent>
      )}

      {/* {" "}
      <CardComponent
        style={{
          paddingTop: "30px",
          paddingLeft: "20px",
          paddingRight: "20px",
          paddingBottom: "30px",
        }}
      >
        {isValidateAuthenticateEnabled && !isAuthenticated ? (
          <>
            <div style={{ marginLeft: "10px" }}>
              <Typography align="center" variant="h4">
                Not Authorized
              </Typography>
            </div>
            <br />
            <br />
            <br />

            <Typography align="center" variant="h3">
              Aureto has send you a varification code please enter Your Code to
              get Authenticated
            </Typography>

            <br />

            <Typography variant="h4">Enter OTP Verifcation Number</Typography>
            <br />

            <Formik
              enableReinitialize
              initialValues={initialValues}
              validationSchema={basicValidationSchema}
              onSubmit={(values, actions) => {
                VerifyOtp(values,actions)
              }}
            >
              {({
                errors,
                touched,
                values,
                handleSubmit,
                isSubmitting,
                getFieldProps,
                setFieldValue,
              }) => (
                <form>
                  <CardContent style={{ padding: 0 }}>
                    <Grid container>
                      <Grid item xs={12} sm={12} md={8} lg={8}>
                        <TextField
                          fullWidth
                          label={getTranslation("Title", "Titre", "Titel")}
                          required
                          size="small"
                          variant="outlined"
                          name="otp"
                          {...getFieldProps("otp")}
                          error={touched.otp && Boolean(errors.otp)}
                          helperText={touched.otp && errors.otp}
                          InputLabelProps={{
                            shrink: true,
                          }}
                        />
                      </Grid>
                    </Grid>
                  </CardContent>
                  <CardActions>
                    <Box
                      pr={1}
                      pb={1}
                      width="100%"
                      display="flex"
                      alignItems="center"
                      justifyContent="flex-end"
                    >
                      <ButtonWithLoading
                        title={getTranslation("Create", "Créer", "Erstellen")}
                        size="small"
                        variant="contained"
                        color="primary"
                        loading={isSubmitting}
                        onClick={handleSubmit}
                      />
                    </Box>
                  </CardActions>
                </form>
              )}
            </Formik>
          </>
        ) : (
          <>
            <Typography variant="h4" align="center">
              {getTranslation("Minutes Report")}
            </Typography>
            <br />
            <br />
            <Box
              display="flex"
              alignItems={"flex-end"}
              justifyContent="flex-end"
            >
              <ButtonWithLoading
                title={
                  <>
                    <DoneAll style={{ color: "green" }} />
                    &nbsp;&nbsp;&nbsp;
                    <Typography>Mark as Approved</Typography>
                  </>
                }
                variant="contained"
                color="primary"
                loading={isBusy}
                onClick={() => accept()}
                // disabled={meetingInfo?.minuteReportStatus!=2}
                disabled={
                  meetingInfo?.minuteReportStatus === 0 ||
                  meetingInfo?.minuteReportStatus === 1
                }
              />
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              <ButtonWithLoading
                title={
                  <>
                    <Close style={{ color: "red" }} />
                    &nbsp;&nbsp;&nbsp;
                    <Typography>Mark as Declined</Typography>
                  </>
                }
                variant="contained"
                color="default"
                loading={isBusy}
                onClick={() => decline()}
                //disabled={meetingInfo?.minuteReportStatus!=2}
                disabled={
                  meetingInfo?.minuteReportStatus === 0 ||
                  meetingInfo?.minuteReportStatus === 1
                }
              />
            </Box>
            <br />
            <br />
            <MeetingInformation meetingInfo={meetingInfo} />
            <br />
            <br />
            <CompanyInformation companyInfo={companyInfo} />
            <br />
            <br />
            <CardComponent>
              <CardHeader
                title={getTranslation(
                  "Uploaded Documents",
                  "Uploaded Documents",
                  "Uploaded Documents"
                )}
              />
              <CardContent>
                <UploadFiles
                  uploadedFiles={meetingDocuments}
                  getFileName={(file) => {
                    return file.fileName;
                  }}
                  onAddFile={(file) => {
                    console.log(file);
                  }}
                  disabled
                />
              </CardContent>
            </CardComponent>
            <br />
            <br />
            <ReviewAgenda agendas={meetingAgendas} ref={printComponent} />
            <DeclineCommentsDialog
              open={openDeclineCommentsDialog}
              onClose={() => setOpenDeclineCommentsDialog(false)}
              onSubmit={decline}
            />
          </>
        )}
      </CardComponent> */}
    </>
  );
};
