import React, { useState, useEffect } from "react";
import {
  CardContent,
  CardActions,
  Grid,
  TextField,
  Box,
  IconButton,
} from "@material-ui/core";
import { Form, Formik } from "formik";
import { useSnackbar } from "notistack";
import Card from "../../../../../components/Card";
import { makeStyles } from "@material-ui/core/styles";
import Autocomplete, {
  createFilterOptions,
} from "@material-ui/lab/Autocomplete";
import ButtonWithLoading from "../../../../../components/ButtonWithLoading";
import { useDispatch, useSelector } from "react-redux";
import * as Yup from "yup";
import { useNavigate } from "react-router-dom";
import CircularProgress from "@material-ui/core/CircularProgress";
import { Create, Get } from "../../../../../actions";
import {
  GET_AllContactList_URL,
  Get_AllServices_URL,
} from "../../../../../constants/apiUrls";
import { getTranslation } from "../../../../../heplers/translationHelper";
import { Cancel } from "@material-ui/icons";

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

// const representativeRoles = ["President", "Secretary", "Ordinary Member"];

const CreateService = ({
  onCancel,
  onSubmit,
  companyId,
  editExternalServiceProvider,
  isSubmitting,
}) => {
  console.log("klsjdhfkjsdflg", editExternalServiceProvider);
  if (editExternalServiceProvider) {
    editExternalServiceProvider.contactId =
      editExternalServiceProvider.contact.id;
  }
  const [ceresServiceTypes, setCeresServiceTypes] = useState([]);
  const [ceresServiceContactList, setCeresServiceContactList] = useState([]);
  const history = useNavigate();
  const filter = createFilterOptions();
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    loadAllCeresServiceTypes();
    loadAllCeresServiceContactList();
  }, []);

  const loadAllCeresServiceContactList = () => {
    Get(
      {},
      GET_AllContactList_URL,
      null,
      (resp) => {
        setCeresServiceContactList(resp.data);
      },
      (error) => {}
    );
  };
  const loadAllCeresServiceTypes = () => {
    Get(
      {},
      Get_AllServices_URL,
      history,
      (response) => {
        setCeresServiceTypes(response.data);
      },
      (error) => {}
    );
  };
  const initialValues = {
    representativeRole: "",
    companyId: companyId,
    serviceId: 0,
    contactId: editExternalServiceProvider
      ? editExternalServiceProvider.contact.id
      : 0,
    service: editExternalServiceProvider
      ? editExternalServiceProvider.service
      : "",
    contact: editExternalServiceProvider
      ? editExternalServiceProvider.contact
      : "",
  };

  // const basicValidationSchema = Yup.object().shape({
  //   serviceId: Yup.number()
  //     .min(1)
  //     .required(
  //       getTranslation(
  //         "Service Provider is required",
  //         "Le fournisseur de services est requis",
  //         "Dienstanbieter ist erforderlich"
  //       )
  //     ),
  //   contactId: Yup.number()
  //     .min(1)
  //     .required(getTranslation("Contact is required")),
  //   representativeRole: Yup.string().required(
  //     getTranslation("Representative Role")
  //   ),
  // });

  const basicValidationSchema = Yup.object().shape({
    serviceId: Yup.number().test('serviceRequired', 'Service is required', value => value !== 0),
    contactId: Yup.number().test('serviceRequired', 'Person Representative is required', value => value !== 0),
    representativeRole: Yup.string().required(
      getTranslation("Representative Role")
    ),
  });

 
  
  

  const defaultValue = editExternalServiceProvider
    ? editExternalServiceProvider
    : initialValues;
  return (
    <Card
      title={getTranslation(
        "Add Service Provider",
        "Ajouter un fournisseur de services",
        "Dienstanbieter hinzufügen"
      )}
    >
      {/* <CardHeader title='Details' /> */}
      <Formik
        enableReinitialize
        initialValues={defaultValue}
        validationSchema={basicValidationSchema}
        validate={(values) => {
          console.log("Validation triggered", values);
          return {};
        }}
        onSubmit={(values, actions) => {
          actions.setSubmitting(true);
          onSubmit(values, actions);
          actions.resetForm();
        }}
      >
        {({
          errors,
          touched,
          values,
          handleSubmit,
          //isSubmitting,
          getFieldProps,
          setFieldValue,
          setTouched
        }) => (
          <Form>
            {console.log("knvlkfsgdfg", errors)}
            <CardContent>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={12} md={3} lg={3}>
                  <Autocomplete
                    name="serviceId"
                    //value={values.service}
                    options={ceresServiceTypes}
                    getOptionLabel={(option) =>
                      option?.title + "\t" + option?.serviceType?.title
                    }
                    size="small"
                    onChange={(e, value) => {
                      if (value) {
                    //     setFieldValue("serviceId", value?.id);
                    //     setFieldValue("service", value.title);
                    //   }
                    // }}
                   // setSelectedContact(value); 
                    setFieldValue("serviceId", value.id); 
                    setFieldValue("service",  value.title); 
                    setTouched("serviceId", true); 
                    setTouched("service", true); 
                      }
                  }}
                  onBlur={() => {
                    setTouched("serviceId", true);
                    setTouched("service", true);
                  }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label={getTranslation(
                          "Select Service Provider",
                          "Sélectionnez le fournisseur de services",
                          "Dienstanbieter auswählen"
                        )}
                        variant="outlined"
                      //   error={touched.serviceId && Boolean(errors.serviceId)}
                      // />
                      error={(touched.serviceId || touched.service) && Boolean(errors.serviceId || errors.service)}
                      helperText={(touched.serviceId || touched.service) && (errors.serviceId || errors.service)}
                    />
                    )}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={3} lg={3}>
                  <Autocomplete
                    name="contactId"
                    value={values?.contact}
                    options={ceresServiceContactList}
                    getOptionLabel={(option) => option.fullName}
                    size="small"
                    onChange={(e, representative) => {
                      if (representative) {
                        setFieldValue("contactId", representative.id);
                        setFieldValue("contact", representative);
                        setTouched("conatctId", true); 
                        setTouched("contact", true); 
                      }
                    }}
                    onBlur={() => {
                      setTouched("contactId", true);
                      setTouched("contact", true);
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label={getTranslation(
                          "Person Representative",
                          "Représentant de la personne",
                          "Personenvertreter"
                        )}
                        variant="outlined"
                        error={(touched.contactId || touched.contact) && Boolean(errors.contactId || errors.contact)}
                        helperText={(touched.contactId || touched.contact) && (errors.contactId || errors.contact)}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={3} lg={3}>
                  <TextField
                    fullWidth
                    size="small"
                    variant="outlined"
                    id="representativeRole"
                    value={values.representativeRole}
                    label="Representative Role"
                    name="representativeRole"
                    // {...getFieldProps("representativeRole")}
                    onChange={(e)=>{
                      setFieldValue("representativeRole",e.target.value)
                    }}
                    error={
                      touched.representativeRole &&
                      Boolean(errors.representativeRole)
                    }
                    helperText={
                      touched.representativeRole && errors.representativeRole
                    }
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={3} lg={3}>
                  <Box pr={1} pb={1} width="100%">
                    {editExternalServiceProvider?
                      <IconButton onClick={onCancel}>
                        <Cancel/>
                      </IconButton>
                    :null}
                    <ButtonWithLoading
                      title={
                        editExternalServiceProvider
                          ? getTranslation(
                              "Update",
                              "Mettre à jour",
                              "Aktualisieren"
                            )
                          : getTranslation("Add", "Ajouter", "Addieren")
                      }
                      size="small"
                      variant="contained"
                      color="primary"
                      loading={isSubmitting}
                      onClick={() => {
                        if (Object.keys(errors).length > 0) {
                          enqueueSnackbar("Some fields are missing.", {
                            variant: "error",
                          });
                        } else handleSubmit();
                      }}
                    />
                  </Box>
                </Grid>
              </Grid>
            </CardContent>
          </Form>
        )}
      </Formik>
    </Card>
  );
};

export default CreateService;
