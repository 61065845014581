import Dialog from "../../../../../../components/Dialog";
import {
  Autocomplete,
  ToggleButton,
  ToggleButtonGroup,
} from "@material-ui/lab";
import { useState } from "react";
import { Box, TextField } from "@material-ui/core";
import { useEffect } from "react";
import ButtonWithLoading from "../../../../../../components/ButtonWithLoading";
import { Get } from "../../../../../../actions";
import {
  GET_AllContactList_URL,
  GET_AllUsersWithoutAuthorization_URL,
} from "../../../../../../constants/apiUrls";
import { getTranslation } from "../../../../../../heplers/translationHelper";
import { useSnackbar } from "notistack";
import { Form, Formik } from "formik";
import * as Yup from "yup";
const SelectContactDialog = ({ selectedContact, open, onClose, onSubmit }) => {
  // selected contact will be used later for update. leaving for now
  const [selected, setSelected] = useState("contacts");
  const [contacts, setContacts] = useState([]);
  const [ceresMembers, setCeresMembers] = useState([]);
  const { enqueueSnackbar } = useSnackbar();
  useEffect(() => {
    if (selected === "contacts") {
      loadContacts();
    } else {
      loadCeresMembers();
    }
  }, [selected]);

  const loadContacts = () => {
    Get(
      {},
      GET_AllContactList_URL,
      null,
      (resp) => {
        setContacts(resp?.data);
      },
      (error) => {
        enqueueSnackbar(error.data, { variant: "error" });
      }
    );
  };

  const loadCeresMembers = () => {
    Get(
      {},
      GET_AllUsersWithoutAuthorization_URL,
      null,
      (resp) => {
        setCeresMembers(resp?.data);
      },
      (error) => {
        enqueueSnackbar(error.data, { variant: "error" });
      }
    );
  };

  const initialValues = {
    reportingUser: "",
    userType: "",
  };
  return (
    <Dialog fullWidth open={open} onClose={onClose} title="Select Contact">
      <ToggleButtonGroup
        size="small"
        variant="outlined"
        value={selected}
        exclusive
        onChange={(event, value) => {
          if (value) {
            setSelected(value);
          }
        }}
      >
        <ToggleButton value="contacts">
          {getTranslation("Contacts", "Contacts", "Kontakte")}
        </ToggleButton>
        <ToggleButton value="ceresMember">
          {getTranslation("Ceres Members", "Cérès Membres", "Ceres Mitglieder")}
        </ToggleButton>
      </ToggleButtonGroup>
      <br />
      <br />

      <Formik
        initialValues={initialValues}
        onSubmit={(values) => {
          onSubmit(values);
        }}
      >
        {({ errors, values, handleSubmit, setFieldValue }) => (
          <Form>
            <Autocomplete
              maxWidth="100%"
              fullWidth
              size="small"
              options={selected === "contacts" ? contacts : ceresMembers}
              getOptionLabel={(option) =>
                selected === "contacts" ? option.fullName : option.name
              }
              onChange={(e, user) => {
                setFieldValue("userType", selected);
                if (user) {
                  setFieldValue("reportingUser", user);
                }
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label={selected === "contacts" ? "Contact" : "CERES Member"}
                  variant="outlined"
                />
              )}
            />
            <Box
              display="flex"
              alignItems="center"
              justifyContent="center"
              mt={2}
            >
              <ButtonWithLoading
                title={
                  selected === "contacts" ? "Add Contact" : "Add CERES Member"
                }
                onClick={handleSubmit}
                color="primary"
                variant="contained"
                size="small"
              />
            </Box>
          </Form>
        )}
      </Formik>
    </Dialog>
  );
};
export default SelectContactDialog;
