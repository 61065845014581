import moment from 'moment'
import parse from "html-react-parser";
export function format(formatType,value){
    switch(formatType){
      case "date":
        return moment(value).format('DD-MM-YYYY');
      case "dateTime":
        return moment(value).format('DD-MM-YYYY HH:mm');
        case "time":
          return moment(value).format('LT');
      default :
        return value;
    }
  }   
 
  export function parseHTML(html){
    if(html)
    return parse(html)
  }