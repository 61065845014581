import React, { useEffect, useState } from "react";
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Grid,
  TextField,
  AppBar,
  Toolbar,
  IconButton,
  Button,
  Typography,
  Slide
} from "@material-ui/core";
import { Collapser } from "../../../../components/Collapser";
import { Get } from "../../../../actions";
import {
  Get_AllForms_URL,
  Get_AllTables_URL,
} from "../../../../constants/apiUrls";
import ButtonWithLoading from "../../../../components/ButtonWithLoading";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import SectionTable from "./sectionTables";
import { getTranslation } from "../../../../heplers/translationHelper";
import { HtmlEditor } from "../../../../components/HtmlEditor";
import { useRef } from "react";
import Dialog from "@material-ui/core/Dialog";
import { makeStyles } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';
const useStyles = makeStyles((theme) => ({
  appBar: {
    position: 'relative',
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
})
const AddSection = ({ editSection, onSubmit, open, onClose }) => {
  const classes = useStyles();
  console.log("jhasdvgfsdf", editSection);
  const [tabelRows, setTabelRows] = useState([]);
  const [forms, setForms] = useState([]);
  const [isFormsLoading, setIsFormsLoading] = useState(true);
  const [isTablesLoading, setIsTablesLoading] = useState(true);
  //const [htmlCode, setHtmlCode] = useState(`<p>&nbsp;</p>`);

  useEffect(() => {
    loadTables();
    loadforms();
  }, []);

  const loadTables = () => {
    setIsTablesLoading(true)
    Get(
      {},
      Get_AllTables_URL,
      null,
      (resp) => {
        setTabelRows(resp.data);
        setIsTablesLoading(false)
      },
      (error) => {
        console.log(error);
        setIsTablesLoading(false)
      }
    );
  };

  const loadforms = () => {
    setIsFormsLoading(true)
    Get(
      {},
      Get_AllForms_URL,
      null,
      (resp) => {
        setForms(resp?.data);
        setIsFormsLoading(false)
      },
      (error) => {
        console.log(error);
        setIsFormsLoading(false)
      }
    );
  };


  const initialValues = {
    id: "",
    title: "",
    sectionHtml: "",
  }
  const basicValidationSchema = Yup.object().shape({
    title: Yup.string().required("Title is required"),
    sectionHtml: Yup.string().required("Section HTML is required"),
  });
  const defaultValue = editSection ? editSection : initialValues;
  const editorRef = useRef(null);

  return (

    <Dialog fullScreen open={open} onClose={onClose} TransitionComponent={Transition} >
      <AppBar className={classes.appBar}>
        <Toolbar>
          <IconButton edge="start" color="inherit" onClick={onClose} aria-label="close">
            <CloseIcon />
          </IconButton>
          <Typography variant="h6" className={classes.title}>
            {editSection ? "Edit Section" : "Add Section"}
          </Typography>
        </Toolbar>
      </AppBar>
      <Box>
        <Formik
          initialValues={defaultValue}
          validationSchema={basicValidationSchema}
          onSubmit={(values, actions) => {
            actions.setSubmitting(true);
            onSubmit(values, actions)
          }
          }

        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            getFieldProps,
            handleSubmit,
            isSubmitting,
            setFieldValue,
          }) => (
            <Form>
              <Card>
                <CardHeader title="Section" />
                <CardContent>

                  <TextField
                    id="title"
                    fullWidth
                    name="title"
                    value={values.title}
                    label="Subject"
                    variant="outlined"
                    size="small"
                    {...getFieldProps("title")}
                    error={errors.title && touched.title}
                    helperText={errors.title && touched.title && errors.title}
                  />
                  <br />
                  <br />
                  <HtmlEditor
                   
                    text={values.sectionHtml}
                    onChange={(html) => {
                      console.log("jkalhdjkahkdjhaksdj", html)
                      setFieldValue("sectionHtml", html);
                      handleSubmit()
                    }}
                    isLoading={isFormsLoading || isTablesLoading}
                    forms={forms}
                    tables={tabelRows}
                  />
                  <br />
                </CardContent>
              </Card>
            </Form>
          )}
        </Formik>
      </Box>
    </Dialog>
  );
};
export default AddSection;
